import { Badge, type BadgeProps } from '@ctw/shared/components/badge';
import { Button } from '@ctw/shared/components/button';
import { Heading } from '@ctw/shared/components/heading';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type PropsWithChildren, type ReactNode, useLayoutEffect, useRef, useState } from 'react';

export interface DrawerContentProps extends PropsWithChildren {
  label?: string;
  sublabel?: string;
  title?: string;
  subtitle?: string;
  badges?: Array<BadgeProps>;
  isDocked: boolean;
  close: () => void;
  content: {
    header?: ReactNode;
    body: ReactNode;
    footer?: ReactNode;
  };
}

export const DrawerContent = ({
  label,
  sublabel,
  title,
  subtitle,
  badges,
  close,
  content,
}: DrawerContentProps) => {
  const footerContentRef = useRef<HTMLDivElement>(null);
  const [footerContentHasHeight, setFooterContentHasHeight] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (footerContentRef.current) {
      setFooterContentHasHeight(footerContentRef.current.clientHeight > 0);
    }
  }, [footerContentRef.current, content.footer]);

  return (
    <section data-testid="drawer" className={tw`relative flex h-full flex-col`}>
      <Button
        testId="drawer-close-button"
        type="button"
        ariaLabel="Close Drawer"
        variant="circular"
        size="xs"
        className={tw`absolute top-1 right-1 z-navigation-sticky flex items-center justify-center bg-white p-2 text-content-main shadow-none transition-all duration-300 hover:bg-content-subtle/15 active:scale-[.98] active:bg-content-subtle/15`}
        onClick={close}
      >
        <FontAwesomeIcon icon={faXmark} className={tw`h-4 w-4`} />
      </Button>
      {(label ||
        sublabel ||
        title ||
        subtitle ||
        content.header ||
        (badges && badges.length > 0)) && (
        <header
          data-testid="drawer-header"
          className={tw`z-navigation flex flex-col border-divider-main border-b p-2 pr-10 transition-all duration-300`}
        >
          <div className={tw`flex items-center justify-between gap-2`}>
            <div className={tw`flex items-center`}>
              {label && (
                <span
                  data-testid="drawer-label"
                  className={tw`font-semibold text-content-subtle text-xs uppercase`}
                >
                  {label}
                </span>
              )}
              {sublabel && (
                <span
                  data-testid="drawer-sublabel"
                  className={tw`font-medium text-content-subtle text-sm`}
                >
                  <span>&nbsp;&ndash;&nbsp;</span>
                  <span>{sublabel}</span>
                </span>
              )}
            </div>
            <div data-testid="drawer-badges" className={tw`flex gap-2`}>
              {badges &&
                badges.length > 0 &&
                badges.map((badge) => <Badge key={badge.content} {...badge} size="sm" />)}
            </div>
          </div>
          <div>
            {title && (
              <Heading
                level="h2"
                testId="drawer-title"
                className={tw`font-medium text-background-inverse`}
              >
                {title}
              </Heading>
            )}
          </div>
          {sublabel && (
            <span>
              <span data-testid="drawer-subtitle" className={tw`text-sm`}>
                {subtitle}
              </span>
            </span>
          )}
          {content.header && (
            <div className={tw`flex h-[62px] items-center border-divider-main border-b`}>
              {content.header}
            </div>
          )}
        </header>
      )}
      <div
        data-testid="drawer-body"
        className={tw`scroll-shadows flex-1 overflow-y-auto overflow-x-visible p-2`}
      >
        {content.body}
      </div>
      <footer
        data-testid="drawer-footer"
        className={twx('z-navigation border-divider-main border-t transition-all duration-300', {
          'p-2': content.footer && footerContentHasHeight,
        })}
      >
        <div ref={footerContentRef} className={tw`m-full h-full`}>
          {content.footer}
        </div>
      </footer>
    </section>
  );
};
