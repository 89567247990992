import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { Basic, FhirResource, Resource } from 'fhir/r4';
import { createOrEditFhirResource } from './action-helper';
import type { FHIRModel } from './models/fhir-model';
import { getUsersPractitionerReference } from './practitioner';
import { SYSTEM_BASIC_RESOURCE_TYPE, SYSTEM_ZUS_PROFILE_ACTION } from './system-urls';

export async function recordProfileAction<T extends Resource>(
  existingBasic: Basic | undefined,
  model: FHIRModel<T>,
  ctw: CTWState,
  telemetry: ReturnType<typeof useTelemetry>,
  profileAction: string,
) {
  if (!model.id) {
    throw new Error(`Tried to ${profileAction} a resource that hasn't been created yet.`);
  }

  if (model.ownedByBuilder(ctw.requestContext.builderId)) {
    throw new Error(`Tried to ${profileAction} a patient record resource.`);
  }

  const basic: Basic = {
    resourceType: 'Basic',
    id: existingBasic?.id,
    code: {
      coding: [
        {
          system: SYSTEM_BASIC_RESOURCE_TYPE,
          code: 'adminact',
          display: 'Administrative Activity',
        },
        {
          system: SYSTEM_ZUS_PROFILE_ACTION,
          code: profileAction,
        },
      ],
    },
    subject: {
      reference: `${model.resourceType}/${model.id}`,
      type: model.resourceType,
    },
    author: await getUsersPractitionerReference(ctw),
  };

  try {
    const response = (await createOrEditFhirResource(basic, ctw)) as FhirResource;

    if (!response.id) {
      throw new Error(`Failed to ${profileAction} resource with id of ${model.id}`);
    }
  } catch (error) {
    telemetry.trackError({ message: 'Error executing createOrEditFhirResource', error });
    throw error;
  }
}

export async function toggleDismiss<T extends Resource>(
  model: FHIRModel<T>,
  ctw: CTWState,
  telemetry: ReturnType<typeof useTelemetry>,
) {
  const existingBasic = model.getLatestAction(['archive', 'unarchive']);
  const profileAction = model.isDismissed ? 'unarchive' : 'archive';
  model.optimisticToggleIsDismiss();
  await recordProfileAction(existingBasic, model, ctw, telemetry, profileAction);
}

export async function toggleRead<T extends Resource>(
  model: FHIRModel<T>,
  ctw: CTWState,
  telemetry: ReturnType<typeof useTelemetry>,
) {
  const existingBasic = model.getLatestAction(['read', 'unread']);
  const profileAction = model.isRead ? 'unread' : 'read';
  model.optimisticToggleIsRead();
  await recordProfileAction(existingBasic, model, ctw, telemetry, profileAction);
}
