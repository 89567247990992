import { MedicationModel } from '@ctw/shared/api/fhir/models/medication';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { MAX_OBJECTS_PER_REQUEST, fqsRequest } from '@ctw/shared/api/fqs/client';
import type {
  Basic,
  Bundle,
  FhirResource,
  MedicationAdministration,
  MedicationDispense,
  MedicationRequest,
  MedicationStatement,
} from 'fhir/r4';
import {
  cloneDeep,
  compact,
  groupBy,
  last,
  mapValues,
  sortBy,
  takeWhile,
  uniqWith,
} from 'lodash-es';
import { bundleToResourceMap } from './bundle';

import { getIdentifyingRxNormCode } from '@ctw/shared/api/fhir/medication';
import {
  type MedicationAdministrationGraphqlResponse,
  medicationAdministrationQuery,
} from '@ctw/shared/api/fqs/queries/medication-administration';
import {
  type MedicationDispenseGraphqlResponse,
  medicationDispenseQuery,
} from '@ctw/shared/api/fqs/queries/medication-dispense';
import {
  type MedicationRequestGraphqlResponse,
  medicationRequestQuery,
} from '@ctw/shared/api/fqs/queries/medication-request';
import {
  type MedicationStatementGraphqlResponse,
  medicationStatementQuery,
} from '@ctw/shared/api/fqs/queries/medication-statements';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_MEDICATION_HISTORY } from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';
import type { GraphQLClient } from 'graphql-request';
import type { ResourceMap } from './types';

export type InformationSource =
  | 'Patient'
  | 'Practitioner'
  | 'PractitionerRole'
  | 'RelatedPerson'
  | 'Organization';

export type MedicationResults = {
  bundle: Bundle | undefined;
  medications: Array<MedicationStatement>;
  basic: Array<Basic>;
};

export async function getMedicationStatementsForPatientByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  resourceIds: Array<string> | undefined,
): Promise<MedicationResults> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return { bundle: undefined, medications: [], basic: [] };
  }
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    telemetry,
    graphqlClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  const nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
  return { bundle: undefined, medications: nodes, basic: [] };
}

export async function getMedicationAdministrationsForPatientByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  resourceIds: Array<string> | undefined,
): Promise<Array<MedicationAdministration>> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const { data } = await fqsRequest<MedicationAdministrationGraphqlResponse>(
    telemetry,
    graphqlClient,
    medicationAdministrationQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  return data.MedicationAdministrationConnection.edges.map((x) => x.node);
}

export async function getMedicationDispensesForPatientByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  resourceIds: Array<string> | undefined,
): Promise<Array<MedicationDispense>> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const { data } = await fqsRequest<MedicationDispenseGraphqlResponse>(
    telemetry,
    graphqlClient,
    medicationDispenseQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );

  return data.MedicationDispenseConnection.edges.map((x) => x.node);
}

export async function getMedicationRequestsForPatientByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  resourceIds: Array<string> | undefined,
): Promise<Array<MedicationRequest>> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const { data } = await fqsRequest<MedicationRequestGraphqlResponse>(
    telemetry,
    graphqlClient,
    medicationRequestQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  return data.MedicationRequestConnection.edges.map((x) => x.node);
}

export async function getMedicationStatementsByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  medicationStatementIds: Array<string> = [],
) {
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    telemetry,
    graphqlClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      sort: {},
      first: MAX_OBJECTS_PER_REQUEST,
      filter: {
        ids: {
          anymatch: medicationStatementIds,
        },
      },
    },
  );

  return data.MedicationStatementConnection.edges.map(
    (x) => new MedicationStatementModel(x.node, undefined, x.node.BasicList),
  );
}

// Helper function to filter out medications missing RxNorm codes.
export function filterMedicationsWithNoRxNorms(
  medications: Array<MedicationStatement>,
  bundle: FhirResource,
) {
  const resourceMap = bundleToResourceMap(bundle);
  return medications.filter((m) => getIdentifyingRxNormCode(m, resourceMap) !== undefined);
}

// Splits medications into those that the builder already knows about ("Provider Medications"),
// those that they do not know about ("Other Provider Medications"), and those they didn't know
// about originally and then dismissed ("Dismissed Other Provider Medications").
export function splitMedications(
  summarizedMedications: Array<MedicationStatementModel>,
  builderOwnedMedications: Array<MedicationStatementModel>,
) {
  const otherProviderMedications: Array<MedicationStatementModel> = [];
  const firstPartyMedications: Array<MedicationStatementModel> = [];

  summarizedMedications.forEach((lensMed) => {
    const matchingBuilderMeds = builderOwnedMedications.filter((builderMed) =>
      lensMed.aggregatedFromIds.includes(builderMed.id),
    );

    if (matchingBuilderMeds.length === 0) {
      otherProviderMedications.push(lensMed);
      return;
    }

    if (matchingBuilderMeds.length > 0) {
      // Overwrite displays with the builder owned resources.
      const mostRecentBuilderMed = matchingBuilderMeds.reduce((latestMed, currentMed) => {
        const latestDate = new Date(latestMed.resource.meta?.lastUpdated || 0);
        const currentDate = new Date(currentMed.resource.meta?.lastUpdated || 0);

        return currentDate > latestDate ? currentMed : latestMed;
      }, matchingBuilderMeds[0]);

      // Overwrite some fields with most recent builder medication's.
      const updatedLensMed = cloneDeep(lensMed.resource);
      updatedLensMed.medicationCodeableConcept =
        mostRecentBuilderMed.resource.medicationCodeableConcept;
      updatedLensMed.dosage = mostRecentBuilderMed.resource.dosage;
      updatedLensMed.meta = updatedLensMed.meta || {};
      updatedLensMed.meta.tag = mostRecentBuilderMed.resource.meta?.tag;

      firstPartyMedications.push(
        new MedicationStatementModel(updatedLensMed, lensMed.includedResources, lensMed.basics),
      );
    }
  });

  // Include builder-owned medications that haven't been summarized yet.
  // See https://zushealth.slack.com/archives/C027RAKJPC1/p1698159159488389?thread_ts=1698158304.209859&cid=C027RAKJPC1 for reasoning.
  const unaggregatedBuilderMeds = builderOwnedMedications.filter(
    (builderMed) =>
      !summarizedMedications.some((lensMed) => lensMed.aggregatedFromIds.includes(builderMed.id)),
  );
  firstPartyMedications.push(...unaggregatedBuilderMeds);

  return {
    firstPartyMedications,
    otherProviderMedications,
  };
}

export function dedupeMedicationsByActiveIngredient(
  medications: Array<MedicationStatementModel>,
): Array<MedicationStatementModel> {
  const groupsByActiveIngredient: Array<Array<MedicationStatementModel>> = Object.values(
    groupBy(medications, (medication: MedicationStatementModel) => {
      if (medication.activeIngredients.length === 0) {
        return medication.identifyingRxNorm ?? medication.id;
      }
      return medication.activeIngredients
        .map(({ code, system }) => `${system}_${code}`.toLocaleLowerCase())
        .sort()
        .join('|');
    }),
  );

  // Find and return the best MedicationStatement(s) to display from each group of duplicates and set the unused MedicationStatements
  // as "duplicatedBy" on the returned ones so we are still able to query FQS for the complete detailed history.
  return groupsByActiveIngredient.flatMap((group) => {
    const updateDuplicatedBy = (medication: MedicationStatementModel) => {
      const duplicates = group.filter((med) => med.id !== medication.id);
      medication.setDuplicatedBy(duplicates);
      return medication;
    };

    // Try to sort by last fill date
    const sortedByFill = sort(group, 'lastFillDateISO', 'desc', true);
    const { lastFillDate } = sortedByFill[0];
    if (lastFillDate) {
      // If multiple medications have the same fill date we should not dedupe them
      return takeWhile(sortedByFill, (med) => med.lastFillDate === lastFillDate).map(
        updateDuplicatedBy,
      );
    }

    // If we don't have a last fill date, sort by prescribed date
    const sortedByPrescribed = sort(group, 'lastPrescribedDateISO', 'desc', true);
    const { lastPrescribedDate } = sortedByPrescribed[0];
    if (lastPrescribedDate) {
      return updateDuplicatedBy(sortedByPrescribed[0]);
    }

    // Last resort, return the latest date asserted
    const medicationWithLatestAssertedDate = sort(group, 'dateAssertedISO', 'desc', true)[0];
    return updateDuplicatedBy(medicationWithLatestAssertedDate);
  });
}

export function useMedicationHistory(medication?: MedicationStatementModel) {
  return usePatientQuery({
    queryId: QUERY_KEY_MEDICATION_HISTORY,
    queryKey: [medication?.id],
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      if (!medication) {
        return { medications: [], includedResources: {} as ResourceMap };
      }
      try {
        const groups = groupBy(medication.aggregatedFrom, 'type');
        const resources = mapValues(groups, (group) =>
          compact(group.map((g) => last(g.reference?.split('/')))),
        );

        const [
          medicationStatementResponse,
          medicationAdministrationResponse,
          medicationRequestResponse,
          medicationDispenseResponse,
        ] = await Promise.all([
          getMedicationStatementsForPatientByIdFQS(
            telemetry,
            graphqlClient,
            patient,
            resources.MedicationStatement,
          ),
          getMedicationAdministrationsForPatientByIdFQS(
            telemetry,
            graphqlClient,
            patient,
            resources.MedicationAdministration,
          ),
          getMedicationRequestsForPatientByIdFQS(
            telemetry,
            graphqlClient,
            patient,
            resources.MedicationRequest,
          ),
          getMedicationDispensesForPatientByIdFQS(
            telemetry,
            graphqlClient,
            patient,
            resources.MedicationDispense,
          ),
        ]);
        let medicationResources = compact([
          ...medicationStatementResponse.medications,
          ...medicationAdministrationResponse,
          ...medicationRequestResponse,
          ...medicationDispenseResponse,
        ]).map((m) => new MedicationModel(m));

        // force FQS results to be sorted by id just like ODS results to ensure both functions return the same output.
        // TODO: Remove once the ODS code path no longer exists.
        medicationResources = sortBy(medicationResources, (a) => a.resource.id);

        const medications = sort(
          uniqWith(
            medicationResources,
            (a, b) => a.date === b.date && a.resource.resourceType === b.resource.resourceType,
          ),
          'date',
          'desc',
          true,
        );
        return { medications, includedResources: {} as ResourceMap };
      } catch (e) {
        throw new Error(`Failed fetching medication history for medication ${medication.id}: ${e}`);
      }
    },
  });
}
