import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { tw } from '@ctw/shared/utils/tailwind';
import { useCallback } from 'react';
import { type OverviewResources, PatientOverview } from './patient-overview';

export const PatientOverviewZap = () => {
  const { navigate } = useZuiContext();

  const handleSeeAllResources = useCallback(
    (resource: OverviewResources) => {
      navigate(resource);
    },
    [navigate],
  );

  return <PatientOverview className={tw`pt-5`} onSeeAllResources={handleSeeAllResources} />;
};
