import type { Citation } from '@ctw/shared/api/ai/types';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useCallback } from 'react';
import { CitationLink } from './citation-link';

export type CitationTextProps = {
  className?: string;
  content: string;
  citations?: Array<Citation>;
};

export const CitationText = ({ content, citations }: CitationTextProps) => {
  const { trackInteraction } = useTelemetry();

  const handleSelection = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      return;
    }

    // Get the selected text's container element
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer.parentElement;

    // Find the closest citation data by looking up the DOM tree
    let currentElement = container;
    let citationData: Citation | undefined;
    while (currentElement && !citationData) {
      const citationId = currentElement.getAttribute?.('data-citation-id');
      if (citationId && citations) {
        citationData = citations.find((c) => c.id === Number(citationId));
      }
      currentElement = currentElement.parentElement;
    }

    trackInteraction('select_gps_narrative_text', {
      resourceId: citationData?.source_id,
      resourceType: citationData?.source_resource_type,
    });
  }, [citations, trackInteraction]);

  // break up the content into parts based on the citation references, breaks, etc
  const parts = content.split(/(\[\d+\]|<br>)/g).filter((part) => part.length > 0);

  // Remove leading/trailing breaks which happen sometimes
  while (parts.length > 0 && parts[0] === '<br>') {
    parts.shift();
  }
  while (parts.length > 0 && parts.at(-1) === '<br>') {
    parts.pop();
  }

  // only happens if there's an error in the API call
  if (!citations || citations.length === 0) {
    return <span>{content}</span>;
  }

  return (
    <div onMouseUp={handleSelection}>
      {parts.map((part) => {
        const citation = citations.find((c) => `[${c.id}]` === part);
        if (citation) {
          return (
            <span data-citation-id={citation.id} key={`citation_${part}${citation.id}`}>
              <CitationLink
                resourceInfo={{
                  resourceID: citation.source_id,
                  resourceType: citation.source_resource_type,
                }}
                citation={citation}
              />
            </span>
          );
        }

        if (part === '<br>') {
          return <br key={`br_${part}`} />;
        }
        return <span key={`span_${part}`}>{part}</span>;
      })}
    </div>
  );
};
