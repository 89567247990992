import { useRegisterPresence } from '@ctw/shared/context/zui-provider';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type LoadingSpinnerProps = {
  className?: string;
  iconClass?: string;
  message?: string;
  centered?: boolean;
};

export const LoadingSpinner = ({
  className = 'text-sm',
  iconClass,
  message,
  centered,
}: LoadingSpinnerProps) => {
  useRegisterPresence('loadingSpinner');

  return (
    <div
      style={{ display: 'none' }} /* Hide the loading spinner until Tailwind loads */
      className={twx('!flex', {
        /* Show the loading spinner once Tailwind loads */
        'h-full min-h-full w-full min-w-full items-center justify-center': centered,
      })}
    >
      <div
        className={twx(
          'flex items-center justify-center space-x-2',
          {
            'h-full min-h-full w-full min-w-full flex-1': centered,
          },
          className,
        )}
      >
        <Spinner className={twx(iconClass)} />
        {message && <span className={tw`italic`}>{message}</span>}
      </div>
    </div>
  );
};

interface SpinnerProps {
  className?: string;
}

const Spinner = ({ className }: SpinnerProps) => (
  <svg
    className={twx('inline h-4 w-4 animate-spin', className)}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <title>Loading</title>
    <circle
      className={tw`opacity-25`}
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className={tw`opacity-75`}
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);
