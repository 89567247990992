import { Viewer } from '@react-pdf-viewer/core';
import { DOMParser } from '@xmldom/xmldom';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import xpath from 'xpath';
import { CcdaViewer } from './ccda-viewer';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './styles.scss';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { Alert } from '@ctw/shared/components/alert';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tiffToPNG } from '@ctw/shared/content/CCDA/tiff-to-png';
import { tw } from '@ctw/shared/utils/tailwind';

const xmlTypes = ['/xml', '/xhtml+xml', 'application/xml', 'text/xml'];
const tiffTypes = ['/tif', '/tiff'];
const webImageTypes = ['/jpg', '/jpeg', '/png', '/apng', '/avif', '/gif', '/webp', '/svg+xml'];

const isSpecificContentType = (extensions: Array<string>, contentType: string) =>
  extensions.some((extension) => contentType.includes(extension));

interface BinaryFieldProps {
  data: string;
  blob: Blob;
  contentType: string;
  fileName: string | undefined;
  onClose?: () => void;
  patient: PatientModel;
}

export const BinaryField = ({ data, blob, contentType, patient }: BinaryFieldProps) => {
  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer>();

  useEffect(() => {
    if (isSpecificContentType(tiffTypes, contentType)) {
      // Convert blob to an array buffer, which is used only for tiff files atm.
      void (async () => {
        setArrayBuffer(await blob.arrayBuffer());
      })();
    }
  }, [blob, contentType]);

  const ccdaDoc: ReactNode = useMemo((): ReactNode => {
    if (isSpecificContentType(tiffTypes, contentType)) {
      return <PreviewTiffImg data={arrayBuffer} />;
    }

    if (isSpecificContentType(webImageTypes, contentType)) {
      const url = URL.createObjectURL(blob);
      return (
        <img
          src={url}
          alt=""
          data-testid="binary-field-img"
          className={tw`h-full max-h-[95vh] w-auto object-contain`}
        />
      );
    }

    if (contentType.includes('/pdf')) {
      return <PreviewPdf data={blob} />;
    }

    if (isSpecificContentType(xmlTypes, contentType)) {
      const xmlDocument = new DOMParser({
        locator: {},
        errorHandler: () => null,
      }).parseFromString(data, contentType);
      if (xpath.select1("//*[name()='patientRole']", xmlDocument)) {
        return xmlDocument as unknown as ReactNode;
      }
    }

    return undefined;
  }, [contentType, data, arrayBuffer, blob]);

  return (
    <>
      {ccdaDoc && isSpecificContentType(xmlTypes, contentType) ? (
        <CcdaViewer document={ccdaDoc as unknown as Document} patient={patient} />
      ) : (
        <div className={tw`w-full space-y-4`}>
          <div
            className={tw`${
              ccdaDoc ? 'flex flex-col items-center p-4' : 'ccda-base64-binary-text'
            }`}
          >
            {ccdaDoc ?? data}
          </div>
        </div>
      )}
    </>
  );
};

function PreviewTiffImg({ data }: { data?: ArrayBuffer }) {
  if (!data) {
    return <LoadingSpinner message="Loading..." />;
  }

  const { dataUrl, description } = tiffToPNG(data);
  return (
    <>
      <img
        src={dataUrl}
        alt={description}
        data-testid="binary-field-img"
        className={tw`h-full max-h-[95vh] w-auto`}
      />
      <p className={tw`text-content-icon text-sm leading-relaxed`}>{description}</p>
    </>
  );
}

function PreviewPdf({ data }: { data: Blob }) {
  try {
    const url = URL.createObjectURL(data);
    return (
      <div className={tw`pdf-viewer w-[450px] min-w-full sm:w-[645px] md:w-[750px] lg:w-[960px]`}>
        <Viewer fileUrl={url} />
      </div>
    );
  } catch (e) {
    // biome-ignore lint/suspicious/noConsole: <explanation>
    console.warn(e);
    return (
      <Alert type="error" header="Error loading PDF">
        Unable to preview this PDF file in the web browser.
      </Alert>
    );
  }
}

export function downloadBlob(blob: Blob, fileName?: string) {
  const fileUrl = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = fileUrl;

  tempLink.download = fileName || '';
  document.body.appendChild(tempLink);

  tempLink.click();

  document.body.removeChild(tempLink);

  setTimeout(() => {
    URL.revokeObjectURL(fileUrl);
  }, 100);
}
