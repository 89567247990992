import type { Observation, Provenance } from 'fhir/r4';
import { gql } from 'graphql-request';
import type { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentObservation, fragmentReference } from './fragments';

export type ObservationWithProvenance = Observation & {
  ProvenanceList: Array<Provenance>;
};

export interface ObservationConnection {
  pageInfo: GraphqlPageInfo;
  edges: Array<GraphqlConnectionNode<ObservationWithProvenance>>;
}

export interface ObservationGraphqlResponse {
  ObservationConnection: ObservationConnection;
}

export const observationQuery = gql`
  ${fragmentReference}
  ${fragmentObservation}

  query Observations(
    $upid: ID!
    $cursor: String!
    $sort: ObservationSortParams!
    $filter: ObservationFilterParams!
    $first: Int!
  ) {
    ObservationConnection(
      upid: $upid
      after: $cursor
      filter: $filter
      sort: $sort
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Observation
        }
      }
    }
  }
`;
