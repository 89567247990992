import type { Basic, Encounter, Provenance } from 'fhir/r4';
import { gql } from 'graphql-request';
import type { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentEncounter } from './fragments/encounter';

export interface EncounterConnection {
  pageInfo: GraphqlPageInfo;
  edges: Array<GraphqlConnectionNode<EncounterWithReferences>>;
}

export type EncounterWithReferences = Encounter & {
  ProvenanceList: Array<Provenance>;
  BasicList: Array<Basic>;
};

export interface EncounterGraphqlResponse {
  EncounterConnection: EncounterConnection;
}

export const encountersQuery = gql`
  ${fragmentEncounter}
  query Encounters(
    $upid: ID!
    $cursor: String!
    $sort: EncounterSortParams!
    $first: Int!
    $filter: EncounterFilterParams! = {}
  ) {
    EncounterConnection(upid: $upid, after: $cursor, sort: $sort, first: $first, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Encounter
        }
      }
    }
  }
`;
