import { tw } from '@ctw/shared/utils/tailwind';
import DOMPurify from 'dompurify';
import { Interweave } from 'interweave';

export function getNoteDisplay(noteText: string | undefined) {
  if (noteText === undefined) {
    return undefined;
  }
  const cleanNote = DOMPurify.sanitize(noteText);

  return (
    <div className={tw`overflow-auto`}>
      <Interweave content={cleanNote} />
    </div>
  );
}
