import {
  type BinaryData,
  useGetBinaryDocument,
  useGetPatientFromBinaryId,
} from '@ctw/shared/api/fhir/binaries';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type MouseEvent, useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BinaryField, downloadBlob } from './binary-field';
import { CCDAModalBase } from './ccda-modal-base';

type ViewDocumentProps = {
  binaryId: string;
  fileName: string | undefined;
  showPrintBtn?: boolean;
  onClose?: () => void;
  patient?: PatientModel;
};

export const ViewDocument = ({
  binaryId,
  fileName,
  onClose,
  patient: initialPatient,
}: ViewDocumentProps) => {
  const { data, isLoading } = useGetBinaryDocument(binaryId);
  const patientQuery = useGetPatientFromBinaryId(binaryId, !initialPatient);
  const patient = initialPatient ?? patientQuery.data;

  if (patientQuery.isLoading || !patient) {
    return <LoadingSpinner message="Loading..." centered={true} />;
  }

  return (
    <BinaryContent
      isLoading={isLoading}
      rawBinary={data?.binary}
      fileName={fileName}
      binaryId={binaryId}
      onClose={onClose}
      patient={patient}
    />
  );
};

export type BinaryContentProps = {
  isLoading: boolean;
  rawBinary: BinaryData['binary'] | undefined;
  fileName: string | undefined;
  onClose?: () => void;
  patient: PatientModel;
  binaryId: string;
};

const BinaryContent = ({
  isLoading,
  rawBinary,
  fileName,
  onClose,
  patient,
  binaryId,
}: BinaryContentProps) => {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: fileName,
    contentRef,
  });

  if (isLoading) {
    return (
      <CCDAModalBase onClose={onClose} patient={patient} binaryId={binaryId}>
        <LoadingSpinner message="Loading..." className={tw`flex justify-center p-12`} />
      </CCDAModalBase>
    );
  }

  if (!rawBinary?.data) {
    return (
      <CCDAModalBase onClose={onClose} patient={patient} binaryId={binaryId}>
        <Alert type="error" header="Unable to view document" className={tw`justify-center`}>
          The document could not be loaded.
        </Alert>
      </CCDAModalBase>
    );
  }

  const downloadButton = (
    <DownloadButtons
      fileName={fileName}
      contentType={rawBinary.contentType}
      blob={rawBinary.blob}
      handlePrint={handlePrint}
    />
  );

  return (
    <div
      className={tw`ccda-container ccda-styles`}
      ref={contentRef}
      data-testid="ccda-viewer"
      id="ccda-viewer"
    >
      <CCDAModalBase
        onClose={onClose}
        actions={downloadButton}
        patient={patient}
        binaryId={binaryId}
      >
        <BinaryField
          data={rawBinary.data}
          blob={rawBinary.blob}
          contentType={rawBinary.contentType}
          fileName={fileName}
          onClose={onClose}
          patient={patient}
        />
      </CCDAModalBase>
    </div>
  );
};

export type DownloadButtonsProps = {
  fileName: string | undefined;
  contentType: string;
  blob: Blob;
  handlePrint: ReturnType<typeof useReactToPrint>;
};

function DownloadButtons({ fileName, contentType, blob, handlePrint }: DownloadButtonsProps) {
  const downloadDocument = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // Don't want the click event to bubble up to the parent element (the anchor tag).
      event.stopPropagation();
      // Use the helper function to download the blob.
      downloadBlob(blob, fileName);
    },
    [blob, fileName],
  );

  return (
    <div className={tw`flex justify-between gap-2 print:hidden`}>
      <Button
        aria-label="Download"
        type="button"
        size="sm"
        variant="circular"
        className={tw`flex items-center justify-center bg-content-main p-3 hover:bg-content-subtle/25 active:bg-content-subtle/25`}
        onClick={downloadDocument}
      >
        <ContentTypeIcon contentType={contentType} className={tw`h-5 w-5 fill-white`} />
      </Button>

      <Button
        aria-label="Print"
        type="button"
        size="sm"
        variant="circular"
        className={tw`items-center justify-center bg-content-main p-3 hover:bg-content-subtle/25 active:bg-content-subtle/25`}
        onClick={() => handlePrint()}
      >
        <FontAwesomeIcon icon={faPrint} className={tw`h-4 w-4 text-white`} />
      </Button>
    </div>
  );
}
