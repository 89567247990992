import { ZapVersions } from '@ctw/shared/context/zui-provider';
import { trimStart } from 'lodash-es';

export const pathWithParameters = (url: string, parameters: Record<string, unknown>): string => {
  const newUrl = new URL(url, `${window.location.protocol}//${window.location.host}`);

  Object.entries(parameters).forEach(([key, value]) => {
    if (value === null) {
      newUrl.searchParams.delete(key);
    } else {
      newUrl.searchParams.set(key, String(value));
    }
  });

  let newPathname = newUrl.pathname;
  if (
    newPathname.startsWith('/zap') ||
    ZapVersions.some((zapVersion) => newPathname.startsWith(`/${zapVersion}/`))
  ) {
    newPathname = newPathname.substring(newPathname.indexOf('/', 1));
  }

  return `${newPathname}${newUrl.search}${newUrl.hash}`;
};

interface HrefIsActiveOptions {
  href: string;
  pathname: string;
}

export const hrefIsActive = ({ href, pathname }: HrefIsActiveOptions): boolean => {
  const nonRelativeHref = href.replaceAll('.', '');
  const normalizedHref = trimStart(
    nonRelativeHref[0] === '/' ? nonRelativeHref.slice(1) : nonRelativeHref,
  );

  return pathname.includes(`${normalizedHref}`);
};
