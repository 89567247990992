import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const defaultCareGapSort: SortOption<CareGapModel> = {
  display: 'Last Updated Date (New to Old)',
  sorts: [{ key: 'updatedDate', dir: 'desc', isDate: true }],
};

export const careGapSortOptions: Array<SortOption<CareGapModel>> = [
  defaultCareGapSort,
  {
    display: 'Last Updated Date (Old to New)',
    sorts: [{ key: 'updatedDate', dir: 'asc', isDate: true }],
  },
];
