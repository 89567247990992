import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { faCalendarDay, faEnvelope, faPhone, faUser } from '@fortawesome/pro-solid-svg-icons';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';

export const usePatientHeaderFields = () => {
  const { patient } = usePatientContext();

  return useMemo(
    () => [
      {
        icon: faCalendarDay,
        label: 'Birth date',
        value: `${patient.dob} (${patient.age})`,
      },
      {
        icon: faUser,
        label: 'Gender',
        value: capitalize(patient.gender ?? 'Unknown'),
        collapseToIconOnlyUnder: 'md',
        hideUnder: 'sm',
      },
      {
        icon: faPhone,
        label: 'Phone number',
        value: patient.phoneNumber ?? '',
        collapseToIconOnlyUnder: 'md',
        hideUnder: 'sm',
      },
      {
        icon: faEnvelope,
        label: 'Email address',
        value: patient.email ?? '',
        collapseToIconOnlyUnder: 'md',
        hideUnder: 'sm',
      },
    ],
    [patient.dob, patient.age, patient.email, patient.gender, patient.phoneNumber],
  );
};
