import { getBinaryDocument } from '@ctw/shared/api/fhir/binaries';
import { usePatientRenderableDocuments } from '@ctw/shared/api/fhir/document';
import type { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import type { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useCCDAModal } from '@ctw/shared/content/CCDA/modal-ccda';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useDocumentReferenceDrawer } from '@ctw/shared/hooks/use-document-reference-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw } from '@ctw/shared/utils/tailwind';
import type { Binary } from 'fhir/r4';
import { useMemo } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { getDateRangeView } from '../resource/view-date-range';
import { patientDocumentColumns } from './helpers/columns';
import { documentsFilter } from './helpers/filters';
import { defaultDocumentSort, documentSortOptions } from './helpers/sorts';

export const PatientDocuments = withErrorBoundary({
  boundaryName: 'PatientDocuments',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { patient } = usePatientContext();
    const openCCDA = useCCDAModal(patient);
    const validFilters = useMemo(() => documentsFilter(), []);
    const patientDocumentQuery = usePatientRenderableDocuments();
    const rowActions = useRowActions();
    const { viewOptions, allTime } = useMemo(
      () => getDateRangeView<DocumentModel>('dateCreated'),
      [],
    );

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-documents',
        defaultSort: defaultDocumentSort,
        viewOptions,
        defaultView: allTime.display,
        records: patientDocumentQuery.data,
      });
    const isEmptyQuery = patientDocumentQuery.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: documentSortOptions,
          onChange: setSort,
        }}
        viewOptions={{
          onChange: setViewOption,
          options: viewOptions,
          defaultView,
        }}
      />,
      [validFilters, filters, defaultSort, documentSortOptions, viewOptions, defaultView],
    );

    return (
      <div>
        <ResourceTable
          stackedBreakpoint={'sm'}
          isLoading={patientDocumentQuery.isLoading}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="documents"
            />
          }
          columns={patientDocumentColumns(requestContext.builderId)}
          onRightClick={(document) => {
            if (!document.binaryId) {
              return;
            }

            const url = `/ccda/${document.binaryId}`;
            window.open(url, '_blank');
          }}
          onRowClick={(document) => {
            if (!document.binaryId) {
              return;
            }
            openCCDA(document.binaryId, document.resourceTypeTitle);
          }}
          rowActions={rowActions}
          enableDismissAndReadActions={true}
        />
      </div>
    );
  },
});

const useDocumentDrawerActions = () => {
  const { patient } = usePatientContext();
  const openCCDA = useCCDAModal(patient);

  return (record: DocumentModel): RowActionsConfigProp<DocumentModel> => {
    const actions: RowActionsConfigProp<DocumentModel> = [];

    if (record.binaryId) {
      actions.push({
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text fill-white ml-1 capitalize',
        testId: 'preview-document',
        text: 'Preview',
        render: () => (
          <span className={tw`space-x-1.5`}>
            <span className={tw`relative inline-block w-4 pr-4 align-middle`}>
              <ContentTypeIcon
                contentType={record.contentType ?? ''}
                className={tw`-top-2.5 absolute left-0 h-4 w-4`}
              />
            </span>
            <span>Preview</span>
          </span>
        ),
        onClick: () => {
          openCCDA(record.binaryId as string, record.resourceTypeTitle);
        },
      });
    }

    return actions;
  };
};

function useRowActions(onAddToRecord?: (document: DocumentModel, binary: Binary) => void) {
  const documentActions = useDocumentDrawerActions();
  const { trackInteraction } = useTelemetry();
  const { fetchFromFqs } = useCTW();
  const { openDrawer } = useDocumentReferenceDrawer({ resourceActions: documentActions as never });

  return (record: DocumentModel): RowActionsConfigProp<DocumentModel> => {
    const { binaryId } = record;
    const actions: RowActionsConfigProp<DocumentModel> = [];

    actions.push({
      className:
        'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover ml-1 capitalize',
      testId: 'view-details',
      text: 'View Details',
      onClick: () => {
        openDrawer({ model: record });
      },
    });

    if (binaryId && onAddToRecord) {
      actions.push({
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text ml-1 capitalize',
        testId: 'add-to-record',
        text: 'Add to Record',
        onClick: async () => {
          const { binary } = await getBinaryDocument(fetchFromFqs, binaryId);
          onAddToRecord(record, binary);
          trackInteraction('add_to_record');
        },
      });
    }

    return actions;
  };
}
