import type { CTWState } from '@ctw/shared/context/ctw-context';
import { getZusApiBaseUrl } from './urls';

export type PatientHistoryResponseError = {
  // TODO: Can code be a list of status codes? Do we have that type defined anywhere.
  code: number;
  title: string;
  details: string;
};

export const schedulePatientHistory = async (
  ctw: CTWState,
  resultData: { id: string; npi: string; role: string; name: string },
) => {
  const endpointUrl = `${getZusApiBaseUrl(
    ctw.requestContext.env,
    // If patientID is empty, just pass any non-identifying string in url.
  )}/patient-history/jobs`;

  const body = {
    data: {
      type: 'patient-history/jobs',
      attributes: {
        requestConsent: true,
        priority: true,
        practitioner: {
          npi: resultData.npi,
          name: resultData.name,
          role: resultData.role.toLocaleLowerCase(),
        },
      },
      relationships: {
        patient: {
          data: { type: 'fhir/Patient', id: resultData.id },
        },
      },
    },
  };

  const { data } = await ctw.ctwFetch(endpointUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${ctw.requestContext.authToken}`,
      'Zus-Account': ctw.requestContext.builderId,
    },
    body: JSON.stringify(body),
  });

  return data;
};
