import { getContactDetails } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { GeneralInfo, LabelValueType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { isEmpty } from 'lodash-es';
import xpath from 'xpath';
import { getGuardian as getGuardianFromHeader } from '../../Header/data/getGuardian';

export const getGuardian = (patientRole: Document): Array<GeneralInfo> | undefined => {
  const guardians = xpath.select(
    "//*[name()='patientRole']/*[name()='patient']/*[name()='guardian']",
    document,
  ) as Array<Document> | undefined;

  if (!guardians || isEmpty(guardians)) {
    return undefined;
  }

  const guardianNames = getGuardianFromHeader(patientRole) as Array<LabelValueType>;

  return guardians.map((guardian, index) => {
    const contactDetails = getContactDetails(
      xpath.select("*[name()='addr']", guardian) as Array<Document>,
      xpath.select("*[name()='telecom']", guardian) as Array<Document>,
    );

    return { name: guardianNames[index].value, contactDetails };
  });
};
