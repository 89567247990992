import { getEHRIntegrationServiceBaseUrl } from '@ctw/shared/api/urls';
import { notify } from '@ctw/shared/components/toast';
import {
  type CreateOrEditConditionFormData,
  getChronicity,
} from '@ctw/shared/content/forms/actions/conditions';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { type EhrWriteResponse, useWritebacks } from '@ctw/shared/context/writeback-provider';
import { pickBy } from 'lodash-es';
import { useCallback } from 'react';

export function useConditionWrite() {
  const { requestContext, onResourceSave } = useCTW();
  const { env } = requestContext;
  const { patientID, systemURL } = usePatientContext();
  const { onWritebackSuccess } = useWritebacks();

  if (!(patientID && systemURL)) {
    throw new Error('Patient ID or system URL not found');
  }

  const conditionWriteRequest = useCallback(
    async (condition: CreateOrEditConditionFormData): Promise<EhrWriteResponse> => {
      const { builderId } = requestContext;
      if (!builderId) {
        throw new Error('Builder ID not found');
      }

      if (!condition.condition.code) {
        throw new Error('Condition needs to have a snomed code');
      }

      const body = {
        data: {
          type: 'condition',
          attributes: pickBy({
            snomedCode: condition.condition.code,
            chronicity: getChronicity(condition.type),
            status: condition.status.toLowerCase(),
            onsetDate: condition.onset?.toISOString(),
            abatementDate: condition.abatement?.toISOString(),
            recordedDate: new Date().toISOString(),
            description: condition.condition.display,
            note: condition.note,
            ehrContext: requestContext.ehrContext?.ah_department
              ? { ah_department: requestContext.ehrContext.ah_department }
              : undefined,
          }),
          relationships: {
            patient: {
              data: {
                type: 'fhir/Patient',
                identifierSystemURL: systemURL,
                identifierValue: patientID,
              },
            },
          },
        },
      };

      const url = `${getEHRIntegrationServiceBaseUrl(env)}/condition`;

      const headers: Record<string, string> = {
        Authorization: `Bearer ${requestContext.authToken}`,
        'Content-Type': 'application/json',
        'Zus-Account': builderId,
      };

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
      });

      if (!response.ok) {
        const error = await response.json();
        notify({
          type: 'error',
          title: `Error adding ${condition.condition.display} to chart.`,
          body: error.data.errors.message,
        });
      }

      const writebackResponse = (await response.json()) as EhrWriteResponse;
      onWritebackSuccess(condition.id, writebackResponse);
      onResourceSave(
        {
          resourceType: 'Condition',
          id: condition.id,
        },
        'create',
      );

      return writebackResponse;
    },
    [env, requestContext, onWritebackSuccess, patientID, systemURL, onResourceSave],
  );

  return conditionWriteRequest;
}
