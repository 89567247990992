import { getMeasureWithDefaultsFromCanonicalUrlMap } from '@ctw/shared/api/fhir/mappings/canonical-measures';
import { type CareGapEvidence, CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { searchAllRecords, searchFirstPartyRecords } from '@ctw/shared/api/fqs-rest/search-helpers';
import type { CTWRequestContext, CTWState } from '@ctw/shared/context/ctw-context';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_CARE_GAPS } from '@ctw/shared/utils/query-keys';
import type { MeasureReport } from 'fhir/r4';
import { find, groupBy, mapValues, sortBy, uniq, uniqBy } from 'lodash-es';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

// TODO: We're using FQS/REST here, while most other resources use FQS/GraphQL.  This results in an
// extra network request.  We should consider converting this to use FQS/GraphQL, or decide for sure
// that we're OK with the extra calls.
export const usePatientCareGaps = () =>
  usePatientQuery({
    queryId: QUERY_KEY_PATIENT_CARE_GAPS,
    queryFn: async ({ requestContext, ctwFetch, patient }) =>
      searchCareGaps(ctwFetch, requestContext, {
        upid: patient.UPID ?? '',
        _count: '200',
      }),
  });

export async function getCareGapByMeasureIdFromFQS(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patient: PatientModel,
  measureId: string,
): Promise<CareGapModel | undefined> {
  const allCareGaps = await searchCareGaps(ctwFetch, requestContext, {
    upid: patient.UPID ?? '',
    _count: '200',
  });

  return find(allCareGaps, { id: measureId });
}

// This is a local function to unify all the various "here's a search string, go get a list of
// MeasureReports" actions
async function searchCareGaps(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  searchParams: Record<string, string>,
) {
  const measureReports = (await searchFirstPartyRecords(
    ctwFetch,
    {
      env: requestContext.env,
      builderId: requestContext.builderId,
      authToken: requestContext.authToken,
    },
    'MeasureReport',
    searchParams,
  )) as Array<MeasureReport>;

  // Fetch a Measure for each `MeasureReport.measure` we care about.
  const measures = await fetchMeasures(ctwFetch, requestContext, uniqBy(measureReports, 'measure'));

  // Fetch all diagnostic reports in the evaluatedResource fields.
  const diagnosticReports = await fetchDiagnosticReports(ctwFetch, requestContext, measureReports);

  // CareGapModels created with de-duped MeasureReports by measure url
  return Object.values(groupBy(measureReports, 'measure'))
    .map((reports: Array<MeasureReport>) => {
      const measureWithDefaults = getMeasureWithDefaultsFromCanonicalUrlMap(
        reports[0].measure,
        requestContext.env,
      );
      const measure = find(measures, { id: measureWithDefaults.id }) ?? measureWithDefaults;
      const evidence = createEvidenceForMeasureReports(reports, diagnosticReports);

      return new CareGapModel(measure, reports, evidence);
    })
    .sort(
      // Sort by title first, then by report date
      (a: CareGapModel, b: CareGapModel) =>
        a.title.localeCompare(b.title) || Date.parse(b.updatedDate) - Date.parse(a.updatedDate),
    );
}

const createEvidenceForMeasureReports = (
  mReports: Array<MeasureReport>,
  dReports: Array<DiagnosticReportModel>,
): CareGapEvidence => {
  const initialEvidence: CareGapEvidence = {};
  return mReports.reduce((evidence, mReport) => {
    const diagReportsForMeasReport = dReports.filter((dr) =>
      mReport.evaluatedResource?.some(
        (resource) => resource.reference === `DiagnosticReport/${dr.id}`,
      ),
    );
    return {
      ...evidence,
      [mReport.id ?? '']: diagReportsForMeasReport,
    };
  }, initialEvidence);
};

// Note: This logic expects that the `MeasureReport` resource has a `measure` field that is a
// full URL pointing to a specific `Measure` resource.  This is *not* a Canonical URL as described
// in the FHIR spec (our ODS service doesn't support searching for Measures by the url field, so
// we use this as a workaround.)
async function fetchMeasures(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  measureReports: Array<MeasureReport>,
) {
  const measureIds = measureReports
    .map(({ measure }) => getMeasureWithDefaultsFromCanonicalUrlMap(measure, requestContext.env).id)
    .filter((measureId) => UUID_REGEX.test(measureId ?? ''));

  const measureSearchParams = {
    _id: uniq(measureIds).join(','),
  };
  return searchFirstPartyRecords(
    ctwFetch,
    {
      env: requestContext.env,
      builderId: requestContext.builderId,
      authToken: requestContext.authToken,
    },
    'Measure',
    measureSearchParams,
  );
}

// Each MeasureReport may have references in the evaluatedResource field.  Some of those references
// may be to DiagnosticReport resources.  We look through all the MeasureReports, extract the
// references to the DiagnosticReports, and then fetch them.
async function fetchDiagnosticReports(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  measureReports: Array<MeasureReport>,
) {
  const diagnosticReportIds = measureReports
    .flatMap((measureReport) =>
      measureReport.evaluatedResource?.map((resource) => resource.reference),
    )
    .filter((reference) => `${reference}`.startsWith('DiagnosticReport/'))
    .map((reference) => `${reference}`.split('/')[1]);

  if (diagnosticReportIds.length === 0) {
    return [];
  }

  const diagnosticReportSearchParams = {
    _id: uniq(diagnosticReportIds).join(','),
  };
  const fhirReports = await searchAllRecords(
    ctwFetch,
    requestContext,
    'DiagnosticReport',
    diagnosticReportSearchParams,
  );

  return fhirReports.map((report) => new DiagnosticReportModel(report));
}

// Returns the most recent report for each unique gap title.
export function mostRecentGaps(careGaps: Array<CareGapModel>) {
  // Group the gaps by their titles
  const groupedCareGaps = groupBy(careGaps, 'title');
  // Grab the newest gap from each group by report date.
  const newestGaps = mapValues(
    groupedCareGaps,
    (gaps) => gaps.sort((a, b) => Date.parse(b.updatedDate) - Date.parse(a.updatedDate))[0],
  );

  return sortBy(newestGaps, (gap) => gap.title);
}
