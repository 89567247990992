import { tw, twx } from '@ctw/shared/utils/tailwind';
import './pagination';
import { Button } from '@ctw/shared/components/button';

export const DEFAULT_PAGE_SIZE = 10;

export type ExpandListProps = {
  className?: string;
  total: number;
  count: number;
  pageSize?: number;
  changeCount: (amount: number) => void;
};

export const ExpandList = ({
  total,
  count,
  pageSize = DEFAULT_PAGE_SIZE,
  className,
  changeCount,
}: ExpandListProps) => {
  const allShown = count >= total || total === 0;
  const hasPages = total > pageSize;

  return (
    <div
      className={twx(
        '!mt-1 sm:!mt-2 flex h-7 justify-end space-x-3 px-2 pr-4 text-content-subtle',
        className,
      )}
    >
      <div className={tw`text-background-tooltip text-sm`}>
        Showing <span className={tw`font-medium`}>{Math.min(count, total)}</span> of{' '}
        <span className={tw`font-medium`}>{total}</span> records
      </div>

      {(!allShown || hasPages) && (
        <div className={tw`leading-5`}>
          {!allShown && (
            <Button
              type="button"
              variant="link"
              className={tw`whitespace-nowrap`}
              onClick={() => changeCount(total)}
            >
              Show All
            </Button>
          )}

          {allShown && hasPages && (
            <Button
              type="button"
              variant="link"
              className={tw`whitespace-nowrap`}
              onClick={() => changeCount(pageSize)}
            >
              Clear
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
