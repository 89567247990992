import type { Appointment } from 'fhir/r4';
import { gql } from 'graphql-request';
import type { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentAppointment } from './fragments';

export interface AppointmentConnection {
  pageInfo: GraphqlPageInfo;
  edges: Array<GraphqlConnectionNode<Appointment>>;
}

export interface AppointmentGraphqlResponse {
  AppointmentConnection: AppointmentConnection;
}

export const AppointmentQuery = gql`
  ${fragmentAppointment}

  query Appointments($upid: ID!) {
    AppointmentConnection(upid: $upid) {
      edges {
        node {
          ...Appointment
        }
      }
    }
  }
`;
