import { chunk, isArray, partition } from 'lodash-es';
import { BasicRow } from './BasicRow';
import { TupleRow } from './TupleRow';
import '../../../styles.scss';
import type { TableInfo } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { tw } from '@ctw/shared/utils/tailwind';

import type { JSX } from 'react';

type TableProps = {
  data: Array<TableInfo | [TableInfo, TableInfo]>;
  chunkSize?: 2 | 3;
};

export const Table = ({ data, chunkSize = 2 }: TableProps): JSX.Element => {
  const [normalEntries, tupleEntries] = partition(data, (d) => !isArray(d)) as [
    Array<TableInfo>,
    Array<[TableInfo, TableInfo]>,
  ];

  const entries = chunk(normalEntries, chunkSize);

  //    overflow: auto;
  //   display: block;

  return (
    <table className={tw`ccda-common-table`}>
      <tbody>
        {entries.map((dataChunk: Array<TableInfo>, index) => (
          <BasicRow
            key={`${dataChunk[0].label}-${index}`}
            dataChunk={dataChunk}
            chunkSize={chunkSize}
            indexKey={index}
          />
        ))}
        {tupleEntries.map((tuple: [TableInfo, TableInfo], index) => (
          <TupleRow
            key={`${tuple[0].label}-${tuple[1].label}-${index}`}
            tuple={tuple}
            indexKey={index}
          />
        ))}
      </tbody>
    </table>
  );
};
