import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import {
  type MinRecordItem,
  type RowActionsConfigProp,
  Table,
  type TableProps,
} from '@ctw/shared/components/table/table';
import { twx } from '@ctw/shared/utils/tailwind';
import { type ReactElement, useRef } from 'react';
import { useToggleRead } from '../hooks/use-toggle-read';
import { useAdditionalResourceActions } from './use-additional-resource-actions';
import './resource-table.scss';
import type { Breakpoint } from '@ctw/config/zui';
import { DEFAULT_PAGE_SIZE } from '@ctw/shared/components/pagination/expand-list';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import type { Resource } from 'fhir/r4';

export type ResourceTableProps<T extends MinRecordItem> = {
  className?: string;
  columns: TableProps<T>['columns'];
  data: Array<T>;
  emptyMessage?: string | ReactElement;
  isLoading?: boolean;
  onRowClick?: TableProps<T>['handleRowClick'];
  rowActions?: (r: T) => RowActionsConfigProp<T>;
  onRightClick?: TableProps<T>['onRightClick'];
  showTableHead?: boolean;
  enableDismissAndReadActions?: boolean;
  hidePagination?: boolean;
  stacked?: boolean;
  stackedBreakpoint?: Breakpoint;
};

export const ResourceTable = <T extends Resource, M extends FHIRModel<T>>({
  className,
  columns,
  data,
  emptyMessage,
  isLoading = false,
  onRowClick,
  onRightClick,
  rowActions,
  showTableHead,
  enableDismissAndReadActions,
  stacked = false,
  hidePagination = false,
  stackedBreakpoint = 'sm',
}: ResourceTableProps<M>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { requestContext } = useCTW();
  const { breakpoints } = useContainerQuery({ containerRef });

  const { toggleRead } = useToggleRead();

  // Create the RowActions react node
  const RowActionsWithAdditions = useAdditionalResourceActions({
    rowActions,
    enableDismissAndReadActions,
  });

  const onRowClickWithRead = onRowClick
    ? (record: M) => {
        if (
          !record.isRead &&
          enableDismissAndReadActions &&
          !record.ownedByBuilder(requestContext.builderId)
        ) {
          void toggleRead(record);
        }
        onRowClick(record);
      }
    : undefined;

  return (
    <div
      ref={containerRef}
      data-testid="resource-table"
      className={twx(className, 'resource-table')}
    >
      <Table
        getRowClassName={(record) =>
          twx({
            'tr-dismissed': record.isDismissed,
            'tr-unread':
              enableDismissAndReadActions &&
              !record.ownedByBuilder(requestContext.builderId) &&
              !record.isRead,
          })
        }
        showTableHead={showTableHead}
        stacked={stacked || breakpoints.isAtMost[stackedBreakpoint]}
        emptyMessage={emptyMessage}
        loading={isLoading}
        records={data}
        RowActions={RowActionsWithAdditions}
        columns={columns}
        handleRowClick={(resource) => {
          if (onRowClickWithRead) {
            void onRowClickWithRead(resource);
          }
        }}
        onRightClick={onRightClick}
        pagination={hidePagination ? undefined : { type: 'show-more', pageSize: DEFAULT_PAGE_SIZE }}
      />
    </div>
  );
};
