import type { DomainResource, FhirResource, Resource } from 'fhir/r4';

// All of the possible resourceType strings.
export type ResourceTypeString = FhirResource['resourceType'];

// Usage ResourceType<"MedicationStatement"> to return the MedicationStatement type.
// This is useful in combination with ResourceTypeString, allowing us to go
// from the resourceType string to the actual resource type.
export type ResourceType<T extends ResourceTypeString> = Extract<FhirResource, { resourceType: T }>;

export type ResourceMap = { [key: string]: Resource | undefined };

export type Tag = { system: string; code: string };

export function isFHIRDomainResource(resource: unknown): resource is DomainResource {
  return (
    isFHIRResource(resource) &&
    // Exclude resource types that extend "Resource" and not "DomainResource".
    resource.resourceType !== 'Binary' &&
    resource.resourceType !== 'Bundle'
  );
}

export function isFHIRResource(resource: unknown): resource is FhirResource {
  return typeof resource === 'object' && resource !== null && 'resourceType' in resource;
}
