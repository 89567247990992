import { twx } from '@ctw/shared/utils/tailwind';
import { faFileImage, faFileInvoice, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ContentTypeIconProps = {
  className?: string;
  contentType: string;
};

const IMAGE_RE = /^image\//i;
const PDF_RE = /^application\/.*(pdf|fdf)$/i;

export function ContentTypeIcon({
  contentType,
  className = 'fill-primary-text w-5 h-5',
}: ContentTypeIconProps) {
  if (IMAGE_RE.test(contentType)) {
    return <FontAwesomeIcon icon={faFileImage} className={twx(className)} />;
  }
  if (PDF_RE.test(contentType)) {
    return <FontAwesomeIcon icon={faFilePdf} className={twx(className)} />;
  }
  return <FontAwesomeIcon icon={faFileInvoice} className={twx(className)} />;
}
