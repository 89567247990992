import { twx } from '@ctw/shared/utils/tailwind';
import type { MouseEvent, ReactNode } from 'react';

export type ButtonSize = 'lg' | 'base' | 'sm' | 'xs';
export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'circular'
  | 'link'
  | 'unstyled';

export type ButtonProps = {
  className?: string;
  children: ReactNode;
  type: ButtonType;
  variant: ButtonVariant;
  size?: ButtonSize;
  name?: string;
  ariaLabel?: string;
  value?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  testId?: string;
};

export const Button = ({
  className,
  children,
  type,
  variant,
  size = 'base',
  name,
  ariaLabel,
  value,
  onClick,
  testId,
  disabled,
}: ButtonProps) => (
  <button
    type={type}
    name={name}
    value={value}
    onClick={(event) => {
      void onClick?.(event);
    }}
    data-testid={testId}
    aria-label={ariaLabel}
    disabled={disabled}
    className={twx(
      variant === 'unstyled'
        ? ''
        : 'cursor-pointer rounded-md font-medium font-sans focus:outline-4 focus:outline-primary-main focus:outline-offset-[3px] focus-visible:outline-4 focus-visible:outline-primary-main focus-visible:outline-offset-[3px] active:scale-[.99] disabled:pointer-events-none disabled:cursor-not-allowed',
      {
        'btn-lg text-base': size === 'lg' && variant !== 'unstyled',
        'btn-base text-sm': size === 'base' && variant !== 'unstyled',
        'btn-sm text-sm': size === 'sm' && variant !== 'unstyled',
        'btn-xs text-xs': size === 'xs' && variant !== 'unstyled',
        'btn-lg px-4 py-[9px]': size === 'lg' && !['unstyled', 'link'].includes(variant),
        'btn-base px-4 py-[9px]': size === 'base' && !['unstyled', 'link'].includes(variant),
        'btn-sm px-3 py-[7px]': size === 'sm' && !['unstyled', 'link'].includes(variant),
        'btn-xs px-2.5 py-[7px]': size === 'xs' && !['unstyled', 'link'].includes(variant),
      },
      {
        'bg-primary-main fill-white text-white shadow hover:bg-primary-text disabled:bg-content-disabled':
          variant === 'primary',
        'border border-border-main bg-white fill-content-disabled text-content-main shadow hover:bg-background-hover disabled:bg-background-disabled disabled:text-content-disabled':
          variant === 'secondary',
        'fill-primary-text text-primary-text no-underline hover:bg-background-hover disabled:fill-content-disabled disabled:text-content-disabled':
          variant === 'tertiary',
        'border border-border-error bg-white fill-content-disabled text-error-text shadow hover:bg-error-background disabled:bg-background-disabled disabled:fill-content-disabled disabled:text-content-disabled':
          variant === 'danger',
        'aspect-square rounded-full bg-primary-main fill-white text-white shadow hover:bg-primary-text disabled:bg-content-disabled':
          variant === 'circular',
        'fill-primary-text text-primary-text no-underline hover:underline focus:no-underline disabled:fill-content-disabled disabled:text-content-disabled':
          variant === 'link',
      },
      className,
    )}
  >
    {children}
  </button>
);
