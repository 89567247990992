import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { allergyData, allergyHistory } from '@ctw/shared/content/allergies/patient-allergies';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize } from 'lodash-es';

export const useAllergyDrawer = () =>
  useResourceDrawer({
    resourceType: 'AllergyIntolerance',
    enableDismissAndReadActions: true,
    action: ({ model }) => ({
      label: 'Allergy / Intolerance',
      title: capitalize(model.display),
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList items={allergyData(model)} />
            </ContentCard>
            {allergyHistory({ model })}
          </div>
        ),
      },
    }),
  });
