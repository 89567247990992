import {
  DrawerContent,
  type DrawerContentProps,
} from '@ctw/shared/components/containers/_internal/drawer-content';
import { usePresence } from '@ctw/shared/context/zui-provider';
import { twx } from '@ctw/shared/utils/tailwind';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Fragment } from 'react';

export interface DrawerProps extends Omit<DrawerContentProps, 'isDocked'> {
  isOpen: boolean;
  onOpen?: () => void;
  onAfterOpen?: () => void;
  close: () => void;
  onAfterClose?: () => void;
}

export const Drawer = ({
  isOpen,
  onOpen,
  close,
  onAfterOpen,
  onAfterClose,
  ...drawerContentProps
}: DrawerProps) => {
  const isLegacyMainNavigationBarPresent = usePresence('legacyMainNavigationBar');
  const isMainNavigationBarPresent = usePresence('mainNavigationBar');
  const isSubNavigationBarPresent = usePresence('subNavigationBar');

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog unmount={false} onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <DialogBackdrop
            className={twx('fixed inset-0 z-drawer-scrim bg-content-main/25', {
              'top-[64px]': isLegacyMainNavigationBarPresent,
              'top-[62px]': isMainNavigationBarPresent && !isSubNavigationBarPresent,
              'top-[45px]': isSubNavigationBarPresent && !isMainNavigationBarPresent,
              'top-[107px]': isMainNavigationBarPresent && isSubNavigationBarPresent,
              'top-0': !(
                isMainNavigationBarPresent ||
                isSubNavigationBarPresent ||
                isLegacyMainNavigationBarPresent
              ),
            })}
          />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="-translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="-translate-x-0"
          leaveTo="translate-x-full"
          afterLeave={onAfterClose}
          beforeEnter={onOpen}
          afterEnter={onAfterOpen}
        >
          <DialogPanel
            className={twx(
              'fixed right-0 bottom-0 z-drawer w-full overflow-visible border-divider-main border-t bg-white shadow-l-2xl md:w-[576px] md:min-w-[576px] md:max-w-[576px] lg:border-t-0',
              {
                'top-[64px]': isLegacyMainNavigationBarPresent,
                'top-[62px]': isMainNavigationBarPresent && !isSubNavigationBarPresent,
                'top-[45px]': isSubNavigationBarPresent && !isMainNavigationBarPresent,
                'top-[107px]': isMainNavigationBarPresent && isSubNavigationBarPresent,
                'top-0': !(
                  isMainNavigationBarPresent ||
                  isSubNavigationBarPresent ||
                  isLegacyMainNavigationBarPresent
                ),
              },
            )}
          >
            <DrawerContent close={close} isDocked={false} {...drawerContentProps} />
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};
