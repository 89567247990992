import { bestName, formatName } from '@ctw/shared/api/fhir/formatters/human-name';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import type { Appointment, Encounter, Practitioner, ServiceRequest } from 'fhir/r4';
import { AppointmentModel } from './appointment';
import type { DocumentModel } from './document';
import { EncounterModel } from './encounter';
import { FHIRModel } from './fhir-model';
import type { PatientModel } from './patient';

type ReferralStatus = 'ordered' | 'scheduled' | 'fulfilled';

interface ReferredPractitioner {
  name: string | undefined;
  practiceName: string | undefined;
}

export class ReferralModel extends FHIRModel<ServiceRequest> {
  public kind = 'Referral' as const;

  private readonly patientModel: PatientModel;

  private readonly appointmentResource: Appointment | undefined;

  private readonly encounterResource: Encounter | undefined;

  private readonly documentPool: Array<DocumentModel> = [];

  private isLoadingDocuments = false;

  public constructor(
    serviceRequestResource: ServiceRequest,
    patientModel: PatientModel,
    appointmentResource?: Appointment,
    encounterResource?: Encounter,
    documentPool?: Array<DocumentModel>,
  ) {
    super(serviceRequestResource);

    this.documentPool = documentPool || [];
    this.patientModel = patientModel;
    this.appointmentResource = appointmentResource;

    if (this.appointmentResource) {
      this.encounterResource = encounterResource;
    }
  }

  public get patient(): PatientModel {
    return this.patientModel;
  }

  public get appointment(): AppointmentModel | undefined {
    return this.appointmentResource ? new AppointmentModel(this.appointmentResource) : undefined;
  }

  public getIsLoadingDocuments() {
    return this.isLoadingDocuments;
  }

  public setIsLoadingDocuments(isLoading: boolean) {
    this.isLoadingDocuments = isLoading;
  }

  public get encounter(): EncounterModel | undefined {
    const encounterModel = this.encounterResource
      ? new EncounterModel(this.encounterResource, [])
      : undefined;

    if (encounterModel) {
      encounterModel.setClinicalNotesFromDocumentPool(this.documentPool);
    }

    return encounterModel;
  }

  public get resourceTypeTitle() {
    return 'Referral';
  }

  public get title() {
    return this.reason;
  }

  public get referringPracticeName(): string | undefined {
    return this.patient.organizationDisplayName;
  }

  public get referringPractitionerName(): string | undefined {
    const practitioner = (this.resource.requester as Record<string, unknown> | undefined)
      ?.resource as Practitioner | undefined;

    if (this.resource.requester?.display) {
      return this.resource.requester.display;
    }

    if (practitioner?.name !== undefined) {
      const name = bestName(practitioner.name);

      if (name) {
        return formatName(name);
      }
    }

    return undefined;
  }

  public get referredAtDisplay() {
    const time = this.resource.occurrenceDateTime ?? this.resource.authoredOn;
    return time ? formatFHIRDate(time) : undefined;
  }

  public get scheduledAtDisplay() {
    return this.appointmentResource?.start
      ? formatFHIRDate(this.appointmentResource.start)
      : undefined;
  }

  public get referredPractitioners(): Array<ReferredPractitioner> {
    return (this.resource.performer || this.resource.locationReference || []).map(
      (performer, index) => ({
        name: performer.display,
        practiceName: this.resource.locationReference?.[index]?.display,
      }),
    );
  }

  public get referredPractitionerName() {
    return this.referredPractitioners[0]?.name;
  }

  public get referredPracticeName() {
    return this.referredPractitioners[0]?.practiceName;
  }

  public get completedAtDisplay() {
    const time = this.appointmentResource?.end ?? this.appointmentResource?.start;
    return time ? formatFHIRDate(time) : undefined;
  }

  public get status(): ReferralStatus {
    if (!this.appointment) {
      return 'ordered';
    }

    if (!this.encounter) {
      return 'scheduled';
    }

    return 'fulfilled';
  }

  public get reason() {
    return (
      this.resource.reasonCode?.[0].text ??
      this.resource.category?.[0].text ??
      (this.referredPractitionerName || this.referredPracticeName
        ? `Referral to ${this.referredPractitionerName ?? this.referredPracticeName}`
        : 'Referral')
    );
  }
}
