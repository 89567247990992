import { formatAddress } from '@ctw/shared/api/fhir/formatters/address';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { useMatchedPatients } from '@ctw/shared/api/fhir/patient-helper';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import type { TableColumn } from '@ctw/shared/components/table/table';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { formatPhoneNumber } from '@ctw/shared/utils/phone-number';
import { sort } from '@ctw/shared/utils/sort';
import { twx } from '@ctw/shared/utils/tailwind';
import { tw } from '@ctw/shared/utils/tailwind';
import { uniqBy } from 'lodash-es';
import { capitalize } from 'lodash-es';
import { useRef } from 'react';

export interface PatientSourcesProps {
  className?: string;
}

export const PatientSources = withErrorBoundary({
  boundaryName: 'PatientSources',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientSourcesProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const patientSourcesQuery = useMatchedPatients();

    const hasNoData = patientSourcesQuery.data?.length === 0;

    const dedupedData = uniqBy(
      patientSourcesQuery.data ?? [],
      (p: PatientModel) =>
        [
          p.createdAtDisplay,
          p.organizationDisplayName,
          p.display,
          p.dob,
          p.age,
          p.gender,
          p.phoneNumber,
          p.email,
          p.homeAddress,
        ].join('|'), // Stringify the array to compare its contents
    );
    const sortedData = sort(dedupedData, 'createdAt', 'desc');

    return (
      <div ref={containerRef} className={twx(className)}>
        <ResourceTable
          isLoading={patientSourcesQuery.isLoading}
          data={sortedData}
          emptyMessage={
            <EmptyPatientTable hasZeroFilteredRecords={hasNoData} resourceName="patients" />
          }
          columns={patientSourcesColumns}
        />
      </div>
    );
  },
});

const patientSourcesColumns: Array<TableColumn<PatientModel>> = [
  {
    widthPercent: 10,
    minWidth: 128,
    title: 'Date',
    render: (matchedPatient) => <div>{matchedPatient.createdAtDisplay}</div>,
  },
  {
    widthPercent: 30,
    title: 'Source',
    render: (matchedPatient) => (
      <div className={tw`capitalize`}>{matchedPatient.organizationDisplayName}</div>
    ),
  },
  {
    widthPercent: 30,
    title: 'Patient Details',
    render: (matchedPatient) => (
      <div className={tw`stacked-concat`}>
        {matchedPatient.displayFull && <div>{matchedPatient.displayFull}</div>}
        {Boolean(
          matchedPatient.dob || matchedPatient.age !== undefined || matchedPatient.gender,
        ) && (
          <div>
            <span>
              {matchedPatient.dob} ({matchedPatient.age})
            </span>
            <span> {capitalize(matchedPatient.gender)}</span>
          </div>
        )}
      </div>
    ),
  },
  {
    widthPercent: 40,
    title: 'Contact Info',
    render: (matchedPatient) => (
      <div className={tw`stacked-concat`}>
        {matchedPatient.phoneNumber && <div>{formatPhoneNumber(matchedPatient.phoneNumber)}</div>}
        {matchedPatient.email && <div>{matchedPatient.email}</div>}
        {matchedPatient.homeAddress && formatAddress(matchedPatient.homeAddress)}
      </div>
    ),
  },
];
