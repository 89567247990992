import { filterResourcesByBuilderId } from '@ctw/shared/api/common';
import { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from '@ctw/shared/api/fhir/system-urls';
import { MAX_OBJECTS_PER_REQUEST, fqsRequestAll } from '@ctw/shared/api/fqs/client';
import {
  type ConditionBuilderDataGraphqlResponse,
  type ConditionBuilderInfo,
  type ConditionGraphqlResponse,
  type ConditionWithBasics,
  conditionsBuilderQuery,
  conditionsQuery,
} from '@ctw/shared/api/fqs/queries/conditions';
import { getLensBuilderId } from '@ctw/shared/api/urls';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
} from '@ctw/shared/utils/query-keys';
import { isAfter, subYears } from 'date-fns';
import type { GraphQLClient } from 'graphql-request';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';
import { getLensSummariesWithSyncedWithRecord } from './summary';

export function usePatientConditionsAll(enabled = true) {
  const queryForSummary = usePatientQuery({
    queryId: QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequestAll<ConditionGraphqlResponse>(
        telemetry,
        graphqlClient,
        conditionsQuery,
        'ConditionConnection',
        {
          upid: patient.UPID,
          cursor: '',
          first: 500,
          filter: {
            tag: {
              allmatch: [
                `${SYSTEM_ZUS_SUMMARY}|Common`,
                `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
              ],
            },
          },
        },
      );
      const nodes = data.ConditionConnection.edges.map((x) => x.node);
      const conditions = setupConditionModelsWithFQS(nodes);
      return filterAndSort(conditions);
    },
    enabled,
  });
  const queryForBuilder = usePatientQuery({
    queryId: QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
    queryFn: async ({
      requestContext,
      telemetry,
      graphqlClient,
      patient,
    }): Promise<Array<ConditionBuilderInfo>> => {
      const { data } = await fqsRequestAll<ConditionBuilderDataGraphqlResponse>(
        telemetry,
        graphqlClient,
        conditionsBuilderQuery,
        'ConditionConnection',
        {
          upid: patient.UPID,
          cursor: '',
          first: 500,
          filter: {
            tag: {
              nonematch: [SYSTEM_SUMMARY, SYSTEM_ZUS_THIRD_PARTY],
              // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
              // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
              // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
            },
          },
        },
      );

      let nodes = data.ConditionConnection.edges.map((x) => x.node);
      nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
      return nodes;
    },
    enabled,
  });

  const conditions = useMemo(() => {
    const summaries = getLensSummariesWithSyncedWithRecord(
      queryForSummary.data ?? [],
      queryForBuilder.data ?? [],
      (summary, builderResources) =>
        builderResources.some((builderResource) => summary.knownCodingsMatch(builderResource.code)),
    );
    return filterUndesirableConditions(summaries);
  }, [queryForSummary.data, queryForBuilder.data]);

  return {
    ...queryForSummary,
    isLoading: queryForSummary.isLoading || queryForBuilder.isLoading,
    data: conditions,
  };
}

// Filter out conditions that we never want to display
export function filterUndesirableConditions(conditions: Array<ConditionModel>) {
  const outdatedThreshold = subYears(new Date(), 5);

  return (
    conditions
      // Filter out summaries where CCS Category code starts with FAC or XXX.
      .filter((condition) => !['FAC', 'XXX'].includes(condition.ccsChapterCode ?? ''))
      .filter(
        // Filter out low quality conditions
        (condition) =>
          condition.ccsChapterCode !== 'SYM' ||
          !['A', 'U', 'N'].includes(condition.chronic?.code ?? '') ||
          isAfter(new Date(condition.recordedDate ?? ''), outdatedThreshold),
      )
  );
}

function setupConditionModelsWithFQS(
  conditionResources: Array<ConditionWithBasics>,
): Array<ConditionModel> {
  return conditionResources.map((c) => new ConditionModel(c, undefined, c.BasicList));
}

function filterAndSort(conditions: Array<ConditionModel>): Array<ConditionModel> {
  return orderBy(
    conditions.filter((condition) => condition.resource.asserter?.type !== 'Patient'),
    ['resource.recordedDate', 'display'],
    ['desc'],
  );
}

export async function fetchConditionsByIdFQS(
  telemetry: ReturnType<typeof useTelemetry>,

  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: Array<string>,
) {
  const { data } = await fqsRequestAll<ConditionGraphqlResponse>(
    telemetry,
    graphqlClient,
    conditionsQuery,
    'ConditionConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );
  const nodes = data.ConditionConnection.edges.map((x) => x.node);
  return setupConditionModelsWithFQS(nodes);
}
