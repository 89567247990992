import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import {
  DiagnosticReportObservationList,
  diagnosticReportData,
} from '@ctw/shared/content/diagnostic-reports/helpers/observation-list';
import { useDiagnosticRowActions } from '@ctw/shared/content/diagnostic-reports/helpers/row-actions';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';

interface UseDiagnosticReportDrawerProps {
  enableDismissAndReadActions: boolean;
}

export const useDiagnosticReportDrawer = (
  { enableDismissAndReadActions }: UseDiagnosticReportDrawerProps = {
    enableDismissAndReadActions: true,
  },
) => {
  const rowActions = useDiagnosticRowActions();
  return useResourceDrawer({
    resourceType: 'DiagnosticReport',
    enableDismissAndReadActions,
    action: ({ model }) => ({
      label: 'Diagnostic Report',
      title: model.displayName,
      resourceActions: rowActions(model),
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList items={diagnosticReportData(model)} />
            </ContentCard>
            <DiagnosticReportObservationList model={model} />
          </div>
        ),
      },
    }),
  });
};
