import './resource-overview-card.scss';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import {
  OverviewCard,
  type OverviewCardProps,
} from '@ctw/shared/components/containers/overview-card';
import type { MinRecordItem, RowActionsProp, TableProps } from '@ctw/shared/components/table/table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import type { Resource } from 'fhir/r4';
import { isFunction } from 'lodash-es';
import type { ReactElement } from 'react';
import { useToggleRead } from '../hooks/use-toggle-read';

export interface SpecificResourceOverviewCardProps {
  onSeeAllResources: () => void;
  isInPatientSummary?: boolean;
}
export interface ResourceOverviewCardProps<T extends MinRecordItem>
  extends Omit<OverviewCardProps, 'children' | 'empty'> {
  renderResource: (
    record: T,
    writebackInProgress?: boolean,
  ) => ReactElement<{ record: T; writeBackInProgress?: boolean }>;
  data: Array<T>;
  onRowClick?: TableProps<T>['handleRowClick'];
  telemetryTargetName: string;
  rowClassName?: ClassName;
  RowActions?: RowActionsProp<T>;
  isInPatientSummary?: boolean;
}

export const ResourceOverviewCard = <T extends Resource, M extends FHIRModel<T>>({
  renderResource,
  data = [],
  onRowClick,
  rowClassName,
  RowActions,
  footerCTA,
  telemetryTargetName,
  isInPatientSummary = false,
  ...overviewCardProps
}: ResourceOverviewCardProps<M>) => {
  const { trackInteraction } = useTelemetry();
  const { requestContext } = useCTW();
  const { toggleRead } = useToggleRead();

  function handleRowClick(record: M) {
    if (onRowClick) {
      if (!(record.isRead || record.ownedByBuilder(requestContext.builderId))) {
        void toggleRead(record);
      }
      onRowClick(record);
      trackInteraction('click_row', { target: telemetryTargetName });
    }
  }

  function handleRowHover(record: M) {
    if (isInPatientSummary) {
      trackInteraction('hover_gps_evidence_row', {
        resourceType: record.resourceType,
        resourceId: record.id,
      });
    }
  }

  return (
    <OverviewCard
      {...overviewCardProps}
      empty={data.length === 0}
      fullbleed={true}
      footerCTA={
        footerCTA
          ? {
              label: footerCTA.label,
              onClick: () => {
                trackInteraction('see_all_of_resource', { target: telemetryTargetName });
                footerCTA.onClick();
              },
            }
          : undefined
      }
    >
      {data.map((record) => (
        <div
          data-testid="resource-overview-row"
          className={twx('resource-overview-row grouped group relative px-3 py-1.5', rowClassName, {
            'cursor-pointer hover:bg-background-hover': isFunction(onRowClick),
            'last:rounded-b-lg': !footerCTA,
            '!text-content-subtle': record.isDismissed,
          })}
          key={record.key}
          // Needed for accessibility (eslint and sonar rules).
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRowClick(record);
            }
          }}
          onMouseEnter={() => handleRowHover(record)}
          onClick={() => handleRowClick(record)}
        >
          {renderResource(record)}
          {RowActions && (
            // Add onClick to prevent clicks from propagating to the row.
            <div
              aria-label="row-actions"
              className={tw`resource-overview-actions group invisible absolute row-actions group-hover:visible`}
              onClick={(event) => event.stopPropagation()}
            >
              <RowActions record={record} />
            </div>
          )}
        </div>
      ))}
    </OverviewCard>
  );
};
