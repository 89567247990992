import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import type { TableColumn } from '@ctw/shared/components/table/table';
import {
  type CanonicalContact,
  isCanonicalAddress,
  isCanonicalEmail,
  isCanonicalPhone,
} from '@ctw/shared/content/demographics/helpers/canonicalization';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/resource-title-column';
import { formatPhoneNumber } from '@ctw/shared/utils/phone-number';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize } from 'lodash-es';

export const canonicalContactColumns = (
  canonicalContactTitle: string,
): Array<TableColumn<CanonicalContact>> => [
  {
    title: canonicalContactTitle,
    render: (contact) => {
      if (isCanonicalPhone(contact)) {
        return (
          <div className={tw`flex gap-2`}>
            {RenderSyncedWithRecordIcon(contact.syncedWithRecord)}
            <CopyToClipboard className={tw`text-left font-medium`}>
              {formatPhoneNumber(contact.value)}
            </CopyToClipboard>
          </div>
        );
      }

      if (isCanonicalEmail(contact) || isCanonicalAddress(contact)) {
        return (
          <div className={tw`flex gap-2`}>
            {RenderSyncedWithRecordIcon(contact.syncedWithRecord)}
            <CopyToClipboard className={tw`text-left font-medium`}>{contact.value}</CopyToClipboard>
          </div>
        );
      }

      return <></>;
    },
    widthPercent: 35,
    minWidth: 200,
  },
  {
    title: 'Type',
    render: (contact) => <>{contact.uses.map((use) => capitalize(use)).join(', ')}</>,
    widthPercent: 18,
  },
  {
    title: 'Sources',
    render: (contact) => <>{contact.sources.join(', ')}</>,
  },
];
