import {
  displayForName,
  getContactDetails,
  getId,
} from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { GeneralInfo } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import xpath from 'xpath';

export const getProviderOrganization = (patientRole: Document): GeneralInfo => {
  const providerOrganization = xpath.select1("*[name()='providerOrganization']", patientRole) as
    | Document
    | undefined;

  const custodian = xpath.select1(
    "//*[name()='custodian']/*[name()='assignedCustodian']/*[name()='representedCustodianOrganization']",
    patientRole,
  ) as Document | undefined;

  const finalProvider = providerOrganization || custodian;

  if (!finalProvider) {
    return {
      name: '',
      contactDetails: '',
    };
  }

  const ids = (xpath.select("*[name()='id']", finalProvider) as Array<Document>)
    .map(getId)
    .join(', ');

  const names = displayForName(
    xpath.select("string(*[name()='name'])", finalProvider) as Array<string>,
  );

  const contactDetails = getContactDetails(
    xpath.select("*[name()='addr']", finalProvider) as Array<Document>,
    xpath.select("*[name()='telecom']", finalProvider) as Array<Document>,
  );

  const withNames = names ? `\n${names}` : '';
  return {
    name: `${ids}${withNames}`,
    contactDetails,
  };
};
