import type { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { useZapContext } from '@ctw/shared/context/zap-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMedicationDrawer } from '@ctw/shared/hooks/use-medication-drawer';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { faPills } from '@fortawesome/pro-solid-svg-icons';
import {
  ResourceOverviewCard,
  type SpecificResourceOverviewCardProps,
} from '../overview/resource-overview-card';
import { RenderSyncedWithRecordIcon, ResourceTitleColumn } from '../resource/resource-title-column';
import { getDateRangeView } from '../resource/view-date-range';
import { defaultMedicationSortOverview } from './helpers/sorts';
import { useMedicationRowActions } from './patient-medications';
const TITLE = 'Medications';

export const PatientMedicationOverview = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'PatientMedicationOverview',
  Component: ({ onSeeAllResources, isInPatientSummary }: SpecificResourceOverviewCardProps) => {
    const { requestContext } = useCTW();
    const { isWritebackEnabled } = useWritebacks();
    const query = useQueryAllPatientMedications();
    const { onZapEvent } = useZapContext();
    const rowActions = useMedicationRowActions((record) => {
      void onZapEvent({
        name: 'addToRecord',
        payload: {
          resource: record.resource,
          resourceType: record.resourceType,
        },
      });
    });

    const { openDrawer } = useMedicationDrawer({
      resourceActions: isWritebackEnabled('medications-all') ? (rowActions as never) : undefined,
      enableDismissAndReadActions: false,
    });

    const { past6Months } = getDateRangeView<MedicationStatementModel>('lastActivityDate', false);

    const { data } = useFilteredSortedData({
      defaultSort: defaultMedicationSortOverview, // Alphabetical
      viewOptions: [past6Months],
      defaultView: past6Months.display,
      records: query.allMedications,
    });

    return (
      <ResourceOverviewCard
        onRowClick={(model) => openDrawer({ model })}
        data={data}
        emptyStateMessage={
          query.allMedications.length === 0
            ? "We didn't find any medication records for this patient."
            : 'There are no medications with prescriptions or fills within the past 6 months for this patient.'
        }
        footerCTA={
          query.allMedications.length === 0
            ? undefined
            : {
                label: 'All Medications',
                onClick: onSeeAllResources,
              }
        }
        title={TITLE}
        helpText="Medications with prescriptions or fills within the last 6 months"
        loading={query.isLoading}
        headerIcon={faPills}
        telemetryTargetName="medications_overview"
        testId="medications-overview"
        isInPatientSummary={isInPatientSummary}
        renderResource={(record: MedicationStatementModel) => (
          <ResourceTitleColumn
            title={record.display}
            renderIcon={RenderSyncedWithRecordIcon(record.ownedByBuilder(requestContext.builderId))}
          />
        )}
      />
    );
  },
});
