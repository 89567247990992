'use client';

import { Button } from '@ctw/shared/components/button';
import { ButtonGroup } from '@ctw/shared/components/button-group';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { hrefIsActive } from '@ctw/shared/utils/url';
import { useMemo } from 'react';

export const DemographicsTabs = () => {
  const { navigate, pathname } = useZuiContext();

  const isEmbedded = window.self !== window.top;

  const tabs = useMemo(
    () => [
      {
        id: 'contact-information',
        label: 'Contact Information',
        href: 'contact-information',
      },
      {
        id: 'sources',
        label: 'Sources',
        href: 'sources',
      },
      {
        id: 'zus-records',
        label: 'Zus Records',
        href: 'zus-records',
        hidden: isEmbedded,
      },
    ],
    [isEmbedded],
  );

  const selectedTab = useMemo(
    () => tabs.find((tab) => hrefIsActive({ pathname, href: tab.href })),
    [pathname, tabs],
  );

  return (
    <ButtonGroup>
      {tabs
        .filter((tab) => !tab.hidden)
        .map((tab) => (
          <Button
            key={tab.id}
            testId={`${tab.id}-tab`}
            type="button"
            variant={selectedTab?.id === tab.id ? 'primary' : 'secondary'}
            onClick={() => {
              navigate(tab.href);
            }}
          >
            {tab.label}
          </Button>
        ))}
    </ButtonGroup>
  );
};
