import { Heading } from '@ctw/shared/components/heading';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';

export interface TwoColumnContentProps {
  className?: ClassName;
  leftColumn?: ColumnContentProps;
  rightColumn?: ColumnContentProps;
}

export const TwoColumnContent = ({ className, leftColumn, rightColumn }: TwoColumnContentProps) => {
  return (
    <div className={twx('flex overflow-x-auto', className)}>
      <div className={twx('flex h-full w-full min-w-full')}>
        {leftColumn && <ColumnContent {...leftColumn} scrollContainerClassName={tw`pr-2`} />}
        {rightColumn && (
          <ColumnContent
            {...rightColumn}
            className={tw`flex-1`}
            scrollContainerClassName={twx({ 'pl-2': leftColumn && !leftColumn.hidden })}
          />
        )}
      </div>
    </div>
  );
};

interface ColumnContentProps {
  title?: string;
  header?: ReactNode;
  icon?: IconDefinition;
  hidden?: boolean;
  className?: ClassName;
  scrollContainerClassName?: ClassName;
  content: ReactNode;
}

const ColumnContent = ({
  title,
  header,
  hidden = false,
  icon,
  className,
  scrollContainerClassName,
  content,
}: ColumnContentProps) => (
  <section
    className={twx(
      'relative flex flex-col',
      {
        hidden,
      },
      className,
    )}
  >
    <div
      className={twx(
        'flex h-14 max-h-14 min-h-14 w-full items-center gap-3 border-border-main border-b py-3',
        className,
      )}
    >
      {icon && <FontAwesomeIcon icon={icon} className={tw`text-content-subtle`} />}
      <div className={tw`flex h-full w-full flex-col justify-center`}>
        {title && (
          <Heading
            level="h2"
            className={twx(
              'line-clamp-2 flex w-full items-center gap-2 text-content-subtle text-xs uppercase',
            )}
          >
            {title}
          </Heading>
        )}
        {header}
      </div>
    </div>

    <div className={twx('scroll-shadows h-full overflow-y-auto', scrollContainerClassName)}>
      <div className={twx('py-4')}>{content}</div>
    </div>
  </section>
);
