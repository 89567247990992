import { type ClassName, twx } from '@ctw/shared/utils/tailwind';

export interface HorizontalDividerProps {
  thickness?: 'regular' | 'thick';
  className?: ClassName;
}

export const HorizontalDivider = ({ className, thickness = 'regular' }: HorizontalDividerProps) => (
  <hr
    className={twx(
      'my-2 h-0 max-h-0 w-full min-w-full border-divider-main',
      {
        'border-t-[1.5px]': thickness === 'regular',
        'border-t-2': thickness === 'thick',
      },
      className,
    )}
  />
);
