import type { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import type { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize } from 'lodash-es';

export const patientAllergiesColumns = (builderId: string) => {
  const allergyColumns: Array<TableColumn<AllergyModel>> = [
    {
      title: 'Name',
      render: (allergy) => (
        <ResourceTitleColumn
          title={<CopyToClipboard>{allergy.display}</CopyToClipboard>}
          renderIcon={RenderSyncedWithRecordIcon(allergy.ownedByBuilder(builderId))}
        />
      ),
    },
    {
      title: 'Last Updated',
      render: (allergy) => (
        <div>
          {allergy.recordedDate ? (
            <>
              <div className={tw`font-normal text-sm`}>Last Updated: {allergy.recordedDate}</div>
              <div>{allergy.patientOrganizationName}</div>
            </>
          ) : (
            <div>{allergy.patientOrganizationName}</div>
          )}
        </div>
      ),
    },
    {
      title: 'Details',
      render: (allergy) => (
        <div className={tw`font-normal`}>
          {!!allergy.manifestations && allergy.manifestations !== 'unknown' && (
            <div>Reaction: {capitalize(allergy.manifestations)}</div>
          )}
        </div>
      ),
    },
  ];

  return allergyColumns;
};
