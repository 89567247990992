import { FormField } from '@ctw/shared/content/forms/form-field';
import { FormFieldLabel } from '@ctw/shared/content/forms/form-field-label';
import type { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import {
  type AnyZodSchema,
  type InputPropType,
  useFormInputProps,
} from '@ctw/shared/utils/form-helper';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { isArray } from 'lodash-es';
import { Fragment, type InputHTMLAttributes, type JSX } from 'react';
import { DrawerForm, type DrawerFormProps, type FormErrors } from './drawer-form';

export interface FormFieldOption {
  label: string;
  value: string;
}

export type FormFieldType = {
  label: string;
  field: string;
  value?: string | Array<string>;
  lines?: number;
  readonly?: boolean;
  hidden?: boolean;
  render?: (
    readOnly: boolean | undefined,
    inputProps: InputHTMLAttributes<HTMLInputElement>,
  ) => JSX.Element;
  options?: ReadonlyArray<FormFieldOption>;
};

export type FormContentType = {
  field?: never;
  value?: never;
  lines?: never;
  readonly?: never;
  hidden?: never;
  render: () => JSX.Element;
  label: string;
};

export type FormEntry = FormFieldType | FormContentType | Array<FormFieldType>;

export type DrawerFormWithFieldsProps<T> = {
  data?: Array<FormEntry>;
} & Pick<
  DrawerFormProps<T>,
  | 'boundaryName'
  | 'onSubmit'
  | 'closeOnSubmit'
  | 'onSubmitSuccess'
  | 'onSubmitError'
  | 'action'
  | 'schema'
  | 'errorHeader'
> & { trackingMetadata?: TrackingMetadata };

export const DrawerFormWithFields = <T,>({
  data = [],
  schema,
  action,
  ...drawerFormProps
}: DrawerFormWithFieldsProps<T>) => {
  const inputProps = useFormInputProps(schema);

  return (
    <DrawerForm action={action} schema={schema} {...drawerFormProps}>
      {(submitting, errors) => (
        <div className={tw`space-y-4`}>
          <div className={tw`space-y-6`}>
            {data.map((entry) => {
              if (isArray(entry)) {
                return (
                  <FormFieldEntries
                    recordList={entry}
                    errors={errors}
                    submitting={submitting}
                    schema={schema}
                    key={entry[0].label}
                  />
                );
              }

              const { label, field, value, lines, readonly, hidden, render } = entry;

              if (!field) {
                return <FormField key={label} readonly={readonly} render={render} name={label} />;
              }

              const props = inputProps(entry, schema);

              if (hidden) {
                return (
                  <FormField
                    {...props}
                    key={label}
                    lines={lines}
                    disabled={submitting}
                    readonly={readonly}
                    defaultValue={value}
                    errors={errors?.[field]}
                    hidden={hidden}
                    render={render}
                  />
                );
              }

              return (
                <FormFieldEntry
                  key={entry.label}
                  entry={entry}
                  errors={errors}
                  props={props}
                  submitting={submitting}
                />
              );
            })}
          </div>
        </div>
      )}
    </DrawerForm>
  );
};

export type FormFieldEntries = {
  recordList: Array<FormFieldType>;
  errors: FormErrors | undefined;
  submitting: boolean;
  schema: AnyZodSchema;
};

const FormFieldEntries = ({ recordList, errors, submitting, schema }: FormFieldEntries) => {
  const inputProps = useFormInputProps(schema);

  return (
    <div className={tw`flex space-x-3`}>
      {recordList.map((record) => (
        <Fragment key={record.label}>
          <FormFieldEntry
            entry={record}
            props={inputProps(record, schema)}
            submitting={submitting}
            errors={errors}
          />
        </Fragment>
      ))}
    </div>
  );
};

export type FormFieldEntry = {
  entry: FormFieldType;
  errors: FormErrors | undefined;
  props: InputPropType;
  submitting: boolean;
};

const FormFieldEntry = ({ entry, errors, props, submitting }: FormFieldEntry) => {
  const { label, value, field, lines, readonly, hidden, render } = entry;
  const fieldErrors = errors?.[field];

  return (
    <div
      key={label}
      className={twx({
        'flex grow basis-0 flex-col space-y-1.5 font-medium text-background-inverse text-sm':
          !hidden,
      })}
    >
      {!hidden && (
        <FormFieldLabel label={label} name={props.name} required={props['aria-required']} />
      )}
      <FormField
        {...props}
        key={label}
        lines={lines}
        disabled={submitting}
        readonly={readonly}
        defaultValue={value}
        errors={fieldErrors}
        hidden={hidden}
        render={render}
        data-zus-telemetry-focus={label}
      />
    </div>
  );
};
