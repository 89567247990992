import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faExclamation, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';

export type AlertProps = {
  header: string;
  children?: ReactNode;
  className?: string;
  type: 'error' | 'caution';
};

export const Alert = ({ header, children, className, type }: AlertProps) => (
  <div
    className={twx(
      'flex items-start space-x-2 rounded-md bg-caution-background p-4 text-left',
      className,
    )}
  >
    <FontAwesomeIcon
      icon={type === 'error' ? faExclamationCircle : faExclamation}
      className={twx('h-5 flex-none', {
        'text-caution-main': type === 'caution',
        'text-error-main': type === 'error',
      })}
    />
    <div className={tw`space-y-2`}>
      <div
        className={twx('font-medium', {
          'text-caution-text': type === 'caution',
          'text-error-text': type === 'error',
        })}
      >
        {header}
      </div>
      {children && (
        <div
          className={twx({
            'text-caution-text': type === 'caution',
            'text-error-text': type === 'error',
          })}
        >
          {children}
        </div>
      )}
    </div>
  </div>
);
