import { twx } from '@ctw/shared/utils/tailwind';

export type PrototypeStage = 'beta' | 'pilot';

export type PrototypeLabelProps = {
  className?: string;
  stage: PrototypeStage;
  deemphasized?: boolean;
};

export const PrototypeLabel = ({ className, stage, deemphasized }: PrototypeLabelProps) => (
  <span
    className={twx(
      className,
      'relative bottom-1 break-keep font-bold text-[10px] uppercase tracking-wider',
      'text-primary-text',
      {
        'text-content-icon': deemphasized,
      },
    )}
  >
    {stage}
  </span>
);
