import { useBinaryId } from '@ctw/shared/api/fhir/binaries';
import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import type { ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useCCDAModal } from '@ctw/shared/content/CCDA/modal-ccda';
import { useDownloadBinary } from '@ctw/shared/content/CCDA/use-download-binary';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { FhirModelSubclassType } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

export interface ResponsiveSourceDocumentLinkProps<TResourceType extends ResourceTypeString> {
  binaryId?: string;
  model: FhirModelSubclassType<TResourceType>;
}

export const ResponsiveSourceDocumentLink = <TResourceType extends ResourceTypeString>({
  binaryId: externalBinaryId,
  model,
}: ResponsiveSourceDocumentLinkProps<TResourceType>) => {
  const { patient } = usePatientContext();
  const openCCDAModal = useCCDAModal(patient);
  const { allowSmallBreakpointCCDAViewer } = useCTW();
  const { trackInteraction } = useTelemetry();
  const { downloadBinaryById, error, isDownloading } = useDownloadBinary();
  const [width, setWidth] = useState(window.innerWidth);
  const binaryFetch = useBinaryId(model, true, externalBinaryId === undefined);
  const binaryId = useMemo(
    () => externalBinaryId ?? binaryFetch.data,
    [binaryFetch.data, externalBinaryId],
  );

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  const isDownloadBtn = !allowSmallBreakpointCCDAViewer && width < 460;
  const downloadText = isDownloadBtn && !isDownloading ? 'Download' : '';

  if (binaryFetch.isLoading) {
    return <LoadingSpinner centered={true} iconClass="w-5 h-5" />;
  }

  if (!binaryId || isEmpty(binaryId)) {
    return <>{externalBinaryId}</>;
  }

  return (
    <div>
      {error && <Alert type="error" header={error} />}
      <Button
        type="button"
        variant="link"
        className={tw`flex items-center hover:opacity-80`}
        disabled={isDownloading}
        onClick={() => {
          if (binaryId) {
            if (isDownloadBtn) {
              downloadBinaryById(binaryId, model.resourceTypeTitle);
              trackInteraction('download_document', {
                target: model.resourceType,
              });
            } else {
              openCCDAModal(binaryId, model.resourceTypeTitle);
              trackInteraction('open_source_document', {
                target: model.resourceType,
              });
            }
          }
        }}
      >
        <ContentTypeIcon
          contentType={
            model instanceof DocumentModel ? (model.contentType ?? 'unknown') : 'unknown'
          }
        />

        <span>Source document</span>
        <span className={tw`block whitespace-nowrap`}>
          {!isDownloading ? (
            downloadText
          ) : (
            <LoadingSpinner className={tw`h-3`} message="Downloading" />
          )}
        </span>
      </Button>
    </div>
  );
};
