import { PractitionerModel } from '@ctw/shared/api/fhir/models/practitioner';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { Practitioner, Reference } from 'fhir/r4';

// Use their email address as the display if no practitioner reference available.
export async function getUsersPractitionerReference(ctw: CTWState): Promise<Reference> {
  const { practitionerId, email } = ctw.requestContext.authTokenState;

  if (!practitionerId) {
    return {
      display: email,
      type: 'Practitioner',
    };
  }

  const baseUrl = `${getZusServiceUrl(ctw.requestContext.env, 'fqs')}/rest`;
  const { data } = await ctw.ctwFetch(`${baseUrl}/Practitioner/${practitionerId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${ctw.requestContext.authToken}`,
    },
  });

  return {
    reference: `Practitioner/${practitionerId}`,
    type: 'Practitioner',
    display: new PractitionerModel(data as Practitioner).fullName,
  };
}
