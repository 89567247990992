import { searchProvenancesFQS } from '@ctw/shared/api/fqs/queries/provenances';
import type { Provenance, Resource } from 'fhir/r4';
import type { GraphQLClient } from 'graphql-request';
import { uniq } from 'lodash-es';
import type { FHIRModel } from './models/fhir-model';

export async function searchProvenances<T extends Resource>(
  graphqlClient: GraphQLClient,
  models: Array<FHIRModel<T>>,
): Promise<Array<Provenance>> {
  if (models.length === 0) {
    return [];
  }

  const targets = uniq(models.map((m) => `${m.resourceType}/${m.id}`));

  // Builder-scoped queries will fix the issue of FQS not supporting Practitioner provenance search.
  if (models[0].resourceType === 'Practitioner') {
    return [];
  }

  return searchProvenancesFQS(graphqlClient, models[0].resourceType, targets);
}

export function getBinaryIDFromProvenance(provenance?: Array<Provenance>) {
  if (provenance && provenance.length > 0) {
    let binaryIDReference = '';
    for (const prov of provenance) {
      const entity = prov.entity?.find((e) => e.what.reference !== undefined);
      if (entity?.what.reference) {
        binaryIDReference = entity.what.reference;
        break;
      }
    }
    if (binaryIDReference) {
      return binaryIDReference.split('/')[1];
    }
  }
  return undefined;
}
