import type { OnResourceSaveCallback } from '@ctw/shared/api/fhir/action-helper';
import { getFetchFromFqs } from '@ctw/shared/api/fqs/client';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { notify } from '@ctw/shared/components/toast';
import { BuilderConfigProvider } from '@ctw/shared/context/builder-config-context';
import { DrawerProvider } from '@ctw/shared/context/drawer-context';
import { ModalProvider } from '@ctw/shared/context/modal-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { TelemetryInitializer } from '@ctw/shared/context/telemetry/telemetry-initializer';
import {
  WritebackProvider,
  type WritebackProviderProps,
} from '@ctw/shared/context/writeback-provider';
import type { ZusService } from '@ctw/shared/context/zui-provider';
import {
  type ZusAuthTokenState,
  getAuthTokenState,
  isAuthTokenExpired,
  isAuthTokenPastExpiryGracePeriod,
} from '@ctw/shared/utils/auth';
import { getPageEnv } from '@ctw/shared/utils/get-page-env';
import { assertResponseOK, getResponseContent } from '@ctw/shared/utils/http';
import { Worker } from '@react-pdf-viewer/core';
import { QueryCache, QueryClient } from '@tanstack/query-core';
import { QueryClientProvider, type QueryClientProviderProps } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GraphQLClient } from 'graphql-request';
import { debounce } from 'lodash-es';
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { BulkActionProvider } from './bulk-action-provider';
import { FeatureFlagProvider } from './feature-flag-provider';
import { ToastProvider } from './toast-provider';
import type { Env } from './types';

// We use an expiry padding to provide a buffer to prevent race conditions.
// A race condition could happen in that we check if the token is expired,
// it isn't, but by time we do our request(s) it has expired.
// We track the id of the toast that we open to avoid stacking.
const AUTH_ERROR_TOAST_ID = 'invalid-token' as const;

export const CTW_REQUEST_HEADER = { 'Zus-Request-Source': 'component-library' } as const;

type CtwFetchFn = <TData>(
  url: Parameters<typeof fetch>[0],
  init: Parameters<typeof fetch>[1],
) => Promise<{
  data: TData;
  ok: boolean;
  headers: Response['headers'];
}>;

export interface CTWRequestContext {
  isInIframe: boolean;
  env: Env;
  headers?: HeadersInit;
  authToken: string;
  ehr: string;
  builderId: string;
  service: ZusService;
  serviceVersion: string;
  serviceVariant?: string;
  serviceEnv: Env;
  isAuthTokenExpired: () => boolean;
  authTokenState: ZusAuthTokenState;
  ehrContext?: Record<string, string>;
}

export interface CTWState {
  requestContext: CTWRequestContext;
  headers?: HeadersInit;
  allowSmallBreakpointCCDAViewer: boolean;
  featureFlags: Record<string, boolean>;
  onResourceSave: OnResourceSaveCallback;
  ehrContext?: Record<string, string>;
  updateBuilderId: (builderId: string) => void;
  graphqlClient: GraphQLClient;
  /*
   * @deprecated This method should not be used and will be replaced soon.
   */
  fetchFromFqs: (
    url: string,
    options: RequestInit,
  ) => Promise<{ data: unknown; headers: Response['headers'] }>;
  ctwFetch: CtwFetchFn;
}

export const CTWContext = createContext<CTWState | undefined>(undefined);

export type CTWProviderProps = {
  env: Env;
  service: ZusService;
  serviceVersion: string;
  serviceVariant?: string;
  builderId?: string;
  ehr: string;
  headers?: Record<string, string>;
  authToken: string;
  getRefreshedAuthToken?: (token: string) => Promise<string>;
  onAuthTokenExpiration?: () => void;
  featureFlags?: Record<string, boolean>;
  onResourceSave?: OnResourceSaveCallback;
  writebacks?: WritebackProviderProps;
  ehrContext?: Record<string, string>;
  allowSmallBreakpointCCDAViewer?: boolean;
};

/**
 * CTWProvider is required for all apps in the monorepo. It should be loaded as early as possible
 * and provides global context and telemetry for the app.
 */
export const CTWProvider = ({
  children,
  env,
  builderId,
  ehr,
  service,
  serviceVersion,
  serviceVariant,
  featureFlags,
  headers,
  authToken: initialAuthToken,
  onResourceSave,
  getRefreshedAuthToken,
  onAuthTokenExpiration,
  ehrContext,
  allowSmallBreakpointCCDAViewer = false,
  writebacks,
}: PropsWithChildren<CTWProviderProps>) => {
  const telemetry = useTelemetry();
  const [authToken, setAuthToken] = useState(initialAuthToken);
  const [authTokenState, setAuthTokenState] = useState(getAuthTokenState(authToken));
  const [currentBuilderId, setCurrentBuilderId] = useState(builderId ?? authTokenState.builderId);
  const [showingAuthTokenError, setShowingAuthTokenError] = useState(false);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const debouncedGetRefreshedAuthToken = useCallback(
    debounce(async (token) => getRefreshedAuthToken?.(token), 2000, { leading: true }),
    [getRefreshedAuthToken],
  );

  useEffect(() => {
    telemetry.logger.debug('Mounting CTWProvider', {
      env,
      ehr,
      builderId,
      service,
      serviceVersion,
      serviceVariant,
      featureFlags,
    });
  }, [
    builderId,
    ehr,
    env,
    featureFlags,
    service,
    serviceVariant,
    serviceVersion,
    telemetry.logger,
  ]);

  const queryClient: QueryClientProviderProps['client'] = useMemo(
    (): QueryClientProviderProps['client'] =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            gcTime: 1000 * 30, // garbage collect data every 30s
            staleTime: 1000 * 30, // data is valid for 30s, stale while revalidate after
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            telemetry.trackError({
              message: 'Unhandled react query error',
              error,
              context: {
                query: {
                  key: query.queryKey,
                  status: query.state.status,
                },
                queryMeta: query.meta,
              },
            });
          },
        }),
      }) as unknown as QueryClientProviderProps['client'],
    [telemetry],
  );

  const requestContext: CTWRequestContext = useMemo(
    () => ({
      isInIframe: window.location !== window.parent.location,
      authToken,
      isAuthTokenExpired: () => isAuthTokenPastExpiryGracePeriod(authToken),
      authTokenState,
      builderId: currentBuilderId,
      ehr,
      env,
      headers,
      service,
      serviceEnv: getPageEnv(),
      serviceVersion,
      serviceVariant,
      ehrContext,
    }),
    [
      authToken,
      authTokenState,
      currentBuilderId,
      ehr,
      env,
      headers,
      service,
      serviceVariant,
      serviceVersion,
      ehrContext,
    ],
  );

  const graphqlClient = useMemo(() => {
    const endpoint = `${getZusServiceUrl(requestContext.env, 'fqs')}/query`;

    return new GraphQLClient(endpoint, {
      errorPolicy: 'all',
      headers: {
        ...CTW_REQUEST_HEADER,
        authorization: `Bearer ${requestContext.authToken}`,
      },
    });
  }, [requestContext.authToken, requestContext.env]);

  const ctwFetch: CtwFetchFn = useCallback(
    async (input, init = {}) => {
      const newInit: Parameters<typeof fetch>[1] = {
        ...init,
        headers: {
          ...init.headers,
          ...CTW_REQUEST_HEADER,
        },
      };

      try {
        const fetchResponse = await fetch(input, newInit);
        await assertResponseOK(fetchResponse);

        return {
          data: await getResponseContent(fetchResponse),
          ok: fetchResponse.ok,
          headers: fetchResponse.headers,
        };
      } catch (error) {
        telemetry.trackError({
          error,
          context: {
            fetch: {
              url: input.toString(),
              init: {
                ...newInit,
                headers: telemetry.redactSensitiveHttpHeaders(newInit.headers),
              },
            },
          },
        });
        return {
          data: {},
          ok: false,
          headers: new Headers(),
        };
      }
    },
    [telemetry],
  );

  const providerState: CTWState = useMemo(
    () => ({
      requestContext,
      allowSmallBreakpointCCDAViewer,
      featureFlags: featureFlags ?? {},
      headers,
      onResourceSave: (resource, action, error) => onResourceSave?.(resource, action, error),
      ehrContext,
      updateBuilderId: setCurrentBuilderId,
      fetchFromFqs: getFetchFromFqs(ctwFetch, env, authToken),
      graphqlClient,
      ctwFetch,
    }),
    [
      requestContext,
      allowSmallBreakpointCCDAViewer,
      featureFlags,
      headers,
      onResourceSave,
      ehrContext,
      env,
      authToken,
      graphqlClient,
      ctwFetch,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      const handleAuthTokenRefresh = async () => {
        if (isAuthTokenExpired(authToken)) {
          const refreshedAuthToken = await debouncedGetRefreshedAuthToken(authToken);
          const refreshedTokenState = getAuthTokenState(refreshedAuthToken ?? '');

          if (refreshedAuthToken && refreshedAuthToken !== authToken) {
            setAuthToken(refreshedAuthToken);
            setAuthTokenState(refreshedTokenState);
          } else if (
            (!(refreshedAuthToken && refreshedTokenState.email) ||
              isAuthTokenPastExpiryGracePeriod(refreshedAuthToken)) &&
            !showingAuthTokenError
          ) {
            onAuthTokenExpiration?.();
            sendInvalidTokenNotification(providerState.requestContext, AUTH_ERROR_TOAST_ID);
            setShowingAuthTokenError(true);
          }
        }
      };

      void handleAuthTokenRefresh();
    }, 300);

    return () => {
      // Ensure the last enqueued execution of the interval finishes before clearing it
      setTimeout(() => {
        clearInterval(intervalId);
      }, 1000);
    };
  }, [
    authToken,
    debouncedGetRefreshedAuthToken,
    getRefreshedAuthToken,
    onAuthTokenExpiration,
    providerState.requestContext,
    showingAuthTokenError,
  ]);

  useLayoutEffect(() => {
    // We don't want to stack toast notifications. So we track if there is one already open with auth errors
    const unsubscribe = toast.onChange((t) => {
      if (t.id === AUTH_ERROR_TOAST_ID && t.status === 'removed') {
        setShowingAuthTokenError(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <CTWContext.Provider value={providerState}>
      <TelemetryInitializer>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastProvider>
            <FeatureFlagProvider env={env}>
              <WritebackProvider {...writebacks}>
                <BulkActionProvider>
                  {/* https://react-pdf-viewer.dev/docs/basic-usage/ */}
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <BuilderConfigProvider>
                      <ModalProvider>
                        {({ modal }) => (
                          <>
                            <DrawerProvider allowDocking={false}>
                              {({ drawer }) => (
                                <>
                                  {drawer}
                                  {children}
                                </>
                              )}
                            </DrawerProvider>
                            {modal}
                          </>
                        )}
                      </ModalProvider>
                    </BuilderConfigProvider>
                  </Worker>
                </BulkActionProvider>
              </WritebackProvider>
            </FeatureFlagProvider>
          </ToastProvider>
        </QueryClientProvider>
      </TelemetryInitializer>
    </CTWContext.Provider>
  );
};

const sendInvalidTokenNotification = (requestContext: CTWRequestContext, toastId: string) => {
  const message = requestContext.isAuthTokenExpired()
    ? 'This token has expired'
    : 'Only user tokens are allowed';
  notify({
    type: 'error',
    title: 'Warning',
    body: `Invalid auth token: ${message}`,
    options: {
      toastId,
    },
  });
};

export const useCtwIfAvailable = (): CTWState | undefined => useContext(CTWContext);

export const useCTW = () => {
  const context = useContext(CTWContext);

  if (!context) {
    throw new Error('useCTW must be used within a CTWProvider');
  }

  return context;
};
