const SESSION_INACTIVE_TIME_MS = 1000 * 60 * 60; // 1 hour
const SESSION_TIMESTAMP_KEY = 'zus:sessionLastActiveTimestamp';
const SESSION_USER_ID_KEY = 'zus:sessionUserId';

export class Session {
  public static isActive() {
    const timestampStr = localStorage.getItem(SESSION_TIMESTAMP_KEY);
    if (timestampStr) {
      const lastActiveTimestamp = Number.parseInt(timestampStr, 10);
      // biome-ignore lint/complexity/useDateNow: <explanation>
      const now = new Date().getTime();
      return now - SESSION_INACTIVE_TIME_MS < lastActiveTimestamp;
    }
    return false;
  }

  public static setSessionLastActiveTimestamp() {
    // biome-ignore lint/complexity/useDateNow: <explanation>
    localStorage.setItem(SESSION_TIMESTAMP_KEY, new Date().getTime().toString());
  }

  public static clearSessionLastActiveTimestamp() {
    localStorage.removeItem(SESSION_TIMESTAMP_KEY);
  }

  /**
   * Sets the session user id. If the user id is already set and not of the
   * same value, the active session will be cleared.
   */
  public static setSessionUserId(userId: string) {
    const sessionUserId = localStorage.getItem(SESSION_USER_ID_KEY);
    if (sessionUserId !== userId) {
      Session.clearSession();
      localStorage.setItem(SESSION_USER_ID_KEY, userId);
    }
  }

  public static clearSession() {
    localStorage.removeItem(SESSION_USER_ID_KEY);
    localStorage.removeItem(SESSION_TIMESTAMP_KEY);
  }
}
