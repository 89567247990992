import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { PatientContactInformation } from '@ctw/shared/content/demographics/patient-contact-information';
import { PatientSources } from '@ctw/shared/content/patient-sources/patient-sources';
import { tw } from '@ctw/shared/utils/tailwind';
import { DemographicsTabs } from './demographics-tabs';

export const PatientDemographics = withErrorBoundary({
  boundaryName: 'PatientDemographics',
  includeTelemetryBoundary: true,
  Component: () => {
    useMainContentHeader(
      <div className={tw`flex h-full w-full items-center justify-start`}>
        <DemographicsTabs />
      </div>,
      [],
    );

    return (
      <div className={tw`flex flex-col gap-3 py-3`}>
        {window.location.pathname.endsWith('contact-information') && <PatientContactInformation />}
        {window.location.pathname.endsWith('sources') && <PatientSources />}
      </div>
    );
  },
});
