import { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import {
  fetchResource,
  searchBuilderRecords,
  searchFirstPartyRecords,
} from '@ctw/shared/api/fqs-rest/search-helpers';
import {
  type GraphqlPageInfo,
  MAX_OBJECTS_PER_REQUEST,
  fqsRequest,
} from '@ctw/shared/api/fqs/client';
import {
  type PatientGraphqlResponse,
  patientsForBuilderQuery,
  patientsForUPIDQuery,
} from '@ctw/shared/api/fqs/queries/patients';
import type { CTWRequestContext, CTWState } from '@ctw/shared/context/ctw-context';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import {
  QUERY_KEY_BUILDER_PATIENTS_BY_UPID,
  QUERY_KEY_MATCHED_PATIENTS,
  QUERY_KEY_PATIENTS_LIST_FQS,
  QUERY_KEY_PATIENT_SEARCH,
} from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';
import { hasNumber } from '@ctw/shared/utils/types';
import type { UseQueryResult } from '@tanstack/react-query';
import type { Patient } from 'fhir/r4';
import type { GraphQLClient } from 'graphql-request';
import { useMemo } from 'react';
import { filterByTags } from './resource-helper';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
  SYSTEM_ZUS_UPI_RECORD_TYPE,
} from './system-urls';
import type { Tag } from './types';

// As of 1/9/24, if all of the patients for the given UPID are inactive, getPatientsForUPIDFQS will throw an
// unauthorized error so we return the patient used to initiate the query.
export const useBuilderPatientsByUPID = (): UseQueryResult<Array<PatientModel>> =>
  usePatientQuery({
    queryId: QUERY_KEY_BUILDER_PATIENTS_BY_UPID,
    queryFn: async ({ graphqlClient, telemetry, requestContext, patient }) =>
      getPatientsForUPIDFQS(telemetry, graphqlClient, patient, {
        tag: {
          allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      }).catch(() => [patient]),
  });

interface UseMatchedPatientsOptions {
  includeCurrentBuilder?: boolean;
}

export const useMatchedPatients = ({ includeCurrentBuilder }: UseMatchedPatientsOptions = {}) => {
  const filterFn = useMemo(
    () =>
      includeCurrentBuilder
        ? undefined
        : (requestContext: CTWRequestContext) => ({
            tag: {
              nonematch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
            },
          }),
    [includeCurrentBuilder],
  );

  return usePatientQuery({
    queryId: QUERY_KEY_MATCHED_PATIENTS,
    queryKey: [includeCurrentBuilder, filterFn],
    queryFn: async ({ graphqlClient, telemetry, requestContext, patient }) =>
      getPatientsForUPIDFQS(telemetry, graphqlClient, patient, filterFn?.(requestContext)),
  });
};

type FQSFilterMatchLogic = {
  allmatch?: Array<string>;
  nonematch?: Array<string>;
};

type FQSFilter = {
  ids?: FQSFilterMatchLogic;
  tag?: FQSFilterMatchLogic;
};

export async function getPatientsForUPIDFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  filter: FQSFilter = {},
) {
  const tagHasAllmatch = filter.tag?.allmatch ?? false;
  const { data } = await fqsRequest<PatientGraphqlResponse>(
    telemetry,
    graphqlClient,
    patientsForUPIDQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: tagHasAllmatch
        ? filter
        : {
            ...filter,
            tag: {
              ...filter.tag,
              nonematch: [SYSTEM_SUMMARY, ...(filter.tag?.nonematch ?? [])],
            },
          },
    },
  );
  let nodes = data.PatientConnection.edges.map((x) => x.node);

  // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
  // Once https://zeushealth.atlassian.net/browse/DRT-249 is resolved,
  // remove the below filter and use the filter in the query.
  if (tagHasAllmatch) {
    nodes = nodes.filter((node) => !node.meta?.tag?.some((t) => t.system === SYSTEM_ZUS_SUMMARY));
  }

  return nodes
    .filter(
      (node) =>
        !node.meta?.tag?.some(
          (t) => t.system === SYSTEM_ZUS_UPI_RECORD_TYPE && t.code === 'universal',
        ),
    )
    .map((node) => new PatientModel(node));
}

export async function pollForPatientByIdentifier(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patientID: string,
  systemURL: string,
): Promise<PatientModel> {
  const maxAttempts = 6;
  const baseDelay = 2000;
  const maxDelay = 120000; // 2 minute max delay

  const attemptFetch = async (attempt: number): Promise<PatientModel> => {
    try {
      return await getBuilderFhirPatientByIdentifier(
        ctwFetch,
        requestContext,
        patientID,
        systemURL,
      );
    } catch (error) {
      if (attempt >= maxAttempts) {
        throw error;
      }

      const delay = Math.min(baseDelay * 2 ** attempt, maxDelay);
      return new Promise((resolve) => {
        setTimeout(() => resolve(attemptFetch(attempt + 1)), delay);
      });
    }
  };

  return attemptFetch(0);
}

// Returns a single FHIR patient given a patientID and systemURL.
// If multiple patients are found then it returns the last updated.
export async function getBuilderFhirPatientByIdentifier(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patientID: string,
  systemURL: string,
  tags?: Array<Tag>,
): Promise<PatientModel> {
  const params = {
    identifier: `${systemURL}|${patientID}`,
    _count: '50',
  };

  const patients = await searchBuilderRecords(
    ctwFetch,
    {
      env: requestContext.env,
      builderId: requestContext.builderId,
      authToken: requestContext.authToken,
    },
    'Patient',
    params,
  );

  const filteredPatients = filterByTags(patients, tags);

  if (filteredPatients.length === 0) {
    throw new Error(
      `Failed to find patient information for patient from patientID ${patientID} with system ${systemURL}`,
    );
  }

  // sort by lastUpdated in descending order to prefer more recent patients
  const sortedPatients = sort(filteredPatients, 'meta.lastUpdated', 'desc', true);
  // prefer active patient or patient with a name so we avoid using a stub/duplicate patient
  const patient =
    sortedPatients.find((x) => x.active === true) ??
    sortedPatients.find((x) => (x.name?.length ?? 0) > 0) ??
    sortedPatients[0];
  return new PatientModel(patient);
}

export async function getPatientByID(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patientID: string,
): Promise<PatientModel> {
  const response = await fetchResource(
    ctwFetch,
    {
      env: requestContext.env,
      builderId: requestContext.builderId,
      authToken: requestContext.authToken,
    },
    'Patient',
    patientID,
  );

  return new PatientModel(response as Patient);
}

interface UsePatientsListData {
  patients: Array<PatientModel>;
  pageInfo: GraphqlPageInfo;
}

export const usePatientsList = (pageSize: number, cursor: string) =>
  useCtwQuery({
    queryId: QUERY_KEY_PATIENTS_LIST_FQS,
    queryKey: [pageSize, cursor],
    queryFn: async ({ requestContext, telemetry, graphqlClient }): Promise<UsePatientsListData> => {
      const { data } = await fqsRequest<PatientGraphqlResponse>(
        telemetry,
        graphqlClient,
        patientsForBuilderQuery,
        {
          builderID: requestContext.builderId,
          cursor,
          first: pageSize,
          filter: {
            tag: {
              nonematch: [SYSTEM_ZUS_THIRD_PARTY],
            },
          },
        },
      );
      const models = data.PatientConnection.edges.map((x) => new PatientModel(x.node));
      return {
        patients: models,
        pageInfo: data.PatientConnection.pageInfo,
      };
    },
  });

export function usePatientSearchList(searchValue: string | undefined) {
  return useCtwQuery({
    queryId: QUERY_KEY_PATIENT_SEARCH,
    queryKey: [searchValue],
    queryFn: async ({ requestContext, ctwFetch }) => {
      if (!searchValue) {
        return [];
      }

      const params = {
        _count: '100',
        builderID: requestContext.builderId,
        [hasNumber(searchValue as string) ? 'identifier' : 'name']: searchValue as string,
      };

      const patients = await searchFirstPartyRecords(
        ctwFetch,
        {
          env: requestContext.env,
          builderId: requestContext.builderId,
          authToken: requestContext.authToken,
        },
        'Patient',
        params,
      );

      return patients.map((patient) => new PatientModel(patient));
    },
    enabled: Boolean(searchValue),
  });
}
