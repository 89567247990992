import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { type CTWState, useCTW } from '@ctw/shared/context/ctw-context';
import { useDrawer } from '@ctw/shared/context/drawer-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import { getFormResponseErrors } from '@ctw/shared/utils/errors';
import { type AnyZodSchema, getFormData } from '@ctw/shared/utils/form-helper';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { isEmpty } from 'lodash-es';
import { type FormEvent, type ReactNode, useState } from 'react';
import type { ZodEffects, ZodTypeAny } from 'zod';

export type FormErrors = Record<string, Array<string>>;
type InputError = Record<string, Array<string>>;

export type DrawerFormProps<T> = {
  boundaryName: string;
  action: (data: T, ctw: CTWState) => Promise<unknown>;
  closeOnSubmit?: boolean;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: (errorMessage: string) => void;
  schema: AnyZodSchema | ZodEffects<ZodTypeAny, unknown, unknown>;
  errorHeader?: string;
  children: (submitting: boolean, errors?: FormErrors) => ReactNode;
  trackingMetadata?: TrackingMetadata;
};

export const DrawerForm = <T,>({
  boundaryName,
  action,
  closeOnSubmit = false,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  children,
  schema,
  errorHeader = 'There was an error with your submission',
  trackingMetadata,
}: DrawerFormProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{
    formErrors?: FormErrors;
    requestErrors?: Array<string>;
  }>();
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const { closeDrawer } = useDrawer({
    boundaryName,
    onDrawerEvent: (eventType) => {
      if (eventType === 'close') {
        setErrors({});
        setIsSubmitting(false);
      }
    },
  });

  const onFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setErrors({});
    setIsSubmitting(true);
    const form = event.target;

    const inputs = Array.from((event.target as HTMLElement).querySelectorAll('input'));

    const inputErrors: InputError = {};

    for (const input of inputs) {
      if (!input.checkValidity()) {
        inputErrors[input.name] = [`The value is not valid for ${input.name}`];
      }
    }

    if (!isEmpty(inputErrors)) {
      setErrors({ formErrors: inputErrors });
      setIsSubmitting(false);
      return;
    }

    const data = new FormData(form as HTMLFormElement);

    // Validation that occurs before the request.
    const formResult = await getFormData(data, schema);
    if (!formResult.success) {
      // biome-ignore lint/suspicious/noConsole: <explanation>
      console.error('There was an error processing form data. formResult:', formResult);
      setErrors({
        formErrors: formResult.errors,
        requestErrors: undefined,
      });
      setIsSubmitting(false);
      return;
    }

    let response: unknown;
    let responseIsSuccess = true;
    let requestErrors: ReturnType<typeof getFormResponseErrors>['requestErrors'] | undefined;
    try {
      if (closeOnSubmit) {
        closeDrawer();
      }
      if (onSubmit) {
        onSubmit();
      }
      response = await action(formResult.data as never, ctw);
    } catch (e) {
      telemetry.trackError({ error: e });
      responseIsSuccess = false;
      requestErrors = getFormResponseErrors(e).requestErrors;
    }

    if (response) {
      const formResponseErrors = getFormResponseErrors(response);
      responseIsSuccess = formResponseErrors.responseIsSuccess;
      requestErrors = formResponseErrors.requestErrors;
    }

    // Setting any errors from the response to the form.
    if (!responseIsSuccess) {
      setErrors({
        formErrors: undefined,
        requestErrors,
      });
      setIsSubmitting(false);
      if (onSubmitError) {
        onSubmitError(requestErrors?.join(', ') ?? 'An error occurred');
      }
    } else {
      setIsSubmitting(false);
      if (!closeOnSubmit) {
        closeDrawer();
      }
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    }
  };

  return (
    <form
      className={tw`flex h-full flex-col overflow-y-auto`}
      onSubmit={(event) => {
        void onFormSubmit(event);
        telemetry.trackInteraction('submit_form', trackingMetadata);
      }}
      noValidate={true} // Removes the browser tooltip functionality.
    >
      <div className={tw`flex-1 space-y-4 px-2`}>
        {errors?.requestErrors && (
          <Alert type="error" header={errorHeader}>
            {errors.requestErrors.length === 1 ? (
              errors.requestErrors[0]
            ) : (
              <ul className={tw`m-0 list-disc px-4`}>
                {errors.requestErrors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}
        {children(isSubmitting, errors?.formErrors)}
      </div>
      <div className={tw`flex flex-col gap-3 border-divider-main border-t p-3`}>
        {(errors?.requestErrors || errors?.formErrors) && (
          <div className={tw`font-medium text-error-text text-sm`}>
            There was an error with your submission
          </div>
        )}
        <div className={tw`flex items-center justify-end`}>
          <Button
            type="button"
            variant="link"
            className={tw`!px-4 !py-2`}
            onClick={() => {
              closeDrawer();
              telemetry.trackInteraction('close_drawer', {
                target: 'footer_close_icon',
                ...trackingMetadata,
              });
            }}
          >
            Cancel
          </Button>
          <SaveButton submitting={isSubmitting} />
        </div>
      </div>
    </form>
  );
};

type SaveButtonProps = {
  submitting: boolean;
};

const SaveButton = ({ submitting }: SaveButtonProps) => (
  <Button
    type="submit"
    variant="primary"
    disabled={submitting}
    className={twx('save-button w-28 whitespace-nowrap')}
  >
    {submitting ? 'Saving...' : 'Save'}
    {submitting && <LoadingSpinner iconClass={tw`ml-2 text-white`} />}
  </Button>
);
