import { toggleDismiss } from '@ctw/shared/api/fhir/basic';
import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { Button } from '@ctw/shared/components/button';
import type { TableColumn } from '@ctw/shared/components/table/table';
import { useConditionWrite } from '@ctw/shared/content/conditions/use-condition-write';
import type { ConditionType } from '@ctw/shared/content/forms/actions/conditions';
import type { BulkAction } from '@ctw/shared/content/hooks/use-bulk-action-modal';
import type { ZAPSelectedResources } from '@ctw/shared/context/bulk-action-context';
import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { PatientState } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
} from '@ctw/shared/utils/query-keys';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import type { Condition } from 'fhir/r4';
import { useMemo } from 'react';

export const bulkDismissConditions: BulkAction<Condition, ConditionModel> = {
  type: 'dismiss-bulk',
  action: async (
    patient: PatientState,
    ctw: CTWState,
    telemetry: ReturnType<typeof useTelemetry>,
    selectedConditions: Array<ConditionModel>,
  ) => {
    await Promise.all(
      selectedConditions.map(async (condition) => {
        if (condition.preferredCoding && patient.patient.id) {
          await toggleDismiss(condition, ctw, telemetry);
        }
      }),
    );
  },
};

export const useBulkAddConditions = (): BulkAction<Condition, ConditionModel> => {
  const queryClient = useQueryClient();
  const conditionWrite = useConditionWrite();

  return useMemo(
    () => ({
      type: 'add',
      action: async (
        patient: PatientState,
        _ctw: CTWState,
        telemetry: ReturnType<typeof useTelemetry>,
        selected: Array<ConditionModel>,
      ) => {
        await Promise.all(
          selected.map(async (condition) => {
            if (!(condition.preferredCoding && patient.patient.id)) {
              return;
            }

            try {
              await conditionWrite({
                id: condition.id,
                status: condition.displayStatus,
                type: condition.type as ConditionType,
                condition: condition.preferredCoding,
                abatement: condition.abatement ? new Date(condition.abatement) : undefined,
                onset: condition.onset ? new Date(condition.onset) : undefined,
                note: condition.notes.join('\n'),
              });
            } catch (error) {
              telemetry.trackError({ message: 'Error executing conditionWrite', error });
              throw error;
            }
            await queryClient.invalidateQueries({
              queryKey: [
                QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
                QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
              ],
            });
          }),
        );
      },
    }),
    [conditionWrite, queryClient],
  );
};

export const conditionsBulkActionColumns = (
  selectedResources: ZAPSelectedResources,
  setSelectedResources: (resources: ZAPSelectedResources) => void,
): Array<TableColumn<ConditionModel>> => [
  {
    title: 'Condition',
    render: (condition) => <div>{condition.display}</div>,
    widthPercent: 35,
  },
  {
    title: 'Onset Date',
    render: (condition) => <div>{condition.onset}</div>,
    widthPercent: 18,
  },
  {
    title: 'Status',
    render: (condition) => <div>{condition.displayStatus}</div>,
    widthPercent: 18,
  },
  {
    title: 'Type',
    render: (condition) => <div>{condition.type}</div>,
    widthPercent: 18,
  },
  {
    title: '',
    render: (condition) => (
      <Button
        type="button"
        variant="link"
        disabled={selectedResources['conditions-all'].length === 1}
        className={twx('di space-x-1', {
          'text-content-icon hover:text-content-icon':
            selectedResources['conditions-all'].length === 1,
        })}
        onClick={() => {
          const newSelectedResources = {
            ...selectedResources,
            'conditions-all': selectedResources['conditions-all'].filter(
              (c) => c.id !== condition.id,
            ),
          };
          setSelectedResources(newSelectedResources);
        }}
      >
        <span>
          <FontAwesomeIcon icon={faTrash} className={tw`w-4`} />
        </span>
        <span>Remove</span>
      </Button>
    ),
    widthPercent: 18,
  },
];
