import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useModal } from '@ctw/shared/context/modal-context';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { tw } from '@ctw/shared/utils/tailwind';
import { SupportFormLink } from '../support-form-link';
import { usePatientHistory } from './use-patient-history';
import { usePatientHistoryStatus } from './use-patient-history-status';

export function useRequestRecordsModal() {
  const { openHistoryRequestDrawer } = usePatientHistory();
  const { isLoading, lastCompletedDate } = usePatientHistoryStatus();

  let header = (
    <div>
      <LoadingSpinner message="Loading" />
    </div>
  );

  // Because of query caching, it is unlikely for users to ever see the above loading state.
  if (!isLoading) {
    if (lastCompletedDate) {
      header = <span>Records last updated {formatFHIRDate(lastCompletedDate.toISOString())}</span>;
    } else {
      header = <span>Records never requested</span>;
    }
  }
  const { openModal, closeModal } = useModal({
    boundaryName: 'RequestRecordsModal',
  });

  return () => {
    openModal({
      content: {
        body: (
          <>
            <div className={tw`w-[372px] space-y-6`}>
              <div className={tw`space-y-2`}>
                <div className={tw`font-medium`}>{header}</div>
                <div className={tw`text-sm`}>
                  Patient records are automatically updated when Zus thinks new information is
                  available. If you feel that something is missing please let us know.
                </div>
              </div>

              <div className={tw`flex justify-between`}>
                <Button type="button" onClick={closeModal} variant="link">
                  Cancel
                </Button>

                <div className={tw`flex space-x-3`}>
                  <Button
                    type="button"
                    onClick={() => {
                      closeModal();
                      void openHistoryRequestDrawer();
                    }}
                    variant="secondary"
                    className={tw`!normal-case`}
                  >
                    Request records
                  </Button>
                  <SupportFormLink
                    buttonText="Report an issue"
                    className={tw`!normal-case rounded-md border border-transparent bg-primary-main px-4 py-2 font-medium text-sm text-white shadow-sm hover:bg-primary-text`}
                  />
                </div>
              </div>
            </div>
          </>
        ),
      },
    });
  };
}
