import { Telemetry } from '@ctw/shared/context/telemetry';
import { tw } from '@ctw/shared/utils/tailwind';
import { Theme as RadixUiTheme } from '@radix-ui/themes';
import type { RouterConfig } from '@react-types/shared';
import { useLocalStorage } from '@uidotdev/usehooks';
import { trim, trimStart, uniqueId } from 'lodash-es';
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

export type RouterOptions = Parameters<ReturnType<typeof useZuiContext>['navigate']>[1];

export type ZusService = 'standalone' | 'zap' | 'smart-on-fhir';

export const ZapVersions = ['v1', 'v2'] as const;
export type ZapVersion = (typeof ZapVersions)[number];

export const isZapVersion = (ZuiVersion: string | null | undefined): ZuiVersion is ZapVersion =>
  ZapVersions.includes(ZuiVersion as never);

type ZuiPresence =
  | 'legacyMainNavigationBar'
  | 'mainNavigationBar'
  | 'subNavigationBar'
  | 'dockedDrawer'
  | 'loadingSpinner';

type ZuiPresences = {
  [key in ZuiPresence]: boolean;
};

type ZuiPresenceRegistrations = {
  [key in ZuiPresence]: Array<string>;
};

const defaultZuiPresences: ZuiPresences = {
  dockedDrawer: false,
  legacyMainNavigationBar: false,
  mainNavigationBar: false,
  subNavigationBar: false,
  loadingSpinner: false,
} as const;

const defaultZuiPresenceRegistrations: ZuiPresenceRegistrations = {
  dockedDrawer: [],
  legacyMainNavigationBar: [],
  mainNavigationBar: [],
  subNavigationBar: [],
  loadingSpinner: [],
} as const;

type ZuiEventType = 'presenceAppear' | 'presenceDisappear' | 'beforeNavigate' | 'afterNavigate';

interface ZuiEventPayloads extends Record<ZuiEventType, Record<string, unknown>> {
  beforeNavigate: {
    to: string;
  };
  afterNavigate: {
    to: string;
  };
  presenceAppear: {
    presence: ZuiPresence;
  };
  presenceDissapear: {
    presence: ZuiPresence;
  };
}

interface ZuiEvent<EventName extends ZuiEventType = ZuiEventType> {
  name: ZuiEventType;
  payload: ZuiEventPayloads[EventName];
}

interface ZuiState {
  service: string;
  navigate: ZuiProviderProps['navigate'];
  presences: ZuiPresences;
  registerPresence: (presence: ZuiPresence) => () => void;
  onZuiEvent: <TEventName extends ZuiEventType>(event: ZuiEvent<TEventName>) => void;
  registerZuiEventListener: <TEventName extends ZuiEventType>(
    eventName: TEventName,
    handler: (event: ZuiEvent<TEventName>) => void,
  ) => () => void;
  isNavigating: boolean;
  pathname: string;
  setUiFlag: (key: string, value: boolean) => void;
  getUiFlag: (key: string, defaultValue: boolean) => boolean;
  setUiPreference: (key: string, value: string) => void;
  getUiPreference: (key: string, defaultValue: string) => string;
}

export const ZuiContext = createContext<ZuiState | null>(null);

export const useZuiContext = () => {
  const context = useContext(ZuiContext);

  if (!context) {
    throw new Error('useZuiContext must be used within a ZuiProvider');
  }

  return context;
};

export const useZuiContextIfAvailable = () => useContext(ZuiContext);

export const useRegisterPresence = (presence: ZuiPresence): void => {
  const context = useContext(ZuiContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => context?.registerPresence(presence), [presence]);
};

export const usePresence = (presence: ZuiPresence): boolean => {
  const context = useContext(ZuiContext);

  return context?.presences[presence] ?? false;
};

interface ZuiProviderProps extends PropsWithChildren {
  service: ZusService | 'storybook' | 'playwright';
  navigate: (path: string, routerOptions?: RouterConfig['routerOptions'] | undefined) => void;
  navigateBasePath?: string;
  navigatePersistentQueryParams?: Array<string>;
  pathname: () => string | { pathname: string };
}

export const ZuiProvider = ({
  service,
  navigate,
  navigateBasePath,
  navigatePersistentQueryParams,
  pathname: usePathname,
  children,
}: ZuiProviderProps) => {
  const pathname = usePathname();
  const [isNavigating, setIsNavigating] = useState(false);
  const presences = useMemo(() => ({ ...defaultZuiPresences }), []);
  const [eventListeners, setEventListeners] = useState<
    Record<
      Parameters<ZuiState['registerZuiEventListener']>[0],
      Array<Parameters<ZuiState['registerZuiEventListener']>[1]>
    >
  >({
    presenceAppear: [],
    presenceDisappear: [],
    beforeNavigate: [],
    afterNavigate: [],
  });
  const presenceRegistrations: ZuiPresenceRegistrations = useMemo(
    (): ZuiPresenceRegistrations => ({ ...defaultZuiPresenceRegistrations }),
    [],
  );
  const [uiPreferences, setUiPreferences] = useLocalStorage<Record<string, unknown>>(
    'zui-preferences',
    {},
  );

  const onZuiEvent = useCallback(
    (event: ZuiEvent) => {
      for (const listener of eventListeners[event.name]) {
        listener(event);
      }
    },
    [eventListeners],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const zuiContext: ZuiState = useMemo(
    () => ({
      service,
      navigate: (requestedPath, routerOptions) => {
        // Disallow navigation if we're already in the middle of navigating
        if (isNavigating) {
          return;
        }

        // Save the pathname and search params from the current URL before navigating
        const pathnameBeforeNavigation = window.location.pathname;
        const searchBeforeNavigation = window.location.search;

        // Try to parse `requestedPath` into a pathname and search params
        let requestedPathname: string;
        let requestedSearchParams: URLSearchParams | undefined;
        try {
          const newUrl = new URL(
            requestedPath,
            `${window.location.protocol}//${window.location.host}`,
          );
          requestedPathname = newUrl.pathname;
          requestedSearchParams = newUrl.searchParams;
        } catch {
          // Failure to parse `requestedPath` means the path was invalid, so we can't navigate to it
          return;
        }

        // Construct our destination URL
        let destinationUrl: URL | undefined;
        try {
          const destinationUrlComponents = [
            trim(navigateBasePath, '/') /* The base path the `ZuiProvider` is configured with */,
            trimStart(requestedPathname, '/') /* The requested pathname */,
          ];
          destinationUrl = new URL(
            destinationUrlComponents.join('/'),
            `${window.location.protocol}//${window.location.host}`,
          );

          // If `ZuiProvider` was provided a `navigatePersistentQueryParams` prop, we will always copy
          // those search params from the current URL (if present) to the destination search params
          if (navigatePersistentQueryParams) {
            const currentUrl = new URL(window.location.href);
            for (const param of navigatePersistentQueryParams) {
              const value = currentUrl.searchParams.get(param);
              if (value) {
                destinationUrl.searchParams.set(param, value);
              }
            }
          }

          // If the requested path included query params, we will also copy those to the destination
          for (const [param, value] of requestedSearchParams.entries()) {
            destinationUrl.searchParams.set(param, value);
          }
        } catch {
          // If we fail to construct the destination URL, we can't navigate to it
          return;
        }

        if (
          destinationUrl.pathname === pathnameBeforeNavigation &&
          destinationUrl.search === searchBeforeNavigation
        ) {
          // Disallow navigating to the current URL unless the search params have changed
          return;
        }

        const requestedPathIsAbsolute =
          requestedPath.startsWith('/') || requestedPath.includes('://');

        // Construct the destination path we will pass to the underlying `navigate` implementstion
        const destinationPathname = requestedPathIsAbsolute
          ? destinationUrl.pathname
          : trimStart(destinationUrl.pathname, '/');
        const destinationPath = `${destinationPathname}${destinationUrl.search}`;

        // Only trigger navigation events for real page changes; this ensures that we don't trigger
        // navigation events for things like opening a drawer with a deep link, which would cause
        // unnecessary UI churn and potentially extra loading indicators.
        const triggerNavigationEvents = requestedPathIsAbsolute
          ? destinationPathname !== pathnameBeforeNavigation
          : destinationPathname !== trimStart(pathnameBeforeNavigation, '/');

        // Trigger the `beforeNavigate` event and set the `isNavigating` state to `true`
        if (triggerNavigationEvents) {
          onZuiEvent({
            name: 'beforeNavigate',
            payload: {
              to: requestedPathname,
            },
          });
          setIsNavigating(true);
        }

        // Perform the actual navigation
        navigate(destinationPath, routerOptions);

        // Poll, waiting for the current URL's pathname or search params to change, and then trigger
        // the `afterNavigate` event and set the `isNavigating` state to `false`
        const handleNavigationCompletion = async () => {
          let failedToNavigate = false;

          // Set a timeout which will flag the navigation as failed if it doesn't finish naturally
          setTimeout(() => {
            if (
              window.location.pathname === pathnameBeforeNavigation &&
              window.location.search === searchBeforeNavigation
            ) {
              failedToNavigate = true;
            }
          }, 5000);

          do {
            await new Promise((resolve) => {
              setTimeout(resolve, 300);
            });
          } while (
            !failedToNavigate &&
            window.location.pathname === pathnameBeforeNavigation &&
            window.location.search === searchBeforeNavigation
          );

          if (triggerNavigationEvents) {
            Telemetry.afterNavigate();
            onZuiEvent({
              name: 'afterNavigate',
              payload: {
                to: requestedPathname,
              },
            });
            setIsNavigating(false);
          }
        };

        void handleNavigationCompletion();
      },
      registerPresence: (presence: ZuiPresence) => {
        const presenceId = uniqueId();

        presenceRegistrations[presence].push(presenceId);

        if (presenceRegistrations[presence].length === 1) {
          onZuiEvent({
            name: 'presenceAppear',
            payload: {
              presence,
            },
          });

          presences[presence] = true;
        }

        return () => {
          presenceRegistrations[presence].splice(
            presenceRegistrations[presence].indexOf(presenceId),
            1,
          );

          if (presenceRegistrations[presence].length === 0) {
            onZuiEvent({
              name: 'presenceDisappear',
              payload: {
                presence,
              },
            });

            presences[presence] = false;
          }
        };
      },
      onZuiEvent,
      registerZuiEventListener: (eventName, listener) => {
        setEventListeners((prev) => ({
          ...prev,
          [eventName]: [...prev[eventName], listener],
        }));

        return () =>
          setEventListeners((prev) => ({
            ...prev,
            [eventName]: prev[eventName].filter((l) => l !== listener),
          }));
      },
      isNavigating,
      presences,
      pathname: typeof pathname === 'string' ? pathname : pathname.pathname,
      setUiFlag: (key, value) => setUiPreferences({ ...uiPreferences, [key]: value }),
      getUiFlag: (key, defaultValue) =>
        typeof uiPreferences[key] === 'boolean' ? uiPreferences[key] : defaultValue,
      setUiPreference: (key, value) => setUiPreferences({ ...uiPreferences, [key]: value }),
      getUiPreference: (key, defaultValue) =>
        typeof uiPreferences[key] === 'string' ? uiPreferences[key] : defaultValue,
    }),
    [
      service,
      isNavigating,
      pathname,
      navigatePersistentQueryParams,
      navigateBasePath,
      uiPreferences,
    ],
  );

  return (
    // https://www.radix-ui.com/themes/docs/components/theme
    <RadixUiTheme
      className={tw`flex h-[100vh] max-h-[100vh] w-[100vw] max-w-[100vw] flex-col overflow-hidden`}
      hasBackground={false}
      appearance="light"
      panelBackground="translucent"
      radius="medium"
      scaling="100%"
      accentColor="green"
      grayColor="gray"
    >
      <ZuiContext.Provider value={zuiContext}>{children}</ZuiContext.Provider>
    </RadixUiTheme>
  );
};
