import { codeableConceptLabel } from '@ctw/shared/api/fhir/codeable-concept';
import {
  SYSTEM_ENRICHMENT,
  SYSTEM_LOINC,
  SYSTEM_NULL_FLAVOR,
} from '@ctw/shared/api/fhir/system-urls';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { LOINC_LAB } from '@ctw/shared/utils/loinc-codes';
import type { Coding, DiagnosticReport } from 'fhir/r4';
import { compact, find } from 'lodash-es';
import { FHIRModel } from './fhir-model';

export class DiagnosticReportModel extends FHIRModel<DiagnosticReport> {
  public kind = 'DiagnosticReport' as const;

  public get displayName() {
    return this.loincCode?.display || codeableConceptLabel(this.resource.code);
  }

  public get date() {
    return (
      this.resource.effectivePeriod?.start ||
      this.resource.effectiveDateTime ||
      this.resource.issued
    );
  }

  public get numResultsDisplay() {
    if (this.resource.result?.length === 0) {
      return '';
    }
    const tResult = this.resource.result && this.resource.result.length > 1 ? 'results' : 'result';
    return `${this.resource.result?.length ?? 0} ${tResult}`;
  }

  public get dateDisplay() {
    return this.date ? formatFHIRDate(this.date) : undefined;
  }

  public get performer() {
    const performers = this.resource.performer ?? [];
    const org = find(performers, { type: 'Organization' });
    return org?.display ?? performers[0]?.display;
  }

  public get title() {
    return this.displayName;
  }

  public get observationIds() {
    return compact(this.resource.result?.map((result) => result.reference?.split('/')[1]));
  }

  public get loincCode() {
    return findEnrichedLoincCoding(this.resource.code.coding);
  }

  public get isLabReport() {
    return this.resource.code.coding?.some(
      (coding) =>
        coding.code === LOINC_LAB &&
        coding.system === SYSTEM_LOINC &&
        coding.extension?.some(
          (ext) => ext.valueString === 'LOINC Category' && ext.url === SYSTEM_ENRICHMENT,
        ),
    );
  }
}

export const findEnrichedLoincCoding = (coding?: Array<Coding>) => {
  if (!coding) {
    return undefined;
  }
  return coding.find(
    (x) =>
      x.extension?.find(
        (ext) => ext.valueString === 'LOINC Standardization' && ext.url === SYSTEM_ENRICHMENT,
      ) &&
      x.display &&
      x.system !== SYSTEM_NULL_FLAVOR,
  );
};
