import type { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { Env } from '@ctw/shared/context/types';
import { isEmptyValue } from '@ctw/shared/utils/types';
import type { GraphQLClient, Variables } from 'graphql-request';
import { get, isEmpty } from 'lodash-es';
import { graphQLToFHIR } from './graphql-to-fhir';

export interface GraphqlPageInfo {
  hasNextPage: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface GraphqlConnectionNode<T> {
  node: T;
}

export interface GenericConnection<T extends ResourceTypeString> {
  pageInfo: GraphqlPageInfo;
  edges: Array<GraphqlConnectionNode<ResourceType<T>>>;
}

// interface FqsResponse<T extends ResourceTypeString> {
//   data: {
//     [key: T]: GenericConnection<T>;
//   };
// }

/*
 * FQS has hard limit of 500 objects per request
 */
export const MAX_OBJECTS_PER_REQUEST = 500;

export const ALL_PAGES = 9999;

/*
 * @deprecated This method should not be used and will be replaced soon.
 */
export async function fqsRequest<T>(
  telemetry: ReturnType<typeof useTelemetry>,
  client: GraphQLClient,
  query: string,
  variables: object,
): Promise<{ data: T }> {
  const updatedVariables = { ...variables };
  if (
    'first' in updatedVariables &&
    typeof updatedVariables.first === 'number' &&
    updatedVariables.first > MAX_OBJECTS_PER_REQUEST
  ) {
    updatedVariables.first = MAX_OBJECTS_PER_REQUEST;
  }
  const { data, errors } = await client.rawRequest<T>(query, updatedVariables as Variables);

  void telemetry.trackMetric('fqs_request', {
    query,
    variables: updatedVariables,
  });

  const fhirData = graphQLToFHIR(data);
  if (errors) {
    const requestCompletelyFailed = isEmptyValue(data);

    telemetry.logger.error(
      requestCompletelyFailed
        ? 'FQS request failed'
        : 'FQS request partially failed, proceeding with returned data',
      {
        requestCompletelyFailed,
        errors: errors.slice(0, 10),
        errorsTruncated: errors.length > 10,
      },
    );
    if (requestCompletelyFailed) {
      throw errors;
    }
  }
  return { data: fhirData };
}

/*
 * @deprecated This method should not be used and will be replaced soon.
 */
export async function fqsRequestAll<T>(
  telemetry: ReturnType<typeof useTelemetry>,
  client: GraphQLClient,
  query: string,
  namespace: string,
  variables: object,
  pageLimit = ALL_PAGES,
): Promise<{ data: T; fqsPagesFetched: number; fqsHadMorePages: boolean }> {
  if (!isEmpty(get(variables, 'sort'))) {
    throw new Error(
      "'sort' variable not allowed in fqsRequestAll; FQS does not support sorting with pagination",
    );
  }
  let pageCount = 0;
  let fqsHasNextPageClaim = true;
  const allResponses: Array<{ data: T }> = [];
  let lastEndCursor = '';

  const processResponses = (responses: Array<{ data: T }>) =>
    responses.reduce(
      (acc, next) => {
        const edges = get(acc, ['data', namespace, 'edges'], []);
        const nextEdges = get(next, ['data', namespace, 'edges'], []);

        return {
          ...acc,
          data: {
            [namespace]: {
              edges: edges.concat(nextEdges),
            },
          } as T,
        };
      },
      { data: { [namespace]: { edges: [] } } } as { data: T },
    );

  do {
    const updatedVariables = {
      ...variables,
      // Some queries require 'sort', but if it is set to anything besides an empty object, fqs pagination breaks!
      sort: {},
      cursor: lastEndCursor,
    };
    const response = await fqsRequest<T>(telemetry, client, query, updatedVariables);
    allResponses.push(response);
    const endCursor = get(response, ['data', namespace, 'pageInfo', 'endCursor'], '');
    fqsHasNextPageClaim =
      lastEndCursor !== endCursor &&
      get(response, ['data', namespace, 'pageInfo', 'hasNextPage'], false);

    lastEndCursor = endCursor;
    pageCount += 1;
  } while (pageLimit > pageCount && fqsHasNextPageClaim);

  // This metric informs how many pages we've fetched and whether we finished early with still more pages to fetch.
  telemetry.trackMetric('fqs_request_all', {
    pages_fetched: pageCount,
    has_next_page: fqsHasNextPageClaim,
    query_type: namespace,
  });

  const processedResponses = processResponses(allResponses);

  return {
    data: processedResponses.data,
    fqsHadMorePages: fqsHasNextPageClaim,
    fqsPagesFetched: pageCount,
  };
}

/*
 * @deprecated This method should not be used and will be replaced soon.
 */
export function getFetchFromFqs(ctwFetch: CTWState['ctwFetch'], env: Env, accessToken: string) {
  return (url: string, options: RequestInit) =>
    ctwFetch(`${getZusServiceUrl(env, 'fqs')}/${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      } as Record<string, string>,
    });
}
