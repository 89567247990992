import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';

export interface ContentErrorProps {
  icon?: FontAwesomeIconProps['icon'];
  title: string;
  message?: string;
  children?: ReactNode;
  className?: string;
}

export const ContentError = ({ icon, title, message, children, className }: ContentErrorProps) => (
  <div className={twx(className)}>
    <div className={twx('text-xl')}>
      <div className={tw`flex justify-center`}>
        <FontAwesomeIcon
          icon={icon ?? faExclamationCircle}
          className={tw`h-16 text-content-subtle`}
        />
      </div>
      <div className={tw`h-full space-y-1 text-center text-base`}>
        <div className={tw`font-medium text-lg`}>{title}</div>
        <div>
          {message && <span>{message}</span>}
          <div>{children}</div>
        </div>
      </div>
    </div>
  </div>
);
