import { getBinaryId } from '@ctw/shared/api/fhir/binaries';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { searchProvenances } from '@ctw/shared/api/fhir/provenance';
import { excludeTagsinPatientRecordSearch } from '@ctw/shared/api/fhir/search-helpers';
import type { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import type { GenericConnection } from '@ctw/shared/api/fqs/client';
import { allergyQuery } from '@ctw/shared/api/fqs/queries/allergies';
import { conditionsQuery } from '@ctw/shared/api/fqs/queries/conditions';
import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { tw } from '@ctw/shared/utils/tailwind';
import type { UseQueryResult } from '@tanstack/react-query';
import type { Resource } from 'fhir/r4';
import type { GraphQLClient } from 'graphql-request';
import { isEqual, orderBy, uniqWith } from 'lodash-es';
import { useState } from 'react';
import { HistoryEntry } from './history-entry';
import type { HistoryEntryProps } from './history-entry';

export type UseHistoryProps<T extends ResourceTypeString, M extends FHIRModel<ResourceType<T>>> = {
  resourceType: T;
  model: M;
  queryKey: string;
  valuesToDedupeOn: (m: M) => unknown;
  getHistoryEntry: (m: M) => HistoryEntryProps;
  getFiltersFQS?: (m: M) => object | undefined;
  clientSideFiltersFQS?: (model: M, resources: Array<ResourceType<T>>) => Array<ResourceType<T>>;
};

export const useHistory = <T extends ResourceTypeString, M extends FHIRModel<ResourceType<T>>>({
  resourceType,
  model,
  queryKey,
  valuesToDedupeOn,
  getHistoryEntry,
  getFiltersFQS,
  clientSideFiltersFQS,
}: UseHistoryProps<T, M>) =>
  usePatientQuery({
    queryId: queryKey,
    queryKey: [model],
    queryFn: async ({ graphqlClient, telemetry, patient }) =>
      fetchResourcesFQS(
        resourceType,
        model,
        graphqlClient,
        telemetry,
        patient,
        valuesToDedupeOn,
        getHistoryEntry,
        clientSideFiltersFQS,
        getFiltersFQS?.(model),
      ),
    enabled: true,
  });

function dedupeHistory<T extends Resource, M extends FHIRModel<T>>(
  resources: Array<M>,
  valuesToDedupeOn: (m: M) => unknown,
) {
  // We sort by isEnriched because we want enriched records to be preferred in uniqWith function.
  const enrichedFirst = orderBy(resources, ['isEnriched'], 'desc');

  return uniqWith(enrichedFirst, (a, b) => isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)));
}

function getResourceFQSQuery(resourceType: ResourceTypeString) {
  switch (resourceType) {
    case 'Condition':
      return conditionsQuery;
    case 'AllergyIntolerance':
      return allergyQuery;
    default:
      throw new Error(`Resource type to FQS query not implemented yet for ${resourceType}`);
  }
}

function getResourceNodes<T extends ResourceTypeString>(response: object): Array<ResourceType<T>> {
  const values = Object.values(response) as Array<GenericConnection<T>>;

  return values.flatMap((x) => x.edges.map((y) => y.node));
}

async function fetchResourcesFQS<
  T extends ResourceTypeString,
  M extends FHIRModel<ResourceType<T>>,
>(
  resourceType: T,
  model: M,
  graphqlClient: GraphQLClient,
  telemetry: ReturnType<typeof useTelemetry>,
  patient: PatientModel,
  valuesToDedupeOn: (m: M) => unknown,
  getHistoryEntry: (m: M) => HistoryEntryProps,
  clientSideFiltersFQS?: (model: M, resources: Array<ResourceType<T>>) => Array<ResourceType<T>>,
  filter?: object | undefined,
) {
  try {
    const resources =
      filter || clientSideFiltersFQS
        ? getResourceNodes<T>(
            await graphqlClient.request(getResourceFQSQuery(resourceType), {
              upid: patient.UPID,
              cursor: '',
              first: 500,
              sort: {
                lastUpdated: 'DESC',
              },
              filter,
            }),
          )
        : [model.resource];

    let filteredResources = filterLensAndSummary(resources, resourceType);

    if (clientSideFiltersFQS) {
      filteredResources = clientSideFiltersFQS(model, filteredResources);
    }

    // biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
    const constructor = model.constructor as new (r: ResourceType<T>) => M;
    const models = filteredResources.map((c) => new constructor(c));

    const entries = dedupeHistory(models, valuesToDedupeOn).map(getHistoryEntry);

    // Fetch provenances and add binaryId to each entry.
    const provenances = await searchProvenances(graphqlClient, models);
    entries.forEach((entry) => {
      entry.binaryId = getBinaryId(provenances, entry.id);
    });

    return entries;
  } catch (e) {
    telemetry.trackError({
      message: `Failed fetching ${resourceType} history for patient via FQS: ${patient.UPID}}`,
      error: e,
    });
    throw e;
  }
}

function filterLensAndSummary<T extends ResourceTypeString>(
  resources: Array<ResourceType<T>>,
  resourceType: T,
) {
  // filter out anything we don't want
  return resources.filter((resource) => {
    // no tags are allowed through (should be an edge case)
    if (!resource.meta?.tag) {
      return true;
    }

    const hasExcludableTag =
      resource.meta.tag.filter((tag) =>
        excludeTagsinPatientRecordSearch(resourceType).includes(`${tag.system}|${tag.code}`),
      ).length > 0;

    return !hasExcludableTag;
  });
}

type HistoryEntries = Array<HistoryEntryProps>;

type HistoryProps<M> = {
  limit?: number;
  getHistory?: (model: M) => UseQueryResult<HistoryEntries | undefined>;
  model: M;
};

const DEFAULT_HISTORY_PAGE_LIMIT = 20;

export function History<T extends Resource, M extends FHIRModel<T>>({
  limit = DEFAULT_HISTORY_PAGE_LIMIT,
  getHistory,
  model,
}: HistoryProps<M>) {
  const history = getHistory?.(model);
  const entries: HistoryEntries = history?.data ?? [];
  const [showAll, setShowAll] = useState(!limit || entries.length <= limit);

  // Sort entries by:
  //  1. date descending
  //  2. has a source document (binaryId present or not)
  //  3. title ascending
  //  4. subtitle ascending
  //  5. versionId descending (this is to ensure consistent ordering)
  const sortedEntries = orderBy(
    entries,
    [
      // Convert the date string to a Date object so that it sorts correctly.
      (e) => (e.date ? new Date(e.date) : ''), // desc
      // We want to promote entries with a source document to the top.
      (e) => !!e.binaryId, // desc
      'title', // asc
      'subtitle', // asc
      'versionId', // desc
    ],
    ['desc', 'desc', 'asc', 'asc', 'desc'],
  );

  const displayedEntries = showAll || !limit ? sortedEntries : sortedEntries.slice(0, limit);

  return history?.isLoading ? (
    <LoadingSpinner message="Loading history..." />
  ) : (
    <div className={tw`space-y-4`}>
      <div className={tw`font-semibold text-lg`}>History</div>
      {displayedEntries.map((entry, idx) => (
        // We can have multiple items with the same condition id
        <div key={`${entry.id}-${idx}`}>
          <HistoryEntry
            id={entry.id}
            date={entry.date}
            title={entry.title}
            subtitle={entry.subtitle}
            details={entry.details}
            hideEmpty={entry.hideEmpty}
            binaryId={entry.binaryId}
            model={model}
          />
        </div>
      ))}
      {!showAll && (
        <div className={tw`text-center`}>
          <Button type="button" variant="primary" onClick={() => setShowAll(true)}>
            {/* We know limit must be set if showAll is false. */}
            Load {sortedEntries.length - (limit ?? 0)} More
          </Button>
        </div>
      )}
    </div>
  );
}
