import './rotated-table.scss';

import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { type ReactElement, useRef } from 'react';
import type { DataIndexSpecified, MinRecordItem, RenderSpecified } from './table';

export type RotatedTableRows<T extends MinRecordItem> = {
  title: string;
  shortTitle?: string;
  greyed?: boolean;
} & (DataIndexSpecified<T> | RenderSpecified<T>);

export type RotatedTableProps<T extends MinRecordItem> = {
  className?: string;
  rows: Array<RotatedTableRows<T>>;
  records: Array<T>;
  isLoading?: boolean;
  emptyMessage?: string | ReactElement;
};

export const RotatedTable = <T extends MinRecordItem>({
  className,
  rows,
  records,
  isLoading = false,
  emptyMessage: message = 'No records found',
}: RotatedTableProps<T>) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const firstColCellRef = useRef<HTMLTableCellElement>(null);

  if (isLoading) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  if (Object.entries(records).length === 0) {
    return <div className={tw`flex justify-center`}>{message}</div>;
  }

  return (
    <div
      className={twx(
        'rotated-table-container md:rotated-table-small relative h-full max-h-full',
        className,
      )}
    >
      <div
        className={tw`no-scrollbar relative flex h-full max-h-full overflow-x-auto overflow-y-hidden`}
      >
        <table
          ref={tableRef}
          className={tw`no-scrollbar rotated-table-scroll-shadows block h-full max-h-full w-full overflow-y-auto overflow-x-visible`}
        >
          <colgroup>
            <col />
            {records.map((record) => (
              <col key={record.key} className={tw`first-of-type:w-0`} />
            ))}
          </colgroup>
          <tbody>
            {rows.map((row, index) => (
              <tr
                key={row.title}
                className={twx(
                  'border-divider-main border-b align-top first-of-type:sticky first-of-type:top-0 first-of-type:z-navigation-sticky first-of-type:bg-white first-of-type:font-semibold first-of-type:outline first-of-type:outline-2 first-of-type:outline-divider-main',
                  {
                    'bg-background-hover mix-blend-darken': row.greyed,
                  },
                )}
              >
                <td
                  ref={index === 0 ? firstColCellRef : undefined}
                  className={twx(
                    'title-column relative sticky left-0 z-navigation whitespace-pre-wrap bg-white p-3 px-2 py-3 text-background-inverse text-sm',
                    {
                      'bg-background-hover first-of-type:mix-blend-normal': row.greyed,
                    },
                  )}
                >
                  <span className={tw`block md:hidden`}>{row.shortTitle ?? row.title}</span>
                  <span className={tw`hidden md:block`}>{row.title}</span>
                  <div className={tw`absolute top-0 right-0 bottom-0 w-[2px] bg-divider-main`} />
                </td>
                {records.map((record) => {
                  if (row.dataIndex) {
                    const value = record[row.dataIndex] as string | undefined;
                    return (
                      <td key={record.key}>
                        <div className={tw`flex h-full items-center`}>
                          <div
                            title={value}
                            className={tw`rotated-table-truncate overflow-hidden text-ellipsis whitespace-nowrap p-1`}
                          >
                            {value}
                          </div>
                        </div>
                      </td>
                    );
                  }

                  return (
                    <td key={record.key}>
                      <div className={tw`flex h-full items-center`}>
                        <div
                          className={tw`rotated-table-truncate overflow-hidden text-ellipsis whitespace-nowrap p-1`}
                        >
                          {row.render?.(record)}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
