import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const lastUpdatedMostRecentConditionSort: SortOption<ConditionModel> = {
  display: 'Last Diagnosed (New to Old)',
  sorts: [{ key: 'recordedDate', dir: 'desc', isDate: true }],
};

export const nameAzConditionSort: SortOption<ConditionModel> = {
  display: 'Name (A-Z)',
  sorts: [{ key: 'display', dir: 'asc' }],
};

export const conditionSortOptions: Array<SortOption<ConditionModel>> = [
  nameAzConditionSort,
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'display', dir: 'desc' }],
  },
  {
    display: 'Category (A-Z)',
    sorts: [{ key: 'ccsChapter', dir: 'asc' }],
  },
  {
    display: 'Category (Z-A)',
    sorts: [{ key: 'ccsChapter', dir: 'desc' }],
  },
  {
    display: 'Type (A-Z)',
    sorts: [{ key: 'typeDisplay', dir: 'asc' }],
  },
  {
    display: 'Type (Z-A)',
    sorts: [{ key: 'typeDisplay', dir: 'desc' }],
  },
  {
    display: 'Last Diagnosed (Old to New)',
    sorts: [{ key: 'recordedDate', dir: 'asc', isDate: true }],
  },
  lastUpdatedMostRecentConditionSort,
];

export const groupedConditionSortOptions: Array<SortOption<ConditionModel>> =
  conditionSortOptions.filter((sort) => sort.sorts[0].key !== 'ccsChapter');
