import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Button } from '@ctw/shared/components/button';
import { Heading } from '@ctw/shared/components/heading';
import type { TableProps } from '@ctw/shared/components/table/table';
import { notify } from '@ctw/shared/components/toast';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import type { WritebackAction } from '@ctw/shared/content/resource/resource-title-column';
import {
  BulkActionContext,
  type ZAPSelectedResources,
} from '@ctw/shared/context/bulk-action-context';
import { type CTWState, useCTW } from '@ctw/shared/context/ctw-context';
import { useModal } from '@ctw/shared/context/modal-context';
import { type PatientState, usePatientContext } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { tw } from '@ctw/shared/utils/tailwind';
import { Transition } from '@headlessui/react';
import type { Resource } from 'fhir/r4';
import { useContext, useState } from 'react';

export type BulkAction<T extends Resource, M extends FHIRModel<T>> = {
  type: WritebackAction;
  action: (
    patient: PatientState,
    ctw: CTWState,
    telemetry: ReturnType<typeof useTelemetry>,
    selected: Array<M>,
  ) => Promise<void>;
};

export type BulkActionHeaderProps<T extends Resource, M extends FHIRModel<T>> = {
  selectedKey: keyof ZAPSelectedResources;
  resourceName: string;
  onWritebackStart: (action: WritebackAction, id: string) => void;
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  bulkAdd: BulkAction<T, M>;
  bulkDismiss: BulkAction<T, M>;
  columns: TableProps<M>['columns'];
};

export type UseBulkActionModalProps<T extends Resource, M extends FHIRModel<T>> = {
  selectedKey: keyof ZAPSelectedResources;
  resourceName: string;
  onWritebackStart: (action: WritebackAction, id: string) => void;
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  bulkAction: BulkAction<T, M>;
  columns: TableProps<M>['columns'];
};

export function useBulkActionModal<T extends Resource, M extends FHIRModel<T>>({
  selectedKey,
  resourceName,
  onWritebackStart,
  onWritebackEnd,
  bulkAction,
  columns,
}: UseBulkActionModalProps<T, M>) {
  const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
  const selectedResourcesOfType = selectedResources[selectedKey] as unknown as Array<M>;
  const patient = usePatientContext();
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const displayInfo = bulkActionDisplays[bulkAction.type] ?? {
    title: '',
    bodyText: '',
    btnText: 'Confirm',
  };
  const { openModal, closeModal } = useModal({
    boundaryName: 'BulkAddModal',
  });

  return () => {
    openModal({
      content: {
        body: (
          <>
            <div className={tw`space-y-3`}>
              <Heading level="h1">{displayInfo.title}</Heading>
              <div className={tw`space-y-6 text-lg`}>{displayInfo.bodyText}</div>
            </div>

            <div>
              <ResourceTable
                columns={columns}
                data={selectedResourcesOfType}
                emptyMessage={
                  <EmptyPatientTable
                    hasZeroFilteredRecords={selectedResourcesOfType.length === 0}
                    resourceName={resourceName}
                  />
                }
                isLoading={false}
              />
            </div>
            <div className={tw`space-x-2 text-right`}>
              <Button type="button" variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  for (const resource of selectedResourcesOfType) {
                    onWritebackStart(bulkAction.type, resource.id);
                  }
                  closeModal();
                  bulkAction
                    .action(patient, ctw, telemetry, selectedResourcesOfType)
                    .finally(() => {
                      // Reset the selected resources after adding to chart
                      setSelectedResources({ [selectedKey]: [] });
                    })
                    .then(() => {
                      for (const resource of selectedResourcesOfType) {
                        onWritebackEnd(bulkAction.type, resource.id, false);
                      }
                    })
                    .catch((error) => {
                      for (const resource of selectedResourcesOfType) {
                        onWritebackEnd(
                          bulkAction.type,
                          resource.id,
                          true,
                          error instanceof Error ? error.message : String(error),
                        );
                      }
                    });
                }}
              >
                {displayInfo.btnText}
              </Button>
            </div>
          </>
        ),
      },
    });
  };
}

export const BulkActionHeaderSelectedState = <T extends Resource, M extends FHIRModel<T>>({
  selectedKey,
  resourceName,
  onWritebackStart,
  onWritebackEnd,
  bulkAdd,
  bulkDismiss,
  columns,
}: BulkActionHeaderProps<T, M>) => {
  const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
  const [isSyncing, setIsSyncing] = useState(false);
  const selected = selectedResources[selectedKey];

  const onWriteBackStartTracked = (action: WritebackAction, id: string) => {
    setIsSyncing(true);
    onWritebackStart(action, id);
  };
  const onWriteBackEndTracked = (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => {
    setIsSyncing(false);
    onWritebackEnd(action, id, isError, errorMessage);
  };

  const openBulkAddModal = useBulkActionModal<T, M>({
    selectedKey,
    resourceName,
    onWritebackStart: onWriteBackStartTracked,
    onWritebackEnd: onWriteBackEndTracked,
    bulkAction: {
      type: 'add',
      action: (patient, ctw, telemetry, selectedThings) =>
        bulkAdd.action(patient, ctw, telemetry, selectedThings).catch((e) => {
          notify({
            type: 'error',
            title: 'Error encountered adding to chart.',
            body: 'Try again or add it manually. Dismiss it to no longer see it in this list.',
          });
          throw e;
        }),
    },
    columns,
  });
  const openBulkDismissModal = useBulkActionModal<T, M>({
    selectedKey,
    resourceName,
    onWritebackStart: onWriteBackStartTracked,
    onWritebackEnd: onWriteBackEndTracked,
    bulkAction: {
      type: 'dismiss-bulk',
      action: (patient, ctw, telemetry, selectedThings) =>
        bulkDismiss.action(patient, ctw, telemetry, selectedThings).catch((e) => {
          notify({
            type: 'error',
            title: 'Error encountered dismissing.',
            body: 'Try again or dismiss it manually to no longer see it in this list.',
          });
          throw e;
        }),
    },
    columns,
  });

  return (
    <Transition
      show={selected.length > 0} // Conditionally show the banner based on the selected items
      enter="ease-out duration-200"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-40"
      leave="ease-out duration-100"
      leaveFrom="opacity-100 max-h-40"
      leaveTo="opacity-0 max-h-0"
    >
      <div
        className={tw`flex w-full justify-between rounded-md bg-primary-background-hover px-3 py-2`}
      >
        <div className={tw`flex items-center gap-2`}>
          <div>
            <span className={tw`font-medium`}>{selected.length}</span>{' '}
            <span>
              {resourceName}
              {selected.length > 1 && 's'} {isSyncing ? 'syncing' : 'selected'}{' '}
            </span>
          </div>
          <Button
            onClick={() => {
              setSelectedResources({ [selectedKey]: [] });
            }}
            type="button"
            variant="link"
            size="sm"
            disabled={isSyncing}
          >
            Deselect All
          </Button>
        </div>
        <div className={tw`flex gap-2`}>
          <Button
            type="button"
            variant="secondary"
            size="xs"
            onClick={() => openBulkDismissModal()}
            disabled={isSyncing}
          >
            Dismiss
          </Button>
          <Button
            type="button"
            variant="primary"
            size="xs"
            onClick={() => openBulkAddModal()}
            disabled={isSyncing}
          >
            Add to Chart
          </Button>
        </div>
      </div>
    </Transition>
  );
};

const bulkActionDisplays: Partial<
  Record<WritebackAction, { title: string; bodyText: string; btnText: string }>
> = {
  add: {
    title: 'Add to Chart',
    bodyText: 'The following data will be imported to the chart:',
    btnText: 'Add to Chart',
  },
  'dismiss-bulk': {
    title: 'Dismiss from Chart',
    bodyText: 'The following data will be dismissed from the chart:',
    btnText: 'Dismiss',
  },
};
