import { SYSTEM_SUMMARY, SYSTEM_ZUS_LENS, SYSTEM_ZUS_UPI_RECORD_TYPE } from './system-urls';
import type { ResourceTypeString } from './types';

// TODO: move this into client.ts after we are off ODS.
export function excludeTagsinPatientRecordSearch<T extends ResourceTypeString>(
  resourceType: T,
): Array<string> {
  switch (resourceType) {
    case 'Patient':
      return [...UPI_TAGS];
    case 'Condition':
      return [...CONDITIONS_LENS_TAGS, ...SUMMARY_TAGS];
    case 'MedicationStatement':
      return [...MEDICATION_LENS_TAGS, ...SUMMARY_TAGS];
    default:
      return [];
  }
}

// Enumerating Medication-specific lens tags.
const MEDICATION_LENS_TAGS = [`${SYSTEM_ZUS_LENS}|ActiveMedications`];

// Enumerating Condition-specific lens tags.
const CONDITIONS_LENS_TAGS = [`${SYSTEM_ZUS_LENS}|ChronicConditions`];

const UPI_TAGS = [`${SYSTEM_ZUS_UPI_RECORD_TYPE}|universal`];

const SUMMARY_TAGS = [`${SYSTEM_SUMMARY}|Common`];
