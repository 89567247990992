import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { pathWithParameters } from '@ctw/shared/utils/url';
import { useLayoutEffect, useState } from 'react';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const deepLinkResourceTypes = [
  'Condition',
  'Immunization',
  'AllergyIntolerance',
  'Encounter',
  'DiagnosticReport',
  'ServiceRequest',
  'MedicationStatement',
  'Practitioner',
  'DocumentReference',
  'Measure',
] as const;

export type DeepLinkResourceType = (typeof deepLinkResourceTypes)[number];

export const isDeepLinkResourceType = (
  resourceType: string,
): resourceType is DeepLinkResourceType => deepLinkResourceTypes.includes(resourceType as never);

export const isValidResourceId = (resourceId: string) => UUID_REGEX.test(resourceId);

export interface DeepLinkResource {
  resourceId: string;
  resourceType: DeepLinkResourceType;
}

export function useDeepLinkResource() {
  const { navigate } = useZuiContext();
  const [deepLinkResource, setDeepLinkResource] = useState<DeepLinkResource | null>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    // Ensure that the URL has updated on drawer close before checking for deep link resource. This
    // also effectively debounces calls to `setDeepLinkResource` when the URL changes since we won't
    // call the setter if the resource type and ID have not changed.
    setTimeout(() => {
      const url = new URL(window.location.href);
      const urlResourceType = url.searchParams.get('resourceType') as DeepLinkResourceType | null;
      const urlResourceId = url.searchParams.get('resourceId');

      if (
        urlResourceType &&
        urlResourceId &&
        deepLinkResource?.resourceType !== urlResourceType &&
        deepLinkResource?.resourceId !== urlResourceId &&
        isDeepLinkResourceType(urlResourceType) &&
        isValidResourceId(urlResourceId)
      ) {
        setDeepLinkResource({ resourceType: urlResourceType, resourceId: urlResourceId });
      }
    }, 100);
  }, [window.location.search]);

  return {
    deepLinkResource,
    setDeepLinkResource: ({ resourceType, resourceId }: DeepLinkResource) => {
      if (isValidResourceId(resourceId)) {
        navigate(
          pathWithParameters(window.location.pathname, {
            resourceType,
            resourceId,
          }),
        );
      }
    },
    removeDeepLinkResource: () => {
      const url = new URL(window.location.href);
      const urlResourceType = url.searchParams.get('resourceType') as DeepLinkResourceType | null;
      const urlResourceId = url.searchParams.get('resourceId');

      if (urlResourceType || urlResourceId) {
        navigate(
          pathWithParameters(window.location.pathname, {
            resourceType: null,
            resourceId: null,
          }),
        );
      }
    },
  };
}
