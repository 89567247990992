import { tw } from '@ctw/shared/utils/tailwind';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ToastOptions, toast } from 'react-toastify';

export type ToastProps = {
  title?: string;
  body?: string;
  options?: Omit<ToastOptions, 'type' | 'containerId' | 'className'>;
  containerId?: string;
  type: 'success' | 'info' | 'error';
};

export const APP_TOAST_CONTAINER_ID = 'toast-container';
export const APP_TOAST_CONTAINER_DRAWER_ID = 'toast-container-drawer';

export const notifyFromDrawer = ({ type, title, body, options }: Omit<ToastProps, 'containerId'>) =>
  notify({
    type,
    title,
    body,
    options,
    containerId: APP_TOAST_CONTAINER_DRAWER_ID,
  });

export const notify = ({
  type,
  title,
  body,
  options,
  containerId = APP_TOAST_CONTAINER_ID,
}: ToastProps) => {
  const toastOptions: ToastOptions = {
    closeOnClick: false,
    draggable: false,
    // Auto close all toasts after 4 seconds, except for error toasts.
    autoClose: type === 'error' ? undefined : 4000,
    containerId,
    ...options,
  };

  const content = (
    <div className={tw`toast`}>
      {title && <div className={tw`font-medium`}>{title}</div>}
      {body && <div>{body}</div>}
    </div>
  );

  switch (type) {
    case 'success':
      toast.success(content, {
        ...toastOptions,
        icon: <FontAwesomeIcon icon={faCircleCheck} className={tw`h-5 text-success-main`} />,
      });
      break;

    case 'info':
      toast.info(content, {
        ...toastOptions,
        icon: false,
      });
      break;

    case 'error':
      toast.error(content, {
        ...toastOptions,
        icon: <FontAwesomeIcon icon={faCircleExclamation} className={tw`h-5 text-error-main`} />,
      });
      break;

    default:
      // Type of "type" prop should prevent this from happening.
      throw new Error(`Unknown toast type: ${type}`);
  }
};
