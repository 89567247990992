import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';

export type LoadingMessageProps = {
  message?: string;
};

const LoadingMessage = ({ message = 'Loading Zus Aggregated Profile' }: LoadingMessageProps) => (
  <LoadingSpinner className={tw`text-xl`} centered={true} message={message} />
);

export default LoadingMessage;
