import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { QUERY_KEY_ON_DEMAND_LENS_API } from '@ctw/shared/utils/query-keys';
import type { UseQueryResult } from '@tanstack/react-query';

type LensType = 'conditions' | 'encounters' | 'medications' | 'transitions';

type LensOnDemandJobApiResponse = {
  data: {
    type: 'job';
    id: string;
    attributes: {
      status: string;
    };
  };
  meta: {
    traceId: string;
  };
};

const enabledMap: Record<LensType, boolean> = {
  conditions: true,
  encounters: true,
  medications: true,
  transitions: false,
};

export const useTriggerLensOnDemandJob = (
  type: LensType,
  upid?: string,
): UseQueryResult<LensOnDemandJobApiResponse> =>
  useCtwQuery({
    queryId: QUERY_KEY_ON_DEMAND_LENS_API,
    queryKey: [type, upid],
    queryFn: async ({ requestContext, ctwFetch }) => {
      const { data } = (await ctwFetch(`${getZusServiceUrl(requestContext.env, 'lens')}/jobs`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${requestContext.authToken}`,
        },
        body: JSON.stringify({
          upid,
          type,
        }),
        mode: 'cors',
      })) as { data: LensOnDemandJobApiResponse };

      return data;
    },
    enabled: Boolean(enabledMap[type] && upid),
  });
