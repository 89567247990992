import { tw, twx } from '@ctw/shared/utils/tailwind';
import {
  faThumbsDown as faThumbsDownRegular,
  faThumbsUp as faThumbsUpRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export interface ThumbsUpOrDownProps {
  onChange: (isThumbsUp: boolean) => void;
  className?: string;
  initialIsThumbsUp?: boolean;
  selectedStateDisabled?: boolean;
}

export const ThumbsUpOrDown = ({
  onChange,
  className,
  initialIsThumbsUp,
  selectedStateDisabled,
}: ThumbsUpOrDownProps) => {
  const [isThumbsUp, setIsThumbsUp] = useState<boolean | undefined>(initialIsThumbsUp);

  const handleThumbsUp = () => {
    !selectedStateDisabled && setIsThumbsUp(true);
    onChange(true);
  };

  const handleThumbsDown = () => {
    !selectedStateDisabled && setIsThumbsUp(false);
    onChange(false);
  };

  return (
    <div className={twx('flex gap-[10px]', className)}>
      <button
        className={tw`inline-block h-[38px] w-[38px] rounded-full ${isThumbsUp === true ? 'bg-primary-main' : 'border border-border-main bg-white hover:bg-background-hover'}`}
        onClick={handleThumbsUp}
        type="button"
        aria-label="Thumbs up"
      >
        <FontAwesomeIcon
          icon={isThumbsUp === true ? faThumbsUpSolid : faThumbsUpRegular}
          className={twx('text-content-icon', { 'text-white': isThumbsUp === true })}
        />
      </button>
      <button
        className={tw`inline-block h-[38px] w-[38px] rounded-full transition ${isThumbsUp === false ? 'bg-primary-main' : 'border border-border-main bg-white hover:bg-background-hover'}`}
        type="button"
        aria-label="Thumbs down"
        onClick={handleThumbsDown}
      >
        <FontAwesomeIcon
          icon={isThumbsUp === false ? faThumbsDownSolid : faThumbsDownRegular}
          className={twx('scale-x-[-1] text-content-icon', { 'text-white': isThumbsUp === false })}
        />
      </button>
    </div>
  );
};
