import type { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { getNoteDisplay } from '@ctw/shared/content/resource/notes';
import { NoteSummary } from '@ctw/shared/content/resource/notes-entry';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { recursivelyTransposeTables } from '@ctw/shared/utils/transpose-table';
import { faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash-es';
import { useState } from 'react';

type NotesProps = {
  entries: Array<DocumentModel>;
};

export function useEncounterDrawer() {
  return useResourceDrawer({
    resourceType: 'Encounter',
    enableDismissAndReadActions: true,
    action: ({ model }) => ({
      label: 'Encounter',
      title: model.dateDisplay,
      subtitle: model.classDisplay,
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList
                items={[
                  { label: 'Status', value: capitalize(model.status) },
                  { label: 'Class', value: capitalize(model.classDisplay) },
                  { label: 'Type', value: capitalize(model.typeDisplay) },
                  { label: 'Location', value: model.location.join(', ') },
                  {
                    label: 'Providers',
                    value: model.practitioners.length > 0 && (
                      <ul className={tw`m-0 list-disc pl-4`}>
                        {model.practitioners.map((p) => (
                          <li key={p}>{p}</li>
                        ))}
                      </ul>
                    ),
                  },
                  {
                    label: 'Location Type',
                    value: (() => {
                      const locationType = model.locationType.filter(
                        (value) => value.toLowerCase() !== 'unknown',
                      );
                      if (locationType.length > 0) {
                        return (
                          <ul className={tw`m-0 list-disc pl-4`}>
                            {locationType
                              .filter((value) => value.toLowerCase() !== 'unknown')
                              .map((s) => (
                                <li key={s}>{s}</li>
                              ))}
                          </ul>
                        );
                      }
                      return null;
                    })(),
                  },
                  { label: 'Reason', value: model.reason },
                  {
                    label: 'Diagnoses',
                    value: model.diagnoses.length > 0 && (
                      <ul className={tw`m-0 list-disc pl-4`}>
                        {model.diagnoses.map((d) => (
                          <li key={d}>{d}</li>
                        ))}
                      </ul>
                    ),
                  },
                  { label: 'Discharge Disposition', value: model.dischargeDisposition },
                ]}
              />
            </ContentCard>
            <EncounterNotesWithLoading model={model} />
          </div>
        ),
      },
    }),
  });
}

const EncounterNotesWithLoading = ({ model }: { model: EncounterModel }) => {
  if (model.isLoadingClinicalNotes) {
    return (
      <div className={tw`flex w-full justify-center`}>
        <LoadingSpinner className={tw`text-content-icon`} message="Loading clinical notes" />
      </div>
    );
  }
  if (model.docsAndNotes.length > 0) {
    return <EncounterNotes entries={model.docsAndNotes} />;
  }
  return <></>;
};

const EncounterNotes = ({ entries }: NotesProps) => (
  <div className={tw`space-y-4`}>
    <div className={tw`font-semibold text-lg`}>Notes</div>
    {entries.map((entry) => (
      <DocumentCard doc={entry} key={entry.id} />
    ))}
  </div>
);

// Component for displaying a document with multiple notes
const DocumentCard = ({ doc }: { doc: DocumentModel }) => {
  const { binaryId, title } = doc;

  return (
    <div
      className={tw`flex flex-col gap-2 divide-y divide-divider-main rounded-lg border bg-white p-3 text-left`}
      id={binaryId}
      key={binaryId}
    >
      <div className={tw`flex items-center justify-between`}>
        <div className={tw`flex items-center gap-2`}>
          <span className={tw`h-full`}>
            {binaryId && (
              <FontAwesomeIcon icon={faFileLines} className={tw`h-5 w-5 text-content-icon`} />
            )}
          </span>
          <span className={tw`font-medium text-background-inverse text-base`}>{title}</span>
        </div>
        <div className={tw`flex items-center justify-center`}>
          <ResponsiveSourceDocumentLink model={doc} />
        </div>
      </div>
      {doc.sectionDocuments && doc.sectionDocuments.length > 0 && (
        <div className={tw`divide-y divide-divider-main`}>
          {doc.sectionDocuments?.map((entry) => (
            <div key={entry.id} className={tw`last:!border-b border-divider-main py-1`}>
              <DocumentSection section={entry} key={entry.id} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const DocumentSection = ({ section }: { section: DocumentModel }) => {
  const [isDetailShown, setIsDetailShown] = useState(false);
  return (
    <div
      key={section.id}
      className={twx(
        isDetailShown && 'divide-y rounded-lg border-y-border-main bg-background-hover px-4',
      )}
    >
      <NoteSummary
        summary={
          <>
            {section.title && <div>{section.title}</div>}
            {!section.title && <div className={tw`text-content-icon`}>Unknown</div>}
          </>
        }
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        className={twx(
          'section-note-expandable',
          isDetailShown ? 'rounded-t-lg bg-background-hover' : 'rounded-lg px-4',
        )}
      />
      {isDetailShown && (
        <ContentCard variant="focal" title="Details">
          <DefinitionList
            items={[
              {
                label: 'Note',
                value: recursivelyTransposeTables(getNoteDisplay(section.displayContent), 0),
              },
            ]}
          />
        </ContentCard>
      )}
    </div>
  );
};
