import { type ClassName, twx } from '@ctw/shared/utils/tailwind';
import type { PropsWithChildren } from 'react';

export const MAIN_CONTENT_CONTAINER_ID = 'main-content-container';

export interface MainContentContainerProps extends PropsWithChildren {
  className?: ClassName;
}

export const MainContentContainer = ({ className, children }: MainContentContainerProps) => (
  <div
    id={MAIN_CONTENT_CONTAINER_ID}
    className={twx(
      'relative flex h-full min-h-[50%] w-full max-w-full flex-1 gap-4 overflow-hidden p-2',
      className,
    )}
  >
    {children}
  </div>
);
