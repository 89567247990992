import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebabCase } from 'lodash-es';

export type CheckboxProps = {
  className?: ClassName;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name: string;
  label: string;
};
export const Checkbox = ({ className, checked, onChange, name, label }: CheckboxProps) => (
  <div className={twx(className, 'relative inline-flex items-center')}>
    <input
      type="checkbox"
      checked={checked}
      name={name}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      id={`${name}_${kebabCase(label)}`}
      className={tw`absolute top-0 right-0 bottom-0 left-0 z-10 h-full w-full cursor-pointer appearance-none border-none shadow-none outline-none focus:outline-4 focus:outline-primary-main focus:outline-offset-[3px] focus-visible:outline-4 focus-visible:outline-primary-main focus-visible:outline-offset-[3px]`}
    />
    <div
      className={twx('relative h-4 w-4 items-center rounded border bg-background-main', {
        'bg-primary-main': checked,
      })}
    >
      <FontAwesomeIcon
        icon={faCheck}
        className={twx(
          '-translate-x-1/2 -translate-y-1/2 pointer-events-none absolute top-1/2 left-1/2 h-3 w-3 text-white opacity-0 transition-opacity',
          {
            'opacity-100': checked,
          },
        )}
      />
    </div>
    <label htmlFor={`${name}_${kebabCase(label)}`} className={tw`ml-3 select-none`}>
      {label}
    </label>
  </div>
);
