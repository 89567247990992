import type { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { entryFromArray } from '@ctw/shared/utils/entry-from-array';

export const useImmunizationDrawer = () =>
  useResourceDrawer({
    resourceType: 'Immunization',
    enableDismissAndReadActions: true,
    action: ({ model }) => ({
      title: model.description,
      content: {
        body: (
          <ContentCard
            variant="focal"
            title="Details"
            content={{
              header: <ResponsiveSourceDocumentLink model={model} />,
            }}
          >
            <DefinitionList items={immunizationData(model)} />
          </ContentCard>
        ),
      },
    }),
  });

const immunizationData = (immunization: ImmunizationModel) => [
  { label: 'Date Given', value: immunization.occurrence },
  { label: 'Recorded By', value: immunization.managingOrganization },
  { label: 'Status', value: immunization.status },
  { label: 'Dose Quantity', value: immunization.doseQuantity },
  { label: 'Route', value: immunization.route },
  { label: 'Site', value: immunization.site },
  { label: 'Lot Number', value: immunization.resource.lotNumber },
  ...entryFromArray('Note', immunization.notesDisplay),
];
