import { ConditionModel, getNewCondition } from '@ctw/shared/api/fhir/models/condition';
import { DrawerFormWithFields } from '@ctw/shared/components/form/drawer-form-with-fields';
import { useConditionWrite } from '@ctw/shared/content/conditions/use-condition-write';
import {
  type CreateOrEditConditionFormData,
  getAddConditionWithDefaults,
} from '@ctw/shared/content/forms/actions/conditions';
import {
  conditionAddSchema,
  getAddConditionData,
} from '@ctw/shared/content/forms/schemas/condition-schema';
import { useDrawer } from '@ctw/shared/context/drawer-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';

export function useAddConditionForm(
  onWritebackStart: (id: string) => void,
  onWritebackEnd: (id: string, isError: boolean, errorMessage?: string) => void,
) {
  const { openDrawer } = useDrawer({
    boundaryName: 'AddConditionFormDrawer',
    allowDocking: true,
  });
  const { patient } = usePatientContext();
  const conditionWrite = useConditionWrite();

  return (referenceCondition?: ConditionModel) => {
    const condition = new ConditionModel(
      referenceCondition
        ? getAddConditionWithDefaults(referenceCondition.resource)
        : getNewCondition(patient.id),
    );

    openDrawer({
      title: 'Add Condition',
      content: {
        body: (
          <DrawerFormWithFields
            boundaryName="AddConditionFormDrawer"
            schema={conditionAddSchema}
            errorHeader="Unable to add condition to chart"
            closeOnSubmit={true}
            onSubmit={() => {
              if (referenceCondition?.id) {
                onWritebackStart(referenceCondition.id);
              }
            }}
            onSubmitSuccess={() => {
              if (referenceCondition?.id) {
                onWritebackEnd(referenceCondition.id, false);
              }
            }}
            onSubmitError={(errorMessage) => {
              if (referenceCondition?.id) {
                onWritebackEnd(referenceCondition.id, true, errorMessage);
              }
            }}
            action={(data: CreateOrEditConditionFormData) => conditionWrite(data)}
            data={getAddConditionData({ condition })}
            trackingMetadata={{
              action: 'create_resource',
              resourceType: 'condition',
            }}
          />
        ),
      },
    });
  };
}
