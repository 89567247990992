import type { Citation } from '@ctw/shared/api/ai/types';
import { getAllergyIntolerancesById } from '@ctw/shared/api/fhir/allergies';
import { useBinaryId } from '@ctw/shared/api/fhir/binaries';
import { fetchConditionsByIdFQS } from '@ctw/shared/api/fhir/conditions';
import { getDocumentReferenceWithBasicsById } from '@ctw/shared/api/fhir/document';
import { getMedicationStatementsByIdFQS } from '@ctw/shared/api/fhir/medications';
import type { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import type { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import type { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import type { ObservationModel } from '@ctw/shared/api/fhir/models/observation';
import { fetchObservationsById } from '@ctw/shared/api/fhir/observations';
import { usePatientContext, usePatientQuery } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useAllergyDrawer } from '@ctw/shared/hooks/use-allergy-drawer';
import { useConditionDrawer } from '@ctw/shared/hooks/use-condition-drawer';
import { useDocumentReferenceDrawer } from '@ctw/shared/hooks/use-document-reference-drawer';
import { useMedicationDrawer } from '@ctw/shared/hooks/use-medication-drawer';
import { useObservationDrawer } from '@ctw/shared/hooks/use-observation-drawer';
import { useCCDAModal } from '../CCDA/modal-ccda';
import { CitationComponent } from './citation';

export type SupportedCitationLinkResourceType =
  | Array<AllergyModel>
  | Array<ConditionModel>
  | Array<DocumentModel>
  | Array<MedicationStatementModel>
  | Array<ObservationModel>
  | null;

export type CitationLinkProps = {
  resourceInfo: { resourceID: string; resourceType: string };
  citation: Citation;
};

export const CitationLink = ({ resourceInfo, citation }: CitationLinkProps) => {
  const { trackInteraction } = useTelemetry();
  const { isLoading, isError, data } = usePatientQuery<SupportedCitationLinkResourceType>({
    queryId: 'ResourceByTypeAndIDQuery',
    queryKey: [resourceInfo.resourceID, resourceInfo.resourceType],
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      switch (resourceInfo.resourceType) {
        case 'AllergyIntolerance':
          return await getAllergyIntolerancesById(telemetry, graphqlClient, patient, [
            resourceInfo.resourceID,
          ]);
        case 'Condition':
          return await fetchConditionsByIdFQS(telemetry, graphqlClient, patient, [
            resourceInfo.resourceID,
          ]);
        case 'DocumentReference':
          return await getDocumentReferenceWithBasicsById(telemetry, graphqlClient, patient, [
            resourceInfo.resourceID,
          ]);
        case 'MedicationStatement':
          return await getMedicationStatementsByIdFQS(telemetry, graphqlClient, patient, [
            resourceInfo.resourceID,
          ]);
        case 'Observation':
          return await fetchObservationsById(telemetry, graphqlClient, patient, [
            resourceInfo.resourceID,
          ]);
        default:
          return Promise.resolve(null);
      }
    },
  });

  const { patient } = usePatientContext();
  const binaryContent = useBinaryId(data?.[0], !isLoading, true);
  const openCCDAModal = useCCDAModal(patient);

  const { openDrawer: openAllergyDetails } = useAllergyDrawer();
  const { openDrawer: openConditionDrawer } = useConditionDrawer();
  const { openDrawer: openDocumentDrawer } = useDocumentReferenceDrawer();
  const { openDrawer: openMedicationDrawer } = useMedicationDrawer();
  const { openDrawer: openObservationDetails } = useObservationDrawer();

  if (isLoading || isError || !data) {
    return null;
  }

  // if it's not an array, it's not a valid resource
  if (!Array.isArray(data)) {
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  const resource = data[0];
  const titleOfCCDAModal = 'Source Document';

  const trackCitationLinkClick = () => {
    trackInteraction('open_gps_citation', {
      citationNumber: citation.id,
    });
  };

  switch (resource.resourceType) {
    case 'AllergyIntolerance': {
      const allergy = resource as AllergyModel;

      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              trackCitationLinkClick();
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openAllergyDetails({ model: allergy });
          }}
        />
      );
    }
    case 'Condition': {
      const condition = resource as ConditionModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              trackCitationLinkClick();
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openConditionDrawer({ model: condition });
          }}
        />
      );
    }
    case 'DocumentReference': {
      const document = resource as DocumentModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (document.binaryId) {
              trackCitationLinkClick();
              return openCCDAModal(document.binaryId, titleOfCCDAModal);
            }
            return openDocumentDrawer({ model: document });
          }}
        />
      );
    }
    case 'MedicationStatement': {
      const medication = resource as MedicationStatementModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              trackCitationLinkClick();
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openMedicationDrawer({ model: medication });
          }}
        />
      );
    }
    case 'Observation': {
      const observation = resource as ObservationModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              trackCitationLinkClick();
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openObservationDetails({ model: observation });
          }}
        />
      );
    }
    default:
      return null;
  }
};
