import type { Breakpoint } from '@ctw/config/zui';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import type {
  MinRecordItem,
  RowActionsConfigProp,
  RowActionsProp,
  TableProps,
} from '@ctw/shared/components/table/table';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { useGroupedBy } from '@ctw/shared/content/hooks/use-grouped-by';
import { useToggleRead } from '@ctw/shared/content/hooks/use-toggle-read';
import { useAdditionalResourceActions } from '@ctw/shared/content/resource/use-additional-resource-actions';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { isReactNode } from '@ctw/shared/utils/types';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Resource } from 'fhir/r4';
import { isFunction } from 'lodash-es';
import { type ReactElement, type ReactNode, useMemo, useRef } from 'react';
import {
  BulkAddSelectBox,
  type WritebackAction,
  type WritebackStatuses,
  isSelectableWritebackStatus,
} from './resource-title-column';

type ResourceTableGroupedProps<T extends MinRecordItem> = Omit<
  ResourceTableCardContainerProps<T> & {
    columnHeaders?: Array<{
      className: ClassName;
      title?: string;
      render?: () => ReactNode;
    }>;
    stackedBreakpoint?: Breakpoint;
    enableDismissAndReadActions?: boolean;
    groupBy: (record: T) => string;
    rowActions?: (r: T) => RowActionsConfigProp<T>;
    selectedResources?: Array<T>;
    setSelectedResources?: (resources: Array<T>) => void;
    writebackStatuses?: WritebackStatuses;
    onWritebackStart?: (action: WritebackAction, id: string) => void;
    onWritebackEnd?: (
      action: WritebackAction,
      id: string,
      isError: boolean,
      errorMessage?: string,
    ) => void;
  },
  'title'
>;

export const ResourceTableGrouped = <T extends Resource, M extends FHIRModel<T>>({
  columnHeaders,
  data,
  enableDismissAndReadActions,
  groupBy,
  loading: isLoading,
  rowActions,
  selectedResources,
  setSelectedResources,
  writebackStatuses,
  onWritebackStart,
  onWritebackEnd,
  stackedBreakpoint = 'sm',
  ...resourceTableProps
}: ResourceTableGroupedProps<M>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { breakpoints } = useContainerQuery({ containerRef });
  // Group the records using the groupBy function
  const { groupedRecords, sortedKeys } = useGroupedBy(groupBy, data);
  // Create the RowActions react node
  const RowActionsWithAdditions = useAdditionalResourceActions({
    onWritebackStart,
    onWritebackEnd,
    rowActions,
    enableDismissAndReadActions,
  });

  const columnHeaderContainer = useMemo(
    () => (
      <div
        key="column-header-container"
        className={twx(
          'flex justify-between border-0 border-background-subtle border-b border-solid',
          {
            hidden: isLoading || breakpoints.isAtMost[stackedBreakpoint],
          },
        )}
      >
        {columnHeaders?.map((header) => (
          <div
            key={header.title}
            className={twx(
              'group p-2 pb-1 text-left font-medium text-content-subtle text-xs uppercase tracking-wider',
              header.className,
            )}
          >
            {header.render?.() || header.title}
          </div>
        ))}
      </div>
    ),
    [breakpoints.isAtMost, columnHeaders, isLoading, stackedBreakpoint],
  );

  return (
    <div className={twx('@container flex w-full flex-col gap-2')} ref={containerRef}>
      {columnHeaderContainer}
      {sortedKeys.map((title) => {
        const recordsInGroup = groupedRecords[title] ?? [];
        const selectableResources =
          groupedRecords[title]?.filter((r) =>
            isSelectableWritebackStatus(writebackStatuses?.[r.id]),
          ) ?? [];
        const isGroupSelectable = setSelectedResources && selectableResources.length > 0;
        const isGroupSelected =
          !!selectedResources &&
          selectableResources.reduce((acc, r) => acc && selectedResources.includes(r), true);
        return (
          <div key={title} data-testid="resource-table-grouped">
            <ResourceTableCardContainer
              className={tw`!min-h-0 !min-w-0 !text-sm`}
              data={recordsInGroup}
              loading={isLoading}
              title={title}
              RowActions={RowActionsWithAdditions}
              headerIcon={
                isGroupSelectable &&
                selectedResources && (
                  <BulkAddSelectBox
                    isSelected={isGroupSelected}
                    changeState={() => {
                      if (isGroupSelected) {
                        setSelectedResources(
                          selectedResources.filter((r) => !groupedRecords[title]?.includes(r)),
                        );
                      } else {
                        setSelectedResources([
                          ...selectedResources,
                          ...selectableResources.filter((r) => !selectedResources.includes(r)),
                        ]);
                      }
                    }}
                  />
                )
              }
              {...resourceTableProps}
            />
          </div>
        );
      })}
    </div>
  );
};

interface ResourceTableCardContainerProps<T extends MinRecordItem>
  extends Omit<ResourceTableCardProps, 'children' | 'empty'> {
  renderResource: (
    record: T,
    writebackInProgress?: boolean,
  ) => ReactElement<{ record: T; writeBackInProgress?: boolean }>;
  data: Array<T>;
  onRowClick?: TableProps<T>['handleRowClick'];
  telemetryTargetName: string;
  rowClassName?: ClassName;
  RowActions?: RowActionsProp<T>;
}

const ResourceTableCardContainer = <T extends Resource, M extends FHIRModel<T>>({
  renderResource,
  data = [],
  onRowClick,
  rowClassName,
  RowActions,
  footerCTA,
  telemetryTargetName,
  ...tableCardProps
}: ResourceTableCardContainerProps<M>) => {
  const { trackInteraction } = useTelemetry();
  const { requestContext } = useCTW();
  const { toggleRead } = useToggleRead();

  function handleRowClick(record: M) {
    if (onRowClick) {
      if (!(record.isRead || record.ownedByBuilder(requestContext.builderId))) {
        void toggleRead(record);
      }
      onRowClick(record);
      trackInteraction('click_row', { target: telemetryTargetName });
    }
  }

  return (
    <ResourceTableCard
      {...tableCardProps}
      empty={data.length === 0}
      fullbleed={true}
      footerCTA={
        footerCTA
          ? {
              label: footerCTA.label,
              onClick: () => {
                trackInteraction('see_all_of_resource', { target: telemetryTargetName });
                footerCTA.onClick();
              },
            }
          : undefined
      }
    >
      {data.map((record) => (
        <div
          data-testid="resource-overview-row"
          className={twx(
            'resource-overview-row grouped group relative flex h-[53px] min-h-[53px] flex-col justify-center px-3 py-1.5',
            rowClassName,
            {
              'cursor-pointer hover:bg-background-hover': isFunction(onRowClick),
              'last:rounded-b-lg': !footerCTA,
              '!text-content-subtle': record.isDismissed,
            },
          )}
          key={record.key}
          // Needed for accessibility (eslint and sonar rules).
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRowClick(record);
            }
          }}
          onClick={() => handleRowClick(record)}
        >
          {renderResource(record)}
          {RowActions && (
            // Add onClick to prevent clicks from propagating to the row.
            <div
              aria-label="row-actions"
              className={tw`resource-overview-actions group invisible absolute row-actions flex flex-col items-start justify-start pt-1 group-hover:visible`}
              onClick={(event) => event.stopPropagation()}
            >
              <RowActions record={record} />
            </div>
          )}
        </div>
      ))}
    </ResourceTableCard>
  );
};

export interface ResourceTableCardProps {
  title: string;
  children: ReactNode;
  testId?: string;
  className?: ClassName;
  loading?: boolean;
  empty?: boolean;
  fullbleed?: boolean;
  emptyStateMessage?: string;
  fixedHeightScrollable?: boolean;
  helpText?: string;
  headerIcon?: IconProp | ReactNode;
  footerCTA?:
    | {
        label: string;
        onClick: () => void;
      }
    | undefined;
}

export const ResourceTableCard = ({
  children,
  className,
  footerCTA,
  headerIcon,
  loading,
  empty = false,
  fullbleed,
  emptyStateMessage,
  fixedHeightScrollable = true,
  title,
  helpText,
  testId,
}: ResourceTableCardProps) => (
  <section
    data-testid={testId}
    className={twx(
      'flex min-h-[175px] min-w-[320px] flex-col divide-y divide-divider-main rounded-lg border border-divider-main shadow-sm',
      { '@2xl/cards:max-h-[360px]': fixedHeightScrollable },
      className,
    )}
  >
    <div className={tw`flex gap-2 rounded-t-lg bg-background-disabled px-3 py-2`}>
      {headerIcon && (
        <div className={tw`flex items-center`}>
          {isReactNode(headerIcon) ? (
            headerIcon
          ) : (
            <FontAwesomeIcon icon={headerIcon} className={tw`h-5 w-5 text-content-icon`} />
          )}
        </div>
      )}
      <div
        data-testid="overview-card-header"
        className={tw`flex-auto font-medium text-content-subtle text-xs uppercase`}
      >
        {title}
      </div>
      {helpText && (
        <div className={tw`self-center`}>
          <Tooltip content={helpText}>
            <FontAwesomeIcon icon={faInfoCircle} className={tw`block h-4 w-4 text-content-icon`} />
          </Tooltip>
        </div>
      )}
    </div>

    <div
      data-testid="overview-card-body"
      className={twx({
        'flex grow items-center justify-center px-3 py-2 text-center text-sm': loading || empty,
        'scroll-shadows flex w-full flex-1 grow flex-col overflow-y-auto py-0 text-sm': !loading,
        'px-3 py-2': !fullbleed,
      })}
    >
      {loading ? (
        <LoadingSpinner message="Loading..." />
      ) : (
        empty && (emptyStateMessage ?? 'No data')
      )}
      {!(loading || empty) && children}
    </div>

    {footerCTA && !loading && !empty && (
      <Button
        testId="overview-card-footer"
        type="button"
        variant="unstyled"
        className={tw`group flex w-full cursor-pointer content-center justify-between rounded-b-lg bg-white px-3 py-2 text-left font-medium text-content-subtle text-sm capitalize hover:bg-background-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-main focus-visible:outline-offset-2`}
        onClick={footerCTA.onClick}
      >
        {footerCTA.label}
        <FontAwesomeIcon
          icon={faAngleRight}
          className={tw`button-arrow h-5 w-5 text-content-icon opacity-0 group-hover:opacity-100`}
        />
      </Button>
    )}
  </section>
);
