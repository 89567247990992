import { getEHRIntegrationServiceBaseUrl } from '@ctw/shared/api/urls';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { getPageEnv } from '@ctw/shared/utils/get-page-env';
import { tw } from '@ctw/shared/utils/tailwind';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface SmartAppContextProps {
  zusAccessToken?: string;
}

const defaultContextValue: SmartAppContextProps = {};

export const SmartAppContext = createContext<SmartAppContextProps>(defaultContextValue);

export const SmartAppProvider = ({ children }: PropsWithChildren<object>) => {
  const [zusAccessToken, setZusAccessToken] = useState<string>();
  const zusEnvironment = getPageEnv();
  const ehrHooksUrl = getEHRIntegrationServiceBaseUrl(zusEnvironment);

  useEffect(() => {
    const fetchZusToken = async () => {
      try {
        const tokenResponse = await fetch(`${ehrHooksUrl}/smart-auth/context`, {
          credentials: 'include',
        });

        if (tokenResponse.status !== 200) {
          throw new Error('Could not get Zus token');
        }

        const data = await tokenResponse.json();
        setZusAccessToken(data.access_token);
      } catch (_error) {
        throw new Error('An error occurred while fetching the Zus token.');
      }
    };

    void fetchZusToken();
  }, [ehrHooksUrl]);

  const contextValue = useMemo(() => ({ zusAccessToken }), [zusAccessToken]);

  if (!zusAccessToken) {
    return (
      <LoadingSpinner
        className={tw`fixed inset-0 flex h-screen items-center justify-center`}
        message="Getting Zus Access token"
      />
    );
  }

  return <SmartAppContext.Provider value={contextValue}>{children}</SmartAppContext.Provider>;
};

export const useSmartApp = () => useContext(SmartAppContext);
