import { type ClassName, twx } from '@ctw/shared/utils/tailwind';
import { type PropsWithChildren, forwardRef } from 'react';

export interface SheetProps extends PropsWithChildren {
  id?: string;
  className?: ClassName;
  fullbleed?: boolean;
}

export const Sheet = forwardRef<HTMLElement, SheetProps>(
  ({ id, fullbleed, className, children }, ref) => (
    <section
      ref={ref}
      id={id}
      className={twx(
        'overflow-hidden rounded-lg bg-white shadow-md',
        {
          'px-5 py-6': !fullbleed,
        },
        className,
      )}
    >
      {children}
    </section>
  ),
);
