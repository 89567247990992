import type { Activity } from '@ctw/shared/api/fhir/models/care-gap';
import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import { Button } from '@ctw/shared/components/button';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CareGapUserActivityProps = {
  activity?: Activity;
  note?: string;
  showEditButton?: boolean;
};

type CareGapActivityProps = {
  activities: Array<string>;
  builderId: string;
  evidenceReports: Array<DiagnosticReportModel>;
  openDiagnosticReportDrawer: (options: { model: DiagnosticReportModel }) => void;
};

export const CareGapUserActivity = ({
  activity = 'Other',
  note,
  showEditButton,
}: CareGapUserActivityProps) => (
  <div className={tw`w-full space-y-1 text-sm`}>
    <div className={tw`font-medium`}>{activity}</div>
    <div className={tw`flex items-end justify-between`}>
      <div className={tw`font-normal`}>{note}</div>
      {showEditButton && (
        <div
          className={tw`flex min-w-fit items-center gap-2 text-md text-primary-main group-hover:underline`}
        >
          <FontAwesomeIcon icon={faPencil} />
          <span>Edit</span>
        </div>
      )}
    </div>
  </div>
);

export const CareGapActivity = ({
  activities,
  builderId,
  evidenceReports,
  openDiagnosticReportDrawer,
}: CareGapActivityProps) => (
  <div className={tw`w-full space-y-1 text-sm`}>
    <div className={tw`font-medium`}>{activities.join(' ')}</div>
    {evidenceReports.map((diagnostic: DiagnosticReportModel) => {
      const isSyncedWithEhr = diagnostic.ownedByBuilder(builderId);
      return (
        <div className={tw`flex flex-row gap-1 pt-1`} key={diagnostic.id}>
          {RenderSyncedWithRecordIcon(isSyncedWithEhr, false)}
          <Button
            type="button"
            variant="link"
            className={tw`w-fit text-left font-normal text-background-reversed text-xs group-hover:underline`}
            onClick={() => {
              openDiagnosticReportDrawer({
                model: diagnostic,
              });
            }}
          >
            Diagnostic: {diagnostic.displayName}
          </Button>
        </div>
      );
    })}
  </div>
);
