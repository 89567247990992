import type { ResourceTypeString } from '@ctw/shared/api/fhir/types';

export type Citation = {
  id: number;
  source_resource_type: ResourceTypeString;
  source_id: string;
};

export type ChartReviewResponse = {
  upid?: string;
  hpi: string;
  citations: Array<Citation>;
  createdAt?: Date;
};

export type FeedbackRequest = {
  createdAt: string;
  userId: string;
  upid: string;
  content: string;
  citations: Array<Citation>;
  otherFeedback: string;
  // Specific to current form
  usefulnessRating?: number;
  usefulnessFeedback?: string;
  // Specific to form with ctw-hpi-standalone-zap flag
  isThumbsUp?: boolean;
  reasons?: Array<string>;
  contactPermission?: boolean;
};

interface NewBlankFeedbackRequestOptions {
  upid?: string;
}

export const newBlankFeedbackRequest = ({
  upid,
}: NewBlankFeedbackRequestOptions): FeedbackRequest => ({
  createdAt: new Date().toISOString(),
  userId: 'unknown',
  upid: upid ?? 'unknown',
  content: '',
  citations: [],
  otherFeedback: '',
});

export type FeedbackResponse = {
  id: string;
};
