import { mostRecentGaps, usePatientCareGaps } from '@ctw/shared/api/fhir/care-gaps';
import { CareGapSummary } from '@ctw/shared/content/care-gaps/helpers/summary';
import { ResourceOverviewCard } from '@ctw/shared/content/overview/resource-overview-card';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { useCareGapDrawer } from '@ctw/shared/hooks/use-care-gap-drawer';
import { faHandHoldingMedical } from '@fortawesome/pro-solid-svg-icons';
import type { SpecificResourceOverviewCardProps } from '../overview/resource-overview-card';

export const CareGapsOverviewCard = withOverviewCardErrorBoundary({
  cardTitle: 'Care Gaps',
  boundaryName: 'CareGapsOverviewCard',
  Component: ({ onSeeAllResources, isInPatientSummary }: SpecificResourceOverviewCardProps) => {
    const { data: allGaps, isLoading } = usePatientCareGaps();
    const { openDrawer } = useCareGapDrawer();

    const uniqueGaps = mostRecentGaps(allGaps || []);
    uniqueGaps.sort(
      (a, b) =>
        Date.parse(b.updatedDate) - Date.parse(a.updatedDate) || a.title.localeCompare(b.title),
    );

    return (
      <ResourceOverviewCard
        headerIcon={faHandHoldingMedical}
        title="Care Gaps"
        data={uniqueGaps}
        emptyStateMessage="This patient has no currently open gaps in care."
        loading={isLoading}
        helpText="Recently updated gaps in care."
        telemetryTargetName="caregaps_overview"
        testId="care-gaps-card"
        onRowClick={(gap) => openDrawer({ model: gap })}
        footerCTA={{ label: 'All Care Gaps', onClick: onSeeAllResources }}
        renderResource={(gap) => <CareGapSummary gap={gap} />}
        isInPatientSummary={isInPatientSummary}
      />
    );
  },
});
