import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { createContext } from 'react';

// TODO: Enforce that the keys of ZAPSelectedResources are ZAPTabName
export type ZAPSelectedResources = {
  'conditions-all': Array<ConditionModel>;
};

export type BulkActionContextType = {
  selectedResources: ZAPSelectedResources;
  setSelectedResources: (resources: ZAPSelectedResources) => void;
};

export const BulkActionContext = createContext<BulkActionContextType>({
  selectedResources: {
    'conditions-all': [],
  },
  setSelectedResources: () => {
    /* noop */
  },
});
