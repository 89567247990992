import type { PrototypeStage } from '@ctw/shared/components/prototype-label';
import { PatientAllergies } from '@ctw/shared/content/allergies/patient-allergies';
import { PatientCareGaps } from '@ctw/shared/content/care-gaps/patient-care-gaps';
import { PatientCareTeam } from '@ctw/shared/content/care-team/patient-careteam';
import { PatientSummary } from '@ctw/shared/content/chart-review/patient-summary';
import { PatientConditions } from '@ctw/shared/content/conditions/patient-conditions';
import { useHasDataRecordsByResource } from '@ctw/shared/content/data-indicator';
import { PatientDemographics } from '@ctw/shared/content/demographics/patient-demographics';
import { PatientDiagnosticReports } from '@ctw/shared/content/diagnostic-reports/patient-diagnostic-reports';
import { PatientDocuments } from '@ctw/shared/content/document/patient-documents';
import { PatientEncounters } from '@ctw/shared/content/encounters/patient-encounters';
import { PatientImmunizations } from '@ctw/shared/content/immunizations/patient-immunizations';
import { PatientMedications } from '@ctw/shared/content/medications/patient-medications';
import { PatientOverviewZap } from '@ctw/shared/content/overview/patient-overview-zap';
import { PatientReferrals } from '@ctw/shared/content/patient-referrals/patient-referrals';
import { PatientsSearch } from '@ctw/shared/content/patients/patients-search';
import { PatientVitals } from '@ctw/shared/content/vitals/patient-vitals';
import { useHasFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import {
  type IconDefinition,
  faAllergies,
  faClipboardCheck,
  faClipboardList,
  faFileLines,
  faFlask,
  faHandHoldingMedical,
  faHeartPulse,
  faHome,
  faIdCard,
  faPills,
  faSearch,
  faSparkles,
  faSyringe,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { type ComponentType, useMemo } from 'react';

export const SUPPORTED_DATA_INDICATORS = [
  'conditions-all',
  'referrals',
  'medications-all',
  'diagnostic-reports',
  'referrals',
  'demographics',
  'encounters',
  'documents',
  'vitals',
  'allergies',
  'immunizations',
  'care-team',
] as const;

interface ZapSection {
  href: string;
  siblingHrefs?: Array<string>;
  label: string;
  requiredFeature?: string | Array<string>;
  icon: IconDefinition;
  component: ComponentType;
  prototypeStage?: PrototypeStage;
  disabled?: boolean;
}

export const useZapSections = () => {
  const dataIndicators = useHasDataRecordsByResource(SUPPORTED_DATA_INDICATORS);
  const hasFeatureFlag = useHasFeatureFlag();
  const zapSections: Array<ZapSection> = useMemo(
    () =>
      (
        [
          {
            label: 'Overview',
            href: 'overview',
            icon: faHome,
            component: PatientOverviewZap,
            disabled: true,
          },
          {
            label: 'GPS Summary',
            href: 'summary',
            icon: faSparkles,
            requiredFeature: ['ctw-hpi-standalone-zap'],
            component: PatientSummary,
          },
          {
            label: 'Search',
            href: 'search-patient-records',
            icon: faSearch,
            requiredFeature: 'ctw-patient-record-search',
            prototypeStage: 'beta',
            component: PatientsSearch,
            disabled: true,
          },
          {
            label: 'Demographics',
            href: 'contact-information',
            siblingHrefs: ['sources', 'zus-records'],
            icon: faUser,
            component: PatientDemographics,
            disabled: true,
          },
          {
            label: 'Conditions',
            href: 'conditions',
            icon: faClipboardList,
            component: PatientConditions,
            disabled: dataIndicators.get('conditions-all'),
          },
          {
            label: 'Medications',
            href: 'medications',
            icon: faPills,
            component: PatientMedications,
            disabled: dataIndicators.get('medications-all'),
          },
          {
            label: 'Diagnostics',
            href: 'diagnostics',
            icon: faFlask,
            component: PatientDiagnosticReports,
            disabled: dataIndicators.get('diagnostic-reports'),
          },
          {
            label: 'Vitals',
            href: 'vitals',
            icon: faHeartPulse,
            component: PatientVitals,
            disabled: dataIndicators.get('vitals'),
          },
          {
            label: 'Encounters & Notes',
            href: 'encounters',
            icon: faClipboardCheck,
            component: PatientEncounters,
            disabled: dataIndicators.get('encounters'),
          },
          {
            label: 'Referrals',
            href: 'referrals',
            icon: faIdCard,
            requiredFeature: 'ctw-referrals',
            prototypeStage: 'beta',
            component: PatientReferrals,
            disabled: dataIndicators.get('referrals'),
          },
          {
            label: 'Documents',
            href: 'documents',
            icon: faFileLines,
            component: PatientDocuments,
            disabled: dataIndicators.get('documents'),
          },
          {
            label: 'Allergies',
            href: 'allergies',
            icon: faAllergies,
            component: PatientAllergies,
            disabled: dataIndicators.get('allergies'),
          },
          {
            label: 'Immunizations',
            href: 'immunizations',
            icon: faSyringe,
            component: PatientImmunizations,
            disabled: dataIndicators.get('immunizations'),
          },
          {
            label: 'Care Team',
            href: 'care-team',
            icon: faUsers,
            component: PatientCareTeam,
            disabled: dataIndicators.get('care-team'),
          },
          {
            label: 'Care Gaps',
            href: 'care-gaps',
            icon: faHandHoldingMedical,
            component: PatientCareGaps,
            requiredFeature: 'ctw-care-gaps-overview',
            disabled: true,
          },
        ] satisfies Array<ZapSection>
      ).filter(
        (section) =>
          section.requiredFeature === undefined ||
          (Array.isArray(section.requiredFeature)
            ? section.requiredFeature.every((feature) => hasFeatureFlag(feature))
            : hasFeatureFlag(section.requiredFeature)),
      ),
    [dataIndicators, hasFeatureFlag],
  );

  return zapSections;
};
