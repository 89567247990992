import { Button } from '@ctw/shared/components/button';
import type { ModalLayout } from '@ctw/shared/context/modal-context';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, type ReactNode } from 'react';

export interface ModalProps {
  isOpen: boolean;
  onOpen?: () => void;
  onAfterOpen?: () => void;
  close: () => void;
  onAfterClose?: () => void;
  layout: ModalLayout;
  content: {
    body: ReactNode;
  };
}

export const Modal = ({
  layout,
  content,
  isOpen,
  onOpen,
  onAfterOpen,
  close,
  onAfterClose,
}: ModalProps) => (
  <Transition appear={true} show={isOpen} as={Fragment}>
    <Dialog as="div" className={tw`relative z-modal`} onClose={close}>
      <TransitionChild
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={tw`fixed inset-0 transition-opacity`}>
          <div className={tw`h-full w-full bg-content-subtle opacity-75`} />
        </div>
      </TransitionChild>

      <div className={tw`fixed inset-0`}>
        <div
          className={twx(
            'h-full max-h-full w-full max-w-full items-center p-10 max-sm:p-0 max-lg:p-4',
            {
              'overflow-auto': layout.overflowBehavior === 'visible',
            },
          )}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={onAfterClose}
            beforeEnter={onOpen}
            afterEnter={onAfterOpen}
          >
            <DialogPanel
              className={twx(
                'relative z-modal mr-auto ml-auto w-fit max-w-full flex-1 overflow-hidden rounded-md bg-white shadow-xl max-sm:rounded-none',
                {
                  'h-full max-h-full overflow-auto': layout.overflowBehavior === 'scroll',
                  'p-8': !layout.fullbleed,
                  'min-h-full min-w-full': layout.mode === 'full',
                  'h-min': layout.mode === 'fit',
                },
              )}
            >
              <div
                className={twx({
                  'h-full w-full overflow-hidden': layout.overflowBehavior === 'scroll',
                  'h-full min-h-full w-full min-w-full': layout.mode === 'full',
                })}
              >
                {layout.showCloseButton && (
                  <Button
                    type="button"
                    ariaLabel="Close Modal"
                    variant="circular"
                    size="sm"
                    className={tw`absolute top-1 right-1 flex items-center justify-center bg-white p-2 text-content-main shadow-none transition-all duration-300 hover:bg-content-subtle/15 active:scale-[.98] active:bg-content-subtle/15`}
                    onClick={close}
                  >
                    <FontAwesomeIcon icon={faXmark} className={tw`h-4 w-4`} />
                  </Button>
                )}
                <div
                  className={twx('flex h-full max-h-full flex-col', {
                    'overflow-auto': layout.overflowBehavior === 'scroll',
                  })}
                  data-testid="modal"
                >
                  {content.body}
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </Transition>
);
