import { usePatientCareGaps } from '@ctw/shared/api/fhir/care-gaps';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { patientCareGapsColumns } from '@ctw/shared/content/care-gaps/helpers/columns';
import { careGapFilters } from '@ctw/shared/content/care-gaps/helpers/filters';
import {
  careGapSortOptions,
  defaultCareGapSort,
} from '@ctw/shared/content/care-gaps/helpers/sorts';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { ResourceTableActions } from '@ctw/shared/content/resource/resource-table-actions';
import { useCareGapDrawer } from '@ctw/shared/hooks/use-care-gap-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMemo } from 'react';

export const PatientCareGaps = withErrorBoundary({
  boundaryName: 'PatientCareGaps',
  includeTelemetryBoundary: true,
  Component: () => {
    const { openDrawer } = useCareGapDrawer();
    const careGapsQuery = usePatientCareGaps();

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-care-gaps',
      defaultSort: defaultCareGapSort,
      records: careGapsQuery.data,
    });
    const hasZeroFilteredRecords = !careGapsQuery.isLoading && data.length === 0;
    const validFilters = useMemo(() => careGapFilters(careGapsQuery.data), [careGapsQuery.data]);

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: careGapSortOptions,
          onChange: setSort,
        }}
      />,
      [validFilters, filters, defaultSort, careGapSortOptions],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={careGapsQuery.isLoading}
          stackedBreakpoint="xs"
          columns={patientCareGapsColumns()}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="care-gaps"
            />
          }
          onRowClick={(model) => {
            openDrawer({
              model,
              telemetryContext: { target: 'care-gap' },
            });
          }}
        />
      </div>
    );
  },
});
