import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Sheet } from '@ctw/shared/components/sheet';
import { DrawerProvider, useDrawerState } from '@ctw/shared/context/drawer-context';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

interface MainContentWithDockableDrawerState {
  setHeaderContent: (content: ReactNode | null) => void;
  headerContent: ReactNode | null;
}

const MainContentWithDockableDrawerContext =
  createContext<MainContentWithDockableDrawerState | null>(null);

interface MainContentWithDockableDrawerProviderProps extends PropsWithChildren {}

const MainContentWithDockableDrawerProvider = ({
  children,
}: MainContentWithDockableDrawerProviderProps) => {
  const [headerContent, setHeaderContent] = useState<ReactNode | null>(null);

  const state: MainContentWithDockableDrawerState = useMemo(
    () => ({
      headerContent,
      setHeaderContent,
    }),
    [headerContent],
  );

  return (
    <MainContentWithDockableDrawerContext.Provider value={state}>
      {children}
    </MainContentWithDockableDrawerContext.Provider>
  );
};

export const MAIN_CONTENT_ID = 'main-content';

export interface MainContentWithDockableDrawerProps extends PropsWithChildren {
  className?: string;
  focalHeader?: boolean;
  fullbleed?: boolean;
  handleContentLoadingState?: boolean;
}

export const MainContentWithDockableDrawer = ({
  handleContentLoadingState = false,
  ...props
}: MainContentWithDockableDrawerProps) => (
  <MainContentSheet fullbleed={true} handleContentLoadingState={handleContentLoadingState}>
    <MainContentWithDockableDrawerProvider>
      <DrawerProvider allowDocking={true}>
        {({ drawer }) => <MainContentAndDockedDrawer {...props} drawer={drawer} />}
      </DrawerProvider>
    </MainContentWithDockableDrawerProvider>
  </MainContentSheet>
);

interface MainContentAndDockedDrawerProps extends MainContentWithDockableDrawerProps {
  drawer: ReactNode;
}

const MainContentAndDockedDrawer = ({
  className,
  children,
  focalHeader,
  fullbleed,
  drawer,
}: MainContentAndDockedDrawerProps) => {
  const { isDrawerDocked } = useDrawerState();

  return (
    <div className={tw`flex h-full min-h-full w-full min-w-full`}>
      <main
        id={MAIN_CONTENT_ID}
        className={twx(
          'relative flex max-h-full w-full max-w-full grow-0 flex-col transition-all duration-300',
          {
            /* These widths MUST match the widths in DockableDrawer */
            'w-[calc(100%-320px)] max-w-[calc(100%-320px)] lg:w-[calc(100%-450px)] lg:max-w-[calc(100%-450px)]':
              isDrawerDocked,
          },
          className,
        )}
      >
        <MainContentHeader focalHeader={focalHeader} />
        <div
          className={twx('scroll-shadows h-full max-w-full overflow-auto', {
            'p-2': !fullbleed,
          })}
        >
          {children}
        </div>
      </main>
      <div className={tw`border-divider-main border-l transition-all duration-300`}>{drawer}</div>
    </div>
  );
};

export const MAIN_CONTENT_SHEET_ID = 'main-content-sheet';

export interface MainContentSheetProps extends PropsWithChildren {
  className?: string;
  fullbleed?: boolean;
  handleContentLoadingState: boolean;
}

export const MainContentSheet = ({
  className,
  children,
  handleContentLoadingState,
  fullbleed,
}: MainContentSheetProps) => {
  const { isNavigating } = useZuiContext();

  return (
    <Sheet
      id={MAIN_CONTENT_SHEET_ID}
      fullbleed={fullbleed}
      className={twx('flex flex-1 flex-col', className)}
    >
      {isNavigating && handleContentLoadingState ? (
        <LoadingSpinner centered={true} message="Loading..." />
      ) : (
        children
      )}
    </Sheet>
  );
};

interface MainContentHeaderProps extends Pick<MainContentWithDockableDrawerProps, 'focalHeader'> {}

const MainContentHeader = ({ focalHeader }: MainContentHeaderProps) => {
  const context = useContext(MainContentWithDockableDrawerContext);

  return (
    <>
      {context?.headerContent && (
        <div
          className={twx('flex h-fit w-full items-center border-divider-main p-2', {
            'bg-content-focal': focalHeader,
            'border-b bg-white': !focalHeader,
          })}
        >
          {context.headerContent}
        </div>
      )}
    </>
  );
};

export const useMainContentHeader = (content: ReactNode, dependencies: Array<unknown>): void => {
  const context = useContext(MainContentWithDockableDrawerContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    context?.setHeaderContent(content);

    return () => {
      context?.setHeaderContent(null);
    };
  }, dependencies);
};
