import type {
  FormFieldOption,
  FormFieldType,
} from '@ctw/shared/components/form/drawer-form-with-fields';
import type Zod from 'zod';
import {
  ZodArray,
  ZodBoolean,
  ZodDate,
  ZodDefault,
  ZodEffects,
  ZodEnum,
  ZodLiteral,
  ZodNativeEnum,
  ZodNumber,
  ZodObject,
  ZodOptional,
  ZodString,
  type ZodTypeAny,
  ZodUnknown,
  type z,
} from 'zod';

type ActionReturn<T> =
  | { success: true | false; data: T; errors: undefined }
  | { success: false; data: undefined; errors: { [key: string]: Array<string> } };

export type AnyZodSchema = Zod.AnyZodObject | ZodEffects<ZodTypeAny, unknown, unknown>;

export function parseParams(o: object, schema: AnyZodSchema, externalKey: string, value: unknown) {
  let key = externalKey;
  // find actual shape definition for this key
  let shape = schema;

  while (shape instanceof ZodObject || shape instanceof ZodEffects) {
    shape =
      shape instanceof ZodObject
        ? shape.shape
        : shape instanceof ZodEffects
          ? shape._def.schema
          : null;
    if (shape === null) {
      throw new Error(`Could not find shape for key ${key}`);
    }
  }

  if (key.includes('.')) {
    const [parentProp, ...rest] = key.split('.');
    o[parentProp as never] = (o[parentProp as never] as never) ?? {};
    parseParams(o[parentProp as never], shape[parentProp], rest.join('.'), value);
    return;
  }
  if (key.includes('[]')) {
    key = key.replace('[]', '');
  }
  const def = shape[key];
  if (def) {
    processDef(def, o, key, value as string);
  }
}

export function processDef(def: ZodTypeAny, o: object, key: string, value: string) {
  let parsedValue: unknown;
  if (def instanceof ZodString || def instanceof ZodLiteral) {
    parsedValue = value;
  } else if (def instanceof ZodNumber) {
    const num = Number(value);
    parsedValue = Number.isNaN(num) ? value : num;
  } else if (def instanceof ZodDate) {
    const date = Date.parse(value);
    parsedValue = Number.isNaN(date) ? value : new Date(date);
  } else if (def instanceof ZodBoolean) {
    parsedValue = value === 'true' ? true : value === 'false' ? false : Boolean(value);
  } else if (def instanceof ZodNativeEnum || def instanceof ZodEnum) {
    parsedValue = value;
  } else if (def instanceof ZodOptional || def instanceof ZodDefault) {
    // def._def.innerType is the same as ZodOptional's .unwrap(), which unfortunately doesn't exist on ZodDefault
    processDef(def._def.innerType, o, key, value);
    // return here to prevent overwriting the result of the recursive call
    return;
  } else if (def instanceof ZodArray) {
    if (o[key as never] === undefined) {
      o[key as never] = [] as never;
    }
    processDef(def.element, o, key, value);
    // return here since recursive call will add to array
    return;
  } else if (def instanceof ZodEffects) {
    processDef(def._def.schema, o, key, value);
    return;
  } else if (def instanceof ZodObject) {
    const parsedJson = JSON.parse(value);
    parsedValue = parsedJson;
    Object.entries(def.shape).forEach(([key, _]) => {
      if (!(parsedValue as object)[key as never]) {
        delete parsedJson[key];
      }
    });
  } else if (def instanceof ZodUnknown) {
    parsedValue = JSON.parse(value);
  } else {
    throw new Error(`Unexpected type ${def._def.typeName} for key ${key}`);
  }
  if (Array.isArray(o[key as never])) {
    (o[key as never] as Array<unknown>).push(parsedValue);
  } else {
    o[key as never] = parsedValue as never;
  }
}

export function isIterable(maybeIterable: unknown): maybeIterable is Iterable<unknown> {
  return Symbol.iterator in new Object(maybeIterable);
}

export function getParamsInternal<T>(
  params: URLSearchParams | FormData | Record<string, string | undefined>,
  schema: AnyZodSchema,
): ActionReturn<T> {
  // @ts-ignore
  const o: object = {};
  let entries: Array<[string, unknown]>;
  if (isIterable(params)) {
    entries = Array.from(params);
  } else {
    entries = Object.entries(params);
  }

  for (const [key, value] of entries) {
    // infer an empty param as if it wasn't defined in the first place
    if (value === '') {
      continue;
    }
    parseParams(o, schema, key, value);
  }

  const result = schema.safeParse(o);
  if (result.success) {
    return { success: true, data: result.data as T, errors: undefined };
  }
  const errors: { [key: string]: Array<string> } = {};

  result.error.issues.forEach((issue: { message: unknown; path: Array<unknown> }) => {
    const { message, path } = issue;
    const key = path[0];
    if (!Object.prototype.hasOwnProperty.call(errors, key as never)) {
      errors[key as never] = [message as never];
    } else {
      errors[key as never] = [...(errors[key as never] as never), message] as never;
    }
  });

  return { success: false, data: undefined, errors };
}

export async function getFormData<T extends AnyZodSchema>(data: FormData, schema: T) {
  type ParamsType = z.infer<T>;
  return await getParamsInternal<ParamsType>(data, schema);
}

function getOptions(
  schema: Zod.AnyZodObject,
  entry: Readonly<FormFieldType>,
): ReadonlyArray<FormFieldOption> | undefined {
  if (entry.options) {
    return entry.options;
  }

  const zodField = schema.shape[entry.field];

  return (zodField?.options || zodField?.unwrap?.().options)?.map((option: unknown) => ({
    label: option,
    value: option,
  }));
}

export type InputPropType = {
  name: string;
  type: string;
  required?: boolean;
  'aria-required'?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  options?: ReadonlyArray<FormFieldOption> | undefined;
};

export function useFormInputProps(zodThing: AnyZodSchema) {
  const schema = zodThing instanceof ZodEffects ? zodThing._def.schema : zodThing;
  const { shape } = schema as never;
  return (entry: Readonly<FormFieldType>, _options: unknown = {}) => {
    const def = shape[entry.field];
    if (!def) {
      throw new Error(`no such key: ${entry.field}`);
    }
    return getInputProps(entry, schema, def);
  };
}

export function getInputProps(
  entry: Readonly<FormFieldType>,
  schema: unknown,
  def: ZodTypeAny,
): InputPropType {
  let type = 'text';
  let min: number | undefined;
  let max: number | undefined;
  let minLength: number | undefined;
  let maxLength: number | undefined;
  const options = getOptions(schema as never, entry);
  if (def instanceof ZodString) {
    if (def.isEmail) {
      type = 'email';
    } else if (def.isURL) {
      type = 'url';
    }
    minLength = def.minLength ?? undefined;
    maxLength = def.maxLength ?? undefined;
  } else if (def instanceof ZodNumber) {
    type = 'number';
    min = def.minValue ?? undefined;
    max = def.maxValue ?? undefined;
  } else if (def instanceof ZodBoolean) {
    type = 'checkbox';
  } else if (def instanceof ZodDate || def._def.innerType instanceof ZodDate) {
    type = 'date';
  } else if (def instanceof ZodArray) {
    return getInputProps(entry, schema, def.element);
  }

  const inputProps: InputPropType = {
    name: entry.field,
    type,
    options,
  };

  if (!(def instanceof ZodOptional)) {
    inputProps['aria-required'] = true;
  }
  if (min) {
    inputProps.min = min;
  }
  if (max) {
    inputProps.max = max;
  }
  if (minLength && Number.isFinite(minLength)) {
    inputProps.minLength = minLength;
  }
  if (maxLength && Number.isFinite(maxLength)) {
    inputProps.maxLength = maxLength;
  }
  return inputProps;
}
