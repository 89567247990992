import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { ObservationModel } from '@ctw/shared/api/fhir/models/observation';
import { usePatientObservationsWithTrends } from '@ctw/shared/api/fhir/observations';
import { Button } from '@ctw/shared/components/button';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/resource-title-column';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { TelemetryBoundary, useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { tw } from '@ctw/shared/utils/tailwind';
import { twx } from '@ctw/shared/utils/tailwind';
import { faArrowTrendUp, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useState } from 'react';

export type ObservationEntriesComponentProps = {
  model: DiagnosticReportModel;
};

export type ObservationListProps = {
  observations: Array<ObservationModel>;
  isLoadingTrends?: boolean;
};

export const diagnosticReportData = (diagnosticReport: DiagnosticReportModel) => [
  {
    label: 'Effective Date',
    value: diagnosticReport.dateDisplay,
  },
  { label: 'Organization', value: diagnosticReport.performer },
];

export const ObservationList = ({ observations, isLoadingTrends }: ObservationListProps) => {
  const sortedObservations = useMemo(
    () =>
      [...observations]
        .filter((observation) => observation.value)
        .sort((a, b) => (a.title > b.title ? 1 : -1)),
    [observations],
  );

  return (
    <div className={tw`flex flex-col gap-1 divide-y border-divider-main`}>
      {isLoadingTrends ? (
        <LoadingSpinner centered={true} message="Loading trends..." />
      ) : (
        sortedObservations.map((observation) => (
          <div key={observation.id} className={tw`flex flex-col gap-1 py-1`}>
            {observation.valueIsInNotes || observation.value.length > 20 ? (
              <>
                {observation.title.toLowerCase() !== 'unknown' && observation.title && (
                  <span>{observation.title}</span>
                )}
                <div className={tw`text-sm`}>{observation.value}</div>
              </>
            ) : (
              <div className={tw`flex flex-wrap items-center gap-1`}>
                <div>{observation.title}</div>
                <BubbleIcon
                  tooltip={observation.notes}
                  interpretation={observation.interpretation}
                  result={observation.value}
                  className={twx(observation.acceptedInterpretations)}
                />
              </div>
            )}
            {observation.referenceRange && (
              <div className={tw`text-sm`}>
                Reference Range: {observation.referenceRange} {observation.unit}
              </div>
            )}
            <ObservationTrends model={observation} />
          </div>
        ))
      )}
    </div>
  );
};

export const DiagnosticReportObservationList = withErrorBoundary({
  boundaryName: 'ObservationDetails',
  includeTelemetryBoundary: true,
  Component: ({ model }: ObservationEntriesComponentProps) => {
    const query = usePatientObservationsWithTrends(model.observationIds);

    if (query.isLoadingObservations) {
      return <LoadingSpinner centered={true} />;
    }

    if (query.isError) {
      return <ContentError title="Error loading observations" />;
    }

    return <ObservationList observations={query.data} isLoadingTrends={query.isLoadingTrends} />;
  },
});

type ObservationTrendsProps = {
  model: ObservationModel;
};

const ObservationTrends = ({ model }: ObservationTrendsProps) => {
  const [isTrendsShown, setIsTrendsShown] = useState(false);
  const { trackInteraction } = useTelemetry();
  const { requestContext } = useCTW();

  return (
    <TelemetryBoundary boundaryName="ObservationTrends">
      <div className={tw`font-normal text-sm`}>
        {!model.isIncorrectlyCodedGlucose && model.trends && model.trends.length > 1 && (
          <div className={tw`pt-2`}>
            <Button
              aria-label="trends"
              variant="link"
              className={tw`space-x-3`}
              type="button"
              onClick={() => {
                trackInteraction('toggle_trend', {
                  action: isTrendsShown ? 'collapse_trend' : 'expand_trend',
                });
                setIsTrendsShown(!isTrendsShown);
              }}
            >
              <FontAwesomeIcon icon={faArrowTrendUp} />
              <span>Trends</span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={twx('h-3 w-3 pr-1', {
                  'rotate-90': isTrendsShown,
                })}
              />
            </Button>
            {isTrendsShown &&
              [...model.trends]
                .sort((a, b) => {
                  if (!(a.date && b.date)) {
                    return 0;
                  }
                  return new Date(b.date).getTime() - new Date(a.date).getTime();
                })
                .map(
                  (trend) =>
                    !trend.isIncorrectlyCodedGlucose && (
                      <div key={trend.id} className={tw`grid grid-cols-2 py-1 pl-4`}>
                        <div
                          className={twx('relative flex w-24 items-center gap-2 text-sm', {
                            'font-semibold':
                              trend.id === model.id ||
                              (trend.date === model.date && trend.value === model.value),
                          })}
                        >
                          {trend.dateDisplay}
                          {RenderSyncedWithRecordIcon(
                            trend.ownedByBuilder(requestContext.builderId),
                          )}
                        </div>
                        <BubbleIcon
                          tooltip={trend.notes}
                          result={trend.value}
                          interpretation={trend.interpretation}
                          className={twx(`${trend.acceptedInterpretations} w-fit`, {
                            '!font-semibold': trend.id === model.id,
                          })}
                        />
                      </div>
                    ),
                )}
          </div>
        )}
      </div>
    </TelemetryBoundary>
  );
};

type BubbleIconProps = {
  interpretation?: string;
  result: string | number;
  className?: string;
  tooltip?: string;
};

const BubbleIcon = ({ className, result, interpretation, tooltip }: BubbleIconProps) =>
  interpretation ? (
    <div className={twx('whitespace-nowrap', className)} title={tooltip}>
      {result} - {interpretation}
    </div>
  ) : (
    <div className={twx('whitespace-nowrap', className)} title={tooltip}>
      {result}
    </div>
  );
