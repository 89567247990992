import { ActionDropdown } from '@ctw/shared/components/dropdown/action-dropdown';
import { FilterBar } from '@ctw/shared/components/filter-bar/filter-bar';
import type { FilterBarProps } from '@ctw/shared/components/filter-bar/filter-bar-types';
import type { MinRecordItem } from '@ctw/shared/components/table/table';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { Sort } from '@ctw/shared/utils/sort';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ReactNode, useState } from 'react';
import { ViewButton, type ViewButtonProps } from './view-button';

export type SortOption<T extends object> = {
  display: string;
  sorts: Array<Sort<T>>;
};

type SortButtonProps<T extends object> = {
  className?: ClassName;
  defaultSort: SortOption<T>;
  onChange: (sort: SortOption<T>) => void;
  options: Array<SortOption<T>>;
};

const SortButton = <T extends object>({
  className,
  defaultSort,
  onChange,
  options,
}: SortButtonProps<T>) => {
  const [selected, setSelected] = useState<SortOption<T>>(defaultSort);
  const { trackInteraction } = useTelemetry();

  return (
    <ActionDropdown
      className={tw`max-h-[17.25rem] overflow-y-auto`}
      type="select"
      buttonClassName={twx(
        className,
        'w-min rounded-md border border-divider-main bg-transparent p-0 shadow-sm',
      )}
      onItemSelect={(item) => {
        const selectedOption = options.filter((option) => option.display === item.key)[0];
        onChange(selectedOption);
        trackInteraction('sort', {
          value: item.key,
          dir: selectedOption.sorts[0].dir,
        });
        setSelected(selectedOption);
      }}
      items={options.map((option) => ({
        key: option.display,
        name: option.display,
        isSelected: selected.display === option.display,
      }))}
    >
      <div
        className={tw`flex items-center space-x-2 rounded-md border border-border-main border-transparent border-solid bg-white px-4 py-2 font-medium text-content-subtle text-sm capitalize shadow-sm hover:bg-background-hover`}
      >
        <span className={tw`whitespace-nowrap`}>
          Sort: <span className={tw`font-normal`}>{selected.display}</span>
        </span>
        <FontAwesomeIcon icon={faChevronDown} className={tw`ml-auto w-2`} />
      </div>
    </ActionDropdown>
  );
};

export type ResourceTableActionsProps<T extends MinRecordItem> = {
  headAction?: ReactNode;
  action?: ReactNode;
  className?: string;
  filterOptions?: FilterBarProps;
  sortOptions?: SortButtonProps<T>;
  viewOptions?: ViewButtonProps<T>;
};

export const ResourceTableActions = <T extends MinRecordItem>({
  action,
  className,
  filterOptions,
  headAction,
  sortOptions,
  viewOptions,
}: ResourceTableActionsProps<T>) => {
  const isEmpty = !(viewOptions || sortOptions || filterOptions || action);
  if (isEmpty) {
    return null;
  }

  return (
    <div className={twx(className, 'flex flex-wrap')}>
      <div className={tw`flex w-full flex-wrap items-center gap-2`}>
        {headAction && <div className={tw`whitespace-nowrap`}>{headAction}</div>}
        {viewOptions && (
          <ViewButton
            className={twx(viewOptions.className)}
            defaultView={viewOptions.defaultView}
            onChange={viewOptions.onChange}
            options={viewOptions.options}
          />
        )}
        {sortOptions && (
          <SortButton
            className={twx(sortOptions.className)}
            defaultSort={sortOptions.defaultSort}
            onChange={sortOptions.onChange}
            options={sortOptions.options}
          />
        )}
        {filterOptions && filterOptions.filters.length > 0 && <FilterBar {...filterOptions} />}
      </div>
      <div className={tw`whitespace-nowrap`}>{action}</div>
    </div>
  );
};
