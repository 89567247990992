import type { Breakpoint } from '@ctw/config/zui';
import { DrawerContent } from '@ctw/shared/components/containers/_internal/drawer-content';
import { Drawer, type DrawerProps } from '@ctw/shared/components/containers/drawer';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { useBreakpointQuery } from '@ctw/shared/hooks/breakpoints';
import { twx } from '@ctw/shared/utils/tailwind';
import { usePrevious } from '@uidotdev/usehooks';
import { useLayoutEffect, useMemo, useState } from 'react';

interface DockableDrawerContentBreakpoints {
  docked: Breakpoint;
}

const contentBreakpoints: DockableDrawerContentBreakpoints = {
  docked: 'lg',
};

const dockedDrawerContainerWidthClassNames =
  'w-[320px] max-w-[320px] lg:w-[450px] lg:max-w-[450px] h-full' as const;
const dockedDrawerContentWidthClassNames =
  `${dockedDrawerContainerWidthClassNames} min-w-[320px] lg:min-w-[450px]` as const;

export interface DockableDrawerProps extends DrawerProps {
  isOpen: boolean;
}

export const DockableDrawer = ({ isOpen, ...drawerProps }: DockableDrawerProps) => {
  const { breakpoints } = useBreakpointQuery();
  const isDockable = useMemo(() => breakpoints.isAtLeast[contentBreakpoints.docked], [breakpoints]);
  const isDocked = useMemo(() => isDockable && isOpen, [isDockable, isOpen]);
  const [isHidden, setIsHidden] = useState(true);
  const previousIsDocked = usePrevious(isDocked);
  const { registerPresence } = useZuiContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (isDocked) {
      return registerPresence('dockedDrawer');
    }

    return () => {
      /* noop */
    };
  }, [isDocked]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (isDocked && !previousIsDocked) {
      setIsHidden(false);
      drawerProps.onOpen?.();
      setTimeout(() => {
        drawerProps.onAfterOpen?.();
      }, 300);
    } else if (!isDocked && previousIsDocked) {
      setTimeout(() => {
        drawerProps.onAfterClose?.();
        setIsHidden(true);
      }, 300);
    }
  }, [isDocked, previousIsDocked]);

  if (isDockable) {
    return (
      <aside
        className={twx('h-full min-h-full overflow-hidden transition-all duration-300', {
          [dockedDrawerContainerWidthClassNames]: isDocked,
          'h-0 w-0 max-w-0 opacity-0': !isDocked,
        })}
      >
        <div
          className={twx(
            'max-h-full min-h-full overflow-visible transition-all duration-300',
            { hidden: isHidden },

            dockedDrawerContentWidthClassNames,
          )}
        >
          <DrawerContent isDocked={isDocked} {...drawerProps} />
        </div>
      </aside>
    );
  }

  return <Drawer isOpen={isOpen} {...drawerProps} />;
};
