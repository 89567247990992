import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { MAX_OBJECTS_PER_REQUEST, fqsRequestAll } from '@ctw/shared/api/fqs/client';
import {
  type DiagnosticReportGraphqlResponse,
  getDiagnosticReportQuery,
} from '@ctw/shared/api/fqs/queries/diagnostic-reports-query';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS } from '@ctw/shared/utils/query-keys';
import type { GraphQLClient } from 'graphql-request';
import { isEqual, uniqWith } from 'lodash-es';
import { SYSTEM_SUMMARY } from './system-urls';

// Gets diagnostic reports for the patient.
export function usePatientDiagnosticReports(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  const { requestContext } = useCTW();

  return usePatientQuery({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS,
    queryKey: [limit], // Only use the IDs in our key (fixes issue with ciruclar references).
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      const data = await fetchDiagnosticReportsFromFQS(telemetry, graphqlClient, patient, limit);

      const diagnosticReports = data.DiagnosticReportConnection.edges.map(
        (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
      );
      const valuesToDedupeOn = (dr: DiagnosticReportModel) => [dr.displayName, dr.dateDisplay];

      // sort prioritizing builder-owned reports first, then dedupe keeping the first occurrence
      const sortedReports = [...diagnosticReports].sort((a, b) => {
        const aIsBuilderOwned = a.ownedByBuilder(requestContext.builderId);
        const bIsBuilderOwned = b.ownedByBuilder(requestContext.builderId);
        if (aIsBuilderOwned && !bIsBuilderOwned) {
          return -1;
        }
        if (!aIsBuilderOwned && bIsBuilderOwned) {
          return 1;
        }
        return 0;
      });

      return uniqWith(sortedReports, (a, b) => isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)));
    },
    enabled,
  });
}

async function fetchDiagnosticReportsFromFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  limit: number,
) {
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    telemetry,
    graphqlClient,
    getDiagnosticReportQuery,
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: 'DiagnosticReportConnection',
      filter: {
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
      first: limit,
    },
  );
  return data;
}

export async function fetchDiagnosticReportsFromFQSById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: Array<string> = [],
) {
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    telemetry,
    graphqlClient,
    getDiagnosticReportQuery,
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );
  return data.DiagnosticReportConnection.edges.map(
    (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
  );
}
