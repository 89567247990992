import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { ObservationModel } from '@ctw/shared/api/fhir/models/observation';
import { getEHRIntegrationServiceBaseUrl } from '@ctw/shared/api/urls';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { type EhrWriteResponse, useWritebacks } from '@ctw/shared/context/writeback-provider';
import { pickBy } from 'lodash-es';
import { useCallback } from 'react';

export function useLabWriteback() {
  const { requestContext, ctwFetch } = useCTW();
  const { onWritebackSuccess } = useWritebacks();
  const { env } = requestContext;
  const { patientID, systemURL } = usePatientContext();
  if (!(patientID && systemURL)) {
    throw new Error('Patient ID or system URL not found');
  }

  const labWritebackRequest = useCallback(
    async (
      resource: DiagnosticReportModel,
      observations: Array<ObservationModel>,
    ): Promise<EhrWriteResponse> => {
      const { builderId } = requestContext;
      if (!builderId) {
        throw new Error('Builder ID not found');
      }

      const body = {
        data: {
          type: 'lab',
          attributes: {
            ehrContext: {
              ah_department: requestContext.ehrContext?.ah_department,
            },
            displayName: resource.title,
            effectiveDateTime: resource.date,
            observations: observations.map((observation) =>
              pickBy({
                displayName: observation.title,
                loincCode: observation.loincCode?.code,
                value: observation.resource.valueQuantity?.value?.toString() ?? observation.value,
                units: observation.resource.valueQuantity?.unit,
                interpretation: observation.interpretation,
              }),
            ),
          },
          relationships: {
            patient: {
              data: {
                type: 'fhir/Patient',
                identifierSystemURL: systemURL,
                identifierValue: patientID,
              },
            },
          },
        },
      };

      const url = `${getEHRIntegrationServiceBaseUrl(env)}/lab`;

      const response = await ctwFetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${requestContext.authToken}`,
          'Content-Type': 'application/json',
          'Zus-Account': builderId,
        },
      });

      if (response.data && Object.keys(response.data).length === 0) {
        throw new Error('Failed to write lab results to EHR.');
      }

      const writebackResponse = response.data as EhrWriteResponse;
      onWritebackSuccess(resource.id, writebackResponse);

      return writebackResponse;
    },
    [env, requestContext, ctwFetch, onWritebackSuccess, patientID, systemURL],
  );

  return labWritebackRequest;
}
