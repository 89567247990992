import type { Breakpoint } from '@ctw/config/zui';
import { Avatar, type AvatarProps } from '@ctw/shared/components/avatar';
import { Button } from '@ctw/shared/components/button';
import { HorizontalDivider } from '@ctw/shared/components/horizontal-divider';
import { Link, type LinkProps } from '@ctw/shared/components/link';
import { PrototypeLabel, type PrototypeStage } from '@ctw/shared/components/prototype-label';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { VerticalDivider } from '@ctw/shared/components/vertical-divider';
import { usePresence, useRegisterPresence, useZuiContext } from '@ctw/shared/context/zui-provider';
import { useBreakpointQuery } from '@ctw/shared/hooks/breakpoints';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { hrefIsActive } from '@ctw/shared/utils/url';
import { type IconDefinition, faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { kebabCase } from 'lodash-es';
import { Fragment, type ReactNode, useEffect, useMemo, useState } from 'react';

export const SUB_NAVIGATION_BAR_TEST_ID = 'sub-navigation-bar';
export const SUB_NAVIGATION_DRAWER_BUTTON_TEST_ID = 'sub-navigation-drawer-button';
export const subNavigationDrawerLinkTestId = (label: string) =>
  `sub-navigation-drawer-link-${kebabCase(label)}`;

export interface SubNavigationLink {
  label: string;
  href: LinkProps['href'];
  siblingHrefs?: Array<LinkProps['href']>;
  icon: IconDefinition;
  disabled?: boolean;
  prototypeStage?: PrototypeStage;
}

export interface SubNavigationBarHeaderField {
  label: string;
  value: string;
  shortValue?: string;
  icon?: IconDefinition;
  collapseToShortValueUnder?: Breakpoint;
  collapseToIconOnlyUnder?: Breakpoint;
  hideUnder?: Breakpoint;
}

interface SubNavigationBarBreakpoints {
  useMobileSubnavBelow: Breakpoint;
}

const defaultBreakpoints: SubNavigationBarBreakpoints = {
  useMobileSubnavBelow: 'md',
};

export type SubNavigationBarProps = {
  className?: ClassName;
  subNavigationLinks?: Array<SubNavigationLink>;
  headerFields?: Array<SubNavigationBarHeaderField>;
  avatar?: Pick<AvatarProps, 'name' | 'imageUrl'>;
  contentBreakpoints?: SubNavigationBarBreakpoints;
  renderContent?: {
    afterHeaderFields?: () => ReactNode;
    barContentLeft?: () => ReactNode;
    drawerHeader?: () => ReactNode;
    drawerMenuHeader?: () => ReactNode;
  };
};

export const SubNavigationBar = ({
  className,
  subNavigationLinks,
  headerFields,
  avatar,
  renderContent,
  contentBreakpoints = defaultBreakpoints,
}: SubNavigationBarProps) => {
  const { breakpoints } = useBreakpointQuery();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  useRegisterPresence('subNavigationBar');

  const shouldUseMobileLayout = useMemo(
    () => !breakpoints.isAtLeast[contentBreakpoints.useMobileSubnavBelow],
    [breakpoints.isAtLeast, contentBreakpoints.useMobileSubnavBelow],
  );

  useEffect(() => {
    if (!shouldUseMobileLayout) {
      setDrawerIsOpen(false);
    }
  }, [shouldUseMobileLayout]);

  return (
    <aside
      data-testid={SUB_NAVIGATION_BAR_TEST_ID}
      aria-label="Sub Navigation Bar"
      className={twx(
        'relative z-navigation-sticky flex h-[45px] max-h-[45px] min-h-[45px] w-full min-w-full flex-1 basis-full items-center bg-white px-7 py-2 drop-shadow-lg',
        {
          'px-1': shouldUseMobileLayout,
        },
        className,
      )}
    >
      <div className={tw`flex h-full w-full flex-auto gap-1`}>
        <div className={tw`relative h-0 max-h-0`}>
          <SubNavigationDrawer
            subNavigationLinks={subNavigationLinks}
            renderContent={renderContent}
            isOpen={drawerIsOpen}
            onClose={() => setDrawerIsOpen(false)}
          />
        </div>
        {avatar && (
          <div className={tw`flex max-w-[400px] items-center gap-2`}>
            <Avatar
              imageUrl={avatar.imageUrl}
              name={avatar.name}
              size="sm"
              className={tw`bg-content-main`}
            />
            <div
              className={twx(
                'overflow-hidden whitespace-nowrap font-medium text-content-main text-ellipsize',
                {
                  'text-sm': shouldUseMobileLayout,
                },
              )}
            >
              {avatar.name}
            </div>
          </div>
        )}
        <VerticalDivider />
        {headerFields && (
          <ul className={tw`flex min-w-[150px] flex-1 overflow-hidden`}>
            {headerFields.map((field, index) => (
              <Fragment key={field.label}>
                {(!field.hideUnder || breakpoints.isAtLeast[field.hideUnder]) && (
                  <>
                    {index > 0 && <VerticalDivider />}
                    <SubNavigationBarHeaderField {...field} />
                  </>
                )}
              </Fragment>
            ))}
            {renderContent?.afterHeaderFields && (
              <li className={tw`flex`}>
                <VerticalDivider />
                <div className={tw``}>{renderContent.afterHeaderFields()}</div>
              </li>
            )}
          </ul>
        )}
      </div>
      {shouldUseMobileLayout && subNavigationLinks && (
        <div className={tw`flex items-center justify-end gap-2`}>
          <Button
            testId={SUB_NAVIGATION_DRAWER_BUTTON_TEST_ID}
            type="button"
            variant="circular"
            size="xs"
            disabled={drawerIsOpen}
            className={tw`flex items-center justify-center bg-content-inverse text-content-subtle shadow-none hover:bg-content-subtle/25 focus:bg-content-inverse focus:text-primary-text focus-visible:bg-content-inverse focus-visible:text-primary-text active:bg-content-subtle/15 active:text-primary-text disabled:bg-content-subtle/15 disabled:text-primary-text`}
            onClick={() => setDrawerIsOpen(true)}
          >
            <FontAwesomeIcon
              icon={faBars}
              className={tw`h-[18px] w-[18px] transition-all duration-300`}
            />
          </Button>
        </div>
      )}
    </aside>
  );
};

interface SubNavigationDrawerProps
  extends Pick<SubNavigationBarProps, 'subNavigationLinks' | 'renderContent'> {
  isOpen: boolean;
  onClose: () => void;
}

const SubNavigationDrawer = ({
  subNavigationLinks,
  renderContent,
  isOpen,
  onClose,
}: SubNavigationDrawerProps) => {
  const isMainNavigationBarPresent = usePresence('mainNavigationBar');

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog unmount={false} onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <DialogBackdrop
            className={twx('fixed inset-0 bg-content-main/25', {
              'top-[45px]': !isMainNavigationBarPresent,
              'top-[107px]': isMainNavigationBarPresent,
            })}
          />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="-translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="-translate-x-0"
          leaveTo="translate-x-full"
        >
          <DialogPanel
            className={twx(
              'scroll-shadows fixed right-0 bottom-0 z-drawer w-full overflow-y-auto overflow-x-visible border-divider-main border-t bg-white shadow-2xl sm:w-80 sm:min-w-80 sm:max-w-80',
              {
                'top-[45px]': !isMainNavigationBarPresent,
                'top-[107px]': isMainNavigationBarPresent,
              },
            )}
          >
            <div className={tw`flex flex-col py-4`}>
              {renderContent?.drawerHeader && (
                <>
                  <div className={tw`px-3`}>{renderContent.drawerHeader()}</div>
                  <HorizontalDivider />
                </>
              )}
              <ul className={tw`flex flex-col gap-1 px-3`}>
                {renderContent?.drawerMenuHeader?.()}
                {(subNavigationLinks ?? []).map((link) => (
                  <SubNavigationDrawerLink key={link.label} {...link} close={onClose} />
                ))}
              </ul>
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

interface SubNavigationDrawerLinkProps extends SubNavigationLink {
  close: () => void;
}

const SubNavigationDrawerLink = ({
  label,
  href,
  icon,
  disabled,
  prototypeStage,
  close,
}: SubNavigationDrawerLinkProps) => {
  const { pathname } = useZuiContext();
  const active = hrefIsActive({ pathname, href });

  return (
    <Link
      href={href}
      className={twx(
        'jusitfy-center flex items-center gap-2 px-3 py-2 hover:no-underline focus:bg-background-hover focus-visible:bg-background-hover active:bg-content-subtle/10',
        {
          'hover:bg-content-subtle/10': !active,
          'bg-primary-background-hover': active,
          'opacity-80': disabled,
        },
      )}
      dataTestId={subNavigationDrawerLinkTestId(label)}
      onClick={close}
    >
      <FontAwesomeIcon
        icon={icon}
        className={twx('h-6 w-6 text-content-icon', {
          'text-primary-icon': active,
        })}
      />
      <span className={tw`whitespace-nowrap text-content-main`}>
        {label}
        {prototypeStage ? (
          <PrototypeLabel className={tw`pl-1`} stage={prototypeStage} deemphasized={disabled} />
        ) : (
          <></>
        )}
      </span>
    </Link>
  );
};

interface SubNavigationBarHeaderFieldProps extends SubNavigationBarHeaderField {}

const SubNavigationBarHeaderField = ({
  label,
  value,
  shortValue,
  icon,
  collapseToShortValueUnder,
  collapseToIconOnlyUnder,
}: SubNavigationBarHeaderFieldProps) => {
  const { breakpoints } = useBreakpointQuery();

  const collapsedToShortValue = useMemo(
    () => (collapseToShortValueUnder ? !breakpoints.isAtLeast[collapseToShortValueUnder] : false),
    [breakpoints.isAtLeast, collapseToShortValueUnder],
  );

  const collapsedToIcon = useMemo(
    () => (collapseToIconOnlyUnder ? !breakpoints.isAtLeast[collapseToIconOnlyUnder] : false),
    [breakpoints.isAtLeast, collapseToIconOnlyUnder],
  );

  return (
    <li
      className={twx(
        'flex h-full min-h-full w-min min-w-[20px] max-w-min flex-1 items-center gap-1 overflow-hidden rounded-md py-1 transition-all duration-300',
        {
          'w-[20px]': collapsedToIcon,
        },
      )}
      data-testid={tw`sub-navigation-bar-header-field-${kebabCase(label)}`}
    >
      {icon && collapsedToIcon && (
        <Tooltip
          content={`<span><b>${label}: </b>${value}</span>`}
          className={tw`mr-0.5 flex h-[14px] min-h-[14px] w-[14px] min-w-[14px]`}
        >
          <FontAwesomeIcon
            icon={icon}
            className={tw`h-[14px] min-h-[14px] w-[14px] min-w-[14px] pl-0.5 text-content-icon`}
          />
        </Tooltip>
      )}
      {!collapsedToIcon && (
        <div
          className={tw`max-w-min overflow-hidden text-ellipsis whitespace-nowrap font-medium text-content-subtle text-sm leading-tight`}
        >
          {collapsedToShortValue ? value : (shortValue ?? value)}
        </div>
      )}
    </li>
  );
};
