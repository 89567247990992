import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebabCase } from 'lodash-es';
import { useState } from 'react';

export interface PillCheckboxGroupProps {
  className?: ClassName;
  legend: string;
  defaultSelected?: Array<string>;
  options: Array<string>;
  onChange: (options: Array<string>) => void;
}

export const PillCheckboxGroup = ({
  options,
  onChange,
  className,
  legend,
  defaultSelected = [],
}: PillCheckboxGroupProps) => {
  const [selected, setSelected] = useState<Array<string>>(defaultSelected);

  return (
    <fieldset className={twx(className)}>
      <legend className={tw`pb-2.5 text-content-main`}>{legend}</legend>
      <div className={tw`flex flex-wrap gap-2`}>
        {options.map((option) => (
          <PillCheckbox
            key={option}
            name={kebabCase(legend)}
            label={option}
            checked={selected.includes(option)}
            onChange={(checked) => {
              const newSelected = checked
                ? [...selected, option]
                : selected.filter((o) => o !== option);
              setSelected(newSelected);
              onChange(newSelected);
            }}
          />
        ))}
      </div>
    </fieldset>
  );
};

interface PillCheckboxProps {
  label: string;
  name: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

const PillCheckbox = ({ label, name, checked, onChange }: PillCheckboxProps) => (
  <label
    className={twx(
      'relative flex cursor-pointer items-center overflow-visible rounded-[4px] border border-1 border-transparent bg-background-main px-2.5 py-2.5 transition-all duration-300',
      {
        'bg-info-badge text-content-main': checked,
        'border-border-main text-content-subtle hover:bg-info-background': !checked,
      },
    )}
  >
    <FontAwesomeIcon
      className={twx('h-4 w-0 pr-0 text-transparent transition-all duration-300', {
        'w-4 pr-2 text-black': checked,
      })}
      icon={faCheck}
    />
    <span>{label}</span>
    <input
      type="checkbox"
      checked={checked}
      name={name}
      onChange={(e) => onChange?.(e.target.checked)}
      id={`${name}_${kebabCase(label)}`}
      className={tw`pointer-events-none absolute top-0 right-0 bottom-0 left-0 z-navigation h-full w-full appearance-none border-0 focus:outline-4 focus:outline-primary-main focus:outline-offset-[3px]`}
    />
  </label>
);
