import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const defaultEncounterSort: SortOption<EncounterModel> = {
  display: 'Date (New to Old)',
  sorts: [{ key: 'periodStart', dir: 'desc', isDate: true }],
};

export const encounterSortOptions: Array<SortOption<EncounterModel>> = [
  defaultEncounterSort,
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'periodStart', dir: 'asc', isDate: true }],
  },
];
