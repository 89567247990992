import { tw } from '@ctw/shared/utils/tailwind';
import parse from 'html-react-parser';
import { cloneDeep } from 'lodash-es';
import type { ReactElement, ReactNode } from 'react';

const MIN_SOURCE_COLUMNS_IN_NOTE_TO_TRANSPOSE = 3;
const MAX_DEPTH_FIND_TABLE_TO_TRANSPOSE = 6;

export const recursivelyTransposeTables = (node: ReactNode, curDepth: number): ReactNode => {
  if (!node || curDepth >= MAX_DEPTH_FIND_TABLE_TO_TRANSPOSE) {
    return node;
  }

  //  if this doesn't have a props then skip it
  if (node instanceof Boolean || node instanceof String) {
    return node;
  }

  //  is this node a table?
  if ((node as ReactElement).type === 'table') {
    const tbl = node as ReactElement;
    return transposeTable(tbl as ReactElement<Record<string, unknown>>);
  }

  const props = Object.hasOwn(node as object, 'props')
    ? (node as { props: Record<string, unknown> }).props
    : {};

  //  if there are contents, then it's a big 'ol string of html we need to parse
  if (props.content) {
    //  try to render it
    const el = parse(props.content as string, {});
    return recursivelyTransposeTables(el, curDepth + 1);
  }

  if (!props.children) {
    return node;
  }

  const children = Array.isArray(props.children)
    ? (props.children as Array<ReactElement>)
    : ([props.children] as Array<ReactElement>);

  const newChildren = children.map((child) => recursivelyTransposeTables(child, curDepth + 1));
  const newNode = cloneDeep(node) as { props: Record<string, unknown> };

  if (!(newNode as ReactElement).props) {
    return node;
  }

  //  react doesn't want arrays of one; rather, it wants either an object or
  //  an array of 2 or more children
  if (newChildren.length === 1 && newChildren[0]) {
    //  eslint-disable-next-line prefer-destructuring
    newNode.props.children = newChildren[0];
  } else {
    newNode.props.children = newChildren;
  }
  return newNode as ReactNode;
};

const transposeTable = (tbl: ReactElement<Record<string, unknown>>): ReactElement => {
  //  if there's no children, then return with original tbl
  if (!Array.isArray(tbl.props.children)) {
    return tbl;
  }

  //  find the thead, otherwise skip
  const theads = (tbl.props.children as Array<ReactElement>).filter(
    (child) => child.type === 'thead',
  );
  if (theads.length !== 1) {
    return tbl;
  }
  const thead = theads[0] as {
    props: Record<string, { type: string; props: Record<string, unknown> }>;
  };

  //  find the tbody, otherwise skip
  const tbodies = (tbl.props.children as Array<ReactElement>).filter(
    (child) => child.type === 'tbody',
  );
  if (tbodies.length !== 1) {
    return tbl;
  }
  const tbody = tbodies[0] as ReactElement<Record<string, unknown>>;

  //  if there's less than the max number of cols, return the original table
  let headerRows = [] as Array<ReactElement>;
  if (Array.isArray(thead.props.children)) {
    headerRows = thead.props.children as Array<ReactElement>;
  } else if (
    thead.props.children.type === 'tr' &&
    Array.isArray(thead.props.children.props.children)
  ) {
    headerRows = thead.props.children.props.children as Array<ReactElement>;
  } else {
    headerRows = [thead.props.children.props.children] as Array<ReactElement>;
  }

  if (headerRows.length < MIN_SOURCE_COLUMNS_IN_NOTE_TO_TRANSPOSE) {
    return tbl;
  }

  const dataRows = Array.isArray(tbody.props.children)
    ? (tbody.props.children as Array<ReactElement<Record<string, unknown>>>)
    : ([tbody.props.children] as Array<ReactElement<Record<string, unknown>>>);
  if (dataRows.length === 0) {
    return tbl;
  }

  const newRows = dataRows.reduce(
    (acc, dataRow, rowIdx) => {
      //  now for each cell in the data row
      const dataCells = Array.isArray(dataRow.props.children)
        ? (dataRow.props.children as Array<ReactElement>)
        : ([dataRow.props.children] as Array<ReactElement>);

      const newDivs = dataCells.reduce(
        (accRow, dataCell, cellIdx) => {
          const headerRow = headerRows[cellIdx] as ReactElement<
            Record<string, ReactElement<Record<string, unknown>>>
          >;

          return [
            ...accRow,
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            <div key={`cell-${rowIdx}-${cellIdx}`}>
              <p className={tw`font-medium`}>{headerRow.props.children}</p>
              <p>{(dataCell.props as Record<string, never>).children}</p>
            </div>,
          ];
        },
        [] as Array<ReactElement>,
      );

      //  add an extra div we need to separate data rows
      if (dataRows.length - 1 > rowIdx) {
        newDivs.push(
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={`transpose-separator-${rowIdx}`}
            className={tw`note-transposed-row-separator`}
          />,
        );
      }

      return [...acc, ...newDivs];
    },
    [] as Array<ReactElement>,
  );

  return <div className={tw`note-transposed`}>{newRows}</div>;
};
