import type { TelemetryService } from '@ctw/shared/context/telemetry/index';

type TelemetryContextMappings = {
  [key in TelemetryService]?: {
    removeKeys?: Array<string>;
    renameKeys?: { [key: string]: string };
  };
};

const telemetryContextMappings: TelemetryContextMappings = {
  amplitude: {
    removeKeys: ['telemetryEnv', 'patientContext', 'ancestralBoundaryNames'],
    renameKeys: {
      serviceEnv: 'env',
      patientUPID: 'upid',
      boundaryName: 'componentName',
    },
  },
} as const;

export const mapTelemetryContext = (
  telemetryService: TelemetryService,
  context: Record<string, unknown>,
): Record<string, unknown> => {
  if (!(telemetryService in telemetryContextMappings)) {
    return context;
  }

  const newContext = { ...context };

  if (telemetryContextMappings[telemetryService]?.removeKeys) {
    for (const key of telemetryContextMappings[telemetryService].removeKeys) {
      delete newContext[key];
    }
  }

  if (telemetryContextMappings[telemetryService]?.renameKeys) {
    for (const [oldKey, newKey] of Object.entries(
      telemetryContextMappings[telemetryService].renameKeys,
    )) {
      newContext[newKey] = newContext[oldKey];
      delete newContext[oldKey];
    }
  }

  return newContext;
};
