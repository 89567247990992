import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniq } from 'lodash-es';
import type { ReactNode } from 'react';
import { Fragment } from 'react';

export type PaginationProps = {
  currentPage: number;
  isLoading?: boolean;
  pageSize: number;
  setCurrentPage: (n: number) => void;
  total: number;
};

export const Pagination = ({
  currentPage,
  isLoading = false,
  pageSize,
  setCurrentPage,
  total,
}: PaginationProps) => {
  const start = Math.min(total, (currentPage - 1) * pageSize + 1);
  const finish = Math.min(currentPage * pageSize, total);
  const pageCount = Math.ceil(total / pageSize);
  const prevPage = Math.max(currentPage - 1, 1);
  const nextPage = Math.min(currentPage + 1, pageCount);

  // Always show the first page link and the last.
  let pagesToShow = [1, pageCount];
  // Always show currentPage and one before and after.
  pagesToShow.push(currentPage - 1, currentPage, currentPage + 1);
  // Dedupe, sort and filter away any pages that are out of range.
  pagesToShow = uniq(
    pagesToShow.sort((a, b) => a - b).filter((page) => page >= 1 && page <= pageCount),
  );

  return (
    <div className={tw`flex justify-between px-6 py-3`}>
      <div className={tw`text-background-tooltip text-sm`}>
        {isLoading ? (
          <LoadingSpinner message="Loading..." />
        ) : (
          <>
            <span className={tw`font-medium`}>{start}</span> to{' '}
            <span className={tw`font-medium`}>{finish}</span> of{' '}
            <span className={tw`font-medium`}>{total}</span> results
          </>
        )}
      </div>

      {pageCount > 1 && (
        <div>
          <nav
            className={tw`-space-x-px relative z-0 inline-flex rounded-md shadow-sm`}
            aria-label="Pagination"
          >
            <Page
              page={prevPage}
              setCurrentPage={setCurrentPage}
              className={tw`rounded-l-md hover:bg-background-hover`}
              disabled={currentPage === 1}
            >
              <span className={tw`sr-only`}>Previous</span>
              <FontAwesomeIcon icon={faChevronLeft} className={tw`h-5 w-5`} aria-hidden="true" />
            </Page>

            {pagesToShow.map((page, index) => {
              const prev = pagesToShow[index - 1] ?? 0;
              const pagesSkipped = page - prev - 1;

              return (
                <Fragment key={page}>
                  {pagesSkipped > 1 && (
                    <span
                      className={tw`relative inline-flex items-center border border-content-subtle border-b border-solid bg-white px-4 py-2 font-medium text-content-subtle text-sm`}
                    >
                      ...
                    </span>
                  )}
                  {pagesSkipped === 1 && (
                    <Page
                      page={page - 1}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    />
                  )}

                  <Page setCurrentPage={setCurrentPage} page={page} currentPage={currentPage} />
                </Fragment>
              );
            })}

            <Page
              page={nextPage}
              setCurrentPage={setCurrentPage}
              className={tw`rounded-r-md`}
              disabled={currentPage === pageCount}
            >
              <span className={tw`sr-only`}>Next</span>
              <FontAwesomeIcon icon={faChevronRight} className={tw`h-5 w-5`} aria-hidden="true" />
            </Page>
          </nav>
        </div>
      )}
    </div>
  );
};

type PageProps = {
  children?: ReactNode;
  className?: ClassName;
  disabled?: boolean;
  currentPage?: number;
  page: number;
  setCurrentPage: (n: number) => void;
};

const Page = ({
  setCurrentPage,
  page,
  currentPage,
  children,
  className,
  disabled = false,
}: PageProps) => {
  const active = page === currentPage;

  return (
    <Button
      type="button"
      variant="unstyled"
      disabled={disabled}
      onClick={() => {
        setCurrentPage(page);
      }}
      className={twx(
        className,
        'relative inline-flex items-center border border-content-subtle border-b border-solid bg-white px-2 px-4 py-2 font-medium text-content-icon text-content-subtle text-sm',
        {
          'z-10 border-primary-main bg-primary-background-hover text-primary-text': active,
          'cursor-pointer hover:bg-divider-main': !(disabled || active),
        },
      )}
    >
      {children || page}
    </Button>
  );
};
