import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { getUnleashProxyEnvironmentConfig } from '@ctw/shared/utils/unleash';
import { useQueryClient } from '@tanstack/react-query';
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type ZodTypeAny, z } from 'zod';
import { LoadingSpinner } from '../components/loading-spinner';
import type { Env } from './types';

type FeatureFlags = {
  client: UnleashClient;
  flags: Record<string, boolean>;
};

export const FeatureFlagContext = createContext<FeatureFlags | undefined>(undefined);

interface FeatureFlagProviderProps extends PropsWithChildren {
  env: Env;
}

export function FeatureFlagProvider({ env, children }: FeatureFlagProviderProps) {
  const { requestContext } = useCTW();
  const [unleashClientStarted, setUnleashClientStarted] = useState<boolean>();
  const [unleashClientReady, setUnleashClientReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
  const queryClient = useQueryClient();
  const unleashClient = useMemo(
    () =>
      new UnleashClient({
        ...getUnleashProxyEnvironmentConfig(env),
        refreshInterval: 15, // 15 seconds
        appName: 'component-library',
        context: {
          userId: requestContext.authTokenState.zusUserId,
          properties: {
            builderId: requestContext.builderId,
            builderName: requestContext.authTokenState.builderName,
            userType: requestContext.authTokenState.userType,
            email: requestContext.authTokenState.email,
            /* For some reason unleash proxy does not like localhost appearing
             * in the query params and will return 403's.
             * As a work around, we replace localhost with local.
             */
            hostname: window.location.hostname.replace('localhost', 'local'),
          },
        },
      }),
    [
      env,
      requestContext.builderId,
      requestContext.authTokenState.builderName,
      requestContext.authTokenState.email,
      requestContext.authTokenState.userType,
      requestContext.authTokenState.zusUserId,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!unleashClientStarted) {
      const startUnleashClient = async function run() {
        unleashClient.on('ready', () => {
          setUnleashClientReady(true);
          setFeatureFlags({
            client: unleashClient,
            flags: Object.fromEntries(
              unleashClient.getAllToggles().map((toggle) => [toggle.name, toggle.enabled]),
            ),
          });
        });
        setUnleashClientStarted(true);
        await unleashClient.start();
      };

      void startUnleashClient();
    }
  }, [
    requestContext.authToken,
    unleashClient,
    unleashClientStarted,
    unleashClientReady,
    featureFlags,
    requestContext.authTokenState.zusUserId,
    requestContext.authTokenState.builderId,
    requestContext.authTokenState.builderName,
    requestContext.authTokenState.userType,
    requestContext.authTokenState.email,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void queryClient.invalidateQueries();
  }, [featureFlags]);

  if (featureFlags === undefined) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  return (
    <FlagProvider unleashClient={unleashClient} startClient={false}>
      <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    </FlagProvider>
  );
}

export function useFeatureFlag(name: string): boolean {
  const featureFlags = useContext(FeatureFlagContext);

  if (featureFlags === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }

  return featureFlags.flags[name] ?? false;
}

export function useFeatureFlagIfAvailable(name: string): boolean {
  const featureFlags = useContext(FeatureFlagContext);

  return featureFlags?.flags[name] ?? false;
}

export function useHasFeatureFlag(): (name: string) => boolean {
  const featureFlags = useContext(FeatureFlagContext);

  if (featureFlags === undefined) {
    throw new Error('useHasFeatureFlag must be used within a FeatureFlagProvider');
  }

  return (name: string) => featureFlags.flags[name] ?? false;
}

export const ZAP_ALERT_SCHEMA = z.object({
  type: z.enum(['caution', 'info']),
  message: z.string(),
  link: z.string().optional(),
});

const featureFlagNamesWithScalarVariants = ['foop', 'doop'] as const;
type FeatureFlagNameWithScalarVariants = (typeof featureFlagNamesWithScalarVariants)[number];
const isFeatureFlagNameWithScalarVariants = (
  name: string,
): name is FeatureFlagNameWithScalarVariants =>
  featureFlagNamesWithScalarVariants.includes(name as FeatureFlagNameWithScalarVariants);

const featureFlagNamesWithJsonVariants = ['ctw-zap-alert', 'ctw-standalone-alert'] as const;
type FeatureFlagNameWithJsonVariants = (typeof featureFlagNamesWithJsonVariants)[number];
const isFeatureFlagNameWithJsonVariants = (name: string): name is FeatureFlagNameWithJsonVariants =>
  featureFlagNamesWithJsonVariants.includes(name as FeatureFlagNameWithJsonVariants);

type FeatureFlagNameWithVariants =
  | FeatureFlagNameWithJsonVariants
  | FeatureFlagNameWithScalarVariants;

const featureFlagVariantPayloadSchemas: Record<FeatureFlagNameWithJsonVariants, ZodTypeAny> = {
  'ctw-zap-alert': ZAP_ALERT_SCHEMA,
  'ctw-standalone-alert': ZAP_ALERT_SCHEMA,
} as const;

type FeatureFlagVariantJsonPayloads = {
  [key in FeatureFlagNameWithJsonVariants]: z.infer<(typeof featureFlagVariantPayloadSchemas)[key]>;
};

type FeatureFlagVariantScalarPayloads = {
  foop: string;
  doop: number;
};

type FeatureFlagVariantPayloads = FeatureFlagVariantJsonPayloads & FeatureFlagVariantScalarPayloads;

interface Variant<TFeatureFlagName extends FeatureFlagNameWithVariants> {
  name: string;
  payload: FeatureFlagVariantPayloads[TFeatureFlagName];
}

export const useFeatureVariant = <TFeatureFlagName extends FeatureFlagNameWithVariants>(
  name: TFeatureFlagName,
): Variant<TFeatureFlagName> | undefined => {
  const featureFlags = useContext(FeatureFlagContext);
  const telemetry = useTelemetry();

  if (featureFlags === undefined) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }

  const variant = featureFlags.client.getVariant(name);

  if (!(variant.enabled && variant.payload)) {
    return undefined;
  }

  if (isFeatureFlagNameWithScalarVariants(name)) {
    if (variant.payload.type === 'number' && !Number.isNaN(Number(variant.payload.value))) {
      return {
        name: variant.name,
        payload: Number(variant.payload.value) as FeatureFlagVariantPayloads[TFeatureFlagName],
      };
    }

    if (variant.payload.type === 'string' && typeof variant.payload.value === 'string') {
      return {
        name: variant.name,
        payload: Number(variant.payload.value) as FeatureFlagVariantPayloads[TFeatureFlagName],
      };
    }

    telemetry.logger.error(
      `Unexpected variant payload of type "${typeof variant.payload.value}" for variant "${variant.payload.type}" of feature "${name}"`,
    );
    return undefined;
  }

  if (isFeatureFlagNameWithJsonVariants(name)) {
    const variantSchema = Object.keys(featureFlagVariantPayloadSchemas).includes(name)
      ? featureFlagVariantPayloadSchemas[name]
      : undefined;
    if (!variantSchema) {
      telemetry.logger.warn(`Missing variant schema for feature "${name}"`);
      return undefined;
    }

    const result = variantSchema.safeParse(JSON.parse(variant.payload.value));
    if (!result.success) {
      telemetry.logger.error(
        `Failed to parse variant "${variant.name}" for feature "${name}", ${result.error.toString()}`,
      );
      return undefined;
    }

    return {
      name: variant.name,
      payload: result.data,
    };
  }

  telemetry.logger.error(
    `Feature "${name}" returned an unexpected variant type "${variant.payload.type}"`,
  );
  return undefined;
};
