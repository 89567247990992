export type PatientSubscription = {
  patientId: string;
  package?: PatientSubscriptionPackage;
};

export type PatientSubscriptionPackage = {
  id?: string;
  name?: string;
  description: string;
  meta: PackageMeta;
  hasIntelligentRefresh: boolean;
  hasRecurringInterval: boolean;
  requiresManualRequests: boolean;
};

export type PackageMeta = {
  freshmakerProviders?: Array<string>;
  initialProviders?: Array<string>;
  intelligentRefreshProviders?: Array<string>;
  recurringProvidersWithInterval?: Array<{
    intervalDays: number;
    provider: string;
  }>;
  subscriptionProviders?: Array<string>;
};

export type PatientSubscriptionStatus = 'active' | 'pending';

export type PatientSubscriptionData = {
  type: string;
  id: string;
  attributes: {
    createdAt: string;
    status: PatientSubscriptionStatus;
    practitioner: {
      npi: string;
      name: string;
      role: string;
    };
  };
  relationships: {
    package: {
      data: { id: string };
    };
  };
};

export type PatientSubscriptionError = {
  error: Array<{
    title: string;
    detail: string;
    status: string;
  }>;
};

export type GetPatientSubscriptionAPIResponse = {
  data?: Array<PatientSubscriptionData>;
  errors?: PatientSubscriptionError;
};

export type GetPackageAPIResponse = {
  data: {
    type: string;
    id: string;
    attributes: {
      description: string;
      name: string;
      meta: PackageMeta;
    };
  };
};

export const demoPatientSubscriptionPackage: PatientSubscriptionPackage = {
  id: 'demo',
  description: 'Demo Package',
  name: 'Demo Package',
  meta: {
    freshmakerProviders: ['commonwell', 'surescripts'],
    initialProviders: ['commonwell', 'surescripts'],
    recurringProvidersWithInterval: [
      {
        intervalDays: 28,
        provider: 'commonwell',
      },
    ],
    subscriptionProviders: ['bamboo', 'collective', 'quest', 'surescripts'],
  },
  hasIntelligentRefresh: false,
  hasRecurringInterval: true,
  requiresManualRequests: false,
};
