import type { Practitioner } from 'fhir/r4';
import type { CareTeamModel } from './careteam';
import { FHIRModel } from './fhir-model';
import { PractitionerModel } from './practitioner';

export class CareTeamPractitionerModel extends FHIRModel<Practitioner> {
  public kind = 'CareTeamPractitioner' as const;

  public constructor(careTeam: CareTeamModel, practitioner: Practitioner) {
    super(practitioner);
    this.careTeam = careTeam;
  }

  public careTeam: CareTeamModel;

  public get key() {
    return `${this.careTeam.id}_${this.id}`;
  }

  public get practitionerName() {
    return new PractitionerModel(this.resource).fullName;
  }

  public get effectiveStartDate() {
    return this.careTeam.periodStart;
  }

  public get managingOrganization() {
    return this.careTeam.managingOrganization;
  }

  public get role() {
    return this.careTeam.role;
  }

  public get specialty() {
    return this.careTeam.practitionerQualification;
  }

  public get status() {
    return this.careTeam.status;
  }

  public get title() {
    return this.practitionerName;
  }

  public ownedByBuilder(builderId: string): boolean {
    return this.careTeam.ownedByBuilder(builderId);
  }
}
