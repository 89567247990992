interface NoDataProps {
  width: number;
  height: number;
}

export const NoData = ({ width = 384, height = 375 }: NoDataProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 384 375"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>No Data</title>
    <g clip-path="url(#clip0_2471_136751)">
      <path
        d="M243.559 84.8208H140.527C138.179 84.8237 135.929 85.7576 134.268 87.4178C132.608 89.0781 131.674 91.329 131.671 93.6769V322.666L130.49 323.026L105.215 330.766C104.017 331.131 102.724 331.006 101.618 330.418C100.512 329.831 99.6846 328.828 99.3172 327.631L24.1348 82.0518C23.7689 80.8539 23.8936 79.5598 24.4815 78.4538C25.0694 77.3478 26.0723 76.5205 27.2699 76.1536L66.2191 64.2275L179.134 29.6651L218.083 17.7389C218.676 17.5565 219.299 17.4928 219.916 17.5513C220.533 17.6099 221.133 17.7897 221.681 18.0803C222.229 18.371 222.714 18.7668 223.109 19.2452C223.503 19.7235 223.8 20.275 223.981 20.8681L243.199 83.64L243.559 84.8208Z"
        fill="#F2F2F2"
      />
      <path
        d="M266.041 83.6403L242.88 7.98577C242.494 6.72523 241.865 5.55297 241.026 4.53598C240.187 3.51899 239.157 2.67721 237.993 2.05874C236.829 1.44026 235.554 1.05721 234.242 0.931486C232.93 0.80576 231.606 0.939823 230.346 1.326L175.585 18.0876L62.6765 52.6559L7.91638 69.4234C5.37252 70.2045 3.2425 71.963 1.99388 74.313C0.745254 76.663 0.480029 79.4124 1.25643 81.9577L80.418 340.514C81.0487 342.569 82.3214 344.367 84.0492 345.645C85.777 346.923 87.8689 347.614 90.0182 347.616C91.0129 347.617 92.002 347.468 92.9523 347.174L130.49 335.684L131.671 335.318V334.084L130.49 334.445L92.6041 346.046C90.3587 346.73 87.9334 346.496 85.8603 345.395C83.7873 344.294 82.2356 342.415 81.5458 340.171L2.39025 81.6093C2.04846 80.4976 1.92934 79.3293 2.0397 78.1714C2.15006 77.0135 2.48773 75.8887 3.03339 74.8615C3.57906 73.8344 4.322 72.9249 5.21965 72.1852C6.1173 71.4456 7.15204 70.8902 8.26461 70.551L63.0247 53.7835L175.934 19.2212L230.694 2.45369C231.538 2.19615 232.415 2.06484 233.298 2.06402C235.191 2.06827 237.033 2.67872 238.555 3.80596C240.076 4.93321 241.197 6.518 241.752 8.32822L264.808 83.6403L265.174 84.8211H266.402L266.041 83.6403Z"
        fill="#3F3D56"
      />
      <path
        d="M73.2474 76.3467C72.1095 76.3459 71.0017 75.9804 70.0867 75.3039C69.1717 74.6274 68.4976 73.6755 68.1632 72.5877L60.5586 47.7483C60.3543 47.0811 60.2833 46.3802 60.3499 45.6855C60.4165 44.9909 60.6193 44.3162 60.9467 43.6999C61.274 43.0837 61.7196 42.5379 62.2579 42.0939C62.7962 41.6499 63.4168 41.3162 64.084 41.112L167.959 9.3106C169.307 8.8994 170.762 9.03964 172.007 9.70054C173.251 10.3614 174.182 11.489 174.596 12.8357L182.201 37.6754C182.612 39.0229 182.471 40.4785 181.81 41.7226C181.149 42.9668 180.022 43.898 178.675 44.312L74.7998 76.1135C74.2968 76.2678 73.7736 76.3464 73.2474 76.3467Z"
        fill="#008052"
      />
      <path
        d="M113.083 27.4221C119.605 27.4221 124.892 22.1354 124.892 15.614C124.892 9.09253 119.605 3.80586 113.083 3.80586C106.562 3.80586 101.275 9.09253 101.275 15.614C101.275 22.1354 106.562 27.4221 113.083 27.4221Z"
        fill="#008052"
      />
      <path
        d="M113.084 23.0912C117.213 23.0912 120.561 19.7436 120.561 15.614C120.561 11.4844 117.213 8.13672 113.084 8.13672C108.954 8.13672 105.606 11.4844 105.606 15.614C105.606 19.7436 108.954 23.0912 113.084 23.0912Z"
        fill="white"
      />
      <path
        d="M356.616 344.599H157.058C155.728 344.598 154.452 344.068 153.512 343.128C152.571 342.187 152.042 340.911 152.04 339.581V100.467C152.042 99.136 152.571 97.8604 153.512 96.9196C154.452 95.9788 155.728 95.4496 157.058 95.4481H356.616C357.946 95.4496 359.222 95.9788 360.162 96.9197C361.103 97.8605 361.632 99.136 361.634 100.467V339.581C361.632 340.911 361.103 342.187 360.162 343.128C359.222 344.068 357.946 344.598 356.616 344.599Z"
        fill="#E6E6E6"
      />
      <path
        d="M264.807 83.64H140.527C137.866 83.6437 135.316 84.7024 133.434 86.5839C131.553 88.4654 130.494 91.0161 130.49 93.6769V334.444L131.671 334.084V93.6769C131.674 91.329 132.608 89.0781 134.268 87.4178C135.928 85.7576 138.179 84.8237 140.527 84.8208H265.174L264.807 83.64ZM373.147 83.64H140.527C137.866 83.6437 135.316 84.7024 133.434 86.5839C131.553 88.4654 130.494 91.0161 130.49 93.6769V364.083C130.494 366.743 131.553 369.294 133.434 371.176C135.316 373.057 137.866 374.116 140.527 374.119H373.147C375.808 374.116 378.358 373.057 380.24 371.176C382.121 369.294 383.18 366.743 383.184 364.083V93.6769C383.18 91.0161 382.121 88.4654 380.24 86.5839C378.358 84.7024 375.808 83.6437 373.147 83.64ZM382.003 364.083C382 366.43 381.066 368.681 379.406 370.342C377.746 372.002 375.495 372.936 373.147 372.939H140.527C138.179 372.936 135.928 372.002 134.268 370.342C132.608 368.681 131.674 366.43 131.671 364.083V93.6769C131.674 91.329 132.608 89.0781 134.268 87.4178C135.928 85.7576 138.179 84.8237 140.527 84.8208H373.147C375.495 84.8237 377.746 85.7576 379.406 87.4178C381.066 89.0781 382 91.329 382.003 93.6769V364.083Z"
        fill="#3F3D56"
      />
      <path
        d="M311.154 109.618H202.52C201.111 109.616 199.76 109.056 198.764 108.06C197.768 107.064 197.208 105.713 197.206 104.304V78.3263C197.208 76.9176 197.768 75.5669 198.764 74.5708C199.76 73.5746 201.111 73.0143 202.52 73.0127H311.154C312.563 73.0143 313.914 73.5746 314.91 74.5708C315.906 75.5669 316.466 76.9176 316.468 78.3263V104.304C316.466 105.713 315.906 107.064 314.91 108.06C313.914 109.056 312.563 109.616 311.154 109.618Z"
        fill="#008052"
      />
      <path
        d="M256.837 74.7839C263.358 74.7839 268.645 69.4972 268.645 62.9758C268.645 56.4544 263.358 51.1677 256.837 51.1677C250.315 51.1677 245.029 56.4544 245.029 62.9758C245.029 69.4972 250.315 74.7839 256.837 74.7839Z"
        fill="#008052"
      />
      <path
        d="M256.837 70.1681C260.809 70.1681 264.03 66.948 264.03 62.9758C264.03 59.0036 260.809 55.7836 256.837 55.7836C252.865 55.7836 249.645 59.0036 249.645 62.9758C249.645 66.948 252.865 70.1681 256.837 70.1681Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2471_136751">
        <rect
          width="382.368"
          height="373.239"
          fill="white"
          transform="translate(0.815918 0.880615)"
        />
      </clipPath>
    </defs>
  </svg>
);
