import type { Resource } from 'fhir/r4';
import { cloneDeep } from 'lodash-es';
import type { FHIRModel } from './models/fhir-model';

export interface Summary extends FHIRModel<Resource> {
  syncedWithRecord: boolean;
}

export type ResourceBuilderInfo = {
  id?: string;
  meta?: { tag?: Array<{ system?: string; code?: string }> };
};

export function getLensSummariesWithSyncedWithRecord<
  T extends Summary,
  U extends ResourceBuilderInfo,
>(
  summaries: Array<T>,
  builderItems: Array<U>,
  backupSyncedWithRecordCheck?: (summary: T, builderResources: Array<U>) => boolean, // Fallback to match resources that haven't been aggregated yet.
): Array<T> {
  const summaryItems = cloneDeep(summaries);
  const idsInBuilder = builderItems.map((builder) => builder.id);

  summaryItems.forEach((summary) => {
    const hasMatch: boolean =
      // Check if aggregated from ids match any builder ids.
      summary.aggregatedFromIds.some((aggregatedFromId) =>
        idsInBuilder.find((idInBuilder) => idInBuilder === aggregatedFromId),
      ) ||
      (backupSyncedWithRecordCheck ? backupSyncedWithRecordCheck(summary, builderItems) : false);
    summary.syncedWithRecord = hasMatch;
  });

  return summaryItems;
}
