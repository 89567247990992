import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import {
  type UseResourceDrawerOptions,
  useResourceDrawer,
} from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import type { Coding } from 'fhir/r4';
import { capitalize } from 'lodash-es';
import { useConditionHistory } from '../content/conditions/history';
import { History } from '../content/resource/history';

export interface UseConditionDrawerProps
  extends Omit<UseResourceDrawerOptions<'Condition'>, 'resourceType' | 'action'> {}

const conditionHistory = ({ model }: { model: ConditionModel }) => (
  <History getHistory={useConditionHistory} model={model} />
);

export const useConditionDrawer = ({
  resourceActions,
  enableDismissAndReadActions,
}: UseConditionDrawerProps = {}) =>
  useResourceDrawer({
    resourceType: 'Condition',
    resourceActions,
    enableDismissAndReadActions,
    action: ({ model }) => ({
      label: 'Condition',
      title: `${capitalize(model.display)}`,
      subtitle: model.ccsChapter,
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList
                items={[
                  { label: 'Latest Diagnosis', value: model.recordedDate },
                  { label: 'Recorder', value: model.recorder },
                  {
                    label: 'Provider Organization',
                    value: model.patientOrganizationName,
                  },
                  { label: 'Onset Date', value: model.onset },
                  { label: 'Type', value: capitalize(model.type) },
                  { label: 'Status', value: capitalize(model.displayStatus) },
                  { label: 'Abatement Date', value: model.abatement },
                  {
                    label: 'ICD-10',
                    value:
                      model.icd10CMEnrichments.length > 0 ? (
                        <CodingList codings={model.icd10CMEnrichments} />
                      ) : null,
                  },
                  {
                    label: 'HCC',
                    value:
                      model.hccEnrichments.length > 0 ? (
                        <CodingList codings={model.hccEnrichments} />
                      ) : null,
                  },
                  {
                    label: 'Note',
                    value: model.notes.length > 0 && (
                      <div className={tw`flex flex-col space-y-4`}>
                        {model.notes.map((note) => (
                          <div key={note}>
                            <div>{note}</div>
                          </div>
                        ))}
                      </div>
                    ),
                  },
                ]}
              />
            </ContentCard>
            {conditionHistory({ model })}
          </div>
        ),
      },
    }),
  });

type CodingListProps = {
  codings: Array<Coding>;
};

const CodingList = ({ codings }: CodingListProps) => (
  <div className={tw`flex flex-col space-y-4`}>
    {codings.map((coding, idx) => (
      <div key={`${coding.system}-${coding.code}-${idx}`} className={tw`space-x-1`}>
        <span className={tw`font-semibold`}>{coding.code}</span>
        <span>{coding.display}</span>
      </div>
    ))}
  </div>
);
