import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { formatPhoneNumber } from '@ctw/shared/utils/phone-number';
import type { CareTeam, Reference } from 'fhir/r4';
import { find } from 'lodash-es';
import { codeableConceptLabel } from '../codeable-concept';
import { findReference } from '../resource-helper';
import { FHIRModel } from './fhir-model';
import { PractitionerModel } from './practitioner';

export class CareTeamModel extends FHIRModel<CareTeam> {
  public kind = 'CareTeam' as const;

  public get categoryDisplay() {
    return codeableConceptLabel(this.resource.category ? this.resource.category[0] : undefined);
  }

  public get status() {
    return this.resource.status;
  }

  public get periodStart() {
    const start = this.resource.period?.start;
    return start ? formatFHIRDate(start) : undefined;
  }

  public get periodEnd() {
    return this.resource.period?.end;
  }

  public get participants() {
    return this.resource.participant;
  }

  public get phone(): string | undefined {
    const phone = this.resource.telecom?.find((t) => t.system === 'phone');
    return phone?.value ? formatPhoneNumber(phone.value) : '';
  }

  public get managingOrganization() {
    return find(this.resource.participant, 'onBehalfOf')?.onBehalfOf?.display;
  }

  public get role() {
    return codeableConceptLabel(this.resource.participant?.[0].role?.[0]);
  }

  public get practitionerQualification() {
    const reference = this.resource.participant?.[0]?.member;

    const practitioner = findReference(
      'Practitioner',
      this.resource.contained,
      this.includedResources,
      reference,
    );

    if (practitioner) {
      return codeableConceptLabel(
        new PractitionerModel(practitioner).resource.qualification?.[0].code,
      );
    }
    return undefined;
  }

  public get title() {
    return `${this.managingOrganization ?? 'Unknown'} - ${this.role}`;
  }

  public getPractitionerByID(reference: Reference | undefined) {
    return findReference(
      'Practitioner',
      this.resource.contained,
      this.includedResources,
      reference,
    );
  }
}
