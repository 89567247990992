import { usePatientCareTeamMembers } from '@ctw/shared/api/fhir/care-team';
import type { CareTeamPractitionerModel } from '@ctw/shared/api/fhir/models/careteam-practitioner';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useCareteamPractitionerDrawer } from '@ctw/shared/hooks/use-careteam-practitioner-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMemo } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { getDateRangeView } from '../resource/view-date-range';
import { patientCareTeamColumns } from './helpers/columns';
import { careTeamSortOptions, defaultCareTeamSort } from './helpers/sorts';

export const PatientCareTeam = withErrorBoundary({
  boundaryName: 'PatientCareTeam',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { openDrawer } = useCareteamPractitionerDrawer();
    const patientCareTeamQuery = usePatientCareTeamMembers();
    const { viewOptions, allTime } = useMemo(
      () => getDateRangeView<CareTeamPractitionerModel>('effectiveStartDate'),
      [],
    );

    const { data, setSort, setViewOption, defaultSort, defaultView } = useFilteredSortedData({
      cacheKey: 'patient-careteam',
      defaultSort: defaultCareTeamSort,
      viewOptions,
      records: patientCareTeamQuery.data,
      defaultView: allTime.display,
    });
    const isEmptyQuery = patientCareTeamQuery.data && patientCareTeamQuery.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        sortOptions={{
          defaultSort,
          options: careTeamSortOptions,
          onChange: setSort,
        }}
        viewOptions={{
          onChange: setViewOption,
          options: viewOptions,
          defaultView,
        }}
      />,
      [defaultSort, careTeamSortOptions, viewOptions, defaultView],
    );

    return (
      <div>
        <ResourceTable
          isLoading={patientCareTeamQuery.isLoading}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="careteam"
            />
          }
          columns={patientCareTeamColumns(requestContext.builderId)}
          onRowClick={(model) => openDrawer({ model })}
        />
      </div>
    );
  },
});
