// Returns a predicate for use with lodash that will find
// a coding with the given code within an array of CodeableConcepts.
// E.g. find(concepts, codeableConceptPredicate("C")) will find the first

import type { CodeableConcept, Coding } from 'fhir/r4';
import { compact, filter, find, some } from 'lodash-es';
import { SYSTEM_ENRICHMENT } from './system-urls';

/**
  Finds the best display text for a CodeableConcept.
 
  Priority order is:
    1. The display of the coding with the given favoredSystemDisplay
       (skipping enrichment extensions!).
    2. The text of the CodeableConcept.
    3. The display of the first coding with a display.
    4. The code of the first coding with a code.
 */
export const codeableConceptLabel = (
  concept?: CodeableConcept,
  favoredSystemDisplay?: string,
): string => {
  if (favoredSystemDisplay && concept?.coding) {
    const coding = concept.coding.find(
      (c) => c.system === favoredSystemDisplay && c.display && !c.extension,
    );
    if (coding?.display) {
      return coding.display;
    }
  }

  const firstWithDisplay = concept?.coding?.find((c) => c.display);
  const firstWithCode = concept?.coding?.find((c) => c.code);
  return concept?.text ?? firstWithDisplay?.display ?? firstWithCode?.code ?? '';
};

// Returns text if there is one, otherwise returns the first display text or an empty string.
export const codeableConceptTextOrDisplay = (concept?: CodeableConcept): string => {
  if (concept?.text) {
    return concept.text;
  }

  const firstDisplay = compact(concept?.coding?.map((c) => c.display))[0] as string | undefined;
  return firstDisplay ?? '';
};

export function findCoding(system: string, concept?: CodeableConcept): Coding | undefined {
  return find(concept?.coding, { system });
}

export function findAllCodings(system: string, concept?: CodeableConcept): Array<Coding> {
  return filter(concept?.coding, {
    system,
  });
}

// Return true if the concept has a coding with the given system and a matching code.
export function hasMatchingCode(
  system: string,
  codes: Array<string>,
  concept?: CodeableConcept,
): boolean {
  return some(
    concept?.coding,
    (coding) => coding.system === system && codes.includes(coding.code ?? ''),
  );
}

export function findCodingWithEnrichment(
  system: string,
  concept?: CodeableConcept,
): Coding | undefined {
  return find(concept?.coding, {
    system,
    extension: [{ url: SYSTEM_ENRICHMENT }],
  });
}

export function findAllCodingsWithEnrichment(
  system: string,
  concept?: CodeableConcept,
): Array<Coding> {
  return filter(concept?.coding, {
    system,
    extension: [{ url: SYSTEM_ENRICHMENT }],
  });
}

export function findAllCodingsWithEnrichmentValueString(
  valueString: string,
  concept?: CodeableConcept,
): Array<Coding> {
  return filter(concept?.coding, {
    extension: [{ url: SYSTEM_ENRICHMENT, valueString }],
  });
}

export type CodePreference = { system: string; checkForEnrichment?: boolean };

export const findCodingByOrderOfPreference = (
  preferences: Array<CodePreference>,
  concept: CodeableConcept | undefined,
): Coding | undefined => {
  for (const code of preferences) {
    if (code.checkForEnrichment) {
      const coding = findCodingWithEnrichment(code.system, concept);
      if (coding) {
        return coding;
      }
    } else {
      const coding = findCoding(code.system, concept);
      if (coding) {
        return coding;
      }
    }
  }

  return undefined;
};
export const codeableConceptPredicate = (code: string) => [{ coding: [{ code }] }];
export const getCodeableConcept = (
  code: string,
  text?: string,
  system?: string,
): CodeableConcept => ({
  text,
  coding: [
    {
      code,
      display: text,
      system,
    },
  ],
});
