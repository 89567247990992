import type { FilterOptionSelect } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { type IconDefinition, faCheck, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox } from '@headlessui/react';
import { isFunction } from 'lodash-es';
import type { ReactNode, Ref } from 'react';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import { MenuItem } from '../_internal/menu-item';

type ListBoxItem = {
  className?: ClassName;
  divider?: false;
  display: string | ((status: ListBoxOptionStatus) => ReactNode);
  icon?: IconDefinition;
  key: string;
};

export type MinListBoxItem = ListBoxItem | { divider: true };

export type ListBoxProps = {
  btnTestId?: string;
  btnClassName?: ClassName;
  children?: ReactNode;
  className?: ClassName;
  defaultIndex?: number;
  selectedIndex?: number;
  items: Array<MinListBoxItem>;
  onChange: (index: number, item: ListBoxItem) => void;
  optionsClassName?: ClassName;
  useBasicStyles?: boolean;
};

export type ListBoxOptionStatus = {
  active?: boolean;
  filter?: FilterOptionSelect;
  listView?: boolean;
  selected?: boolean;
};

export const ListBox = forwardRef(
  (
    {
      useBasicStyles = false,
      btnTestId,
      btnClassName,
      children,
      className,
      optionsClassName,
      items,
      defaultIndex = 0,
      selectedIndex,
      onChange,
    }: ListBoxProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultIndex);
    const selectedItem = items[selectedTabIndex] ?? {
      display: 'Choose a selection',
    };

    useEffect(() => {
      if (selectedIndex === undefined) {
        return;
      }
      setSelectedTabIndex(selectedIndex);
    }, [selectedIndex]);

    return (
      <div className={twx(className, 'relative block h-full w-min whitespace-nowrap')} ref={ref}>
        <Listbox
          value={selectedTabIndex}
          onChange={(index: number) => {
            const item = items[index];
            if (item.divider) {
              return;
            }
            onChange(index, item);
            setSelectedTabIndex(index);
          }}
        >
          <Listbox.Button
            data-testid={btnTestId}
            className={twx(
              btnClassName,
              !useBasicStyles && 'focus-visible:outline-primary-text',
              'relative cursor-pointer justify-between space-x-2 border-0 border-transparent',
            )}
          >
            {children || renderDisplay(selectedItem, { listView: false })}
            {!useBasicStyles && <FontAwesomeIcon icon={faChevronDown} className={tw`h-4`} />}
          </Listbox.Button>
          <Listbox.Options
            className={twx(
              optionsClassName,
              'fixed z-10 m-0 mt-1 flex max-h-[300px] min-w-fit list-outside list-none overflow-hidden rounded-md bg-white p-0 text-sm shadow-lg ring-background-subtle',
            )}
          >
            <div className={tw`overflow-y-auto overflow-x-visible`}>
              {items.map((item, index) => {
                if (item.divider) {
                  return <div key="divider" className={tw`border-t`} />;
                }

                return (
                  <Listbox.Option key={item.key} value={index} as={Fragment}>
                    {({ active, selected }) => (
                      <li
                        className={twx(
                          'flex cursor-pointer justify-between px-3 py-2',
                          'hover:bg-background-hover',
                          item.className,
                          {
                            'text-medium': active || selected,
                            'text-background-inverse': !(active || selected),
                            'bg-background-hover': active,
                          },
                        )}
                      >
                        <span
                          className={twx('inline-flex whitespace-nowrap align-middle', {
                            'font-semibold': selected && !useBasicStyles,
                          })}
                        >
                          {renderDisplay(item, {
                            active,
                            selected,
                            listView: true,
                          })}
                        </span>
                        {!useBasicStyles && selected && !item.key.startsWith('_') && (
                          <span className={tw`inline-flex pb-0.5`}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={tw`inline-block h-4 stroke-0 align-middle text-primary-text`}
                            />
                          </span>
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                );
              })}
            </div>
          </Listbox.Options>
        </Listbox>
      </div>
    );
  },
);

// A helper function to render items for both the ListBox button and options.
function renderDisplay<T extends MinListBoxItem>(item: T, status: ListBoxOptionStatus) {
  if (item.divider) {
    return null;
  }
  return (
    <MenuItem icon={item.icon}>
      {isFunction(item.display) ? item.display(status) : item.display}
    </MenuItem>
  );
}
