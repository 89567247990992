import type { Binary } from 'fhir/r4';
import { FHIRModel } from './fhir-model';

export class BinaryModel extends FHIRModel<Binary> {
  public kind = 'Binary' as const;

  public get title() {
    return `Binary: ${this.resource.id}`;
  }
}
