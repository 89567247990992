import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Button } from '@ctw/shared/components/button';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList, type DefinitionListItem } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import type { TelemetryUserInteraction } from '@ctw/shared/context/telemetry/user-interactions';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronRight, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Resource } from 'fhir/r4';
import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export type HistoryEntryProps = {
  binaryId?: string;
  model: FHIRModel<Resource>;
  date?: string;
  details: Array<DefinitionListItem>;
  hideEmpty?: boolean;
  id: string;
  subtitle?: string;
  title?: string;
  versionId?: string;
};

type Props = HistoryEntryProps & {
  trackingMetadata?: TrackingMetadata;
};

export const HistoryEntry = ({ binaryId, date, details, subtitle, title, model }: Props) => {
  const [isDetailShown, setIsDetailShown] = useState(false);

  return (
    <div className={tw`flex flex-col gap-1`}>
      <ResourceDetailSummary
        date={date}
        title={title}
        subtitle={subtitle}
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        hasDocument={!!binaryId}
        analyticsInteraction="toggle_history_entry"
        analyticsActionExpand="expand_history_entry"
        analyticsActionCollapse="collapse_history_entry"
      />
      {isDetailShown && (
        <ContentCard
          variant="focal"
          title="Details"
          content={{
            header: <ResponsiveSourceDocumentLink binaryId={binaryId} model={model as never} />,
          }}
        >
          <DefinitionList items={details} />
        </ContentCard>
      )}
    </div>
  );
};

interface ResourceDetailSummaryProps {
  date?: string;
  title?: string;
  subtitle?: string;
  isDetailShown: boolean;
  hasDocument?: boolean;
  analyticsInteraction: TelemetryUserInteraction;
  analyticsActionExpand: string;
  analyticsActionCollapse: string;
  setIsDetailShown: Dispatch<SetStateAction<boolean>>;
}

const ResourceDetailSummary = ({
  date,
  title,
  subtitle,
  isDetailShown,
  analyticsInteraction,
  analyticsActionExpand,
  analyticsActionCollapse,
  hasDocument = false,
  setIsDetailShown,
}: ResourceDetailSummaryProps) => {
  const { trackInteraction } = useTelemetry();

  return (
    <Button
      className={tw`w-full rounded-md border border-divider-main bg-white`}
      type="button"
      aria-label="details"
      onClick={() => {
        setIsDetailShown(!isDetailShown);
        trackInteraction(analyticsInteraction, {
          action: isDetailShown ? analyticsActionCollapse : analyticsActionExpand,
        });
      }}
      variant="link"
    >
      <div className={tw`flex items-center justify-between rounded-lg bg-white p-3 text-left`}>
        <div className={tw`flex space-x-3`}>
          <div className={tw`min-w-[6rem]`}>
            {date ?? <span className={tw`text-content-icon`}>Unknown</span>}
          </div>
          {(title || subtitle) && (
            <div>
              <div className={tw`font-semibold text-background-inverse`}>{title}</div>
              <div className={tw`text-content-subtle`}>{subtitle}</div>
            </div>
          )}
          {/* Show unknown in the title spot if we don't have one AND if we aren't already
              showing "Unknown" in the date spot. */}
          {!(title || subtitle) && date && <div className={tw`text-content-icon`}>Unknown</div>}
        </div>
        <div className={tw`flex items-center space-x-3`}>
          {hasDocument && (
            <FontAwesomeIcon icon={faFileLines} className={tw`h-5 text-content-subtle`} />
          )}
          <div className={tw`justify-right flex`}>
            <FontAwesomeIcon
              icon={faChevronRight}
              className={twx('h-3 w-3 text-content-icon', {
                'rotate-90': isDetailShown,
              })}
            />
          </div>
        </div>
      </div>
    </Button>
  );
};
