import type { Narrative } from 'fhir/r4';
import { isEmpty } from 'lodash-es';
import xpath from 'xpath';
import { parseElement, wrappedInDiv } from './parse-elements';

export const getNarrativeForSection = (xmlData: Document): Narrative | undefined => {
  const text = xpath.select1("*[name()='text']", xmlData) as Document | undefined;
  if (!text) {
    return undefined;
  }

  const textElementsToParse = xpath.select('child::node()', text) as Array<Document>;

  if (isEmpty(textElementsToParse)) {
    return undefined;
  }

  const parsedTextElements = textElementsToParse.map(parseElement).filter(Boolean).join('');

  return {
    status: 'generated',
    div: wrappedInDiv(parsedTextElements),
  };
};
