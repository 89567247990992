import { usePatientDiagnosticReports } from '@ctw/shared/api/fhir/diagnostic-report';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { useDiagnosticRowActions } from '@ctw/shared/content/diagnostic-reports/helpers/row-actions';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { useDiagnosticReportDrawer } from '@ctw/shared/hooks/use-diagnostic-report-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMemo } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { patientDiagnosticReportsColumns } from './helpers/columns';
import { diagnosticReportFilter } from './helpers/filters';
import { defaultDiagnosticReportSort, diagnosticReportSortOptions } from './helpers/sorts';

export const PatientDiagnosticReports = withErrorBoundary({
  boundaryName: 'PatientDiagnosticReports',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { openDrawer } = useDiagnosticReportDrawer();
    const validFilters = useMemo(() => diagnosticReportFilter(), []);
    const query = usePatientDiagnosticReports();
    const rowActions = useDiagnosticRowActions();
    const { writebackStatuses } = useWritebacks();

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-diagnostic-reports',
      defaultSort: defaultDiagnosticReportSort,
      records: query.data,
    });
    const isEmptyQuery = !query.data || query.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: diagnosticReportSortOptions,
          onChange: setSort,
        }}
      />,
      [validFilters, filters, diagnosticReportSortOptions, defaultSort],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={query.isLoading}
          data={data}
          stackedBreakpoint="xs"
          columns={patientDiagnosticReportsColumns(requestContext.builderId, writebackStatuses)}
          onRowClick={(model) =>
            openDrawer({
              model,
            })
          }
          rowActions={rowActions}
          enableDismissAndReadActions={true}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="diagnostics"
            />
          }
        />
      </div>
    );
  },
});
