import type { Basic, DiagnosticReport } from 'fhir/r4';
import { gql } from 'graphql-request';
import type { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentBasic, fragmentCoding, fragmentPractitioner } from './fragments';

export interface DiagnosticReportConnection {
  pageInfo: GraphqlPageInfo;
  edges: Array<GraphqlConnectionNode<DiagnosticReportWithBasics>>;
}

export type DiagnosticReportWithBasics = DiagnosticReport & {
  BasicList: Array<Basic>;
};

export interface DiagnosticReportGraphqlResponse {
  DiagnosticReportConnection: DiagnosticReportConnection;
}

// TODO - consider removing patient/practitioner as well
export const getDiagnosticReportQuery = gql`
  ${fragmentCoding}
  ${fragmentPractitioner}
  ${fragmentBasic}
  query DiagnosticReports(
    $upid: ID!
    $cursor: String!
    $sort: DiagnosticReportSortParams!
    $filter: DiagnosticReportFilterParams!
    $first: Int!
  ) {
    DiagnosticReportConnection(
      upid: $upid
      after: $cursor
      filter: $filter
      sort: $sort
      first: $first
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          resourceType
          BasicList(_reference: "subject") {
            ...Basic
          }
          meta {
            tag {
              system
              code
            }
            extension {
              url
              valueInstant
            }
          }
          extension {
            url
            valueString
          }
          status
          category {
            text
            coding {
              ...Coding
            }
          }
          code {
            text
            coding {
              ...Coding
            }
          }
          effectiveDateTime
          effectivePeriod {
            start
            end
          }
          issued
          conclusion
          performer {
            reference
            resource {
              ...Practitioner
            }
          }
          result {
            reference
            display
          }
        }
      }
    }
  }
`;
