import type { HumanName } from 'fhir/r4';
import { isEmpty } from 'lodash-es';

type FormatNameParts = 'prefix' | 'first' | 'middle' | 'last' | 'suffix';

interface FormatNameOptions {
  separator?: string;
}

export const bestName = (names: Array<HumanName>): HumanName | undefined => {
  const officialNameWithoutEndDate = names.find(
    (name) => name.use === 'official' && !name.period?.end,
  );

  if (officialNameWithoutEndDate) {
    return officialNameWithoutEndDate;
  }

  const officialName = names.find((name) => name.use === 'official');

  if (officialName) {
    return officialName;
  }

  const usualName = names.find((name) => name.use === 'usual');

  if (usualName) {
    return usualName;
  }

  return names[0];
};

export const formatName = (
  name: HumanName,
  partsToInclude: Array<FormatNameParts> = ['prefix', 'first', 'middle', 'last', 'suffix'],
  options: FormatNameOptions = {},
): string => {
  const parts: Record<FormatNameParts, string | undefined> = {
    prefix: name.prefix?.map((p) => p.trim()).join(' '),
    first: name.given?.[0].trim(),
    middle: name.given
      ?.slice(1, name.given.length)
      .map((m) => m.trim())
      .join(' '),
    last: name.family,
    suffix: name.suffix?.map((s) => s.trim()).join(' '),
  };

  return partsToInclude
    .map((part) => parts[part as FormatNameParts])
    .filter((part) => !isEmpty(part))
    .join(options.separator || ' ');
};

export const hasNamePart = (name: HumanName, part: FormatNameParts): boolean =>
  !isEmpty(formatName(name, [part]));
