import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { DrawerFormWithFields } from '@ctw/shared/components/form/drawer-form-with-fields';
import { useCreateMedicationStatement } from '@ctw/shared/content/forms/actions/medications';
import {
  getMedicationFormData,
  medicationStatementSchema,
} from '@ctw/shared/content/forms/schemas/medication-schema';
import { useDrawer } from '@ctw/shared/context/drawer-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { format } from 'date-fns';
import type { MedicationStatement } from 'fhir/r4';

export function useAddMedicationForm() {
  const { openDrawer } = useDrawer({
    boundaryName: 'AddMedicationDrawer',
    telemetryContext: { action: 'add_medication' },
  });

  return (medication: MedicationStatementModel) => {
    openDrawer({
      title: 'Add Medication',
      content: {
        body: <UseAddMedicationForm medication={medication.resource} />,
      },
    });
  };
}

interface AddNewMedDrawerProps {
  medication?: MedicationStatement;
}

const UseAddMedicationForm = ({ medication }: AddNewMedDrawerProps) => {
  const { patient } = usePatientContext();
  const createMedicationStatement = useCreateMedicationStatement();

  const patientSubjectRef = {
    reference: `Patient/${patient.id}`,
    display: patient.display,
  };

  // Create a MedicationStatementModel that can be used to pre-populate the form appropriately.
  let medStatementModelForFormPopulation: MedicationStatementModel;
  // If we're starting from an existing medication, make sure the subject is set correctly
  if (medication) {
    medStatementModelForFormPopulation = new MedicationStatementModel({
      ...medication,
      subject: patientSubjectRef,
      dateAsserted: format(new Date(), 'yyyy-MM-dd'),
    });
  } else {
    medStatementModelForFormPopulation = new MedicationStatementModel({
      resourceType: 'MedicationStatement',
      status: 'active',
      subject: patientSubjectRef,
      dateAsserted: format(new Date(), 'yyyy-MM-dd'),
    });
  }

  const createMedData = getMedicationFormData(medStatementModelForFormPopulation);

  return (
    <DrawerFormWithFields
      boundaryName="AddNewMedDrawer"
      action={createMedicationStatement as never}
      errorHeader="Unable to add medication to chart"
      data={createMedData}
      schema={medicationStatementSchema}
    />
  );
};
