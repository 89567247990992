import { codeableConceptLabel } from '@ctw/shared/api/fhir/codeable-concept';
import { formatPeriod, formatQuantity, formatRange } from '@ctw/shared/api/fhir/formatters';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import type { Basic, Observation, Provenance } from 'fhir/r4';
import { getBinaryIDFromProvenance } from '../provenance';
import { SYSTEM_CPT, SYSTEM_LOINC } from '../system-urls';
import type { ResourceMap } from '../types';
import { type DiagnosticReportModel, findEnrichedLoincCoding } from './diagnostic-report';
import { FHIRModel } from './fhir-model';

export class ObservationModel extends FHIRModel<Observation> {
  public kind = 'Observation' as const;

  public binaryId?: string;

  public diagnosticReport?: DiagnosticReportModel;

  public provenance?: Array<Provenance>;

  public trends?: Array<ObservationModel>;

  public isLoadingTrends = false;

  public constructor(
    resource: Observation,
    provenance?: Array<Provenance>,
    includedResources?: ResourceMap,
    basics?: Array<Basic>,
  ) {
    super(resource, includedResources, basics);
    this.provenance = provenance;
    this.binaryId = getBinaryIDFromProvenance(this.provenance);
  }

  public get loincCode() {
    return findEnrichedLoincCoding(this.resource.code.coding);
  }

  public get dateDisplay() {
    return this.date ? formatFHIRDate(this.date) : undefined;
  }

  public get date() {
    return this.resource.effectivePeriod?.start || this.resource.effectiveDateTime;
  }

  public get valueIsInNotes() {
    return this.resource.valueString?.toLowerCase() === 'see note';
  }

  public get value(): string {
    if (this.resource.valueBoolean !== undefined) {
      return this.resource.valueBoolean ? 'true' : 'false';
    }

    if (this.resource.valueCodeableConcept) {
      return codeableConceptLabel(this.resource.valueCodeableConcept);
    }

    if (this.resource.valueDateTime) {
      return this.resource.valueDateTime;
    }

    if (this.resource.valueInteger !== undefined) {
      return String(this.resource.valueInteger);
    }

    if (this.resource.valuePeriod) {
      return formatPeriod(this.resource.valuePeriod) ?? '';
    }

    if (this.resource.valueQuantity) {
      return formatQuantity(this.resource.valueQuantity);
    }

    if (this.resource.valueRange) {
      return formatRange(this.resource.valueRange);
    }

    if (this.resource.valueString) {
      if ((this.valueIsInNotes || (this.resource.valueString ?? '') === '') && this.notes) {
        return this.notes;
      }
      return this.resource.valueString;
    }

    if (this.resource.valueTime) {
      return this.resource.valueTime;
    }

    return '';
  }

  public get unit() {
    return this.resource.valueQuantity?.unit;
  }

  public get interpretation() {
    const nonAcceptedValues = [
      'n',
      'noinformation',
      'normal',
      'na',
      '(normal)',
      'unknown',
      'not applicable',
      'temporarily unavailable',
      'nml',
      'norm',
      '*',
    ];

    const interpretation = codeableConceptLabel(this.resource.interpretation?.[0]).toLowerCase();
    if (nonAcceptedValues.includes(interpretation)) {
      return undefined;
    }

    return interpretation;
  }

  public get notes() {
    return this.resource.note?.[0].text;
  }

  public get referenceRange() {
    return this.resource.referenceRange?.[0].text;
  }

  public get acceptedInterpretations(): string {
    switch (codeableConceptLabel(this.resource.interpretation?.[0]).toLowerCase()) {
      case 'high':
      case 'low':
      case 'hi':
      case 'abn':
      case '(high)':
      case '(low)':
      case 'abnormal':
      case 'a':
      case 'l':
      case 'h':
      case 'above high normal':
      case 'crit':
      case 'below low normal':
      case 'high alert':
      case '(abnormal)':
      case 'abnormal alert':
      case 'abnormal low':
      case 'abnormal high':
      case 'low alert':
      case 'pos':
      case 'no reference range':
      case 'positive':
      case '(positive)':
      case '(critical high)':
      case '(critical low)':
      case 'critical high':
      case 'critical low':
      case 'c':
        return 'text-caution-text bg-caution-badge inline-flex rounded-full font-medium text-sm p-0.5 px-2';
      default:
        return 'text-background-inverse bg-background-disabled inline-flex rounded-xl leading-5 font-medium text-sm p-0.5 px-2';
    }
  }

  /**
   * Return true if the provided diagnostic and trend combination are incorrectly coded,
   * and therefore should not be displayed in the trends.
   *
   * https://zeushealth.atlassian.net/browse/CDEV-310
   */
  public get isIncorrectlyCodedGlucose() {
    if (this.diagnosticReport) {
      const diagnosticFlagged = this.diagnosticReport.resource.code.coding?.some((coding) => {
        const a1cDisplay = coding.display?.toLowerCase().indexOf('a1c');
        return (
          (coding.system === SYSTEM_LOINC && coding.code === '4548-4') ||
          (coding.system === SYSTEM_CPT && coding.code === '83036') ||
          (a1cDisplay !== undefined && a1cDisplay > -1)
        );
      });
      const trendFlagged = this.resource.code.coding?.some(
        (coding) => coding.system === SYSTEM_LOINC && coding.code === '2345-7',
      );
      return diagnosticFlagged && trendFlagged;
    }
    return false;
  }

  public get title() {
    return this.loincCode?.display || codeableConceptLabel(this.resource.code);
  }
}
