import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList, type DefinitionListItem } from '@ctw/shared/components/definition-list';
import { ActivityHistory } from '@ctw/shared/content/care-gaps/helpers/activity-history';
import { careGapStatusBadgeProps } from '@ctw/shared/content/care-gaps/helpers/status-badge';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { compact } from 'lodash-es';
import type { ReactNode } from 'react';

export function useCareGapDrawer() {
  return useResourceDrawer({
    resourceType: 'Measure',
    action: ({ model, reloadResource }) => ({
      label: 'Care Gap',
      title: model.title,
      badges: [careGapStatusBadgeProps(model)],
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard variant="focal" title="Details">
              <DefinitionList items={createCareGapsData(model)} />
            </ContentCard>
            <ActivityHistory careGap={model} reloadCareGap={reloadResource} />
          </div>
        ),
      },
    }),
  });
}

const detail = (label: string | null, value: ReactNode): DefinitionListItem => ({
  label: label ?? '',
  value,
});

const createCareGapsData = (gap: CareGapModel) =>
  compact([
    detail('Last Updated', gap.updatedDate),
    gap.denominatorReasons.length > 0
      ? detail('Inclusion logic', gap.denominatorReasons.join(' '))
      : null,
    gap.measureDescription ? detail('Description', gap.measureDescription) : null,
    gap.windowStart && gap.windowEnd
      ? detail('Treatment window', `${gap.windowStart} - ${gap.windowEnd}`)
      : null,
    gap.excluded ? detail('Exclusion', gap.exclusionReasons.join(', ')) : null,
  ]);
