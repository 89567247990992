import type {
  CodeableConcept,
  Coding,
  MedicationAdministration,
  MedicationDispense,
  MedicationRequest,
  MedicationStatement,
  Organization,
  Reference,
} from 'fhir/r4';
import { get } from 'lodash-es';
import { findReference } from './resource-helper';
import { SYSTEM_ENRICHMENT, SYSTEM_RXNORM } from './system-urls';
import type { ResourceMap } from './types';

export type Medication =
  | MedicationStatement
  | MedicationAdministration
  | MedicationRequest
  | MedicationDispense;

export type ClinicalStatus =
  | 'active'
  | 'entered-in-error'
  | 'not-taken'
  | 'completed'
  | 'on-hold'
  | 'intended'
  | 'stopped';

// Hardcoded aliased display statuses for patient-reported statuses.
const medStatusDisplays: Record<ClinicalStatus, string> = {
  active: 'Currently taking',
  'entered-in-error': 'Never taken',
  'not-taken': 'Prescribed, not taken',
  completed: 'No longer taking',
  'on-hold': 'On hold',
  intended: 'Intend to take',
  stopped: 'No longer taking',
};

// Medications can have either a medicationCodeableConcept or a
// medicationReference. This helper grabs the codeable concept
// from wherever it exists.
export function getMedicationCodeableConcept(
  medication: Medication,
  includedResources?: ResourceMap,
): CodeableConcept | undefined {
  if (medication.medicationCodeableConcept) {
    return medication.medicationCodeableConcept;
  }

  const medicationResource = findReference(
    'Medication',
    medication.contained,
    includedResources,
    medication.medicationReference,
  );

  return medicationResource?.code;
}

// Returns the best RxNorm code for uniquely identifying a medication.
export function getIdentifyingRxNormCode(
  medication: Medication,
  includedResources?: ResourceMap,
): string | undefined {
  return getIdentifyingRxNormCoding(medication, includedResources)?.code;
}

// Get the gold standard RxNorm code for uniquely identifying a medication.
export function getIdentifyingRxNormCoding(
  medication: Medication,
  includedResources?: ResourceMap,
): Coding | undefined {
  const codeableConcept = getMedicationCodeableConcept(medication, includedResources);

  const standardRxNormCode = codeableConcept?.coding?.find(
    (code) =>
      code.system === SYSTEM_RXNORM &&
      code.extension?.some(
        (e) => e.url === SYSTEM_ENRICHMENT && e.valueString === 'Standardization',
      ),
  );
  if (standardRxNormCode) {
    return standardRxNormCode;
  }

  const unenrichedRxNormCode = codeableConcept?.coding?.find(
    (code) => code.system === SYSTEM_RXNORM && !code.extension,
  );
  if (unenrichedRxNormCode) {
    return unenrichedRxNormCode;
  }

  const activeIngredientCodeableConcept = getActiveIngredientCodeableConcept(
    medication,
    includedResources,
  );
  if (activeIngredientCodeableConcept?.coding?.length === 1) {
    return activeIngredientCodeableConcept.coding[0];
  }

  return undefined;
}

function getActiveIngredientCodeableConcept(
  medication: Medication,
  includedResources?: ResourceMap,
): CodeableConcept | undefined {
  const originalCodeableConcept = getMedicationCodeableConcept(medication, includedResources);
  if (originalCodeableConcept) {
    const codeableConcept = { ...originalCodeableConcept, text: originalCodeableConcept.text };
    codeableConcept.coding = codeableConcept.coding?.filter(
      (code) =>
        code.system === SYSTEM_RXNORM &&
        code.extension?.some(
          (e) => e.url === SYSTEM_ENRICHMENT && e.valueString === 'ActiveIngredient',
        ),
    );
    codeableConcept.text =
      codeableConcept.text ?? codeableConcept.coding?.map((code) => code.display).join(', ');
    return codeableConcept;
  }
  return undefined;
}

// Returns the best (most user friendly) display string for the medication associated with a medicationX resource
// Prefers the "identifying" code display value, if Zus was able to determine one for the medication.
// Otherwise fall back to any display values.
export function getMedicationDisplayName(
  resource: Medication,
  includedResources?: ResourceMap,
): string {
  const code = getIdentifyingRxNormCoding(resource, includedResources);
  if (code?.display) {
    return code.display;
  }

  const medCodeableConcept = getMedicationCodeableConcept(resource, includedResources);
  if (medCodeableConcept?.text) {
    return medCodeableConcept.text;
  }

  const activeIngredientCodings = getActiveIngredientCodeableConcept(resource, includedResources);
  if (activeIngredientCodings?.text) {
    return activeIngredientCodings.text;
  }

  const anyDisplay = medCodeableConcept?.coding?.find(
    (coding) => !!coding.display && coding.display.length > 0,
  )?.display;
  if (anyDisplay) {
    return anyDisplay;
  }

  const anyCode = medCodeableConcept?.coding?.find((coding) => !!coding.code && !!coding.system);
  if (anyCode) {
    return `${anyCode.code} (${anyCode.system})`;
  }

  return 'Unknown';
}

// Returns the organization name of any performer for the medication.
export function getPerformingOrganization(
  resource: Medication,
  includedResources?: ResourceMap,
): Organization | undefined {
  let reference: Reference | undefined;

  switch (resource.resourceType) {
    case 'MedicationAdministration':
    case 'MedicationDispense':
      reference = resource.performer?.[0]?.actor;
      break;

    case 'MedicationRequest':
      reference = resource.performer || resource.dispenseRequest?.performer;
      break;
    default:
      return undefined;
  }

  if (reference?.reference && reference.type === 'Organization') {
    return findReference('Organization', resource.contained, includedResources, reference);
  }

  return undefined;
}

export function patientStatus(status?: string): string {
  return get(medStatusDisplays, status as string, '');
}
