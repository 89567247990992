import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { useBuilderConfig } from '@ctw/shared/context/builder-config-context';
import { twx } from '@ctw/shared/utils/tailwind';

type PatientInfoLineProps = {
  patient?: PatientModel;
  className?: string;
};

export function PatientInfoLine({ patient, className }: PatientInfoLineProps) {
  const builderConfig = useBuilderConfig();

  if (!patient) {
    return null;
  }

  const mrn = patient.findIdentifier(builderConfig.systemMRN);

  return (
    <div className={twx(className)}>
      {patient.dob}
      {patient.age !== undefined && ` (${patient.age})`}
      {mrn && <span>, {mrn}</span>}
    </div>
  );
}
