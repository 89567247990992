import { useCallback } from 'react';
import './feedback.scss';
import type { FeedbackRequest } from '@ctw/shared/api/ai/types';
import { Checkbox } from '@ctw/shared/components/form/checkbox';
import { PillCheckboxGroup } from '@ctw/shared/components/form/pill-checkbox-group';
import { Heading } from '@ctw/shared/components/heading';
import { ThumbsUpOrDown } from '@ctw/shared/components/thumbs-up-or-down';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { isEqual } from 'lodash-es';

export interface FeedbackProps {
  className?: ClassName;
  feedback: FeedbackRequest;
  onFeedbackUpdate: (feedback: FeedbackRequest) => void;
}

export const FeedbackComponent = ({ feedback, onFeedbackUpdate, className }: FeedbackProps) => {
  const handleFeedbackChange =
    (field: keyof FeedbackRequest) => (event: { target: { value: string } }) => {
      const { value } = event.target;
      const updatedFeedback = {
        ...feedback,
        [field]: field.endsWith('Rating') ? Number(value) : value,
      };
      onFeedbackUpdate(updatedFeedback);
    };

  const changeFeedback = useCallback(
    (newFeedback: FeedbackRequest) => {
      if (!isEqual(newFeedback, feedback)) {
        onFeedbackUpdate(newFeedback);
      }
    },
    [feedback, onFeedbackUpdate],
  );

  const reasonOptions = feedback.isThumbsUp
    ? [
        'Improved My Decisions',
        'Highlighted New Information',
        'Was Easy to Read',
        'Saved Me Time',
        'Helped Me Prepare',
        'Matched my Workflow',
      ]
    : [
        'Incorrect or Outdated Information',
        'Patient Safety Concern',
        'Missing Information',
        'Incorrect Prioritization',
        'Improperly Formatted',
      ];

  return (
    <div className={twx('space-y-1', className)}>
      <div className={tw`space-y-3 pb-6`}>
        <Heading level="h4" className={tw`font-medium text-2xl text-content-subtle`}>
          What Did You Think of this Summary?
        </Heading>
        <ThumbsUpOrDown
          initialIsThumbsUp={feedback.isThumbsUp}
          onChange={(isThumbsUp) => changeFeedback({ ...feedback, isThumbsUp, reasons: [] })}
        />
      </div>
      <PillCheckboxGroup
        className={tw`text-sm`}
        options={reasonOptions}
        defaultSelected={feedback.reasons}
        onChange={(selected) => changeFeedback({ ...feedback, reasons: selected })}
        legend="Why did you choose this rating?"
      />
      <div className={tw`pt-4 pb-2`}>
        <label>
          <div className={tw`font-medium text-sm`}>
            Is there any other feedback you would like to share about Patient Summaries?
          </div>
          <textarea
            className={tw`listbox-textarea mt-2 w-full resize-none whitespace-pre-wrap`}
            value={feedback.usefulnessFeedback}
            name="accuracy_textarea"
            onChange={handleFeedbackChange('usefulnessFeedback')}
          />
        </label>
      </div>
      <div>
        <Checkbox
          className={tw`font-medium text-sm`}
          checked={feedback.contactPermission ?? true}
          onChange={(contactPermission) => {
            changeFeedback({ ...feedback, contactPermission });
          }}
          name="contactPermission"
          label="It’s okay to reach out to me about my responses"
        />
      </div>
    </div>
  );
};
