import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useEffect } from 'react';
import { SmartAppProvider } from './context/smart-app-context';

const ZAPV2 = () => {
  const telemetry = useTelemetry();

  useEffect(() => {
    telemetry.logger.info('Mounting smart-on-fhir zap');
    return () => telemetry.logger.info('Unmounting smart-on-fhir zap');
  }, [telemetry]);

  return <div>Test</div>;
};

const ZAPWrapper = () => (
  <SmartAppProvider>
    <ZAPV2 />
  </SmartAppProvider>
);

export default ZAPWrapper;
