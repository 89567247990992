import { type FeedbackRequest, newBlankFeedbackRequest } from '@ctw/shared/api/ai/types';
import { useFeedback } from '@ctw/shared/api/ai/use-feedback';
import {
  NotFoundSummaryHPI,
  usePrecomputedNewPatientSummaryForPatient,
} from '@ctw/shared/api/ai/use-precompute-summary';
import { Button } from '@ctw/shared/components/button';
import { Card } from '@ctw/shared/components/card';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { NoDataError } from '@ctw/shared/components/errors/no-data-error';
import { Heading } from '@ctw/shared/components/heading';
import { TwoColumnContent } from '@ctw/shared/components/layout/two-column-content';
import { ThumbsUpOrDown } from '@ctw/shared/components/thumbs-up-or-down';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { usePatientSummaryDrawer } from '@ctw/shared/content/chart-review/patient-summary-drawer';
import { PrecomputedSummary } from '@ctw/shared/content/chart-review/precomputed-summary';
import {
  type OverviewResources,
  PatientOverview,
} from '@ctw/shared/content/overview/patient-overview';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import { formatDateToLocalString } from '@ctw/shared/utils/dates';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faSidebar, faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useRef, useState } from 'react';
export interface PatientSummaryProps {
  className?: ClassName;
}

export const PatientSummary = withErrorBoundary({
  boundaryName: 'PatientSummary',
  includeTelemetryBoundary: true,
  Component: ({ className }: PatientSummaryProps) => {
    const { patient } = usePatientContext();
    const { trackInteractionWithCondition, trackInteraction } = useTelemetry();

    const { document, generateDocument } = usePrecomputedNewPatientSummaryForPatient('new_patient');

    const [feedback, setFeedback] = useState<FeedbackRequest>(
      newBlankFeedbackRequest({ upid: patient.UPID }),
    );
    const { triggerFeedback } = useFeedback(feedback);

    const containerRef = useRef<HTMLDivElement>(null);
    const { breakpoints } = useContainerQuery({ containerRef });

    const { isDrawerOpen, openDrawer } = usePatientSummaryDrawer();

    const { navigate } = useZuiContext();
    const redirectTo = useCallback(
      (resource: OverviewResources) => {
        navigate(resource);
      },
      [navigate],
    );

    trackInteractionWithCondition('view_patient_summary', 'once_per_page_view');

    const [isEvidenceSetToVisible, setIsEvidenceSetToVisible] = useState(true);

    const handleGenerateDocument = () => {
      try {
        return generateDocument();
      } catch (error) {
        return error;
      }
    };

    const handleOpenDrawerFromThumbs = (isThumbsUp: boolean) => {
      const newFeedback = { ...feedback, isThumbsUp };
      if (feedback.isThumbsUp !== isThumbsUp) {
        newFeedback.reasons = []; // Different set of reasons between thumbs up and down
      }
      openDrawer({
        feedback: newFeedback,
        setFeedback: setFeedback,
        triggerFeedback,
      });
    };

    return (
      <div ref={containerRef} className={tw`relative flex h-full flex-col px-2 pt-2`}>
        <Heading level="h1" className={tw`font-normal font-size-3xl`}>
          Your 5-Minute Briefing on {patient.displayFull}
        </Heading>
        {document?.hpi === NotFoundSummaryHPI ? (
          <NoDataError
            className={tw`my-4 rounded-lg bg-background-hover`}
            message="Sorry, we weren't able to generate a summary for this patient."
          />
        ) : (
          <TwoColumnContent
            leftColumn={{
              hidden: !(breakpoints.isAtLeast.md && isEvidenceSetToVisible),
              header: (
                <div className={tw`flex flex-1 items-center gap-2`}>
                  {breakpoints.isAtLeast.md && (
                    <Button
                      variant="unstyled"
                      size="sm"
                      type="button"
                      onClick={() => {
                        setIsEvidenceSetToVisible(false);
                        trackInteraction('close_evidence_drawer');
                      }}
                      className={tw`flex h-4 w-4 items-center justify-center`}
                    >
                      <FontAwesomeIcon
                        icon={faSidebar}
                        className={tw`text-content-icon hover:text-content-subtle`}
                      />
                    </Button>
                  )}
                  <Heading
                    level="h2"
                    className={twx(
                      'line-clamp-2 flex items-center gap-2 text-content-subtle text-xs uppercase',
                    )}
                  >
                    Clinical Evidence
                  </Heading>
                </div>
              ),
              content: (
                <PatientOverview
                  onSeeAllResources={redirectTo}
                  className={tw`min-w-[320px]`}
                  isInPatientSummary={true}
                />
              ),
            }}
            rightColumn={{
              header: (
                <div className={tw`flex min-w-0 flex-1 justify-between gap-2`}>
                  <div className={tw`flex items-center gap-2`}>
                    {!isEvidenceSetToVisible && breakpoints.isAtLeast.md && (
                      <button
                        type="button"
                        onClick={() => {
                          setIsEvidenceSetToVisible(true);
                          trackInteraction('open_evidence_drawer');
                        }}
                        className={tw`flex h-4 w-4 items-center justify-center`}
                      >
                        <FontAwesomeIcon
                          icon={faSidebar}
                          className={tw`text-content-icon hover:text-content-subtle`}
                        />
                      </button>
                    )}
                    <Heading
                      level="h2"
                      className={twx(
                        'line-clamp-2 flex w-fit items-center gap-2 text-content-subtle text-xs uppercase',
                      )}
                    >
                      Summary of all available information
                    </Heading>
                    <Tooltip content={`Generated ${formatDateToLocalString(document?.createdAt)}`}>
                      <FontAwesomeIcon
                        className={tw`block h-4 w-4 text-content-icon`}
                        icon={faInfoCircle}
                      />
                    </Tooltip>
                  </div>
                  <div className={twx('flex items-center gap-1')}>
                    <Button
                      variant="unstyled"
                      size="sm"
                      onClick={() => {
                        handleOpenDrawerFromThumbs(true);
                      }}
                      type="button"
                      aria-label="Thumbs up"
                      disabled={isDrawerOpen}
                    >
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className={twx('text-content-icon transition-all duration-150', {
                          'hover:text-content-subtle': !isDrawerOpen,
                        })}
                      />
                    </Button>
                    <Button
                      variant="unstyled"
                      size="sm"
                      type="button"
                      aria-label="Thumbs down"
                      onClick={() => {
                        handleOpenDrawerFromThumbs(false);
                      }}
                      disabled={isDrawerOpen}
                    >
                      <FontAwesomeIcon
                        icon={faThumbsDown}
                        className={twx(
                          'scale-x-[-1] text-content-icon transition-all duration-150',
                          {
                            'hover:text-content-subtle': !isDrawerOpen,
                          },
                        )}
                      />
                    </Button>
                  </div>
                </div>
              ), // Right margin for thumbs up/down buttons
              content: (
                <div className={twx('space-y-4')}>
                  <PrecomputedSummary
                    onGenerateSummary={handleGenerateDocument}
                    document={document}
                    className={twx('rounded-lg bg-background-hover p-4', className)}
                  />
                  <Card
                    className={twx('p-2 transition-all duration-300', {
                      'invisible opacity-0': isDrawerOpen,
                      'opacity-100': !isDrawerOpen,
                    })}
                  >
                    <div className={tw`space-y-4`}>
                      <Heading level="h4" className={tw`font-medium text-2xl text-content-subtle`}>
                        What Did You Think of this Summary?
                      </Heading>
                      <ThumbsUpOrDown
                        onChange={handleOpenDrawerFromThumbs}
                        selectedStateDisabled={true}
                      />
                    </div>
                  </Card>
                </div>
              ),
            }}
          />
        )}
      </div>
    );
  },
});
