import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import { usePatientObservationsById } from '@ctw/shared/api/fhir/observations';
import { Button } from '@ctw/shared/components/button';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { Heading } from '@ctw/shared/components/heading';
import { notify } from '@ctw/shared/components/toast';
import { ObservationList } from '@ctw/shared/content/diagnostic-reports/helpers/observation-list';
import { type ModalState, useModal } from '@ctw/shared/context/modal-context';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { tw } from '@ctw/shared/utils/tailwind';
import { useEffect, useState } from 'react';
import { useLabWriteback } from './lab-writeback';

interface LabWritebackModalProps {
  report: DiagnosticReportModel;
  onEvent: ModalState['onModalEvent'];
  close: () => void;
}

const LabWritebackModal = ({ report, onEvent, close }: LabWritebackModalProps) => {
  const { data: observations, isLoading } = usePatientObservationsById(report.observationIds);
  const [error, setError] = useState<string | null>(null);
  const labWriteback = useLabWriteback();
  const { onWritebackStart, onWritebackFailure } = useWritebacks();

  const observationsWithLoinc = observations?.filter((obs) => obs.loincCode?.code) || [];
  const hasAnyLoincCodes = observationsWithLoinc.length > 0;
  const observationsWithoutLoinc = observations?.filter((obs) => !obs.loincCode?.code) || [];

  useEffect(() => onEvent('open', () => setError(null)), [onEvent]);

  const handleSend = async () => {
    if (!(observations && hasAnyLoincCodes)) {
      return;
    }

    setError(null);
    onWritebackStart(report.id);
    close();
    try {
      await labWriteback(report, observationsWithLoinc);
      notify({
        type: 'success',
        title: 'Successfully added to chart',
        body: `"${report.displayName}" has been added to the patient's chart`,
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      notify({
        type: 'error',
        title: `Error adding "${report.displayName}" to chart`,
        body: errorMessage,
      });
      onWritebackFailure(report.id, errorMessage);
    }
  };

  return (
    <div className={tw`space-y-3`}>
      <Heading level="h1">Add {report.displayName} to chart</Heading>

      {isLoading && <div>Loading observations...</div>}

      {error && <ContentError title={error} className={tw`mb-4`} />}

      {observations && !hasAnyLoincCodes && (
        <ContentError
          title="Cannot add to chart - no LOINC codes found for any observations"
          className={tw`mb-4`}
        />
      )}

      {observations && observationsWithoutLoinc.length > 0 && hasAnyLoincCodes && (
        <ContentError
          title={`The following observations will not be included because they lack LOINC codes: ${observationsWithoutLoinc.map((o) => o.title).join(', ')}`}
          className={tw`mb-4`}
        />
      )}

      {observations && (
        <>
          <ObservationList observations={observations} isLoadingTrends={false} />
          <div className={tw`flex justify-end pt-4`}>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                void handleSend();
              }}
              disabled={!hasAnyLoincCodes}
            >
              Add to chart
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export function useLabWritebackModal() {
  const { openModal, onModalEvent, closeModal } = useModal({
    boundaryName: 'LabWritebackModal',
  });

  return (report: DiagnosticReportModel) => {
    openModal({
      content: {
        body: <LabWritebackModal onEvent={onModalEvent} close={closeModal} report={report} />,
      },
    });
  };
}
