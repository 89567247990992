import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { QUERY_KEY_BUILDER_CONFIG } from '@ctw/shared/utils/query-keys';
import { tw } from '@ctw/shared/utils/tailwind';
import { merge } from 'lodash-es';
import { type PropsWithChildren, createContext, useContext } from 'react';

export interface BuilderConfig {
  builderId: string;
  showRequestRecords: boolean;
  overviewCards: Record<string, { enabled: boolean } | undefined>;
  systemMRN?: string;
  hospitalizationExtensionFilters: { systemURL: string; displayName: string } | undefined;
}

interface BuilderConfigState extends BuilderConfig {}

const BuilderConfigContext = createContext<BuilderConfigState | null>(null);

interface BuilderConfigProviderProps extends PropsWithChildren {}

const DEFAULT_BUILDER_CONFIG: Partial<BuilderConfig> = {
  overviewCards: {
    conditions: { enabled: true },
    referrals: { enabled: true },
    medications: { enabled: true },
    diagnostics: { enabled: true },
    encounters: { enabled: true },
    episodesOfCare: { enabled: false },
    vitals: { enabled: true },
    packages: { enabled: true },
    careGaps: { enabled: true },
  },
  showRequestRecords: true,
} as const;

export const BuilderConfigProvider = ({ children }: BuilderConfigProviderProps) => {
  const builderConfigQuery = useCtwQuery<BuilderConfig>({
    queryId: QUERY_KEY_BUILDER_CONFIG,
    queryFn: async ({ requestContext, ctwFetch }) => {
      const response = await ctwFetch(
        `${getCTWBaseUrl(requestContext.env)}/api/builders/${requestContext.builderId}/config`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${requestContext.authToken}`,
          },
          mode: 'cors',
        },
      );

      if (!response.ok && requestContext.serviceEnv !== 'dev') {
        throw new Error('Failed to fetch builder config');
      }

      return merge({}, DEFAULT_BUILDER_CONFIG, response.data) as unknown as BuilderConfig;
    },
  });

  if (builderConfigQuery.isError) {
    return (
      <ContentError
        title="Unable to load"
        message="An unknown error occured loading this page."
        className={tw`flex h-full min-h-full w-full min-w-full items-center justify-center`}
      >
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`text-base`} />.
      </ContentError>
    );
  }

  if (builderConfigQuery.isLoading || !builderConfigQuery.data) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  return (
    <BuilderConfigContext.Provider value={builderConfigQuery.data}>
      {children}
    </BuilderConfigContext.Provider>
  );
};

export const useBuilderConfig = () => {
  const context = useContext(BuilderConfigContext);

  if (!context) {
    throw new Error('useBuilderConfig must be used within a BuilderConfigProvider');
  }

  return context;
};
