import { OperationOutcomeModel } from '@ctw/shared/api/fhir/models/operation-outcome';
import { isOperationOutcome } from './operation-outcome';

export type FhirError = {
  response: {
    status: number;
    data: unknown;
  };
  config: {
    method: string;
    url: string;
    headers: Record<string, string>;
  };
};

export type ZusAPIError = {
  response: {
    data: {
      message: string;
      name: string;
      statusCode: number;
    };
  };
};

export function isFhirError(error: unknown): error is FhirError {
  if (typeof error === 'object' && error !== null) {
    return 'response' in error && 'config' in error;
  }
  return false;
}

export function isZusAPIError(error: unknown): error is ZusAPIError {
  if (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    typeof error.response === 'object' &&
    error.response !== null &&
    'data' in error.response &&
    typeof error.response.data === 'object' &&
    error.response.data !== null
  ) {
    const { data } = error.response;
    return (
      'message' in data &&
      typeof data.message === 'string' &&
      'name' in data &&
      typeof data.name === 'string' &&
      'statusCode' in data &&
      typeof data.statusCode === 'number'
    );
  }
  return false;
}

export const fhirErrorResponse = (title: string, e: FhirError) => {
  const { data, status } = e.response;
  let errorStr = '';
  if (isOperationOutcome(data)) {
    errorStr = new OperationOutcomeModel(data).display;
  }

  return { title, status, statusText: errorStr };
};
