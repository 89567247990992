import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';

export const useCareteamPractitionerDrawer = () =>
  useResourceDrawer({
    resourceType: 'Practitioner',
    action: ({ model }) => ({
      label: 'Care Team Practitioner',
      title: model.practitionerName,
      content: {
        body: (
          <ContentCard variant="focal" title="Details">
            <DefinitionList
              items={[
                { label: 'Organization', value: model.managingOrganization },
                { label: 'Care Team', value: model.careTeam.categoryDisplay },
                { label: 'Phone', value: model.careTeam.phone },
                { label: 'Role', value: model.role },
                { label: 'Specialty', value: model.specialty },
                { label: 'Member Since', value: model.effectiveStartDate },
              ]}
            />
          </ContentCard>
        ),
      },
    }),
  });
