import { usePatientConditionsAll } from '@ctw/shared/api/fhir/conditions';
import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { ResourceOverviewCardGrouped } from '@ctw/shared/content/overview/resource-overview-card-grouped';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { useConditionDrawer } from '@ctw/shared/hooks/use-condition-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw } from '@ctw/shared/utils/tailwind';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import { formatDate } from 'date-fns';
import { isEmpty, orderBy } from 'lodash-es';
import { type OverviewFilter, applyFilters } from '../overview/filters';
import type { SpecificResourceOverviewCardProps } from '../overview/resource-overview-card';
import {
  RenderInfoBadge,
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/resource-title-column';
import { lastUpdatedMostRecentConditionSort } from './sorts';
import { statusView } from './views';

export const filters = (): Array<OverviewFilter<ConditionModel>> => [
  {
    key: 'conditions',
    label: 'chronic conditions or acute conditions with hcc',
    description: 'chronic conditions or acute conditions with hcc',
    predicate: (r: ConditionModel) =>
      r.chronic?.code === 'C' || (r.chronic?.code === 'A' && !isEmpty(r.hccEnrichment)),
  },
];

export interface PatientConditionOverviewProps extends SpecificResourceOverviewCardProps {
  onAfterDetailsDrawerClosed?: () => void;
  conditionIdForDetailsDrawer?: string;
  onOpenDetailsDrawer?: (condition: ConditionModel) => void;
}

export const PatientConditionOverview = withOverviewCardErrorBoundary({
  cardTitle: 'Conditions',
  boundaryName: 'PatientConditionOverview',
  Component: ({
    onSeeAllResources,
    onAfterDetailsDrawerClosed,
    onOpenDetailsDrawer,
    isInPatientSummary,
  }: PatientConditionOverviewProps) => {
    const { current } = statusView;
    const { openDrawer } = useConditionDrawer({
      enableDismissAndReadActions: false,
      onDrawerEvent: (eventType) => {
        if (eventType === 'afterClose') {
          onAfterDetailsDrawerClosed?.();
        }
      },
    });

    const { data, isLoading } = usePatientConditionsAll();

    const { data: filteredData } = useFilteredSortedData({
      defaultSort: lastUpdatedMostRecentConditionSort,
      viewOptions: [current],
      records: data,
      defaultView: current.display,
    });

    const filteredSortedData = orderBy(
      applyFilters(filteredData, filters()),
      (d) => d.display,
      'asc',
    );

    return (
      <ResourceOverviewCardGrouped
        groupBy={(record) => String(record.ccsChapter)}
        onRowClick={(model) => {
          onOpenDetailsDrawer?.(model);
          openDrawer({ model });
        }}
        data={filteredSortedData}
        emptyStateMessage={
          data.length === 0
            ? "We didn't find any condition records for this patient."
            : 'There are no chronic conditions or acute HCCs for this patient.'
        }
        footerCTA={
          data.length === 0
            ? undefined
            : {
                label: 'All Conditions',
                onClick: onSeeAllResources,
              }
        }
        title="Conditions"
        helpText="Chronic Conditions and acute HCCs"
        loading={isLoading}
        headerIcon={faClipboardList}
        telemetryTargetName="conditions_overview"
        testId="conditions-overview"
        isInPatientSummary={isInPatientSummary}
        renderResource={(record: ConditionModel) => (
          <div className={tw`flex justify-between text-left`}>
            <div className={tw`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] text-sm`}>
              {RenderSyncedWithRecordIcon(record.syncedWithRecord)}
            </div>
            <div className={tw`w-full flex-grow text-sm`}>
              <ResourceTitleColumn
                className={tw`w-full`}
                title={
                  <>
                    {record.display}{' '}
                    {record.recordedDate && (
                      <span
                        className={tw`whitespace-nowrap font-normal text-content-subtle text-xs`}
                      >
                        [Last Dx {formatDate(new Date(record.recordedDate), 'yyyy')}]
                      </span>
                    )}
                  </>
                }
                renderIcon={RenderInfoBadge(
                  !isEmpty(record.hccEnrichment) ? 'HCC' : undefined,
                  '',
                  !isEmpty(record.hccEnrichment) ? 'Risk-adjusting condition' : undefined,
                )}
              />
            </div>
          </div>
        )}
      />
    );
  },
});
