import { CareGapModel, type CareGapStatus } from '@ctw/shared/api/fhir/models/care-gap';
import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import {
  CareGapActivity,
  CareGapUserActivity,
} from '@ctw/shared/content/care-gaps/helpers/activity-cards';
import { AddActivityForm } from '@ctw/shared/content/care-gaps/helpers/add-activity';
import { getActivityStatusDescription } from '@ctw/shared/content/care-gaps/helpers/columns';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useDiagnosticReportDrawer } from '@ctw/shared/hooks/use-diagnostic-report-drawer';
import type { useFetchDeepLinkedResource } from '@ctw/shared/hooks/use-fetch-deep-linked-resource';
import { QUERY_KEY_PATIENT_CARE_GAPS } from '@ctw/shared/utils/query-keys';
import { tw } from '@ctw/shared/utils/tailwind';
import { useQueryClient } from '@tanstack/react-query';
import type { Measure, MeasureReport } from 'fhir/r4';
import { Fragment, type PropsWithChildren, useCallback, useMemo, useState } from 'react';

type ActivityHistoryProps = {
  careGap: CareGapModel;
  reloadCareGap: ReturnType<typeof useFetchDeepLinkedResource>['reloadDeepLinkedResource'];
};

type ActivityHistoryListProps = {
  activityMeasureReports: Array<MeasureReport>;
  builderId: string;
  editingEnabled?: boolean;
  evidence?: Partial<Record<string, Array<DiagnosticReportModel>>>;
  measure: Measure;
  onEditActivity: (activityId: string | null) => void;
  openDiagnosticReportDrawer: (options: { model: DiagnosticReportModel }) => void;
};

export function ActivityHistory({ careGap: originalCareGap, reloadCareGap }: ActivityHistoryProps) {
  const { openDrawer: openDiagnosticReportDrawer } = useDiagnosticReportDrawer();
  const { requestContext } = useCTW();
  const queryClient = useQueryClient();
  const [careGap, setCareGap] = useState<CareGapModel>(originalCareGap);
  const [editingActivityId, setEditingActivityId] = useState<string | null>(null);

  const onSaveMeasureReport = useCallback(
    (measureReport: MeasureReport) => {
      setCareGap(
        new CareGapModel(
          careGap.resource,
          [measureReport, ...careGap.measureReports.filter((mr) => mr.id !== measureReport.id)],
          careGap.allEvidence,
        ),
      );
      setEditingActivityId(null);

      void queryClient.refetchQueries({ queryKey: [`patient.${QUERY_KEY_PATIENT_CARE_GAPS}`] });
      void reloadCareGap();
    },
    [careGap, queryClient, reloadCareGap],
  );

  const onDeleteMeasureReport = useCallback(
    (measureReport: MeasureReport) => {
      setCareGap(
        new CareGapModel(
          careGap.resource,
          careGap.measureReports.filter((mr) => mr.id !== measureReport.id),
        ),
      );
      setEditingActivityId(null);

      void queryClient.refetchQueries({ queryKey: [`patient.${QUERY_KEY_PATIENT_CARE_GAPS}`] });
      void reloadCareGap();
    },
    [queryClient, careGap, reloadCareGap],
  );

  // Gather evidence for all measure reports
  const evidence = useMemo(
    () =>
      careGap.measureReports.reduce(
        (acc, measureReport) => {
          acc[measureReport.id as string] = careGap.getEvidenceForSpecificReport(
            measureReport.id as string,
          );
          return acc;
        },
        {} as Record<string, Array<DiagnosticReportModel>>,
      ),
    [careGap],
  );

  return (
    <div className={tw`space-y-2 rounded-lg bg-background-hover px-2 py-4 sm:px-4`}>
      <AddActivityForm
        careGap={careGap}
        onSaveMeasureReport={onSaveMeasureReport}
        editingActivityId={editingActivityId}
        onCancelEdit={() => setEditingActivityId(null)}
        onDeleteMeasureReport={onDeleteMeasureReport}
      />
      <ActivityHistoryList
        activityMeasureReports={careGap.measureReports.filter(({ id }) => id !== editingActivityId)}
        builderId={requestContext.builderId}
        editingEnabled={!editingActivityId}
        evidence={evidence}
        measure={careGap.resource}
        onEditActivity={setEditingActivityId}
        openDiagnosticReportDrawer={openDiagnosticReportDrawer}
      />
    </div>
  );
}

export function ActivityHistoryList({
  activityMeasureReports,
  builderId,
  editingEnabled,
  evidence,
  measure,
  onEditActivity,
  openDiagnosticReportDrawer,
}: ActivityHistoryListProps) {
  return useMemo(
    () =>
      activityMeasureReports.map((measureReport, i) => {
        const entry = new CareGapModel(measure, [measureReport]);
        let prevGapStatus: CareGapStatus['slug'] | undefined;
        if (i < activityMeasureReports.length - 1) {
          prevGapStatus = new CareGapModel(measure, [activityMeasureReports[i + 1]]).status.slug;
        }
        const statusDescription = getActivityStatusDescription(entry, prevGapStatus);
        const isUserActivity = entry.latestMeasureReport.reporter?.type === 'Practitioner';
        // Only enable editing when most recent activity is a user activity and user isn't already editing another activity.
        const editingEnabledForActivity = i === 0 && isUserActivity && editingEnabled;

        // ButtonDiv adds click handlers to the activity card when it is editable. Otherwise, non-user
        // generated activity cards may include button-links to show evidence, which can't be nested
        // inside another button.
        const ButtonDiv = ({ children }: PropsWithChildren<object>) => {
          if (editingEnabledForActivity) {
            return (
              <div
                className={tw`group cursor-pointer space-y-2 rounded-lg bg-white p-3 text-left outline outline-1 outline-divider-main hover:outline-2 hover:outline-primary-main`}
                data-testid="activity"
                onClick={() => onEditActivity(measureReport.id as string)}
                onKeyDown={(e) =>
                  e.key === 'Enter' ? onEditActivity(measureReport.id as string) : undefined
                }
                tabIndex={0}
                role="button"
              >
                {children}
              </div>
            );
          }
          return (
            <div
              className={tw`group space-y-2 rounded-lg bg-white p-3 text-left outline outline-1 outline-divider-main`}
              data-testid="activity"
            >
              {children}
            </div>
          );
        };
        return (
          <Fragment key={measureReport.id}>
            <ButtonDiv>
              <div className={tw`flex items-start justify-between`}>
                <div className={tw`font-normal text-xs`}>{statusDescription}</div>
              </div>
              {isUserActivity ? (
                <CareGapUserActivity
                  activity={entry.practitionerActivity}
                  note={entry.practitionerNote}
                  showEditButton={editingEnabledForActivity}
                />
              ) : (
                <CareGapActivity
                  activities={entry.latestReportReasonsWithoutDenominator}
                  builderId={builderId}
                  evidenceReports={evidence?.[measureReport.id as string] || []}
                  openDiagnosticReportDrawer={openDiagnosticReportDrawer}
                />
              )}
            </ButtonDiv>
          </Fragment>
        );
      }),
    [
      activityMeasureReports,
      builderId,
      editingEnabled,
      evidence,
      measure,
      onEditActivity,
      openDiagnosticReportDrawer,
    ],
  );
}
