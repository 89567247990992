import type { CareGapModel, CareGapStatus } from '@ctw/shared/api/fhir/models/care-gap';
import { SYSTEM_ZUS_ACTIVITY_UPDATED_BY } from '@ctw/shared/api/fhir/system-urls';
import type { TableColumn } from '@ctw/shared/components/table/table';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/helpers/status-badge';
import { ResourceTitleColumn } from '@ctw/shared/content/resource/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';
import { find } from 'lodash-es';

export const patientCareGapsColumns = (): Array<TableColumn<CareGapModel>> => [
  {
    title: 'Measure',
    minWidth: 120,
    render: (careGap) => <ResourceTitleColumn title={careGap.title} />,
  },
  {
    title: 'Status',
    render: (careGap) => <CareGapStatusBadge gap={careGap} />,
    minWidth: 80,
  },
  {
    title: 'Details',
    render: (careGap) => (
      <div className={tw`flex flex-col space-y-1`}>
        {careGap.latestMeasureReport.reporter?.type === 'Practitioner' ? (
          <>
            <div className={tw`font-normal text-xs`}>
              {getActivityStatusDescription(careGap, careGap.status.slug)}
            </div>
            <div className={tw`font-medium`}>{careGap.practitionerNote}</div>
          </>
        ) : (
          <>
            <div className={tw`font-normal text-xs`}>
              {getActivityStatusDescription(careGap, careGap.status.slug)}
            </div>
            <div className={tw`font-medium`}>{careGap.latestReportReasons.join(' ')}</div>
          </>
        )}
      </div>
    ),
  },
];

export function getActivityStatusDescription(
  gap: CareGapModel,
  prevStatus?: CareGapStatus['slug'],
): string {
  const statusDate = gap.updatedDate || 'Unknown';
  const editedAt = gap.lastUpdated;
  const { reporter } = gap.latestMeasureReport;
  if (reporter?.type === 'Practitioner' && reporter.display) {
    const editedBy = find(reporter.extension, { url: SYSTEM_ZUS_ACTIVITY_UPDATED_BY })
      ?.valueReference?.display;

    if (!editedBy) {
      return `Updated ${statusDate} by ${reporter.display}`;
    }
    if (editedBy === reporter.display) {
      return `Updated ${statusDate} by ${reporter.display} (Edited ${editedAt ?? ''})`;
    }
    return `Updated ${statusDate} by ${reporter.display} (Edited ${editedAt ?? ''} by ${editedBy})`;
  }
  if (gap.status.slug === prevStatus) {
    // We want to avoid repeating the same status, if it hasn't changed.
    return `Updated ${statusDate}`;
  }
  return `${gap.status.pastTense} ${statusDate}`;
}
