import { toggleRead } from '@ctw/shared/api/fhir/basic';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { Resource } from 'fhir/r4';
import { useCallback, useState } from 'react';

interface UseToggleReadResult {
  /**
   * Function to call to toggle the read status of the FHIR model
   */
  toggleRead: (model: FHIRModel<Resource>) => Promise<void>;

  /**
   * True when `toggleRead` is called
   */
  isLoading: boolean;
}

/**
 * This hook toggles the read status for the specified FHIR model.
 */
export function useToggleRead(): UseToggleReadResult {
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleToggleRead = useCallback(async (model: FHIRModel<Resource>) => {
    // In production we want to avoid non-builder users from inadvertantly marking records as read
    // In lower environments this is allowed for demos/testing
    if (
      ctw.requestContext.env === 'production' &&
      ctw.requestContext.authTokenState.userType !== 'builder'
    ) {
      return;
    }
    setIsLoading(true);
    await toggleRead(model, ctw, telemetry);
    setIsLoading(false);
  }, []);

  return {
    toggleRead: handleToggleRead,
    isLoading,
  };
}
