import { DockableDrawer } from '@ctw/shared/components/containers/dockable-drawer';
import { Drawer, type DrawerProps } from '@ctw/shared/components/containers/drawer';
import { ErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { TelemetryBoundary, useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface OpenDrawerAction
  extends Pick<DrawerProps, 'label' | 'sublabel' | 'title' | 'subtitle' | 'badges' | 'content'> {
  allowDocking?: boolean;
  telemetryContext?: Record<string, unknown>;
  onDrawerEvent?: (eventType: DrawerEventType) => void;
}

type DrawerEventType = 'open' | 'afterOpen' | 'close' | 'afterClose';

type DrawerEventHandler = (eventType: DrawerEventType) => void;

export interface DrawerBoundary {
  name: string;
  context?: Record<string, unknown>;
}

export interface DrawerState {
  isDrawerOpen: boolean;
  isDrawerDocked: boolean;
  isDrawerTransitioning: boolean;
  openDrawer: (action: OpenDrawerAction) => void;
  closeDrawer: () => void;
  drawerBoundary: DrawerBoundary;
  setDrawerBoundary: (boundary: DrawerBoundary) => void;
  onDrawerEvent: (boundaryName: string, eventHandler: DrawerEventHandler) => () => void;
}

const emptyDrawerAction: OpenDrawerAction = {
  title: '',
  badges: [],
  allowDocking: true,
  content: {
    header: null,
    body: null,
    footer: null,
  },
} as const;

const defaultDrawerBoundary: DrawerBoundary = {
  name: 'Drawer',
} as const;

const DrawerContext = createContext<DrawerState | null>(null);

interface DrawerProviderChildrenArgs {
  drawer: ReactNode;
}

interface DrawerProviderProps {
  children: (args: DrawerProviderChildrenArgs) => ReactNode;
  allowDocking: boolean;
}

export const DrawerProvider = ({ children, allowDocking }: DrawerProviderProps) => {
  const telemetry = useTelemetry();
  const [openDrawerAction, setOpenDrawerAction] = useState<OpenDrawerAction>(emptyDrawerAction);
  const [drawerBoundary, setDrawerBoundary] = useState<DrawerBoundary>(defaultDrawerBoundary);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isDrawerTransitioning = useRef(false);

  const drawerIsDockable = useMemo(
    () => Boolean(allowDocking && openDrawerAction.allowDocking),
    [allowDocking, openDrawerAction.allowDocking],
  );

  const eventRegistrations: Record<string, Array<DrawerEventHandler>> = useMemo(() => ({}), []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const openDrawer: DrawerState['openDrawer'] = useCallback(
    (action) => {
      if (!(isDrawerTransitioning.current || isDrawerTransitioning.current)) {
        telemetry.trackInteraction('open_drawer', action.telemetryContext);
        setIsDrawerOpen(true);
        setOpenDrawerAction(action);
      }
    },
    [drawerBoundary],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const closeDrawer: DrawerState['closeDrawer'] = useCallback(() => {
    if (!(isDrawerTransitioning.current || isDrawerTransitioning.current)) {
      handleEvent('close');
      telemetry.trackInteraction('close_drawer', openDrawerAction.telemetryContext);
      setIsDrawerOpen(false);
      setTimeout(() => {
        setOpenDrawerAction(emptyDrawerAction);
        setDrawerBoundary(defaultDrawerBoundary);
      }, 300);
    }
  }, [drawerBoundary, openDrawerAction]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const state: DrawerState = useMemo(
    () => ({
      openDrawer,
      closeDrawer,
      isDrawerOpen,
      isDrawerDocked: drawerIsDockable && isDrawerOpen,
      isDrawerTransitioning: isDrawerTransitioning.current,
      drawerBoundary,
      setDrawerBoundary,
      onDrawerEvent: (boundaryName, eventHandler) => {
        if (!Array.isArray(eventRegistrations[boundaryName])) {
          eventRegistrations[boundaryName] = [];
        }

        eventRegistrations[boundaryName].push(eventHandler);

        return () => {
          eventRegistrations[boundaryName] = eventRegistrations[boundaryName].filter(
            (handler) => handler !== eventHandler,
          );
        };
      },
    }),
    [drawerBoundary, closeDrawer, isDrawerOpen, openDrawer],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleEvent = useCallback(
    (eventType: DrawerEventType) => {
      openDrawerAction.onDrawerEvent?.(eventType);

      switch (eventType) {
        case 'open':
        case 'close':
          isDrawerTransitioning.current = true;
          break;
        case 'afterOpen':
        case 'afterClose':
          isDrawerTransitioning.current = false;
          break;
        default:
          break;
      }

      if (Array.isArray(eventRegistrations[drawerBoundary.name])) {
        eventRegistrations[drawerBoundary.name].forEach((eventHandler) => {
          const invokeEventHandler = async () => eventHandler(eventType);
          void invokeEventHandler();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerBoundary, openDrawerAction],
  );

  const drawer = useMemo(
    () => (
      <ErrorBoundary boundaryName={drawerBoundary.name} initialContext={drawerBoundary.context}>
        <TelemetryBoundary
          boundaryName={drawerBoundary.name}
          initialContext={drawerBoundary.context}
        >
          {drawerIsDockable && (
            <DockableDrawer
              isOpen={isDrawerOpen}
              onOpen={() => handleEvent('open')}
              onAfterOpen={() => handleEvent('afterOpen')}
              close={closeDrawer}
              onAfterClose={() => handleEvent('afterClose')}
              {...openDrawerAction}
            />
          )}
          {!drawerIsDockable && (
            <Drawer
              isOpen={isDrawerOpen}
              onOpen={() => handleEvent('open')}
              onAfterOpen={() => handleEvent('afterOpen')}
              close={closeDrawer}
              onAfterClose={() => handleEvent('afterClose')}
              {...openDrawerAction}
            />
          )}
        </TelemetryBoundary>
      </ErrorBoundary>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerBoundary, closeDrawer, drawerIsDockable, handleEvent, isDrawerOpen, openDrawerAction],
  );

  return (
    <DrawerContext.Provider value={state}>
      {children({
        drawer,
      })}
    </DrawerContext.Provider>
  );
};

export interface UseDrawerOptions {
  boundaryName: string;
  telemetryContext?: Record<string, unknown>;
  allowDocking?: boolean;
  onDrawerEvent?: (eventType: DrawerEventType) => void;
}

export const useDrawer = ({
  boundaryName,
  telemetryContext,
  allowDocking = true,
  onDrawerEvent,
}: UseDrawerOptions) => {
  const drawerContext = useContext(DrawerContext);

  const drawer: DrawerState | null = useMemo(() => {
    if (!drawerContext) {
      return null;
    }

    return {
      ...drawerContext,
      openDrawer: (action: OpenDrawerAction) => {
        drawerContext.setDrawerBoundary({
          name: boundaryName,
          context: { ...telemetryContext, ...action.telemetryContext },
        });
        drawerContext.openDrawer({ ...action, allowDocking });
      },
    };
  }, [boundaryName, telemetryContext, allowDocking, drawerContext]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(
    () =>
      drawer && onDrawerEvent
        ? drawer.onDrawerEvent(boundaryName, onDrawerEvent)
        : () => {
            /* noop */
          },
    [boundaryName, onDrawerEvent],
  );

  if (!drawer) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }

  return drawer;
};

interface PublicDrawerState {
  isDrawerOpen: boolean;
  isDrawerDocked: boolean;
  isDrawerTransitioning: boolean;
  boundary: DrawerBoundary;
}

export const useDrawerState = (): PublicDrawerState => {
  const drawerState = useContext(DrawerContext);

  return {
    isDrawerOpen: drawerState?.isDrawerOpen ?? false,
    isDrawerDocked: drawerState?.isDrawerDocked ?? false,
    isDrawerTransitioning: drawerState?.isDrawerTransitioning ?? false,
    boundary: drawerState?.drawerBoundary ?? defaultDrawerBoundary,
  };
};
