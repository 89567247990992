import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import type { TableColumn } from '@ctw/shared/components/table/table';
import { TruncatedList } from '@ctw/shared/components/truncated-list';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const patientEncounterColumns = (builderId: string): Array<TableColumn<EncounterModel>> => [
  {
    render: (encounter) => (
      <div className={tw`flex flex-nowrap space-x-2`}>
        {RenderSyncedWithRecordIcon(
          encounter.ownedByBuilder(builderId) || encounter.syncedWithRecord,
        )}
      </div>
    ),
    width: '25px',
  },
  {
    title: 'Date',
    widthPercent: 10,
    minWidth: 120,
    dataIndex: 'dateDisplay',
  },
  {
    title: 'Class',
    render: (encounter) => (
      <ResourceTitleColumn
        title={<span className={tw`break-keep`}>{encounter.classDisplay}</span>}
      />
    ),
  },
  {
    title: 'Location',
    render: (encounter) => {
      // We need to filter by "unknown", but we don't want to display it
      const specialties = encounter.locationType.filter((l) => l.toLocaleLowerCase() !== 'unknown');
      return (
        <>
          <TruncatedList orientation="vertical" items={encounter.location} limit={3} />
          {specialties.length > 0 && (
            <div className={tw`pt-2`}>Speciality: {specialties.join(', ')}</div>
          )}
        </>
      );
    },
  },
  {
    title: 'Provider',
    render: (encounter) => (
      <TruncatedList orientation="horizontal" items={encounter.practitioners} limit={2} />
    ),
  },
  {
    width: '28px',
    render: (encounter) => {
      const notes = encounter.clinicalNotes.map((note) => note.title).sort();

      if (encounter.isLoadingClinicalNotes) {
        return <LoadingSpinner className={tw`h-4 w-4 text-content-icon`} />;
      }

      if (notes.length > 0) {
        return <FontAwesomeIcon className={tw`w-4 text-content-icon`} icon={faNoteSticky} />;
      }

      return <></>;
    },
  },
  {
    title: 'Details',
    render: (encounter) => {
      const { dischargeDisposition } = encounter;
      const { diagnoses } = encounter;
      return (
        <>
          {diagnoses.length > 0 && (
            <TruncatedList orientation="horizontal" items={diagnoses} limit={3} />
          )}
          {dischargeDisposition && (
            <div className={tw`pt-2`}>Discharge: {dischargeDisposition}</div>
          )}
        </>
      );
    },
  },
];
