import { Button } from '@ctw/shared/components/button';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import {
  DefinitionList,
  type DefinitionListItem,
  hasNonEmptyDefinitionListItems,
} from '@ctw/shared/components/definition-list';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Dispatch, type ReactNode, type SetStateAction, useMemo, useState } from 'react';

export type NotesEntryProps = {
  summary?: ReactNode;
  isDetailShownOnOpen?: boolean;
  details: Array<DefinitionListItem>;
};

// Component for displaying a note with a summary and details directly
export const NotesEntry = ({ summary, isDetailShownOnOpen = false, details }: NotesEntryProps) => {
  const canShowDetails = useMemo(() => hasNonEmptyDefinitionListItems(details), [details]);
  const [isDetailShown, setIsDetailShown] = useState(canShowDetails && isDetailShownOnOpen);

  return (
    <div className={tw`space-y-1`}>
      <NoteSummary
        summary={summary}
        canShowDetails={canShowDetails}
        isDetailShown={isDetailShown}
        setIsDetailShown={setIsDetailShown}
        className={tw`space-x-4 rounded-lg bg-white p-3 outline outline-1 outline-divider-main`}
      />
      {isDetailShown && (
        <ContentCard variant="focal" title="Details">
          <DefinitionList items={details} />
        </ContentCard>
      )}
    </div>
  );
};

export const NoteSummary = ({
  summary,
  canShowDetails = true,
  isDetailShown,
  setIsDetailShown,
  className,
}: {
  summary?: ReactNode;
  canShowDetails?: boolean;
  isDetailShown: boolean;
  setIsDetailShown: Dispatch<SetStateAction<boolean>>;
  className?: string;
}) => {
  const { trackInteraction } = useTelemetry();
  return (
    <Button
      disabled={!canShowDetails}
      type="button"
      aria-label="details"
      variant="unstyled"
      className={twx(className, 'flex w-full items-center justify-between space-x-4 text-left')}
      onClick={() => {
        trackInteraction('toggle_note', {
          action: isDetailShown ? 'collapse_note' : 'expand_note',
        });
        return setIsDetailShown(!isDetailShown);
      }}
    >
      <div className={tw`flex grow space-x-3`}>{summary}</div>
      <div className={tw`flex items-center space-x-3`}>
        <div className={tw`justify-right flex`}>
          {canShowDetails && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className={twx('h-3 w-3 text-content-icon', {
                'rotate-90': isDetailShown,
              })}
            />
          )}
        </div>
      </div>
    </Button>
  );
};
