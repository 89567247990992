export type OverviewFilter<T> = {
  key: string;
  label: string;
  description: string;
  predicate: (r: T) => boolean;
};

export function applyFilters<T>(
  data: Array<T>,
  filters: Array<{ predicate: (item: T) => boolean }>,
): Array<T> {
  return data.filter((item) => filters.every((filter) => filter.predicate(item)));
}
