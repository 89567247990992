import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import type { ObservationModel } from '@ctw/shared/api/fhir/models/observation';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { fetchObservationsByLoinc } from '@ctw/shared/api/fhir/observations';
import { MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';
import { vitalLoincCodes } from '@ctw/shared/content/vitals/vital-loinc-codes';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_PATIENT_VITALS } from '@ctw/shared/utils/query-keys';
import type { GraphQLClient } from 'graphql-request';
import { compact, groupBy, orderBy, uniq } from 'lodash-es';
import { getNonLensEncountersFQS } from './encounters';
import { VitalsBucket } from './models/vitals-bucket';
import { referenceToId } from './resource-helper';

// Returns an array of EncounterModelWithVitals, each with an array of vitals.
export const usePatientVitals = () =>
  usePatientQuery({
    queryId: QUERY_KEY_PATIENT_VITALS,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const vitals = await fetchObservationsByLoinc(
        telemetry,
        graphqlClient,
        patient,
        vitalLoincCodes,
      );
      if (vitals.length === 0) {
        return [];
      }

      const encounters = await fetchVitalEncounters(vitals, telemetry, graphqlClient, patient);

      return createVitalBuckets(vitals, requestContext.builderId, encounters);
    },
  });

function fetchVitalEncounters(
  vitals: Array<ObservationModel>,
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
) {
  const encounterRefs = uniq(
    vitals.map((observation) => observation.resource.encounter?.reference),
  );
  const encounterIds = compact(encounterRefs.map(referenceToId));
  return getNonLensEncountersFQS(MAX_OBJECTS_PER_REQUEST, encounterIds)(
    telemetry,
    graphqlClient,
    patient,
  );
}

// Group all vitals by date and grab the most recent encounter for each date.
// This way we handle a few different cases:
// 1. Duplicate encounters for the same date. We'll add all of their vitals to a single bucket
//    and take the average of the matching vitals thus cancelling out the duplications.
// 2. Vitals without encounters will still be grouped by date and displayed in the UI.
// 3. Different encounters on the same date will be displayed together, using the most recent
//    encounter for that date.
function createVitalBuckets(
  vitals: Array<ObservationModel>,
  builderId: string,
  encounters: Array<EncounterModel>,
) {
  const vitalsByDate = groupBy(vitals, (observation) => observation.dateDisplay);
  const encountersByDate = groupBy(encounters, 'periodStart');

  return Object.entries(vitalsByDate).map(([date, dateVitals]) => {
    // Grab the most recent encounter that has a type.
    const orderedEncounters = orderBy(
      encountersByDate[date],
      (e) => e.resource.period?.start,
      'desc',
    );
    const encounter =
      orderedEncounters.find((e) => e.typeDisplay !== 'Unknown') ?? orderedEncounters[0];
    return new VitalsBucket(dateVitals, builderId, encounter);
  });
}
