import { ErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { MainContentContainer } from '@ctw/shared/components/layout/main-content-container';
import { MainContentWithDockableDrawer } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { SubNavigationBar } from '@ctw/shared/components/layout/sub-navigation-bar';
import { VerticalSubNavigationDock } from '@ctw/shared/components/layout/vertical-sub-navigation-dock';
import { AlertFeatureFlag } from '@ctw/shared/content/alert-feature-flag';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import {
  PatientSubNavigationProvider,
  usePatientSubNavigation,
} from '@ctw/shared/context/patient-sub-navigation-context';
import { TelemetryBoundary } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { usePatientHeaderFields } from '@ctw/shared/hooks/use-patient-header-fields';
import { useZapSections } from '@ctw/shared/hooks/use-zap-sections';
import '@ctw/shared/styles/preflight';
import { PatientHistoryStatus } from '@ctw/shared/content/patient-history-status';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { useBreakpointQuery } from '@ctw/shared/hooks/breakpoints';
import { trimEnd, trimStart } from 'lodash-es';
import { type PropsWithChildren, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export type ZAPTabName = Readonly<(typeof ZAP_TAB_NAMES)[number]>;

export const ZAP_TAB_NAMES = [
  'summary',
  'allergies',
  'care-gaps',
  'care-team',
  'conditions-all',
  'diagnostic-reports',
  'documents',
  'encounters',
  'referrals',
  'immunizations',
  'medications-all',
  'search',
  'overview',
  'demographics',
  'vitals',
] as const;

export const ZusAggregatedProfile = () => {
  const zapSections = useZapSections();

  const fallbackRoute = useMemo(() => {
    const url = new URL(window.location.href);
    url.pathname = `${trimEnd(url.pathname, '/')}/${trimStart(zapSections[0].href, '/')}`;
    return `${url.pathname}${url.search}${url.hash}`;
  }, [zapSections]);

  return (
    <TelemetryBoundary boundaryName="ZAP Router">
      <ErrorBoundary boundaryName="ZAP Router">
        <PatientSubNavigationProvider subNavigationLinks={zapSections}>
          <AlertFeatureFlag featureFlag="ctw-zap-alert" />
          <PatientAggregatedProfile>
            <Routes>
              {zapSections.map((section) => (
                <>
                  <Route key={section.href} path={section.href} Component={section.component} />
                  {section.siblingHrefs?.map((siblingHref) => (
                    <Route key={siblingHref} path={siblingHref} Component={section.component} />
                  ))}
                </>
              ))}
              <Route path="*" element={<Navigate to={fallbackRoute} replace={true} />} />
            </Routes>
          </PatientAggregatedProfile>
        </PatientSubNavigationProvider>
      </ErrorBoundary>
    </TelemetryBoundary>
  );
};

interface PatientAggregatedProfile extends PropsWithChildren {}

const PatientAggregatedProfile = ({ children }: PatientAggregatedProfile) => {
  const { patient } = usePatientContext();
  const subNavigation = usePatientSubNavigation();
  const headerFields = usePatientHeaderFields();
  const { breakpoints } = useBreakpointQuery();
  const hasRequestRecordsFeature = useFeatureFlag('ctw-patient-history-form');
  const handleContentLoadingState = useMemo(
    () => subNavigation?.activeLink?.siblingHrefs === undefined,
    [subNavigation],
  );

  return (
    <>
      <SubNavigationBar
        avatar={{
          name: `${patient.firstName} ${patient.lastName}`,
        }}
        headerFields={headerFields}
        subNavigationLinks={subNavigation.links}
        renderContent={{
          afterHeaderFields: () => (
            <PatientHistoryStatus
              expanded={breakpoints.isAtLeast.lg}
              inline={true}
              hideRequestButton={!hasRequestRecordsFeature}
            />
          ),
        }}
      />
      <MainContentContainer>
        <VerticalSubNavigationDock navigationLinks={subNavigation.links} />
        <TelemetryBoundary boundaryName="ZAP Main Content">
          <ErrorBoundary boundaryName="ZAP Main Content">
            <MainContentWithDockableDrawer handleContentLoadingState={handleContentLoadingState}>
              {children}
            </MainContentWithDockableDrawer>
          </ErrorBoundary>
        </TelemetryBoundary>
      </MainContentContainer>
    </>
  );
};
