import { Button } from '@ctw/shared/components/button';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { type VoidValue, isMouseEvent } from '@ctw/shared/utils/types';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { debounce, isEmpty, isObject } from 'lodash-es';
import { Fragment, type JSX, type KeyboardEvent, useCallback, useMemo, useState } from 'react';

export type ComboxboxFieldOption = {
  value: unknown;
  label: string;
  key?: string;
};

export type ComboboxFieldProps<T> = {
  options: Array<ComboxboxFieldOption>;
  className?: ClassName;
  isLoading: boolean;
  name: string;
  defaultValue?: T;
  defaultSearchTerm?: string;
  onSearchChange: (searchTerm: string) => VoidValue;
  readonly: boolean | undefined;
  enableSearchIcon?: boolean;
  onCustomSelectChange?: (e: unknown) => VoidValue;
  renderCustomOption?: (e: unknown) => JSX.Element;
  placeholder?: string;
  minInputLength?: number;
  usesSearchButton?: boolean;
};

export const ComboboxField = <T,>({
  options,
  isLoading,
  className,
  name,
  defaultValue = {} as T,
  defaultSearchTerm = '',
  onSearchChange,
  readonly,
  onCustomSelectChange,
  renderCustomOption,
  placeholder = 'Type to search',
  minInputLength = 3,
  enableSearchIcon = false,
  usesSearchButton = false,
}: ComboboxFieldProps<T>) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm || '');
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState<unknown>({});
  const inputState = isEmpty(inputValue) ? defaultValue : inputValue;

  // Check if inputState is an object to determine if we should JSON.stringify.
  const inputValueParsed = isObject(inputState) ? JSON.stringify(inputState) : inputState;

  const handleSearch = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm.length >= minInputLength) {
        void onSearchChange(newSearchTerm);
        setSearchTerm(newSearchTerm);
        setIsSearchSubmitted(true);
      }

      if (options.filter((item) => item.label === newSearchTerm).length === 0) {
        setInputValue({});
      }
    },
    [onSearchChange, minInputLength, options],
  );

  // Delay handle search input so that we don't fire a bunch of events until the user has had time to type.
  const debouncedSearchInputChange = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const onSelectChange = (e: unknown) => {
    const option = e as ComboxboxFieldOption | undefined;

    if (option?.value) {
      setSearchTerm(option.label);
      setInputValue(option.value);
      void onCustomSelectChange?.(option.value);
    }
  };

  return (
    <Combobox onChange={onSelectChange} value={searchTerm} disabled={readonly}>
      {({ open }) => (
        <div className={twx('relative flex items-center', className)}>
          <ComboboxButton
            className={tw`flex-1`}
            as="div"
            onClick={(e: unknown) => {
              // Prevent options from flashing upon re-click.
              if ((open || searchTerm.length === 0) && isMouseEvent(e)) {
                e.preventDefault();
              }
            }}
          >
            <div className={tw`flex flex-1 items-center gap-2`}>
              <div className={tw`relative grow`}>
                {enableSearchIcon && (
                  <div className={tw`search-icon-wrapper`}>
                    <FontAwesomeIcon icon={faSearch} className={tw`search-icon`} />
                  </div>
                )}
                <ComboboxInput
                  className={twx('listbox-input w-full flex-1', {
                    'pl-10': enableSearchIcon,
                  })}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      if (options.length === 0 || isLoading) {
                        e.preventDefault();
                      }
                      if (usesSearchButton && !isSearchSubmitted) {
                        e.preventDefault();
                        handleSearch(searchTerm);
                      }
                    }

                    // This is required as long as the ComboboxInput is nested within ComboboxButton
                    // https://github.com/tailwindlabs/headlessui/discussions/1687#discussioncomment-10541485
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    if (usesSearchButton) {
                      setSearchTerm(e.target.value);
                      setIsSearchSubmitted(false);
                    } else {
                      // Due to debounce, we have to persist the event.
                      // https://reactjs.org/docs/legacy-event-pooling.html
                      e.persist();
                      debouncedSearchInputChange(e.target.value);
                    }
                  }}
                  placeholder={placeholder}
                />
              </div>
              {usesSearchButton && (
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    handleSearch(searchTerm);
                  }}
                  disabled={searchTerm.length < minInputLength || isSearchSubmitted}
                  className={tw`shrink-0 whitespace-nowrap`}
                >
                  Search
                </Button>
              )}
            </div>
          </ComboboxButton>
          <input hidden={true} name={name} value={inputValueParsed as string} readOnly={true} />
          <ComboboxOptions
            className={tw`listbox absolute top-9 z-10 m-0 mt-1 max-h-60 w-full list-none overflow-auto rounded-md bg-white p-0 py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            <ComboboxOptionsContent
              options={options}
              query={searchTerm}
              isLoading={isLoading}
              renderCustomOption={renderCustomOption}
              minInputLength={minInputLength}
              usesSearchButton={usesSearchButton}
              isSearchSubmitted={isSearchSubmitted}
            />
          </ComboboxOptions>
        </div>
      )}
    </Combobox>
  );
};

type RenderCorrectOptionsProps = {
  isLoading: boolean;
  options: Array<ComboxboxFieldOption>;
  query: string;
  renderCustomOption?: (e: unknown) => JSX.Element;
  minInputLength: number;
  usesSearchButton?: boolean;
  isSearchSubmitted?: boolean;
};

const ComboboxOptionsContent = ({
  options,
  query,
  isLoading,
  renderCustomOption,
  minInputLength,
  usesSearchButton = false,
  isSearchSubmitted = true,
}: RenderCorrectOptionsProps) => {
  if (query.length === 0) {
    return <ComboboxOptionContent option={{ value: '', label: 'Type to search' }} />;
  }

  if (isLoading) {
    return <ComboboxOptionContent option={{ value: '', label: 'Loading...' }} />;
  }

  if (query.length < minInputLength) {
    return (
      <ComboboxOptionContent
        option={{ value: '', label: 'Please type at least three characters' }}
      />
    );
  }

  if (usesSearchButton && !isSearchSubmitted) {
    return (
      <ComboboxOptionContent
        option={{
          value: '',
          label: `Type at least ${minInputLength} letters and click the Search button or Enter key`,
        }}
      />
    );
  }

  if (options.length === 0) {
    return (
      <ComboboxOptionContent
        option={{
          value: '',
          label: `No results found for search term '${query}'`,
        }}
      />
    );
  }

  return (
    <>
      {options.map((option, index) =>
        renderCustomOption ? (
          <Fragment key={option.key ?? `option-${index}`}>{renderCustomOption(option)} </Fragment>
        ) : (
          <ComboboxOptionContent option={option} key={option.key ?? `option-${index}`} />
        ),
      )}
    </>
  );
};

const ComboboxOptionContent = ({ option }: { option: ComboxboxFieldOption }) => (
  <ComboboxOption
    value={option}
    className={({ active }) =>
      `relative cursor-default select-none py-2 pr-4 pl-4 ${
        active ? 'bg-primary-background-hover text-primary-text' : 'text-background-inverse'
      }`
    }
  >
    {option.label}
  </ComboboxOption>
);
