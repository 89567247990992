import { getAllergyIntolerancesById } from '@ctw/shared/api/fhir/allergies';
import { getCareGapByMeasureIdFromFQS } from '@ctw/shared/api/fhir/care-gaps';
import { getCareTeamMembersByIdFromFQS } from '@ctw/shared/api/fhir/care-team';
import { fetchConditionsByIdFQS } from '@ctw/shared/api/fhir/conditions';
import { fetchDiagnosticReportsFromFQSById } from '@ctw/shared/api/fhir/diagnostic-report';
import { getDocumentReferenceWithBasicsById } from '@ctw/shared/api/fhir/document';
import { getEncountersWithClinicalNotesById } from '@ctw/shared/api/fhir/encounters';
import { getImmunizationsFromFQS } from '@ctw/shared/api/fhir/immunizations';
import { getMedicationStatementsByIdFQS } from '@ctw/shared/api/fhir/medications';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { fetchReferralsByIdFQS } from '@ctw/shared/api/fhir/referrals';
import type { ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { usePatientContextIfAvailable } from '@ctw/shared/context/patient-provider';
import {
  isDeepLinkResourceType,
  useDeepLinkResource,
} from '@ctw/shared/hooks/use-deep-link-resource';
import type { FhirModelSubclassType } from '@ctw/shared/hooks/use-resource-drawer';
import { QUERY_KEY_DEEP_LINKED_RESOURCE } from '@ctw/shared/utils/query-keys';
import { isEmpty, snakeCase } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCtwQuery } from './use-ctw-query';

interface UseFetchDeepLinkedResourceOptions {
  resourceType: ResourceTypeString;
  patient?: PatientModel;
}

export function useFetchDeepLinkedResource<
  TResourceType extends ResourceTypeString,
  TModel extends FhirModelSubclassType<TResourceType>,
>({ resourceType, patient: explicitPatient }: UseFetchDeepLinkedResourceOptions) {
  const patientContext = usePatientContextIfAvailable();
  const { deepLinkResource } = useDeepLinkResource();
  const [deepLinkedResource, setDeepLinkedResource] = useState<TModel | null>(null);
  const canFetchDeepLinkedResource = useMemo(
    () =>
      !isEmpty(deepLinkResource?.resourceId) &&
      Boolean(isDeepLinkResourceType(resourceType) && (patientContext || explicitPatient)),
    [explicitPatient, patientContext, deepLinkResource?.resourceId, resourceType],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const patient = useMemo(() => explicitPatient ?? patientContext?.patient, [explicitPatient]);

  const resourceQueryKey = useMemo(
    () => `${QUERY_KEY_DEEP_LINKED_RESOURCE}_${snakeCase(resourceType)}`,
    [resourceType],
  );
  const resourceQuery = useCtwQuery({
    refetchOnMount: false,
    queryId: resourceQueryKey,
    queryKey: [resourceType, deepLinkResource?.resourceId, patient?.patientUPID],
    queryFn: ({
      requestContext,
      telemetry,
      ctwFetch,
      graphqlClient,
    }): Promise<unknown | Array<unknown>> => {
      if (!deepLinkResource?.resourceId || isEmpty(deepLinkResource.resourceId)) {
        throw new Error(
          'useFetchDeepLinkedResource tried to perform a query with an undefined deepLinkedResourceId',
        );
      }

      if (!patient) {
        return Promise.resolve([]);
      }

      switch (resourceType) {
        case 'Condition':
          return (
            fetchConditionsByIdFQS(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'Immunization':
          return (
            getImmunizationsFromFQS(telemetry, graphqlClient, requestContext, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'AllergyIntolerance':
          return (
            getAllergyIntolerancesById(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'Encounter':
          return (
            getEncountersWithClinicalNotesById(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'DiagnosticReport':
          return (
            fetchDiagnosticReportsFromFQSById(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'ServiceRequest':
          return (
            fetchReferralsByIdFQS(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'MedicationStatement':
          return (
            getMedicationStatementsByIdFQS(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'Practitioner':
          return (
            getCareTeamMembersByIdFromFQS(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'DocumentReference':
          return (
            getDocumentReferenceWithBasicsById(telemetry, graphqlClient, patient, [
              deepLinkResource.resourceId,
            ]) ?? []
          );
        case 'Measure':
          return (
            getCareGapByMeasureIdFromFQS(
              ctwFetch,
              requestContext,
              patient,
              deepLinkResource.resourceId,
            ) ?? []
          );
        default:
          return Promise.resolve([]);
      }
    },
    enabled: canFetchDeepLinkedResource,
  });

  const reloadDeepLinkedResource = useCallback(async (): Promise<TModel | null> => {
    if (!canFetchDeepLinkedResource) {
      return null;
    }

    const result = await resourceQuery.refetch();

    if (result.data === null || result.data === undefined) {
      return null;
    }

    return Array.isArray(result.data)
      ? ((result.data[0] ?? null) as unknown as TModel | null)
      : (result.data as unknown as TModel | null);
  }, [canFetchDeepLinkedResource, resourceQuery]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      resourceType === deepLinkResource?.resourceType &&
      !resourceQuery.isLoading &&
      resourceQuery.data &&
      !deepLinkedResource
    ) {
      if (Array.isArray(resourceQuery.data)) {
        setDeepLinkedResource((resourceQuery.data[0] ?? null) as unknown as TModel | null);
      } else {
        setDeepLinkedResource(resourceQuery.data as unknown as TModel | null);
      }
    } else if (deepLinkedResource) {
      setDeepLinkedResource(null);
    }
  }, [deepLinkResource?.resourceType, resourceQuery.isLoading, resourceType]);

  return { deepLinkedResource, reloadDeepLinkedResource, canFetchDeepLinkedResource };
}
