import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile';
import { CTWProvider } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { type ZusAuthTokenState, getAuthTokenState } from '@ctw/shared/utils/auth';
import { useEffect, useState } from 'react';
import ErrorMessage from './ErrorMessage';
import LoadingMessage from './LoadingMessage';
import { EhrFhirClientProvider, useEhrFhirClient } from './context/ehr-fhir-client-context';
import { ZusTokenProvider, useZusToken } from './context/zus-token-context';
import { useEnvironment } from './hooks/use-environment';
import { usePatient } from './hooks/use-patient';

const { VITE_GIT_SHA } = import.meta.env;

const ZAP = () => {
  const environment = useEnvironment();
  const zusToken = useZusToken();
  const patient = usePatient();
  const fhirClient = useEhrFhirClient();
  const telemetry = useTelemetry();
  const [authTokenState, setAuthTokenState] = useState<ZusAuthTokenState | undefined>(undefined);

  useEffect(() => {
    if (zusToken.token) {
      setAuthTokenState(getAuthTokenState(zusToken.token));
    }
  }, [zusToken]);

  useEffect(() => {
    telemetry.logger.info('Mounting smart-on-fhir zap');
    return () => telemetry.logger.info('Unmounting smart-on-fhir zap');
  }, [telemetry]);

  // For Athena we want to refresh the chart after saving a resource
  // so that the user can see the effect of their action. Though we do
  // not want to refresh the chart after saving a Basic resource since
  // those are not reflected in Athena.
  const refreshChart = () => {
    if (environment.ehr !== 'athena') {
      return;
    }
    window.parent.postMessage(
      {
        type: 'embeddedAppAPIMessage',
        method: 'notifyPatientDataChange',
        methodVersion: '1.0.0',
      },
      environment.env === 'production'
        ? 'https://athenanet.athenahealth.com'
        : 'https://preview.athenahealth.com',
    );
  };

  // For Athena we need to pass the department ID in the request headers
  // so that we can use it when saving resources.
  let ehrContext: Record<string, string> | undefined;
  if (environment.ehr === 'athena') {
    const department = fhirClient.client?.state.tokenResponse?.ah_department;
    if (!department) {
      throw new Error('Missing department ID in Athena token response');
    }
    ehrContext = { ah_department: department };
  }

  if (patient.error) {
    return <ErrorMessage message={patient.error.message} />;
  }

  if (zusToken.token && authTokenState && patient.uniqueIdentifier) {
    const effectivePatientId =
      import.meta.env.VITE_SMART_TEST_PATIENT_ID ?? patient.uniqueIdentifier.value;
    const effectiveSystemUrl =
      import.meta.env.VITE_SMART_TEST_PATIENT_SYSTEM_URL ?? patient.uniqueIdentifier.system;
    const effectiveBuilderId =
      import.meta.env.VITE_SMART_TEST_PATIENT_BUILDER_ID ?? authTokenState.builderId;

    return (
      <CTWProvider
        env={environment.env}
        authToken={zusToken.token}
        ehr={environment.ehr ?? 'unknown'}
        builderId={effectiveBuilderId}
        service="smart-on-fhir"
        serviceVersion={VITE_GIT_SHA}
        allowSmallBreakpointCCDAViewer={true}
        onResourceSave={(resource) => {
          if (resource.resourceType !== 'Basic') {
            refreshChart();
          }
        }}
        writebacks={{
          onWritebackSuccess: refreshChart,
        }}
        ehrContext={ehrContext}
      >
        <PatientProvider systemURL={effectiveSystemUrl} patientID={effectivePatientId}>
          <ZusAggregatedProfile />
        </PatientProvider>
      </CTWProvider>
    );
  }

  return <LoadingMessage />;
};

const ZAPWrapper = () => (
  <EhrFhirClientProvider>
    <ZusTokenProvider>
      <ZAP />
    </ZusTokenProvider>
  </EhrFhirClientProvider>
);

export default ZAPWrapper;
