import { Link } from '@ctw/shared/components/link';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { twx } from '@ctw/shared/utils/tailwind';
import { useMemo } from 'react';

interface SupportFormUrlOptions {
  patientId?: string;
  patientUpid?: string;
  builderId?: string;
  builderName?: string;
  email?: string;
  zusUserId?: string;
}

export const supportFormUrl = ({
  builderId,
  builderName,
  email,
  zusUserId,
}: SupportFormUrlOptions) => {
  const url = new URL('https://share.hsforms.com/10NmAPiu7Qd2OXbxAHnseiwcjai8');

  if (typeof builderId === 'string') {
    url.searchParams.append('builderid', builderId);
  }
  if (typeof builderName === 'string') {
    url.searchParams.append('buildername', builderName);
  }
  if (typeof email === 'string') {
    url.searchParams.append('email', email);
  }
  if (typeof zusUserId === 'string') {
    url.searchParams.append('userid', zusUserId);
  }

  return url;
};

interface SupportFormLinkProps {
  buttonText: string;
  className?: string;
}

export const SupportFormLink = ({
  buttonText,
  className = 'link text-sm',
}: SupportFormLinkProps) => {
  const { requestContext } = useCTW();
  const url = useMemo(
    () =>
      supportFormUrl({
        builderId: requestContext.builderId,
        builderName: requestContext.authTokenState.builderName,
        email: requestContext.authTokenState.email,
        zusUserId: requestContext.authTokenState.zusUserId,
      }),
    [
      requestContext.authTokenState.builderName,
      requestContext.authTokenState.email,
      requestContext.authTokenState.zusUserId,
      requestContext.builderId,
    ],
  );

  return (
    <Link
      href={url.toString()}
      className={twx(
        'focus:outline-4 focus:outline-primary-main focus:outline-offset-[3px] focus-visible:outline-4 focus-visible:outline-primary-main focus-visible:outline-offset-[3px]',
        className,
      )}
      target="_blank"
    >
      {buttonText}
    </Link>
  );
};
