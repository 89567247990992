import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { Coding } from 'fhir/r4';
import { getFormsConditionsUrl } from './urls';

interface AutoCompleteConditionsResponseData {
  conditionsList: Array<Coding> | undefined;
}

export const getAutoCompleteConditions = async (ctw: CTWState, searchTerm: string) => {
  const { authToken, builderId } = ctw.requestContext;
  const { data } = await ctw.ctwFetch<AutoCompleteConditionsResponseData>(
    `${getFormsConditionsUrl(ctw.requestContext.env)}?display=${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...(builderId && { 'Zus-Account': builderId }),
      },
    },
  );
  return data.conditionsList;
};
