import { Button } from '@ctw/shared/components/button';
import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { displayFilterItem } from '@ctw/shared/components/filter-bar/filter-bar-utils';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FilterBarTagPillProps = {
  filter: FilterItem;
  onRemove: () => void;
};

export function FilterBarTagPill({ filter, onRemove }: FilterBarTagPillProps) {
  return (
    <Button
      key={filter.key}
      type="button"
      variant="unstyled"
      className={twx(
        'relative flex h-10 w-min max-w-[15rem] cursor-pointer items-center whitespace-nowrap rounded border-0 border-transparent bg-divider-main px-3 py-2 text-background-inverse text-sm capitalize',
        filter.className,
        'space-x-2',
      )}
      onClick={onRemove}
    >
      <div>{displayFilterItem(filter, { active: true })}</div>
      <FontAwesomeIcon icon={faX} className={tw`h-3 w-3`} />
    </Button>
  );
}
