import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/helpers/status-badge';
import { tw } from '@ctw/shared/utils/tailwind';

type CareGapProps = {
  gap: CareGapModel;
};

export const CareGapSummary = ({ gap }: CareGapProps) => (
  <div
    data-testid="care-gap-summary"
    className={tw`group flex cursor-pointer flex-col space-y-2 text-sm hover:bg-background-hover`}
    role="button"
    tabIndex={0}
  >
    <div className={tw`px-3 py-1.5`}>
      <div className={tw`flex items-center justify-between gap-1`}>
        <div className={tw`font-normal text-xs`}>Last updated {gap.updatedDate}</div>
        <CareGapStatusBadge gap={gap} />
      </div>
      <div className={tw`font-medium group-hover:underline`}>{gap.title}</div>
      <div className={tw`truncate font-normal text-xs`}>{gap.latestReportReasons.join(' ')}</div>
    </div>
  </div>
);
