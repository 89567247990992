import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import type { TableColumn } from '@ctw/shared/components/table/table';
import {
  RenderWritebackStatusIcon,
  ResourceTitleColumn,
  type WritebackStatuses,
} from '@ctw/shared/content/resource/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientDiagnosticReportsColumns = (
  builderId: string,
  writebackStatuses: WritebackStatuses,
): Array<TableColumn<DiagnosticReportModel>> => [
  {
    width: '25px',
    render: (diagnostic) => (
      <div className={tw`flex flex-nowrap space-x-2`}>
        {RenderWritebackStatusIcon(
          writebackStatuses[diagnostic.id] ||
            (diagnostic.ownedByBuilder(builderId)
              ? { state: 'known-to-builder' }
              : { state: 'not-known-to-builder' }),
        )}
      </div>
    ),
  },
  {
    title: 'Diagnostic',
    widthPercent: 40,
    minWidth: 200,
    render: (diagnostic) => (
      <ResourceTitleColumn
        title={
          <div className={tw`flex items-center gap-2`}>
            <CopyToClipboard>{diagnostic.displayName}</CopyToClipboard>
          </div>
        }
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'dateDisplay',
    minWidth: 128,
  },
  {
    title: 'Details',
    minWidth: 200,
    className: 'details-cell',
    dataIndex: 'numResultsDisplay',
  },
];
