interface ZusLogoIconOnlyProps {
  width: number;
  height: number;
}
export const ZusLogoIconOnly = ({ width = 35, height = 35 }: ZusLogoIconOnlyProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <title>Zus Logo</title>
    <g transform="matrix(1,0,0,1,-13.5,1.5)">
      <g>
        <clipPath id="_clip1">
          <rect x="0" y="0" width="61.072" height="32" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g transform="matrix(1,0,0,1,16.1713,0)">
            <path
              d="M23.04,22.963L15.492,18.606L20.934,9.18C20.969,9.119 21.032,9.008 20.934,8.837C20.836,8.665 20.706,8.665 20.637,8.665L9.75,8.665L9.75,-0.052L20.637,-0.052C23.912,-0.052 26.844,1.64 28.482,4.476C30.12,7.312 30.12,10.698 28.482,13.534L23.04,22.961L23.04,22.963Z"
              fill="rgb(0,128,81)"
              fillRule="nonzero"
            />
            <path
              d="M19.807,32L9.021,32C5.745,32 2.813,30.308 1.176,27.472C-0.462,24.636 -0.462,21.25 1.176,18.414L6.618,8.987L14.164,13.344L8.722,22.77C8.686,22.831 8.623,22.942 8.722,23.113C8.82,23.285 8.95,23.285 9.019,23.285L19.805,23.285L19.805,32L19.807,32Z"
              fill="rgb(113,178,201)"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
