import type { Query } from '@tanstack/react-query';
import { RequestError } from './error';

type GraphQLResponseError = {
  status: number;
  errors: { [key: string]: string };
};

type GrapqhlRequestError = {
  query: string;
  variables: unknown;
};

export class ReactQueryError extends Error {
  public identifier = '';

  public otherQueryKeys: { [key: string]: unknown } = {};

  public responseErrors?: GraphQLResponseError = { status: 200, errors: {} };

  public requestErrors?: GrapqhlRequestError = { query: '', variables: {} };

  public stack: string | undefined;

  public constructor(query: Query, message?: string, options?: ErrorOptions) {
    super(message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = 'ReactQueryError';

    this.init(query);
  }

  public init(query: Query) {
    const [queryKey, ...rest] = query.queryKey;
    const keys = ReactQueryError.serializedQueryKey(rest);

    this.identifier = queryKey as string;
    this.otherQueryKeys = keys;

    const error = query.state.error as {
      request: { query: string; variables: unknown };
      response: GraphQLResponseError;
    } | null;

    if (error) {
      this.setRequestErrors(error.request);
      this.setResponseErrors(error.response);
    }
  }

  public setRequestErrors(request: { query: string; variables: unknown }) {
    this.requestErrors = request;
  }

  public setResponseErrors(response: { status: number; errors: { [key: string]: string } }) {
    this.responseErrors = response;
  }

  public static serializedQueryKey(keys: Array<unknown>) {
    const serializedObject: { [key: string]: unknown } = {};
    keys.forEach((key, index) => {
      try {
        // Try to serialize the key as JSON
        serializedObject[`query${index + 2}`] = JSON.stringify(key);
      } catch (_error) {
        if ((key as { constructor: { name: string } }).constructor.name) {
          // If the key can't be serialized as JSON, use its constructor name
          serializedObject[`query${index + 2}`] = (
            key as { constructor: { name: string } }
          ).constructor.name;
        }
      }
    });

    return serializedObject;
  }

  public toString() {
    return `${this.name}: ${this.identifier} - ${JSON.stringify(
      this.otherQueryKeys,
    )} - ${JSON.stringify(this.stack)}`;
  }
}
