import { HorizontalDivider } from '@ctw/shared/components/horizontal-divider';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { isEmpty, trim } from 'lodash-es';
import { type ReactNode, useMemo } from 'react';
import { onlyText } from 'react-children-utilities';

export const hasNonEmptyDefinitionListItems = (items: Array<DefinitionListItem>) =>
  items.filter((item) => !(isEmpty(trim(item.label)) || isEmpty(trim(onlyText(item.value)))))
    .length > 0;

export interface DefinitionListLabelAndValue {
  label: string;
  value: string | ReactNode;
  key?: never;
  separator?: never;
}

export interface DefinitionListSeparator {
  label?: never;
  value?: never;
  key: string;
  separator: boolean;
}

const isLabelAndValue = (item: DefinitionListItem): item is DefinitionListLabelAndValue =>
  Object.hasOwn(item, 'label') && Object.hasOwn(item, 'value');

export type DefinitionListItem = DefinitionListLabelAndValue | DefinitionListSeparator;

export interface DefinitionListProps {
  className?: ClassName;
  items: Array<DefinitionListItem>;
}

export const DefinitionList = ({ className, items }: DefinitionListProps) => {
  const nonEmptyItems = useMemo(
    () =>
      items.filter((item) => !(isEmpty(trim(item.label)) || isEmpty(trim(onlyText(item.value))))),
    [items],
  );

  if (nonEmptyItems.length === 0) {
    return <></>;
  }

  return (
    <dl className={twx('w-full space-y-1 py-2', className)}>
      {items.map((item) =>
        isLabelAndValue(item) ? (
          <div key={item.label} className={tw`flex w-full justify-between gap-2`}>
            <dt className={tw`block min-w-[40%] font-medium text-base text-content-main`}>
              {item.label}
            </dt>
            <dd className={tw`block flex-1 text-base text-content-main`}>{item.value}</dd>
          </div>
        ) : (
          <HorizontalDivider key={item.key} thickness="thick" className={tw`my-2`} />
        ),
      )}
    </dl>
  );
};
