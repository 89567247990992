import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { uniqueValues } from '@ctw/shared/utils/filters';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

export function careGapFilters(gaps: Array<CareGapModel> = []): Array<FilterItem> {
  const filters: Array<FilterItem> = [];
  const availableStatusNames = uniqueValues(gaps, 'status');

  if (availableStatusNames.length > 1) {
    filters.push({
      key: 'status',
      type: 'checkbox',
      icon: faUser,
      values: availableStatusNames,
      display: 'status',
    });
  }

  return filters;
}
