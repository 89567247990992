import { type Breakpoint, breakpoints } from '@ctw/config/zui';
import { useWindowSize } from '@uidotdev/usehooks';
import { type RefObject, useLayoutEffect, useMemo, useState } from 'react';

export interface BreakpointQueries {
  isAtLeast: Record<Breakpoint, boolean>;
  isAtMost: Record<Breakpoint, boolean>;
  is: Record<Breakpoint, boolean>;
}

interface UseContainerQueryResult {
  width: number;
  breakpoint: Breakpoint;
  breakpoints: BreakpointQueries;
}

interface UseContainerQueryOptions {
  containerRef: RefObject<Element | null>;
}

/*
 * Given a ref, this hook provides the current width of the element and the result of min and max
 * breakpoint queries across our breakpoints. Note that by default the max queries are inclusive of
 * the upper bounds of the breakpoint's range.
 */
export const useContainerQuery = ({
  containerRef,
}: UseContainerQueryOptions): UseContainerQueryResult => {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (containerRef.current) {
        const dimensions = containerRef.current.getBoundingClientRect();
        setWidth(dimensions.width);
      }
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => observer.disconnect();
  }, [containerRef]);

  return useMemo(
    () => ({
      width,
      breakpoint: Object.entries(breakpoints).find(
        ([_breakpoint, value]) => width >= value.min && width <= value.max,
      )?.[0] as Breakpoint,
      breakpoints: {
        isAtLeast: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [name, width >= value.min]),
        ) as Record<Breakpoint, boolean>,
        isAtMost: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [name, width <= value.max]),
        ) as Record<Breakpoint, boolean>,
        is: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [
            name,
            width >= value.min && width <= value.max,
          ]),
        ) as Record<Breakpoint, boolean>,
      },
    }),
    [width],
  );
};

interface UseBreakpointQueryResult {
  width: number;
  breakpoint: Breakpoint;
  breakpoints: BreakpointQueries;
}

/*
 * Returns the effective breakpoints based on the current window width.
 */
export const useBreakpointQuery = (): UseBreakpointQueryResult => {
  const { width } = useWindowSize();

  return useMemo(
    () => ({
      width: width ?? 0,
      breakpoint: Object.entries(breakpoints).find(
        ([_breakpoint, value]) => (width ?? 0) >= value.min && (width ?? 0) <= value.max,
      )?.[0] as Breakpoint,
      breakpoints: {
        isAtLeast: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [name, (width ?? 0) >= value.min]),
        ) as Record<Breakpoint, boolean>,
        isAtMost: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [name, (width ?? 0) <= value.max]),
        ) as Record<Breakpoint, boolean>,
        is: Object.fromEntries(
          Object.entries(breakpoints).map(([name, value]) => [
            name,
            (width ?? 0) >= value.min && (width ?? 0) <= value.max,
          ]),
        ) as Record<Breakpoint, boolean>,
      },
    }),
    [width],
  );
};
