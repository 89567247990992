import { ActionDropdown } from '@ctw/shared/components/dropdown/action-dropdown';
import type {
  FilterOptionCheckbox,
  FilterValues,
} from '@ctw/shared/components/filter-bar/filter-bar-types';
import { displayFilterItem } from '@ctw/shared/components/filter-bar/filter-bar-utils';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronDown, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, isString, orderBy } from 'lodash-es';

type FilterBarCheckboxPillProps = {
  filter: FilterOptionCheckbox;
  filterValues: FilterValues;
  isOpen: boolean;
  onChange: (key: string, isSelected: boolean) => void;
  onRemove?: () => void;
};

const buttonClassName =
  'flex items-center max-w-[15rem] space-x-2 capitalize text-background-inverse bg-divider-main text-sm rounded py-2 px-3 relative cursor-pointer border-0 border-transparent whitespace-nowrap h-10 w-min';

export function FilterBarCheckboxPill({
  filter,
  filterValues,
  isOpen,
  onRemove,
  onChange,
}: FilterBarCheckboxPillProps) {
  const selected = filterValues ?? [];
  const items = orderBy(
    filter.values.map((item) => ({
      key: isString(item) ? item : item.key,
      name: isString(item) ? item : item.name,
      display: isString(item) ? undefined : item.display,
      isSelected: selected.includes(isString(item) ? item : item.key),
    })),
    ['display', 'name'],
    ['asc', 'asc'],
  );

  const selectedItems = items
    .filter((item) => item.isSelected)
    .map((item) => item.display || item.name || item.key);

  return (
    <ActionDropdown
      className={tw`max-h-[17.25rem] overflow-y-auto`}
      isOpen={isOpen}
      items={items}
      type="checkbox"
      pinnedActions={compact([
        onRemove
          ? {
              icon: faTrash,
              name: 'Remove Filter',
              action: onRemove,
            }
          : null,
      ])}
      buttonClassName={twx(filter.className, buttonClassName)}
      onItemSelect={(item) => onChange(item.key, item.value)}
    >
      <div className={tw`truncate font-medium text-background-inverse`}>
        {displayFilterItem(filter, { active: true })}
        {selectedItems.length > 0 && (
          <span className={tw`font-normal`}>: {selectedItems.join(', ')}</span>
        )}
      </div>
      <FontAwesomeIcon icon={faChevronDown} className={tw`h-3 w-3`} />
    </ActionDropdown>
  );
}
