import { getEHRIntegrationServiceBaseUrl } from '@ctw/shared/api/urls';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { QUERY_KEY_EHR_BACKFILL_REQUEST } from '@ctw/shared/utils/query-keys';
import type { UseQueryResult } from '@tanstack/react-query';

type BackfillRequestApiResponse = {
  data: {
    type: 'backfill-request';
    id: string;
    attributes: {
      status: string;
    };
  };
  meta: {
    traceId: string;
  };
};

export function useTriggerBackfillRequest(
  patientIdentifierSystemURL: string | undefined,
  patientIdentifierValue: string | undefined,
): UseQueryResult<BackfillRequestApiResponse> {
  return useCtwQuery({
    queryId: QUERY_KEY_EHR_BACKFILL_REQUEST,
    queryKey: [patientIdentifierSystemURL, patientIdentifierValue],
    queryFn: async ({ requestContext, ctwFetch }) => {
      const { data } = (await ctwFetch(
        `${getEHRIntegrationServiceBaseUrl(requestContext.env)}/backfill-request`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${requestContext.authToken}`,
          },
          body: JSON.stringify({
            data: {
              type: 'backfill-request',
              attributes: {
                ignoreRateLimit: false,
              },
              relationships: {
                builder: {
                  data: {
                    type: 'auth/builder',
                    id: requestContext.builderId,
                  },
                },
                resources: {
                  data: [
                    {
                      type: 'fhir/Patient',
                      identifierSystemURL: patientIdentifierSystemURL,
                      identifierValue: patientIdentifierValue,
                    },
                  ],
                },
              },
            },
          }),
        },
      )) as { data: BackfillRequestApiResponse };

      return data;
    },
    enabled: Boolean(patientIdentifierSystemURL) && Boolean(patientIdentifierValue),
  });
}
