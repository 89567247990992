import {
  bmiCodes,
  bpDiastolicCodes,
  bpSystolicCodes,
  headCircumferenceCodes,
  heartRateCodes,
  heightCodes,
  lmpCodes,
  o2Codes,
  painCodes,
  respiratoryRateCodes,
  tempCodes,
  weightCodes,
} from '@ctw/shared/content/vitals/vital-loinc-codes';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { sort } from '@ctw/shared/utils/sort';
import type { Quantity } from 'fhir/r4';
import { round } from 'lodash-es';
import { hasMatchingCode } from '../codeable-concept';
import { SYSTEM_LOINC } from '../system-urls';
import type { EncounterModel } from './encounter';
import type { ObservationModel } from './observation';

export class VitalsBucket {
  private vitals: Array<ObservationModel>;

  private readonly builderId: string;

  private readonly encounter?: EncounterModel;

  public constructor(
    vitals: Array<ObservationModel>,
    builderId: string,
    encounter?: EncounterModel,
  ) {
    this.vitals = vitals;
    this.builderId = builderId;
    this.encounter = encounter;
  }

  public get key() {
    return this.encounter?.id || this.vitals[0]?.id || '';
  }

  public get hasAnyFirstParty() {
    return !!(
      this.bmi?.firstParty ||
      this.bloodPressure?.firstParty ||
      this.headCircumference?.firstParty ||
      this.height?.firstParty ||
      this.lmp?.firstParty ||
      this.oxygenSaturation?.firstParty ||
      this.pain?.firstParty ||
      this.pulse?.firstParty ||
      this.respiratoryRate?.firstParty ||
      this.temperature?.firstParty ||
      this.weight?.firstParty
    );
  }

  public get date() {
    return this.dateTime ? formatFHIRDate(this.dateTime) : undefined;
  }

  // Our dateTime is based on the encounter date, if available, or the first vital date.
  public get dateTime() {
    if (this.encounter) {
      return this.encounter.resource.period?.start;
    }

    return this.vitals[0].date;
  }

  public get binaryId() {
    if (this.encounter && this.encounter.docsAndNotes.length > 0) {
      return this.encounter.docsAndNotes[0].binaryId;
    }

    return this.vitals.find((v) => v.binaryId)?.binaryId;
  }

  public get binaryIdSourceModel() {
    if (this.encounter && this.encounter.docsAndNotes.length > 0) {
      return this.encounter;
    }

    return this.vitals.find((v) => v.binaryId);
  }

  public get bmi() {
    return this.getVitalDisplay(bmiCodes);
  }

  public get bloodPressure() {
    const systolic = this.getVitalDisplay(bpSystolicCodes, { precision: 0 });
    const diastolic = this.getVitalDisplay(bpDiastolicCodes, { precision: 0 });

    if (systolic || diastolic) {
      return {
        display: `${systolic?.display || ''} / ${diastolic?.display || ''}`,
        firstParty: (systolic?.firstParty || diastolic?.firstParty) ?? false,
      };
    }
    return undefined;
  }

  public get headCircumference() {
    return this.getVitalDisplay(headCircumferenceCodes, { precision: 1, unit: true });
  }

  public get height() {
    return this.getVitalDisplay(heightCodes, { precision: 1, unit: true });
  }

  // Special key for filtering. See utils/filters.ts.
  public get isHidden() {
    return !!this.encounter?.isHospitalEncounter;
  }

  public get lmp() {
    return this.getVitalDisplay(lmpCodes, { precision: 0 });
  }

  public get location() {
    return this.encounter?.location.join(', ');
  }

  public get oxygenSaturation() {
    return this.getVitalDisplay(o2Codes, { precision: 1 });
  }

  public get pain() {
    return this.getVitalDisplay(painCodes, { precision: 0 });
  }

  public get pulse() {
    return this.getVitalDisplay(heartRateCodes, { precision: 0 });
  }

  public get respiratoryRate() {
    return this.getVitalDisplay(respiratoryRateCodes, { precision: 0 });
  }

  public get temperature() {
    return this.getVitalDisplay(tempCodes, { precision: 1, unit: true });
  }

  public get typeDisplay() {
    return this.encounter?.typeDisplay;
  }

  public get weight() {
    return this.getVitalDisplay(weightCodes, { precision: 1, unit: true });
  }

  private getVitalDisplay(
    codes: Array<string>,
    options?: { precision?: number; unit?: boolean },
  ): { display: string; firstParty: boolean } | undefined {
    const matchingObservationsDesc = sort(
      this.vitals.filter(
        (v) =>
          hasMatchingCode(SYSTEM_LOINC, codes, v.resource.code) &&
          (v.value !== '' || !!v.resource.valueQuantity?.value),
      ),
      (v) => v.date,
      'desc',
      true,
    );

    if (matchingObservationsDesc.length === 0) {
      return undefined;
    }

    // most recent 1st party vital, else most recent 3rd party
    const firstPartyObservationMaybe = matchingObservationsDesc.find((v) =>
      v.ownedByBuilder(this.builderId),
    );
    const observation = firstPartyObservationMaybe ?? matchingObservationsDesc[0];
    const isFirstParty = observation.ownedByBuilder(this.builderId);

    const quantity = observation.resource.valueQuantity;
    let quantityDisplay: string | undefined;
    if (quantity) {
      quantityDisplay = quantityToDisplay(quantity, options);
    }

    return { display: quantityDisplay ?? observation.value, firstParty: isFirstParty };
  }
}

function withUnit(value: number | undefined, unit: string | undefined): string {
  if (value !== undefined && unit !== undefined) {
    return `${value} ${unit}`;
  }

  return String(value);
}

function quantityToDisplay(quantity?: Quantity, options?: { precision?: number; unit?: boolean }) {
  if (quantity?.value === undefined) {
    return undefined;
  }
  let { value } = quantity;
  if (options?.precision !== undefined) {
    value = round(value, options.precision);
  }
  return withUnit(value, options?.unit ? quantity.unit : undefined);
}
