import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { ViewDocument } from '@ctw/shared/content/CCDA/view-document';
import { useModal } from '@ctw/shared/context/modal-context';
import { tw } from '@ctw/shared/utils/tailwind';

export function useCCDAModal(patient?: PatientModel) {
  const { openModal, closeModal } = useModal({
    boundaryName: 'CCDAModal',
    layout: {
      mode: 'full',
      overflowBehavior: 'scroll',
      fullbleed: true,
      showCloseButton: false,
    },
  });

  return (binaryId: string, title: string) => {
    openModal({
      content: {
        body: (
          <div className={tw`w-full items-center`}>
            <ViewDocument
              patient={patient}
              binaryId={binaryId}
              fileName={title}
              onClose={closeModal}
            />
          </div>
        ),
      },
    });
  };
}
