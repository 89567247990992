import { codeableConceptLabel } from '@ctw/shared/api/fhir/codeable-concept';
import type { Medication } from '@ctw/shared/api/fhir/medication';
import { getPerformingOrganization } from '@ctw/shared/api/fhir/medication';
import { MedicationAdministrationModel } from '@ctw/shared/api/fhir/models/medication-administration';
import { MedicationDispenseModel } from '@ctw/shared/api/fhir/models/medication-dispense';
import { MedicationRequestModel } from '@ctw/shared/api/fhir/models/medication-request';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { findReference } from '@ctw/shared/api/fhir/resource-helper';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';

export class MedicationModel extends FHIRModel<Medication> {
  public kind = 'Medication' as const;

  public get performer(): string | undefined {
    return getPerformingOrganization(this.resource, this.includedResources)?.name;
  }

  public get status(): string {
    return this.resource.status;
  }

  public get dosage(): string | undefined {
    switch (this.resource.resourceType) {
      case 'MedicationStatement':
        return this.resource.dosage?.[0]?.text;
      case 'MedicationAdministration':
        return new MedicationAdministrationModel(this.resource, this.includedResources)
          .dosageDisplay;
      case 'MedicationDispense':
      case 'MedicationRequest':
        return codeableConceptLabel(this.resource.dosageInstruction?.[0]);
      default:
        return '';
    }
  }

  public isPrescription(): boolean {
    return this.resource.resourceType === 'MedicationRequest' && !this.resource.reportedBoolean;
  }

  public get date(): string | undefined {
    switch (this.resource.resourceType) {
      case 'MedicationStatement':
        return this.resource.dateAsserted ?? this.resource.effectivePeriod?.start;
      case 'MedicationAdministration':
        return this.resource.effectivePeriod?.start;
      case 'MedicationDispense':
        return this.resource.whenHandedOver ?? this.resource.whenPrepared;
      case 'MedicationRequest':
        return (
          this.resource.authoredOn ??
          this.resource.dosageInstruction?.[0].timing?.repeat?.boundsPeriod?.start
        );
      default:
        return '';
    }
  }

  public get dateDisplay(): string {
    return formatFHIRDate(this.date ?? '') ?? '';
  }

  public get medicationCodeableConceptText(): string | undefined {
    const concept = this.resource.medicationCodeableConcept;
    if (!concept) {
      return undefined;
    }
    return concept.text || concept.coding?.[0]?.display;
  }

  public get patient(): PatientModel | undefined {
    const reference = findReference(
      'Patient',
      this.resource.contained,
      this.includedResources,
      this.resource.subject,
    );

    if (reference) {
      return new PatientModel(reference, this.includedResources);
    }

    return undefined;
  }

  /**
   * This accessor will try to get the prescriber for the underlying medication
   * models resource. Depending on the type of fhir resource, it will delegate
   * the work to a more specific fhir/model/*.ts class before simply grabbing
   * the `display` property from an actor/performer/requester. If all else
   * should fail, the accessor returns an empty string.
   */
  public get prescriber(): string | undefined {
    switch (this.resource.resourceType) {
      case 'MedicationStatement':
        return new MedicationStatementModel(this.resource, this.includedResources).lastPrescriber;
      case 'MedicationDispense':
        return new MedicationDispenseModel(this.resource, this.includedResources).includedPerformer;
      case 'MedicationRequest':
        return new MedicationRequestModel(this.resource, this.includedResources).includedRequester;
      default:
        return undefined;
    }
  }

  public get title() {
    switch (this.resource.resourceType) {
      case 'MedicationStatement':
        return new MedicationStatementModel(this.resource, this.includedResources).title;
      case 'MedicationAdministration':
        return new MedicationAdministrationModel(this.resource, this.includedResources).title;
      case 'MedicationDispense':
        return new MedicationDispenseModel(this.resource, this.includedResources).title;
      case 'MedicationRequest':
        return new MedicationRequestModel(this.resource, this.includedResources).title;
      default:
        return 'Unknown Medication';
    }
  }
}
