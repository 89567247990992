import { type RouterOptions, useZuiContext } from '@ctw/shared/context/zui-provider';
import { type ClassName, twx } from '@ctw/shared/utils/tailwind';
import { type PropsWithChildren, type Ref, useMemo } from 'react';

const isExternalHref = (href: string): boolean => {
  try {
    const url = new URL(href);

    if (url.hostname !== window.location.hostname) {
      return true;
    }
  } catch (_error) {
    /* pass */
  }

  return false;
};

const anchorElementClassName =
  'rounded-md fill-primary-text text-primary-text no-underline hover:underline focus:no-underline cursor-pointer font-sans font-medium focus-visible:outline-4 focus-visible:outline-offset-[3px] focus-visible:outline-primary-main active:scale-[.98]';

export interface LinkProps extends PropsWithChildren {
  ref?: Ref<HTMLAnchorElement>;
  href: string;
  rel?: HTMLAnchorElement['target'];
  target?: '_self' | '_blank' | '_parent' | '_top' | '_unfencedTop';
  dataTestId?: string;
  routerOptions?: RouterOptions;
  className?: ClassName;
  onClick?: () => void;
}

export const Link = ({
  ref,
  href: rawHref,
  target,
  routerOptions,
  className,
  dataTestId,
  onClick,
  children,
}: LinkProps) => {
  const { navigate, pathname } = useZuiContext();
  const href = useMemo(
    () =>
      rawHref.startsWith('..')
        ? new URL(rawHref, `${window.location.origin}${pathname}`).pathname
        : rawHref,
    [pathname, rawHref],
  );
  const isExternal = useMemo(() => isExternalHref(href), [href]);

  if (isExternal) {
    return (
      <a
        ref={ref}
        onClick={onClick}
        data-testid={dataTestId}
        href={href}
        target={target}
        rel="noreferrer noopener"
        className={twx(anchorElementClassName, className)}
      >
        {children}
      </a>
    );
  }

  return (
    <a
      ref={ref}
      data-testid={dataTestId}
      onClick={(event) => {
        onClick?.();
        if (target !== '_blank') {
          event.preventDefault();
          navigate(href, routerOptions);
        }
      }}
      href={href}
      target={target}
      className={twx(anchorElementClassName, className)}
    >
      {children}
    </a>
  );
};
