import type { SubNavigationLink } from '@ctw/shared/components/layout/sub-navigation-bar';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { hrefIsActive } from '@ctw/shared/utils/url';
import { type PropsWithChildren, createContext, useContext, useMemo } from 'react';

interface PatientSubNavigationState {
  links: Array<SubNavigationLink>;
  activeLink: SubNavigationLink | null;
}

const PatientSubNavigationContext = createContext<PatientSubNavigationState | null>(null);

interface PatientSubNavigationProviderProps extends PropsWithChildren {
  subNavigationLinks: Array<SubNavigationLink>;
}

export const PatientSubNavigationProvider = ({
  children,
  subNavigationLinks,
}: PatientSubNavigationProviderProps) => {
  const { pathname, isNavigating } = useZuiContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const activeLink = useMemo(
    () =>
      subNavigationLinks.find(
        (link) =>
          hrefIsActive({ pathname, href: link.href }) ||
          link.siblingHrefs?.some((siblingHref) => hrefIsActive({ pathname, href: siblingHref })),
      ) ?? null,
    [isNavigating, pathname, subNavigationLinks],
  );

  const state: PatientSubNavigationState = useMemo(
    () => ({
      links: subNavigationLinks,
      activeLink,
    }),
    [subNavigationLinks, activeLink],
  );
  return (
    <PatientSubNavigationContext.Provider value={state}>
      {children}
    </PatientSubNavigationContext.Provider>
  );
};

export const usePatientSubNavigation = () => {
  const context = useContext(PatientSubNavigationContext);

  if (!context) {
    throw new Error('usePatientSubNavigation must be used within a PatientSubNavigationProvider');
  }

  return context;
};
