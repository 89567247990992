import type { Citation } from '@ctw/shared/api/ai/types';
import { tw } from '@ctw/shared/utils/tailwind';
import type { KeyboardEvent } from 'react';

export type CitationComponentProps = {
  citation: Citation;
  openDetails?: () => void;
};

export const CitationComponent = ({ citation, openDetails }: CitationComponentProps) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      openDetails?.();
    }
  };

  return (
    <span
      className={tw`flex-shrink-0 cursor-pointer align-super text-primary-text text-xs`}
      onClick={() => {
        openDetails?.();
      }}
      onKeyDown={handleKeyDown}
    >
      [{citation.id}]
    </span>
  );
};
