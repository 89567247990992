import { getHumanName } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { LabelValueType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { isEmpty } from 'lodash-es';
import xpath from 'xpath';

export const getGuardian = (patientRole: Document): Array<LabelValueType> | undefined => {
  const guardians = xpath.select(
    "*[name()='patient']/*[name()='guardian']",
    patientRole,
  ) as Array<Document>;

  if (isEmpty(guardians)) {
    return undefined;
  }

  return guardians.map((guardian) => {
    const guardianPerson = xpath.select1("*[name()='guardianPerson']", guardian) as Document;

    const relationship = String(xpath.select1("string(*[name()='code']/@displayName)", guardian));
    const name = getHumanName(xpath.select("*[name()='name']", guardianPerson) as Array<Document>);

    const withRelationship = relationship ? ` - ${relationship}` : '';
    return {
      label: '',
      value: `${name}${withRelationship}`,
    };
  });
};
