import { oauth2 } from 'fhirclient-pkce';
import type Client from 'fhirclient-pkce/lib/Client';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ErrorMessage from '../ErrorMessage';
import LoadingMessage from '../LoadingMessage';

interface EhrFhirClientContextProps {
  client?: Client;
}

const defaultContextValue: EhrFhirClientContextProps = {};

export const EhrFhirClientContext = createContext<EhrFhirClientContextProps>(defaultContextValue);

export const EhrFhirClientProvider = ({ children }: PropsWithChildren<object>) => {
  const [client, setClient] = useState<Client>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    oauth2
      .ready()
      .then((c) => {
        setClient(c);
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  const contextValue = useMemo(() => ({ client }), [client]);

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  if (!client) {
    return <LoadingMessage />;
  }

  return (
    <EhrFhirClientContext.Provider value={contextValue}>{children}</EhrFhirClientContext.Provider>
  );
};

export const useEhrFhirClient = () => useContext(EhrFhirClientContext);
