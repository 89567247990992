import { usePatientImmunizations } from '@ctw/shared/api/fhir/immunizations';
import type { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import type { TableColumn } from '@ctw/shared/components/table/table';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { dismissFilter } from '@ctw/shared/content/resource/filters';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/resource-title-column';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useImmunizationDrawer } from '@ctw/shared/hooks/use-immunization-drawer';
import { useMemo } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';

export const PatientImmunizations = withErrorBoundary({
  boundaryName: 'PatientImmunizations',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { openDrawer } = useImmunizationDrawer();
    const patientImmunizationsQuery = usePatientImmunizations();
    const validFilters = useMemo(() => immunizationsFilter(), []);

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-immunizations',
      defaultSort: defaultImmunizationSort,
      records: patientImmunizationsQuery.data,
    });
    const isEmptyQuery = patientImmunizationsQuery.data?.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: immunizationSortOptions,
          onChange: setSort,
        }}
      />,
      [validFilters, filters, defaultSort, immunizationSortOptions],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={patientImmunizationsQuery.isLoading}
          data={data}
          columns={patientImmunizationsColumns(requestContext.builderId)}
          onRowClick={(model) => openDrawer({ model })}
          enableDismissAndReadActions={true}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="immunizations"
            />
          }
        />
      </div>
    );
  },
});

const defaultImmunizationSort: SortOption<ImmunizationModel> = {
  display: 'Name (A-Z)',
  sorts: [
    { key: 'description', dir: 'asc' },
    { key: 'occurrence', dir: 'desc', isDate: true },
  ],
};

const immunizationSortOptions: Array<SortOption<ImmunizationModel>> = [
  defaultImmunizationSort,
  {
    display: 'Name (Z-A)',
    sorts: [
      { key: 'description', dir: 'desc' },
      { key: 'occurrence', dir: 'desc', isDate: true },
    ],
  },
  {
    display: 'Date (New to Old)',
    sorts: [{ key: 'occurrence', dir: 'desc', isDate: true }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'occurrence', dir: 'asc', isDate: true }],
  },
];

function immunizationsFilter(): Array<FilterItem> {
  return [dismissFilter];
}

const patientImmunizationsColumns = (builderId: string) => {
  const immunizationColumns: Array<TableColumn<ImmunizationModel>> = [
    {
      title: 'Immunization',
      widthPercent: 60,
      minWidth: 320,
      render: (immunization) => (
        <ResourceTitleColumn
          title={<CopyToClipboard>{immunization.description}</CopyToClipboard>}
          renderIcon={RenderSyncedWithRecordIcon(immunization.ownedByBuilder(builderId))}
        />
      ),
    },
    {
      title: 'Date Given',
      dataIndex: 'occurrence',
    },
  ];

  return immunizationColumns;
};
