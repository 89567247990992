import { fixupFHIR } from '@ctw/shared/api/fhir/data-helper/fixup-fhir';
import { longPollFQS } from '@ctw/shared/api/fqs/long-poll-fqs';
import { getZusApiBaseUrl } from '@ctw/shared/api/urls';
import type { CTWState } from '@ctw/shared/context/ctw-context';
import type { Resource } from 'fhir/r4';
import { isFHIRDomainResource } from './types';

type ResourceSaveOperation = 'update' | 'create' | 'delete';
export type OnResourceSaveCallback = (
  resource: Resource,
  action: ResourceSaveOperation,
  error?: Error,
) => void;

export async function createOrEditFhirResource(
  resource: Resource,
  { ctwFetch, graphqlClient, onResourceSave, requestContext }: CTWState,
) {
  const updating = !!resource.id;
  let response: Resource | undefined;

  const { data } = await ctwFetch(
    updating
      ? `${getZusApiBaseUrl(requestContext.env)}/fhir/${resource.resourceType}/${resource.id}`
      : `${getZusApiBaseUrl(requestContext.env)}/fhir/${resource.resourceType}`,
    {
      method: updating ? 'PUT' : 'POST',
      body: JSON.stringify(fixupFHIR(resource)),
      headers: {
        authorization: `Bearer ${requestContext.authToken}`,
        'content-type': 'application/json',
        'zus-account': requestContext.builderId,
      },
    },
  );
  response = data as Resource;
  onResourceSave(response, updating ? 'update' : 'create');

  // Read-Your-Writes!
  // Wait for FQS to have the latest version of the resource.
  // This way callers can safely refetch/invalidateQueries
  // and be sure to get fresh data from FQS.
  if (
    resource.resourceType !== 'Basic' &&
    isFHIRDomainResource(response) &&
    response.id &&
    response.meta?.lastUpdated
  ) {
    await longPollFQS(graphqlClient, resource.resourceType, response.id, response.meta.lastUpdated);
  }

  return response;
}

export async function deleteFhirResource(
  resource: Resource,
  { ctwFetch, requestContext, onResourceSave }: CTWState,
) {
  if (!resource.id) {
    throw new Error('Unable to delete resource without an id');
  }

  await ctwFetch(
    `${getZusApiBaseUrl(requestContext.env)}/fhir/${resource.resourceType}/${resource.id}`,
    {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${requestContext.authToken}`,
      },
    },
  );

  onResourceSave(resource, 'delete');
}
