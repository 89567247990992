import type { Activity } from '@ctw/shared/api/fhir/models/care-gap';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Label, Radio, RadioGroup, Select, Textarea } from '@headlessui/react';

const NOTE_MAX_LENGTH = 500;

type ActivityRadioGroupProps = {
  activity: Activity | undefined;
  onChange: (activity: Activity) => void;
};

const activityTypesSorted: Array<Activity> = [
  'Patient Outreach',
  'Documentation',
  'Order or Referral',
  'Other',
  'Appointment',
];

export const ActivityRadioGroup = ({ activity, onChange }: ActivityRadioGroupProps) => (
  <div className={tw`space-y-4`}>
    <div className={tw`font-medium`}>
      <label htmlFor="activity" className={tw`initial`}>
        Choose an activity (required)
      </label>
    </div>
    <RadioGroup value={activity || 'empty'} onChange={onChange}>
      <div className={twx('grid grid-cols-1 gap-4 md:grid-cols-2')}>
        {['', ...activityTypesSorted].map((value) => (
          <Field
            key={value}
            className={twx('flex items-center gap-2', {
              // We hide the empty value because we want this radio to appear empty initially but to always be a controlled component.
              hidden: value === '',
            })}
          >
            <Radio
              value={value}
              className={tw`group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-primary-main`}
            >
              <span className={tw`size-2 rounded-full bg-white`} />
            </Radio>
            <Label>{value}</Label>
          </Field>
        ))}
      </div>
    </RadioGroup>
  </div>
);

type NoteTextareaProps = {
  note: string;
  onChange: (note: string) => void;
};

export const NoteTextarea = ({ note, onChange }: NoteTextareaProps) => (
  <div className={tw`space-y-2`}>
    <div className={tw`font-medium`}>
      <label htmlFor="note" className={tw`initial`}>
        Add a note (required)
      </label>
    </div>
    <Textarea
      name="note"
      className={tw`listbox-textarea min-h-[5rem] w-full whitespace-pre-wrap bg-white`}
      value={note}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Leave a note"
      maxLength={NOTE_MAX_LENGTH}
    />
    {note.length > 0 && (
      <p className={tw`text-gray-500 text-sm`}>
        {NOTE_MAX_LENGTH - note.length} characters remaining
      </p>
    )}
  </div>
);

type StatusDropdownProps = {
  isStatusLocked: boolean;
  zusStatus: string;
  zusStatusOptions: Array<string>;
  onStatusChange: (status: string) => void;
};

export const StatusDropdown = ({
  isStatusLocked,
  zusStatus,
  zusStatusOptions,
  onStatusChange,
}: StatusDropdownProps) => (
  <div className={tw`space-y-2`}>
    {!isStatusLocked && <label htmlFor="zusStatus">Update current status (optional)</label>}

    <div className={tw`flex items-center justify-between`}>
      <Select
        name="status"
        data-testid="activity-status-select"
        className={tw`flex w-[15rem] cursor-pointer whitespace-pre-wrap rounded-md border-transparent border-r-8 bg-white px-3 py-2 text-background-inverse text-sm capitalize outline outline-1 outline-border-main ring-background-subtle data-[focus]:bg-primary-main data-[hover]:shadow ${isStatusLocked ? 'cursor-not-allowed opacity-60' : ''}`}
        value={zusStatus}
        onChange={(e) => onStatusChange(zusStatusOptions[e.target.selectedIndex])}
        disabled={isStatusLocked}
      >
        {zusStatusOptions.map((option) => (
          <option className={tw`bg-white p-3 font-bold text-md`} key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
      {isStatusLocked && (
        <Tooltip
          content="Updating status is locked because the gap is closed or excluded"
          noArrow={true}
        >
          <FontAwesomeIcon icon={faLock} className={tw`text-content-icon`} />
        </Tooltip>
      )}
    </div>
  </div>
);
