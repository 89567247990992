import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import type { Resource } from 'fhir/r4';

export const THIRD_PARTY_SOURCE_SYSTEM = 'https://zusapi.com/thirdparty/source';

const isFromThirdParties = (doc: Resource, thirdParties: Array<string>): boolean => {
  const thirdPartyTag = doc.meta?.tag?.find((tag) => tag.system === THIRD_PARTY_SOURCE_SYSTEM);
  return thirdParties.includes(thirdPartyTag?.code || '');
};

export const isRenderableBinary = (doc: Resource): boolean =>
  isFromThirdParties(doc, ['commonwell', 'carequality']);

export const dismissFilter: FilterItem = {
  key: 'showHidden', // Special key for filtering. See utils/filters.ts.
  type: 'tag',
  belowDivider: true,
  display: ({ listView }) => (listView ? 'show dismissed records' : 'dismissed records'),
  icon: faEye,
  toggleDisplay: 'hide dismissed records',
  toggleIcon: faEyeSlash,
};
