'use client';

import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { Telemetry } from '@ctw/shared/context/telemetry';
import { type PropsWithChildren, useLayoutEffect, useState } from 'react';

interface TelemetryProviderProps extends PropsWithChildren {}

export const TelemetryInitializer = ({ children }: TelemetryProviderProps) => {
  const { requestContext } = useCTW();

  useLayoutEffect(() => {
    void Telemetry.init({
      requestContext,
    });
  }, [requestContext]);

  // If the telemetry initialization takes too long, stop waiting and continue rendering
  const [initTimeoutExceeded, setInitTimeoutExceeded] = useState(false);
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setInitTimeoutExceeded(true);
    }, 2000);

    return () => clearTimeout(timeout); // Clean up the timeout if the component unmounts
  }, []);

  if (!(Telemetry.isInitialized || initTimeoutExceeded)) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  return children;
};
