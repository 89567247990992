import type { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { useBinaries } from '@ctw/shared/api/fqs-rest/binaries';
import { Badge } from '@ctw/shared/components/badge';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList, type DefinitionListItem } from '@ctw/shared/components/definition-list';
import { Heading } from '@ctw/shared/components/heading';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { ViewCCDAButton } from '@ctw/shared/content/document/view-ccda-button';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize, compact } from 'lodash-es';
import { useCallback } from 'react';

interface RelatedDocumentsProps {
  model: ReferralModel;
}

const RelatedDocuments = ({ model }: RelatedDocumentsProps) => {
  const binariesQuery = useBinaries(
    compact(model.encounter?.docsAndNotes.map((doc) => doc.binaryId)),
  );

  if (binariesQuery.isLoading) {
    return (
      <div className={tw`flex w-full justify-center`}>
        <LoadingSpinner className={tw`text-content-icon`} message="Loading related documents..." />
      </div>
    );
  }

  if (binariesQuery.data === undefined || binariesQuery.data.length === 0) {
    return <></>;
  }

  return (
    <div className={tw`space-y-4`}>
      <Heading level="h3">Documents</Heading>
      {binariesQuery.data.map((binary) => (
        <ViewCCDAButton
          ccdaTitle={
            model.encounter?.docsAndNotes.find((doc) => doc.binaryId === binary.id)?.title ||
            'Related Encounter Document'
          }
          key={binary.id}
          binary={binary}
          patient={model.patient}
          preferred={false}
          overrideDateDisplay={model.completedAtDisplay}
        />
      ))}
    </div>
  );
};

export const useReferralDrawer = () => {
  const detailItems = useCallback(
    (model: ReferralModel) =>
      compact<DefinitionListItem>([
        {
          label: 'Status',
          value: (
            <Badge
              variant={model.status === 'fulfilled' ? 'muted' : 'primary'}
              content={capitalize(model.status)}
            />
          ),
        },
        { label: 'Reason', value: model.reason },
        {
          label: 'Referred On',
          value: model.referredAtDisplay,
        },
        {
          label: 'Referred By',
          value: (
            <div className={tw`leading-tight`}>
              {model.referringPractitionerName ?? ''}
              {model.referringPracticeName && (
                <>
                  {model.referringPractitionerName && <br />}
                  {model.referringPracticeName}
                </>
              )}
            </div>
          ),
        },
        ...model.referredPractitioners.map((referredPractitioner) => ({
          label: 'Referred To',
          value: (
            <div className={tw`leading-tight`}>
              {referredPractitioner.name ?? ''}
              {referredPractitioner.practiceName && (
                <>
                  {referredPractitioner.name && <br />}
                  {referredPractitioner.practiceName}
                </>
              )}
            </div>
          ),
        })),
        ['scheduled', 'fulfilled'].includes(model.status) && {
          label: 'Scheduled On',
          value: model.scheduledAtDisplay,
        },
        ['scheduled', 'fulfilled'].includes(model.status) && {
          label: 'Scheduled By',
          value: <div className={tw`leading-tight`}>{model.referredPracticeName ?? 'Unknown'}</div>,
        },
        ['fulfilled'].includes(model.status) && {
          label: 'Completed On',
          value: model.completedAtDisplay,
        },
        ['fulfilled'].includes(model.status) && {
          label: 'Completed By',
          value: model.referredPractitionerName ?? model.referredPracticeName,
        },
      ]),
    [],
  );

  return useResourceDrawer({
    resourceType: 'ServiceRequest',
    action: ({ model }) => ({
      label: 'Referral',
      title: model.title,
      subtitle: model.referredPractitionerName ?? model.referredPractitionerName,
      enableDismissAndReadActions: false,
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList items={detailItems(model)} />
            </ContentCard>
            <RelatedDocuments model={model} />
          </div>
        ),
      },
    }),
  });
};
