import { type ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import LoadingMessage from '../LoadingMessage';
import { useEnvironment } from '../hooks/use-environment';
import { useEhrFhirClient } from './ehr-fhir-client-context';

interface ZusTokenContextProps {
  token: string | null;
}

const defaultContextValue: ZusTokenContextProps = {
  token: null,
};

export const ZusTokenContext = createContext<ZusTokenContextProps>(defaultContextValue);

export const ZusTokenProvider = ({ children }: { children: ReactNode }) => {
  const fhirClient = useEhrFhirClient();
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const environment = useEnvironment();

  useEffect(() => {
    if (import.meta.env.VITE_SMART_TEST_FAKE_AUTH_TOKEN) {
      // Fake token auth
      setToken(import.meta.env.VITE_SMART_TEST_FAKE_AUTH_TOKEN);
    } else {
      // Real token auth
      const getZusToken = async () => {
        const tokenResponse = await fetch(`${environment.ehrHooksURL}/token/athena`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: fhirClient.client?.state.tokenResponse?.id_token,
          }),
        });
        if (tokenResponse.status !== 200) {
          setError(new Error('Could not get Zus token'));
        } else {
          const tokenJson = await tokenResponse.json();
          setToken(tokenJson.access_token);
        }
      };

      if (fhirClient.client?.state.tokenResponse?.id_token && !token) {
        getZusToken().catch((e) => {
          setError(e);
        });
      }
    }
  }, [fhirClient.client?.state.tokenResponse?.id_token, environment.ehrHooksURL, token]);

  const contextValue = useMemo(() => ({ token }), [token]);

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  if (!token) {
    return <LoadingMessage />;
  }

  return <ZusTokenContext.Provider value={contextValue}>{children}</ZusTokenContext.Provider>;
};

export const useZusToken = () => useContext(ZusTokenContext);
