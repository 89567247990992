import { EpisodeOfCareModel } from '@ctw/shared/api/fhir/models/episode-of-care';
import { MAX_OBJECTS_PER_REQUEST, fqsRequest } from '@ctw/shared/api/fqs/client';
import {
  type EpisodeOfCareGraphqlResponse,
  episodeOfCareQuery,
} from '@ctw/shared/api/fqs/queries/episode-of-care';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_EPISODES_OF_CARE } from '@ctw/shared/utils/query-keys';

export const usePatientEpisodesOfCare = () =>
  usePatientQuery({
    queryId: QUERY_KEY_PATIENT_EPISODES_OF_CARE,
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      const { data } = await fqsRequest<EpisodeOfCareGraphqlResponse>(
        telemetry,
        graphqlClient,
        episodeOfCareQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: MAX_OBJECTS_PER_REQUEST,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: {
              allmatch: ['https://zusapi.com/summary|TransitionsOfCare'],
            },
          },
        },
      );
      const nodes = data.EpisodeOfCareConnection.edges.map((x) => x.node);
      return nodes.map((c) => new EpisodeOfCareModel(c, []));
    },
  });
