import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import {
  getBuilderFhirPatientByIdentifier,
  getPatientByID,
  useBuilderPatientsByUPID,
} from '@ctw/shared/api/fhir/patient-helper';
import {
  type PatientHistoryResponseError,
  schedulePatientHistory,
} from '@ctw/shared/api/patient-history';
import type { PatientHistoryJobResponse } from '@ctw/shared/api/patient-history/patient-history-types';
import type { PatientHistoryJobResponseJobData } from '@ctw/shared/api/patient-history/patient-history-types';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { DrawerFormWithFields } from '@ctw/shared/components/form/drawer-form-with-fields';
import { notify } from '@ctw/shared/components/toast';
import {
  getRequestData,
  requestHistorySchema,
} from '@ctw/shared/content/forms/schemas/request-history-schema';
import { type CTWRequestContext, type CTWState, useCTW } from '@ctw/shared/context/ctw-context';
import { useDrawer } from '@ctw/shared/context/drawer-context';
import { usePatientContext, usePatientQuery } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_PATIENT_HISTORY_DETAILS } from '@ctw/shared/utils/query-keys';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { omitBy } from 'lodash-es';
import { useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';

interface PatientHistoryJobResponseJob {
  job: PatientHistoryJobResponseJobData;
  targetDate: Date;
}

interface PatientHistoryDetails {
  latestCompleted?: PatientHistoryJobResponseJob;
  latestInProgress?: PatientHistoryJobResponseJob;
  nextScheduledAt?: Date;
}

export function usePatientHistory() {
  const { openDrawer } = useDrawer({
    boundaryName: 'PatientHistoryDrawer',
    telemetryContext: { action: 'patient_history' },
  });
  const { getPatient } = usePatientPromise();
  const { requestContext } = useCTW();
  const details = usePatientHistoryDetails();

  return {
    openHistoryRequestDrawer: async () => {
      let patient: PatientModel | undefined;
      try {
        patient = await getPatient(requestContext);
      } catch {
        patient = undefined;
      }
      openDrawer({
        title: 'Request Records',
        subtitle:
          'Request records from outside provider networks. To enable automatic updates, reach out to your admin.',
        content: {
          body: (
            <PatientHistoryRequestDrawer
              setClinicalHistoryExists={() => {
                /* noop */
              }}
              patient={patient}
            />
          ),
        },
      });
    },
    isLoading: details.isLoading,
    isError: details.isError,
    details: details.data ?? {},
  };
}

function usePatientPromise() {
  const { patientResourceID, patientID, systemURL } = usePatientContext();
  const { ctwFetch } = useCTW();
  const telemetry = useTelemetry();

  const getPatient = useCallback(
    async (requestContext: CTWRequestContext) => {
      const patientIdUsed =
        systemURL && patientID ? `${systemURL}|${patientID}` : patientResourceID;
      try {
        let patient: PatientModel;
        if (systemURL && patientID) {
          patient = await getBuilderFhirPatientByIdentifier(
            ctwFetch,
            requestContext,
            patientID,
            systemURL,
          );
        } else if (patientResourceID) {
          patient = await getPatientByID(ctwFetch, requestContext, patientResourceID);
        } else {
          throw new Error(
            'Must specify a patient ID and system URL or a patient FHIR resource ID to retrieve a patient.',
          );
        }

        if (patient.active !== true) {
          // Assume null is inactive (different from the FHIR spec) since we use this property to control CPR access.
          void telemetry.logger.warn(`User accessing inactive patient: ${patientIdUsed}`);
        }

        return patient;
      } catch (e) {
        const msg = `Failed to get patient with ID ${patientIdUsed}`;
        telemetry.trackError({
          message: msg,
          error: e instanceof Error ? e : new Error(msg),
          context: {
            e,
          },
        });
        throw e;
      }
    },
    [systemURL, patientID, patientResourceID, ctwFetch, telemetry],
  );

  return { patientResourceID, patientID, systemURL, getPatient };
}

export type GetPatientHistoryJobsParams = {
  ctwFetch: CTWState['ctwFetch'];
  requestContext: CTWRequestContext;
  count?: number;
  offset?: number;
  patientId?: string;
  status?: string;
  excludeFutureJobs?: boolean;
};

export async function getPatientHistoryJobs({
  ctwFetch,
  requestContext,
  count = 50,
  offset = 0,
  patientId,
  status,
  excludeFutureJobs,
}: GetPatientHistoryJobsParams) {
  const baseUrl = new URL(`${getZusServiceUrl(requestContext.env, 'patient-history')}/jobs?`);

  const paramsObj = omitBy(
    {
      'page[count]': String(count),
      'page[offset]': String(offset * count),
      'filter[builder-id]': `${requestContext.builderId}`,
      'filter[patient-id]': patientId ? `${patientId}` : '',
      'filter[status]': status ? `${status}` : '',
      ...(!!excludeFutureJobs && {
        'filter[targetDate][until]': format(Date.now(), 'yyyy-MM-dd'),
      }),
    },
    (value) => !value,
  );

  const params = new URLSearchParams([...Object.entries(paramsObj)]).toString();
  const endpointUrl = new URL(`${baseUrl}${decodeURIComponent(params)}`);

  const { data } = await ctwFetch(endpointUrl.href, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      ...(requestContext.builderId && {
        'Zus-Account': requestContext.builderId,
      }),
    },
  });

  return data;
}

async function getPatientHistoryDetails(
  ctwFetch: CTWState['ctwFetch'],
  requestContext: CTWRequestContext,
  patients: Array<PatientModel>,
): Promise<PatientHistoryDetails> {
  const details: PatientHistoryDetails = {};
  const requests = patients.map(async ({ id }) =>
    // iterate over each patient and get the patient history details
    getPatientHistoryJobs({
      ctwFetch,
      requestContext,
      patientId: id,
    }),
  );
  const responses = (await Promise.all(requests)) as Array<PatientHistoryJobResponse>;

  const jobs = responses.flatMap((response) => response.data);

  jobs.forEach((job) => {
    // This is necessary because targetDate was not introduced until May 2023 and it was not backfilled
    const targetDate = new Date(
      job.attributes.targetDate ?? Number(job.attributes.lastUpdatedAt) * 1000,
    );
    switch (job.attributes.jobStatus) {
      case 'queued':
      case 'scheduled': {
        if (!details.nextScheduledAt || targetDate < details.nextScheduledAt) {
          details.nextScheduledAt = targetDate;
        }
        break;
      }
      case 'done':
      case 'error': {
        if (!details.latestCompleted || targetDate > details.latestCompleted.targetDate) {
          details.latestCompleted = {
            job,
            targetDate,
          };
        }
        break;
      }
      case 'in_progress': {
        if (!details.latestInProgress || targetDate > details.latestInProgress.targetDate) {
          details.latestInProgress = {
            job,
            targetDate,
          };
        }
        break;
      }
      default:
        break;
    }
  });

  return details;
}

export function usePatientHistoryDetails() {
  const patients = useBuilderPatientsByUPID();

  // Put the patients data into a list of fhir id's to pass to the query
  const patientFhirIds = patients.data?.map((patient) => patient.id);

  return usePatientQuery({
    queryId: QUERY_KEY_PATIENT_HISTORY_DETAILS,
    queryKey: [patientFhirIds],
    queryFn: async ({ ctwFetch, requestContext, patient }) =>
      getPatientHistoryDetails(ctwFetch, requestContext, patients.data ?? [patient]),
    enabled: Boolean(patients.data),
  });
}

type PatientHistoryRequestDrawer = {
  patient?: PatientModel;
  setClinicalHistoryExists: Dispatch<SetStateAction<boolean | undefined>>;
};

type ScheduleHistoryFormData = {
  npi: string;
  name: string;
  role: string;
  id: string;
};

const PatientHistoryRequestDrawer = ({
  patient,
  setClinicalHistoryExists,
}: PatientHistoryRequestDrawer) => {
  const queryClient = useQueryClient();
  const telemetry = useTelemetry();

  const onScheduleHistory = async (data: ScheduleHistoryFormData, ctw: CTWState) => {
    try {
      const patientHistoryResponse: Record<string, unknown> = (await schedulePatientHistory(
        ctw,
        data,
      )) as Record<string, unknown>;

      if ('errors' in patientHistoryResponse && Array.isArray(patientHistoryResponse.errors)) {
        const requestErrors = [
          patientHistoryResponse.errors.map((err: PatientHistoryResponseError) => err.details),
        ];
        return new Error(requestErrors.join(', '));
      }

      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PATIENT_HISTORY_DETAILS] });

      // patientHistoryResponse has succeeded at this point and should remove empty request history state.
      setClinicalHistoryExists(true);

      notify({
        type: 'success',
        title: 'Successfully requested records refresh',
      });

      return patientHistoryResponse;
    } catch (error) {
      telemetry.trackError({ message: 'Error requesting patient history.', error });
      return error;
    }
  };

  return (
    <DrawerFormWithFields
      boundaryName="PatientHistoryRequestDrawer"
      errorHeader="Unable to request records"
      action={onScheduleHistory as never}
      data={getRequestData(patient, false)}
      schema={requestHistorySchema}
    />
  );
};
