import { Button } from '@ctw/shared/components/button';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type PatientSummaryDrawerFooterProps = {
  feedbackEnabled: boolean;
  onFeedbackClick: () => void;
  onClose: () => void;
};

export const PatientSummaryDrawerFooter = ({
  feedbackEnabled,
  onFeedbackClick,
  onClose,
}: PatientSummaryDrawerFooterProps) => {
  const hasHPIStandaloneZAP = useFeatureFlag('ctw-hpi-standalone-zap');

  const feedbackBtnClass = feedbackEnabled
    ? 'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text'
    : 'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover';

  return (
    <div
      className={twx('flex items-center justify-between gap-5 px-4', {
        'w-full': hasHPIStandaloneZAP,
      })}
    >
      <Button type="button" variant="link" className={tw`hover:underline`} onClick={onClose}>
        Close
      </Button>
      <Button
        type="button"
        variant="unstyled"
        className={twx(feedbackBtnClass)}
        disabled={!feedbackEnabled}
        onClick={onFeedbackClick}
      >
        {hasHPIStandaloneZAP ? 'Send Feedback' : 'Submit Feedback'}
      </Button>
    </div>
  );
};
