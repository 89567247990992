import { Tooltip } from '@ctw/shared/components/tooltip';
import { tw } from '@ctw/shared/utils/tailwind';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const fixedColumns = 'w-[27rem] @[1024px]:w-[30rem] hidden -ml-2 ';

export const conditionsColumnGroupedClassNames = {
  builderOwned: 'flex items-center justify-center max-w-[1.5rem]',
  displayName: 'w-full self-center',
  latestDiagnosis: [fixedColumns, '@[640px]:block @[640px]:ml-0'],
  onset: [fixedColumns, '@[1024px]:block @[1024px]:ml-0'],
  type: [fixedColumns, 'text-ellipsis @[768px]:block @[768px]:ml-0'],
};

export const patientConditionsColumnsGrouped = [
  {
    className: conditionsColumnGroupedClassNames.builderOwned,
  },
  {
    className: conditionsColumnGroupedClassNames.displayName,
    title: 'Name',
  },
  {
    className: conditionsColumnGroupedClassNames.latestDiagnosis,
    title: 'Latest Diagnosis',
  },
  {
    className: conditionsColumnGroupedClassNames.onset,
    render: () => (
      <div className={tw`flex w-full justify-start gap-1.5`}>
        <div>Onset Date</div>
        <div className={tw`flex flex-grow content-start items-center`}>
          <Tooltip content="Earliest known onset or documented date">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className={tw`-mt-0.5 h-3.5 w-3.5 text-content-icon`}
            />
          </Tooltip>
        </div>
      </div>
    ),
  },
  {
    className: conditionsColumnGroupedClassNames.type,
    title: 'Type',
  },
];
