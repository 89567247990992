import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { Optional } from 'utility-types';
import type { WritebackStatus } from '../content/resource/resource-title-column';

export const ehrs = ['athena', 'canvas', 'elation', 'healthie', 'salesforce', 'ecw'] as const;
export type EHR = (typeof ehrs)[number];

export const ehrSystemURLs: Record<EHR, string> = {
  athena: 'https://fhir.athena.io/sid/ah-patient',
  canvas: 'https://canvasmedical.com/patient-id',
  ecw: 'https://www.eclinicalworks.com/patient-id',
  elation: 'https://elationemr.com/patient-id',
  healthie: 'https://www.gethealthie.com',
  salesforce: 'https://salesforce.com/account-id',
};

type WritebackContext = 'medications-all' | 'conditions-all' | 'diagnostic-reports';

type EhrEnabledWritebacks = {
  [key in EHR]: Array<WritebackContext>;
};

const EHR_ENABLED_WRITEBACKS: EhrEnabledWritebacks = {
  canvas: ['medications-all'],
  athena: ['conditions-all', 'diagnostic-reports'],
  elation: ['conditions-all'],
  healthie: [],
  salesforce: [],
  ecw: [],
} as const;

export type EhrWriteResponse = {
  system: string;
  value: string;
};

type WritebackStatuses = Record<string, WritebackStatus>;

export interface WritebackState {
  isWritebackEnabled: (context: WritebackContext) => boolean;
  writebackStatuses: WritebackStatuses;
  onWritebackStart: (id: string) => void;
  onWritebackSuccess: (id: string, response: EhrWriteResponse) => void;
  onWritebackFailure: (id: string, message: string) => void;
}

const disabledWritebackState: WritebackState = {
  isWritebackEnabled: () => false,
  writebackStatuses: {},
  onWritebackStart: () => undefined,
  onWritebackSuccess: () => undefined,
  onWritebackFailure: () => undefined,
} as const;

const WritebackContext = createContext<WritebackState>(disabledWritebackState);

export interface WritebackProviderProps
  extends PropsWithChildren,
    Optional<
      Pick<WritebackState, 'onWritebackStart' | 'onWritebackSuccess' | 'onWritebackFailure'>
    > {}

export const WritebackProvider = ({
  onWritebackStart,
  onWritebackSuccess,
  onWritebackFailure,
  children,
}: WritebackProviderProps) => {
  const { requestContext } = useCTW();
  const telemetry = useTelemetry();
  const isWritebackEnabled = useCallback(
    (context: WritebackContext): boolean => {
      const normalizedEhr = requestContext.ehr.toLowerCase();
      if (!(normalizedEhr in EHR_ENABLED_WRITEBACKS)) {
        return false;
      }

      const enabled = EHR_ENABLED_WRITEBACKS[normalizedEhr as EHR].includes(context);
      telemetry.logger.debug('WritebackProvider::isWritebackEnabled', {
        normalizedEhr,
        context,
        enabled,
      });

      return enabled;
    },
    [requestContext.ehr, telemetry.logger],
  );
  const [writebackStatuses, setWritebackStatuses] = useState<WritebackStatuses>({});

  const value: WritebackState = useMemo(
    (): WritebackState => ({
      isWritebackEnabled,
      writebackStatuses,
      onWritebackStart: (id) => {
        onWritebackStart?.(id);
        setWritebackStatuses((prev) => ({
          ...prev,
          [id]: { state: 'in-progress' },
        }));
      },
      onWritebackSuccess: (id, response) => {
        onWritebackSuccess?.(id, response);
        setWritebackStatuses((prev) => ({
          ...prev,
          [id]: { state: 'known-to-builder-new' },
        }));
      },
      onWritebackFailure: (id, message) => {
        onWritebackFailure?.(id, message);
        setWritebackStatuses((prev) => ({
          ...prev,
          [id]: { state: 'error', message },
        }));
      },
    }),
    [
      isWritebackEnabled,
      onWritebackFailure,
      onWritebackStart,
      onWritebackSuccess,
      writebackStatuses,
    ],
  );

  return <WritebackContext.Provider value={value}>{children}</WritebackContext.Provider>;
};

export function useWritebacks() {
  return useContext(WritebackContext);
}
