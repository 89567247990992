import { ZapProvider } from '@ctw/shared/context/zap-context';
import { ZuiProvider } from '@ctw/shared/context/zui-provider';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Launch from './Launch';
import ZAP from './ZAP';
import ZAPV2 from './ZAP-v2';

const RouteDefinitions = () => {
  const navigate = useNavigate();

  return (
    <ZuiProvider
      service="smart-on-fhir"
      navigate={navigate}
      navigateBasePath="/zap"
      navigatePersistentQueryParams={['ehr']}
      pathname={useLocation}
    >
      <ZapProvider>
        <Routes>
          <Route path="/launch" element={<Launch />} />
          <Route path="/zap/*" element={<ZAP />} />
          <Route path="/zap-v2/*" element={<ZAPV2 />} />
        </Routes>
      </ZapProvider>
    </ZuiProvider>
  );
};

export function App() {
  return (
    <BrowserRouter>
      <RouteDefinitions />
    </BrowserRouter>
  );
}
