import { twx } from '@ctw/shared/utils/tailwind';

export interface AvatarProps {
  className?: string;
  imageUrl?: string;
  name: string;
  size?: 'sm' | 'lg';
}

export const Avatar = ({ className, imageUrl, name, size = 'lg' }: AvatarProps) => {
  const sizeClasses = { sm: 'h-8 w-8 min-w-8 min-h-8', lg: 'h-16 w-16 min-w-16 min-h-16' }[size];
  const textSizeClass = { sm: 'text-sm', lg: 'text-2xl' }[size];

  const initials = name
    .split(' ')
    .map((n, idx, arr) => (n[0] && (idx === 0 || idx === arr.length - 1) ? n[0].toUpperCase() : ''))
    .join('');

  if (imageUrl) {
    return (
      <img
        className={twx(className, sizeClasses, 'aspect-square rounded-full bg-background-tooltip')}
        src={imageUrl}
        alt=""
      />
    );
  }

  return (
    <span
      className={twx(
        'flex items-center justify-center rounded-full bg-background-tooltip',
        sizeClasses,
        className,
      )}
    >
      <span className={twx(textSizeClass, 'font-medium text-white leading-none')}>{initials}</span>
    </span>
  );
};
