'use client';

import { NoData } from '@ctw/shared/components/icons/no-data';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export interface NoDataErrorProps {
  message: string;
  className?: string;
}

export const NoDataError = ({ message, className }: NoDataErrorProps) => (
  <div className={twx('flex h-full flex-col items-center justify-center space-y-6 p-4', className)}>
    <div className={tw`flex justify-center`}>
      <NoData width={214} height={205} />
    </div>
    <div className={tw`text-center font-medium text-content-main text-sm`}>
      <p>{message}</p>
      <p>
        Please reach out to <SupportFormLink buttonText="support@zushealth.com" /> with any
        questions.
      </p>
    </div>
  </div>
);
