import { Heading } from '@ctw/shared/components/heading';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { type PropsWithChildren, type ReactNode, forwardRef } from 'react';

export interface CardProps extends PropsWithChildren {
  id?: string;
  testId?: string;
  className?: ClassName;
  title?: string;
  fullbleed?: boolean;
  content?: {
    header: ReactNode;
  };
}

export const Card = forwardRef<HTMLElement, CardProps>(
  ({ id, testId, fullbleed = false, className, title, content, children }, ref) => (
    <section
      ref={ref}
      id={id}
      data-testid={testId}
      className={twx(
        'flex flex-col overflow-hidden rounded-md border border-divider-main bg-white shadow-sm',
        className,
      )}
    >
      {(title || content?.header) && (
        <div
          className={twx(
            'min-h[55px] flex h-[55px] max-h-[55px] items-center justify-between border-divider-main border-b',
            {
              'p-2': !fullbleed,
            },
          )}
        >
          <div className={tw`flex items-center`}>
            <Heading level="h4" className={tw`text-content-main uppercase tracking-wider`}>
              {title}
            </Heading>
          </div>
          {content?.header && <div>{content.header}</div>}
        </div>
      )}
      <div
        className={twx({
          'p-2': !fullbleed,
        })}
      >
        {children}
      </div>
    </section>
  ),
);
