import type { Breakpoint } from '@ctw/config/zui';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Pagination } from '@ctw/shared/components/pagination/pagination';
import { SimplePagination } from '@ctw/shared/components/pagination/simple-pagination';
import { useContainerQuery } from '@ctw/shared/hooks/breakpoints';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import type { VoidValue } from '@ctw/shared/utils/types';
import { isFunction } from 'lodash-es';
import {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ExpandList } from '../pagination/expand-list';

export interface MinRecordItem {
  key: string | number;
}

export type DataIndexSpecified<T> = { dataIndex: keyof T; render?: never };
export type RenderSpecified<T> = {
  dataIndex?: never;
  render: (row: T) => ReactNode;
};

export type TableColumn<T extends MinRecordItem> = {
  title?: string;
  className?: string;
  widthPercent?: number;
  minWidth?: number | string;
  width?: number | string;
} & (DataIndexSpecified<T> | RenderSpecified<T>);

export type RowActionsProps<T extends MinRecordItem> = {
  record: T;
  onSuccess?: () => void;
  stacked?: boolean;
};

export type RowActionsProp<T extends MinRecordItem> = FunctionComponent<RowActionsProps<T>>;

export type TablePagination =
  | {
      type: 'show-more';
      pageSize: number;
    }
  | {
      type: 'next-previous';
      hasNext: boolean;
      onPageChange?: (page: number) => void;
    }
  | {
      type: 'pages';
      pageSize: number;
      total: number;
      onPageChange?: (page: number) => void;
    };

export type TableProps<T extends MinRecordItem> = {
  className?: ClassName;
  columns: Array<TableColumn<T>>;
  emptyMessage?: string | ReactElement;
  pagination?: TablePagination | undefined;
  loading?: boolean;
  pageSize?: number;
  records: Array<T>;
  showTableHead?: boolean;
  stacked?: boolean;
  stackedBreakpoint?: Breakpoint;
} & Pick<
  TableRowsProps<T>,
  'handleRowClick' | 'canClickRow' | 'RowActions' | 'getRowClassName' | 'onRightClick'
>;

export const Table = <T extends MinRecordItem>({
  className,
  columns,
  records,
  loading = false,
  emptyMessage = 'No records found',
  showTableHead = true,
  stacked = false,
  stackedBreakpoint = 'xs',
  handleRowClick,
  canClickRow,
  onRightClick,
  RowActions,
  getRowClassName,
  pagination,
}: TableProps<T>) => {
  const containerRef = useRef<HTMLTableElement>(null);
  const [internalCurrentPage, setInternalInternalCurrentPage] = useState(1);
  const [isStacked, setStacked] = useState(stacked);

  const [count, setCount] = useState(
    pagination?.type === 'show-more' || pagination?.type === 'pages'
      ? pagination.pageSize
      : records.length,
  );
  const { breakpoints } = useContainerQuery({ containerRef });
  const setCurrentPage = (page: number) => {
    if (pagination?.type === 'next-previous' || pagination?.type === 'pages') {
      pagination.onPageChange?.(page);
    }
    setInternalInternalCurrentPage(page);
  };

  useEffect(() => {
    if (breakpoints.isAtMost[stackedBreakpoint]) {
      setStacked(true);
    } else {
      setStacked(stacked);
    }
  }, [breakpoints.isAtMost, stacked, stackedBreakpoint]);

  const hasData = !loading && records.length > 0;

  useEffect(() => {
    if (pagination?.type === 'show-more' && hasData) {
      setCount(Math.min(Math.max(count, pagination.pageSize), records.length));
    } else {
      setCount(records.length);
    }
  }, [records, count, pagination, hasData]);

  return (
    <div
      className={twx('space-y-4', {
        'table-stacked': isStacked,
      })}
    >
      <div className={twx('relative overflow-hidden border-divider-main border-b', className)}>
        <div className={twx('scroll-shadows overflow-x-auto', { 'overflow-x-hidden': isStacked })}>
          <table ref={containerRef} className={tw`w-full table-auto border-collapse`}>
            {hasData && (
              <colgroup className={twx({ hidden: isStacked })}>
                {columns.map((column, index) => (
                  <col
                    key={column.title ?? index}
                    className={twx(column.className)}
                    width={column.widthPercent ? `${column.widthPercent}%` : undefined}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                  />
                ))}
              </colgroup>
            )}
            {showTableHead && hasData && (
              <thead
                className={twx('border-background-subtle border-b border-solid', {
                  hidden: isStacked,
                })}
              >
                <tr>
                  {columns.map((column, index) => (
                    <th
                      className={tw`group p-2 pb-1 text-left font-medium text-content-subtle text-xs uppercase tracking-wider`}
                      key={column.title ?? index}
                      scope="col"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className={twx({ 'h-[7rem]': records.length === 0 })}>
              <TableRows
                getRowClassName={getRowClassName}
                records={records.slice(0, count)}
                handleRowClick={handleRowClick}
                canClickRow={canClickRow}
                onRightClick={onRightClick}
                RowActions={RowActions}
                columns={columns}
                loading={loading}
                emptyMessage={emptyMessage}
                isStacked={isStacked}
              />
            </tbody>
          </table>
        </div>
      </div>
      {!loading && pagination?.type === 'show-more' && (
        <ExpandList
          className={tw`items-center justify-between gap-2.5 pr-4 pl-4`}
          total={records.length}
          count={count}
          pageSize={pagination.pageSize}
          changeCount={setCount}
        />
      )}
      {!loading && pagination?.type === 'next-previous' && (
        <SimplePagination
          currentPage={internalCurrentPage}
          setCurrentPage={setCurrentPage}
          hasNext={pagination.hasNext}
        />
      )}
      {!loading && pagination?.type === 'pages' && (
        <Pagination
          currentPage={internalCurrentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pagination.pageSize}
          total={pagination.total}
        />
      )}
    </div>
  );
};

export type RowActionsConfigProp<T extends MinRecordItem> =
  | Array<{
      text: string;
      onClick: (record: T, onSuccess?: () => void) => VoidValue;
      className: ClassName;
      disabled?: boolean;
      testId?: string;
      render?: RowActionsProp<T>;
    }>
  | undefined;

type TableRowsProps<T extends MinRecordItem> = {
  RowActions?: RowActionsProp<T>;
  columns: Array<TableColumn<T>>;
  emptyMessage: string | ReactElement;
  getRowClassName?: (record: T) => ClassName;
  handleRowClick?: (record: T) => void;
  canClickRow?: (record: T) => boolean;
  onRightClick?: (record: T) => void;
  loading: boolean;
  records: Array<T>;
  isStacked: boolean;
};

const TableRows = <T extends MinRecordItem>({
  records,
  columns,
  loading,
  emptyMessage,
  handleRowClick,
  canClickRow = () => true,
  onRightClick,
  RowActions,
  getRowClassName,
  isStacked,
}: TableRowsProps<T>) => {
  if (loading) {
    return (
      <tr
        className={twx(
          'flex flex-col items-center justify-center p-6 align-top text-background-inverse [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-background-subtle [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid',
          { 'relative p-3': isStacked },
        )}
      >
        <td
          className={tw`whitespace-pre-wrap px-3 py-4 text-background-inverse text-sm`}
          colSpan={columns.length}
        >
          <LoadingSpinner centered={true} message="Loading..." />
        </td>
      </tr>
    );
  }

  if (records.length === 0) {
    return (
      <tr
        className={twx(
          'flex flex-col items-center p-6 align-top text-background-inverse [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-background-subtle [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid',
          { 'relative block p-3': isStacked },
        )}
      >
        <td
          className={tw`whitespace-pre-wrap px-3 py-4 text-background-inverse text-sm`}
          colSpan={columns.length}
        >
          <span className={tw`empty-message -mt-3.5 sm:mt-0`}>{emptyMessage}</span>
        </td>
      </tr>
    );
  }

  return (
    <>
      {records.map((record) => (
        <tr
          // mx-px fixes bug where side borders disappear on hover when stacked.
          className={twx(
            'group relative mx-px table-row align-top [&:not(:last-child)]:border-0 [&:not(:last-child)]:border-background-subtle [&:not(:last-child)]:border-b [&:not(:last-child)]:border-solid',
            isFunction(getRowClassName) ? getRowClassName(record) : '',
            {
              'cursor-pointer hover:bg-background-hover':
                isFunction(handleRowClick) && canClickRow(record),
              'relative block p-3': isStacked,
            },
          )}
          key={record.key}
          onClick={({ metaKey, ctrlKey }) => {
            // Case for cmd/ctrl + click
            if (isFunction(onRightClick) && (metaKey || ctrlKey)) {
              onRightClick(record);
              return;
            }

            if (handleRowClick) {
              handleRowClick(record);
            }
          }}
        >
          {columns.map((column, index) => (
            <td
              key={column.title ?? `unknown-${index}`}
              className={twx(
                'hyphens-auto whitespace-pre-wrap break-words px-3 py-4 text-background-inverse text-sm',
                {
                  'block w-full p-0 pr-7': isStacked,
                },
                column.className,
              )}
            >
              {column.render
                ? column.render(record)
                : (record[column.dataIndex] as unknown as ReactNode)}
            </td>
          ))}
          {RowActions && (
            <td
              aria-label="row-actions"
              className={twx(
                'invisible absolute top-1 right-0 flex h-fit items-center space-x-2 whitespace-pre-wrap bg-table-actions-gradient px-4 text-background-inverse text-sm group-hover:visible',
                {
                  'mt-0 w-fit justify-end px-4 pr-7': isStacked,
                },
              )}
            >
              <div onClick={(event) => event.stopPropagation()}>
                <RowActions record={record} stacked={isStacked} />
              </div>
            </td>
          )}
        </tr>
      ))}
    </>
  );
};
