import { formatFHIRDateOrDatetime } from '@ctw/shared/utils/dates';
import type { Basic, EpisodeOfCare, Provenance } from 'fhir/r4';
import { capitalize, compact, uniq } from 'lodash-es';
import { codeableConceptLabel, findAllCodings, hasMatchingCode } from '../codeable-concept';
import { findReference } from '../resource-helper';
import { SYSTEM_DIAGNOSIS_ROLE, SYSTEM_SNOMED, SYSTEM_ZUS_CREATED_AT } from '../system-urls';
import type { ResourceMap } from '../types';
import type { EncounterModel } from './encounter';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';

const DISCHARGE_NARRATIVE_URL = 'https://zusapi.com/lens/extension/dischargeNarrative';
const PREFERRED_DISCHARGE_NARRATIVE_URL =
  'https://zusapi.com/lens/extension/preferredDischargeNarrative';
const DISCHARGE_DISP_URL = 'https://zusapi.com/lens/extension/dischargeDisposition';
const DISCHARGE_LOC_URL = 'https://zusapi.com/lens/extension/dischargeLocation';
const ADMIT_LOC_URL = 'https://zusapi.com/lens/extension/admittingLocation';

const findViaExtensionByURL = (r: EpisodeOfCare, url: string) =>
  r.extension?.find((e) => e.url === url);

export class EpisodeOfCareModel extends FHIRModel<EpisodeOfCare> {
  public kind = 'EpisodeOfCareModel' as const;

  public binaryId?: string;

  public provenance: Array<Provenance>;

  public relatedEncounters: Array<EncounterModel> | undefined;

  public constructor(
    resource: EpisodeOfCare,
    provenance: Array<Provenance>,
    includedResources?: ResourceMap,
    basics?: Array<Basic>,
  ) {
    super(resource, includedResources, basics);
    this.provenance = provenance;
  }

  public get key() {
    return this.resource.id || '';
  }

  public get lastUpdated(): string | undefined {
    return (
      this.resource.meta?.lastUpdated ||
      this.resource.meta?.extension?.find((e) => e.url === SYSTEM_ZUS_CREATED_AT)?.valueDateTime
    );
  }

  public get patient(): PatientModel | undefined {
    const patient = findReference('Patient', undefined, undefined, this.resource.patient);
    return patient ? new PatientModel(patient) : undefined;
  }

  public get diagnoses(): Array<string> | undefined {
    return compact(
      uniq(
        this.resource.diagnosis?.map((d) => {
          const c = findReference('Condition', undefined, undefined, d.condition);
          return c?.code?.text;
        }),
      ),
    );
  }

  public diagnosesForRole(code: string): Array<string> {
    const diagnosis = this.resource.diagnosis?.filter((d) =>
      hasMatchingCode(SYSTEM_DIAGNOSIS_ROLE, [code], d.role),
    );

    return compact(
      uniq(
        diagnosis?.map((d) => {
          const c = findReference('Condition', undefined, undefined, d.condition);
          const codes = findAllCodings(SYSTEM_SNOMED, c?.code);
          return codes[0]?.display;
        }),
      ),
    );
  }

  public get class(): string | undefined {
    const classExt = findViaExtensionByURL(
      this.resource,
      'https://zusapi.com/lens/extension/encounterClass',
    );
    return capitalize(classExt?.valueCoding?.display);
  }

  public get classCode(): string {
    const classExt = findViaExtensionByURL(
      this.resource,
      'https://zusapi.com/lens/extension/encounterClass',
    );
    return classExt?.valueCoding?.code?.toUpperCase() ?? '';
  }

  public get dischargeDisposition(): string | undefined {
    const dischargeExt = findViaExtensionByURL(this.resource, DISCHARGE_DISP_URL);
    return codeableConceptLabel(dischargeExt?.valueCodeableConcept);
  }

  public get location(): string | undefined {
    const dischargeLoc = findViaExtensionByURL(this.resource, DISCHARGE_LOC_URL);
    return dischargeLoc?.valueReference?.display || this.admittingLocation;
  }

  public get admittingLocation(): string | undefined {
    const admitLoc = findViaExtensionByURL(this.resource, ADMIT_LOC_URL);
    return admitLoc?.valueReference?.display;
  }

  public get periodEnd() {
    const end = this.resource.period?.end;
    return end ? formatFHIRDateOrDatetime(end) : undefined;
  }

  public get periodStart() {
    const start = this.resource.period?.start;
    return start ? formatFHIRDateOrDatetime(start) : undefined;
  }

  public get dateDisplay() {
    if (this.periodStart !== this.periodEnd && this.periodEnd) {
      return `${this.periodStart} - ${this.periodEnd}`;
    }
    return this.periodStart;
  }

  public get status() {
    switch (this.resource.status) {
      case 'active':
        return 'Active';
      case 'finished':
        return 'Discharged';
      default:
        return this.resource.status;
    }
  }

  public get dischargeBinaryIds(): Array<string> {
    const dischargeExt = findViaExtensionByURL(this.resource, DISCHARGE_NARRATIVE_URL);
    return compact(
      dischargeExt?.extension?.map((e) => e.valueReference?.reference?.substring('Binary/'.length)),
    );
  }

  public get preferredDischargeBinaryIds(): Array<string> {
    const dischargeExtensions =
      findViaExtensionByURL(this.resource, DISCHARGE_NARRATIVE_URL)?.extension ?? [];
    const preferred = dischargeExtensions.filter((extension) =>
      extension.valueReference?.extension?.some(
        (e) => e.url === PREFERRED_DISCHARGE_NARRATIVE_URL && e.valueBoolean === true,
      ),
    );
    return compact(preferred.map((e) => e.valueReference?.reference?.substring('Binary/'.length)));
  }

  public get title() {
    return `${this.dateDisplay} - ${this.class}`;
  }
}
