import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const diagnosticReportSortOptions: Array<SortOption<DiagnosticReportModel>> = [
  {
    display: 'Date (New to Old)',
    sorts: [{ key: 'date', dir: 'desc', isDate: true }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'date', dir: 'asc', isDate: true }],
  },
  {
    display: 'Name (A-Z)',
    sorts: [{ key: 'displayName', dir: 'asc' }],
  },
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'displayName', dir: 'desc' }],
  },
];

export const defaultDiagnosticReportSort = diagnosticReportSortOptions[0];
