import type { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import type { CareTeamPractitionerModel } from '@ctw/shared/api/fhir/models/careteam-practitioner';
import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import type { EpisodeOfCareModel } from '@ctw/shared/api/fhir/models/episode-of-care';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import type { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import type { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import type { ObservationModel } from '@ctw/shared/api/fhir/models/observation';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import type { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import type { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import type { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import {
  type AdditionalResourceActionsProps,
  useAdditionalResourceActions,
} from '@ctw/shared/content/resource/use-additional-resource-actions';
import {
  type OpenDrawerAction,
  type UseDrawerOptions,
  useDrawer,
} from '@ctw/shared/context/drawer-context';
import {
  type DeepLinkResourceType,
  isDeepLinkResourceType,
  useDeepLinkResource,
} from '@ctw/shared/hooks/use-deep-link-resource';
import { useFetchDeepLinkedResource } from '@ctw/shared/hooks/use-fetch-deep-linked-resource';
import { tw } from '@ctw/shared/utils/tailwind';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useCallback, useLayoutEffect } from 'react';

interface ResourceTypeModelMap
  extends Record<DeepLinkResourceType, FHIRModel<ResourceType<ResourceTypeString>>> {
  Condition: ConditionModel;
  Immunization: ImmunizationModel;
  AllergyIntolerance: AllergyModel;
  Encounter: EncounterModel;
  DiagnosticReport: DiagnosticReportModel;
  ServiceRequest: ReferralModel;
  MedicationStatement: MedicationStatementModel;
  Practitioner: CareTeamPractitionerModel;
  DocumentReference: DocumentModel;
  Measure: CareGapModel;
  Observation: ObservationModel;
  EpisodeOfCare: EpisodeOfCareModel;
}

export type FhirModelSubclassType<TResourceType extends ResourceTypeString> =
  TResourceType extends keyof ResourceTypeModelMap ? ResourceTypeModelMap[TResourceType] : never;

interface OpenResourceDrawerAction extends OpenDrawerAction {}

export interface UseResourceDrawerOpenDrawerArgs<TResourceType extends ResourceTypeString>
  extends Omit<OpenDrawerAction, 'allowDocking' | 'content'> {
  model: FhirModelSubclassType<TResourceType>;
}

interface UseResourceDrawerActionArgs<TResourceType extends ResourceTypeString> {
  model: FhirModelSubclassType<TResourceType>;
  reloadResource: ReturnType<typeof useFetchDeepLinkedResource>['reloadDeepLinkedResource'];
}

export interface UseResourceDrawerOptions<TResourceType extends ResourceTypeString>
  extends Omit<UseDrawerOptions, 'boundaryName'> {
  resourceType: TResourceType;
  rowActions?: TResourceType extends keyof ResourceTypeModelMap
    ? (
        model: FhirModelSubclassType<TResourceType>,
      ) => RowActionsConfigProp<FhirModelSubclassType<TResourceType>>
    : never;
  enableDeepLinking?: boolean;
  enableViewFhirButton?: boolean;
  enableDismissAndReadActions?: boolean;
  resourceActions?: AdditionalResourceActionsProps<
    FHIRModel<ResourceType<TResourceType>>
  >['rowActions'];
  patient?: PatientModel;
  action: (args: UseResourceDrawerActionArgs<TResourceType>) => OpenResourceDrawerAction;
}

export const useResourceDrawer = <TResourceType extends ResourceTypeString>({
  resourceType,
  action,
  enableViewFhirButton = true,
  enableDeepLinking = true,
  enableDismissAndReadActions = false,
  resourceActions,
  patient,
  ...useDrawerOptions
}: UseResourceDrawerOptions<TResourceType>) => {
  const { setDeepLinkResource, removeDeepLinkResource } = useDeepLinkResource();
  const { deepLinkedResource, reloadDeepLinkedResource, canFetchDeepLinkedResource } =
    useFetchDeepLinkedResource<TResourceType, FhirModelSubclassType<TResourceType>>({
      patient,
      resourceType,
    });

  const queryClient = useQueryClient();
  const drawer = useDrawer({
    boundaryName: `${resourceType}Drawer`,
    ...useDrawerOptions,
    allowDocking: useDrawerOptions.allowDocking ?? true,
    onDrawerEvent: (eventType) => {
      if (eventType === 'close' && canFetchDeepLinkedResource) {
        removeDeepLinkResource();
      }
      useDrawerOptions.onDrawerEvent?.(eventType);
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const openResourceDrawer = useCallback(
    ({ model }: UseResourceDrawerOpenDrawerArgs<TResourceType>) => {
      const openDrawerArgs = action({ model, reloadResource });
      drawer.openDrawer({
        ...openDrawerArgs,
        content: {
          header: openDrawerArgs.content.header ? (
            <Fragment key={model.id}>{openDrawerArgs.content.header}</Fragment>
          ) : undefined,
          body: openDrawerArgs.content.body ? (
            <Fragment key={model.id}>{openDrawerArgs.content.body}</Fragment>
          ) : undefined,
          footer: (
            <div
              className={tw`flex h-full w-full items-center justify-between gap-5`}
              key={model.id}
            >
              <div>{openDrawerArgs.content.footer}</div>
              <div>
                {resourceActionsWithBuiltins({
                  record: model as never,
                  onSuccess: onMutate,
                })}
              </div>
            </div>
          ),
        },
      });

      if (canFetchDeepLinkedResource && isDeepLinkResourceType(resourceType)) {
        setDeepLinkResource({ resourceType, resourceId: model.id });
      }
    },
    [action, drawer, resourceType],
  );

  const reloadResource =
    useCallback(async (): Promise<FhirModelSubclassType<TResourceType> | null> => {
      const reloadedResource = await reloadDeepLinkedResource();

      if (reloadedResource) {
        openResourceDrawer({ model: reloadedResource });
      }

      return reloadedResource;
    }, [openResourceDrawer, reloadDeepLinkedResource]);

  const onMutate = useCallback(() => {
    for (const query of queryClient.getQueryCache().findAll({ type: 'active' })) {
      query.invalidate();
      void query.fetch();
    }
    void reloadResource();
  }, [queryClient, reloadResource]);

  const resourceActionsWithBuiltins = useAdditionalResourceActions({
    rowActions: resourceActions,
    enableViewFhirButton,
    enableDismissAndReadActions,
    isInFooter: true,
    onWritebackEnd: onMutate,
    onToggleRead: onMutate,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (enableDeepLinking && deepLinkedResource && !drawer.isDrawerOpen) {
      openResourceDrawer({ model: deepLinkedResource });
    }
  }, [deepLinkedResource]);

  return {
    ...drawer,
    openDrawer: openResourceDrawer,
    reloadResource,
  };
};
