import {
  type CodePreference,
  codeableConceptLabel,
  findCoding,
} from '@ctw/shared/api/fhir/codeable-concept';
import { displayOnset } from '@ctw/shared/api/fhir/display-onset';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import type { AllergyIntolerance, Coding } from 'fhir/r4';
import { capitalize, compact, uniqWith } from 'lodash-es';
import { findReference } from '../resource-helper';
import { SYSTEM_NDC, SYSTEM_RXNORM, SYSTEM_SNOMED } from '../system-urls';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';

export class AllergyModel extends FHIRModel<AllergyIntolerance> {
  public kind = 'Allergy' as const;

  public get categories(): string | undefined {
    return this.resource.category?.join(', ');
  }

  public get codeText(): string | undefined {
    return this.resource.code?.text;
  }

  public get resourceTypeTitle(): string {
    return this.resource.resourceType.slice(0, 7);
  }

  public get clinicalStatus(): string {
    return codeableConceptLabel(this.resource.clinicalStatus);
  }

  public get display(): string | undefined {
    return capitalize(codeableConceptLabel(this.resource.code));
  }

  public get lowercaseDisplay(): string {
    return this.display?.toLocaleLowerCase() || '';
  }

  public get manifestations(): string {
    const manifestations: Array<string> = [];

    this.resource.reaction?.forEach((reaction) =>
      reaction.manifestation.forEach((manifestation) =>
        manifestations.push(codeableConceptLabel(manifestation)),
      ),
    );

    return manifestations.join(', ');
  }

  public get patientOrganizationName(): string | undefined {
    const reference = findReference(
      'Patient',
      this.resource.contained,
      this.includedResources,
      this.resource.patient,
    );

    if (reference) {
      return new PatientModel(reference, this.includedResources).organizationDisplayName;
    }

    return undefined;
  }

  public get note(): string | undefined {
    let concatenatedString: string | undefined;
    if (this.resource.note) {
      concatenatedString = this.resource.note.map((note) => codeableConceptLabel(note)).join(', ');
    }

    return concatenatedString;
  }

  public get severity(): string | undefined {
    return this.resource.reaction?.[0].severity;
  }

  public get knownCodings(): Array<Coding> {
    const codings = compact(
      ALLERGY_CODE_PREFERENCE_ORDER.map((code) => findCoding(code.system, this.resource.code)),
    );

    return uniqWith(codings, (prev, next) => prev.system === next.system);
  }

  public get onset(): string | undefined {
    return displayOnset(this.resource);
  }

  public get recordedDate(): string | undefined {
    return this.resource.recordedDate ? formatFHIRDate(this.resource.recordedDate) : undefined;
  }

  public get title() {
    return this.display ?? 'Unknown Allergy';
  }

  public get type(): string {
    return this.resource.type ?? '';
  }
}

const ALLERGY_CODE_PREFERENCE_ORDER: Array<CodePreference> = [
  { system: SYSTEM_RXNORM },
  { system: SYSTEM_NDC },
  { system: SYSTEM_SNOMED },
];
