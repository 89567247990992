import type { Breakpoint } from '@ctw/config/zui';
import type { SubNavigationLink } from '@ctw/shared/components/layout/sub-navigation-bar';
import { Link } from '@ctw/shared/components/link';
import { PrototypeLabel } from '@ctw/shared/components/prototype-label';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { useZuiContext } from '@ctw/shared/context/zui-provider';
import { useBreakpointQuery } from '@ctw/shared/hooks/breakpoints';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { hrefIsActive } from '@ctw/shared/utils/url';
import { type IconDefinition, faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { kebabCase } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const VERTICAL_SUB_NAVIGATION_DOCK_TEST_ID = 'vertical-sub-navigation-dock';
export const VERTICAL_SUB_NAVIGATION_EXPAND_COLLAPSE_BUTTON_TEST_ID =
  'vertical-sub-navigation-dock-expand-collapse-button';

export interface VerticalSubNavigationHeaderField {
  label: string;
  value: string;
  icon: IconDefinition;
}

interface VerticalSubNavigationDockBreakpoints {
  visibleAbove: Breakpoint;
}

const defaultBreakpoints: VerticalSubNavigationDockBreakpoints = {
  visibleAbove: 'md',
};

export interface VerticalSubNavigationDockProps {
  className?: ClassName;
  navigationLinks: Array<SubNavigationLink>;
  contentBreakpoints?: VerticalSubNavigationDockBreakpoints;
}

export const VerticalSubNavigationDock = ({
  className,
  navigationLinks,
  contentBreakpoints = defaultBreakpoints,
}: VerticalSubNavigationDockProps) => {
  const asideRef = useRef<HTMLElement>(null);
  const { breakpoints } = useBreakpointQuery();
  const { setUiFlag, getUiFlag } = useZuiContext();
  const { pathname } = useZuiContext();
  const [activeLink, setActiveLink] = useState<SubNavigationLink | undefined>(
    navigationLinks.find(
      (link) =>
        hrefIsActive({ pathname, href: link.href }) ||
        link.siblingHrefs?.some((siblingHref) => hrefIsActive({ pathname, href: siblingHref })),
    ),
  );

  const isExpanded = useMemo(
    () => getUiFlag('verticalSubNavigationDockExpanded', true),
    [getUiFlag],
  );

  const setIsExpanded = useCallback(
    (expanded: boolean) => setUiFlag('verticalSubNavigationDockExpanded', expanded),
    [setUiFlag],
  );

  useEffect(() => {
    setActiveLink(
      navigationLinks.find(
        (link) =>
          hrefIsActive({ pathname, href: link.href }) ||
          link.siblingHrefs?.some((siblingHref) => hrefIsActive({ pathname, href: siblingHref })),
      ),
    );
  }, [navigationLinks, pathname]);

  if (!breakpoints.isAtLeast[contentBreakpoints.visibleAbove]) {
    return <></>;
  }

  return (
    <aside
      data-testid={VERTICAL_SUB_NAVIGATION_DOCK_TEST_ID}
      ref={asideRef}
      aria-label="Sub Navigation Dock"
      className={twx(
        'relative z-navigation max-h-min flex-1 transition-all duration-300',
        {
          'w-[215px] min-w-[215px] max-w-[215px]': isExpanded,
          'w-[60px] min-w-[60px] max-w-[60px]': !isExpanded,
        },
        className,
      )}
    >
      <VerticalSubNavigationExpandCollapseButton
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      />
      <div className={tw`h-full max-h-full w-full overflow-visible`}>
        <nav
          aria-label="Sub Navigation"
          className={tw`scroll-shadows no-scrollbar relative h-min max-h-full overflow-y-auto overflow-x-visible rounded-md bg-white`}
        >
          <ul className={tw`flex h-full max-h-full w-full flex-col gap-1 px-1 py-2`}>
            {navigationLinks.map((link) => (
              <VerticalSubNavigationDockLink
                isActive={link.href === activeLink?.href}
                key={link.label}
                isExpanded={isExpanded}
                onClick={() => setActiveLink(link)}
                {...link}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

interface VerticalSubNavigationDockLinkProps extends SubNavigationLink {
  isActive: boolean;
  isExpanded: boolean;
  onClick: () => void;
}

const VerticalSubNavigationDockLink = ({
  label,
  href,
  icon,
  disabled,
  prototypeStage,
  isActive,
  isExpanded,
  onClick,
}: VerticalSubNavigationDockLinkProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  const onLinkClick = useCallback(() => {
    linkRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    onClick();
  }, [onClick]);

  return (
    <Link
      ref={linkRef}
      href={href}
      onClick={onLinkClick}
      className={twx(
        'relative flex w-full min-w-full flex-1 items-center px-2 py-2 transition-none duration-500 hover:bg-content-subtle/10 hover:no-underline active:bg-content-subtle/10',
        {
          'bg-primary-background-hover': isActive,
        },
      )}
      dataTestId={`vertical-sub-nav-dock-link-${kebabCase(label)}`}
    >
      {isExpanded ? (
        <FontAwesomeIcon
          icon={icon}
          className={twx('h-[24px] min-h-[24px] w-[24px] min-w-[24px] pl-[4px] text-content-icon', {
            'opacity-80': disabled,
            'text-primary-icon': isActive,
          })}
        />
      ) : (
        <Tooltip
          place="right"
          content={`${label}${prototypeStage ? ` (${prototypeStage})` : ''}`}
          className={tw`mr-1 h-[24px] min-h-[24px] w-[24px] min-w-[24px] pl-[4px]`}
        >
          <FontAwesomeIcon
            icon={icon}
            className={twx('h-[24px] min-h-[24px] w-[24px] min-w-[24px] text-content-icon', {
              'opacity-80': disabled,
              'text-primary-icon': isActive,
            })}
          />
        </Tooltip>
      )}
      <div
        className={twx(
          'ml-2 flex items-center gap-1 overflow-hidden whitespace-nowrap text-content-main transition-all duration-500',
          {
            'opacity-0': !isExpanded,
            'opacity-100': isExpanded,
          },
        )}
      >
        <span>{label}</span>
        {prototypeStage && (
          <PrototypeLabel className={tw`pl-1`} stage={prototypeStage} deemphasized={disabled} />
        )}
      </div>
    </Link>
  );
};

interface VerticalSubNavigationExpandCollapseButtonProps {
  isExpanded: boolean;
  onClick?: () => void;
}

const VerticalSubNavigationExpandCollapseButton = ({
  isExpanded,
  onClick,
}: VerticalSubNavigationExpandCollapseButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={tw`-right-[13px] absolute top-5 flex h-[34px] w-[13px] items-center justify-center rounded-tr-[3px] rounded-br-[3px] border-[0.5px] border-divider-main border-l bg-background-hover px-[5px] py-1 text-primary-main drop-shadow-sm focus:outline-4 focus:outline-primary-main focus:outline-offset-[2px] focus-visible:outline-4 focus-visible:outline-primary-main focus-visible:outline-offset-[2px]`}
    data-testid={VERTICAL_SUB_NAVIGATION_EXPAND_COLLAPSE_BUTTON_TEST_ID}
  >
    {isExpanded ? (
      <FontAwesomeIcon icon={faCaretLeft} className={tw`h-3 w-3`} />
    ) : (
      <FontAwesomeIcon icon={faCaretRight} className={tw`h-3 w-3`} />
    )}
  </button>
);
