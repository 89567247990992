import { formatDate, getHumanName, getPeriod } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { LabelValueType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { isEmpty } from 'lodash-es';
import xpath from 'xpath';

export const getDocumentationOf = (
  document: Document,
): Record<string, Array<LabelValueType>> | undefined => {
  const serviceEvents = xpath.select(
    "//*[name()='documentationOf']/*[name()='serviceEvent']",
    document,
  ) as Array<Document>;

  if (isEmpty(serviceEvents)) {
    return undefined;
  }

  const result = serviceEvents.map((serviceEvent) => {
    const effectiveTime = xpath.select1("*[name()='effectiveTime']", serviceEvent) as Document;

    const period = getPeriod(effectiveTime);

    const performerNames = xpath.select(
      "*[name()='performer']/*[name()='assignedEntity']/*[name()='assignedPerson']/*[name()='name']",
      serviceEvent,
    ) as Array<Document>;

    const performers = performerNames.map((performer) => getHumanName(performer));

    return [
      {
        label: 'Date/Time:',
        value:
          period.start || period.end
            ? `from ${formatDate(period.start) || 'unknown'} to ${
                formatDate(period.end) || 'unknown'
              }`
            : '',
      },
      ...performers.map((performer) => ({
        label: 'Performer:',
        value: performer,
      })),
    ];
  });

  return result.reduce((acc, val, index) => ({ ...acc, [`documentationOf${index + 1}`]: val }), {});
};
