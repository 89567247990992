import { PatientHistoryRequestModel } from '@ctw/shared/api/fhir/models/patient-history';
import { usePatientSubscription } from '@ctw/shared/api/subscriptions/subscriptions';
import { Button } from '@ctw/shared/components/button';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { useRequestRecordsModal } from '@ctw/shared/content/patient-history/request-records-modal';
import { usePatientHistory } from '@ctw/shared/content/patient-history/use-patient-history';
import { usePatientHistoryStatus } from '@ctw/shared/content/patient-history/use-patient-history-status';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import {
  type IconDefinition,
  faArrowsRotate,
  faCircleExclamation,
  faWarning,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

interface PatientHistoryStatus {
  icon: IconDefinition;
  iconClassName?: string;
  label: string;
  message?: string;
}

interface PatientHistoryStatusProps {
  expanded: boolean;
  inline?: boolean;
  hideRequestButton?: boolean;
}

export const PatientHistoryStatus = ({
  expanded,
  inline,
  hideRequestButton,
}: PatientHistoryStatusProps) => {
  const hasPatientRecordSearchFeature = useFeatureFlag('ctw-patient-record-search');
  const patientHistory = usePatientHistory();
  const patientSubscription = usePatientSubscription();
  const patientHistoryStatus = usePatientHistoryStatus();
  const openRequestRecordsModal = useRequestRecordsModal();
  const { openHistoryRequestDrawer } = usePatientHistory();
  const { trackInteraction } = useTelemetry();

  const isLoading = useMemo(
    () =>
      patientHistory.isLoading || patientHistoryStatus.isLoading || patientSubscription.isLoading,
    [patientHistory.isLoading, patientHistoryStatus.isLoading, patientSubscription.isLoading],
  );

  const isError = useMemo(
    () => patientHistory.isError || patientHistoryStatus.isError || patientSubscription.isError,
    [patientHistory.isError, patientHistoryStatus.isError, patientSubscription.isError],
  );

  const shouldSkipModal = useMemo(
    () =>
      patientSubscription.data?.package
        ? patientSubscription.data.package.requiresManualRequests
        : true,
    [patientSubscription.data?.package],
  );

  // Only show last completed if there is one or if there isn't a latest in progress or next scheduled
  const lastCompleted: Date | null = useMemo(
    () =>
      Boolean(patientHistory.details.latestInProgress) ||
      !(patientHistory.details.latestInProgress || patientHistory.details.nextScheduledAt)
        ? (patientHistoryStatus.lastCompletedDate ?? null)
        : null,
    [
      patientHistory.details.latestInProgress,
      patientHistory.details.nextScheduledAt,
      patientHistoryStatus.lastCompletedDate,
    ],
  );

  const status: PatientHistoryStatus = useMemo(
    () =>
      (() => {
        const latestHistoryJobInProgress = patientHistory.details.latestInProgress;
        const latestHistoryJobPartiallyComplete = patientHistory.details.latestInProgress
          ? PatientHistoryRequestModel.getDerivedProviderStatus(
              patientHistory.details.latestInProgress.job.attributes.providers,
            ) === 'partiallyComplete'
          : false;
        const lastHistoryJobFailed = patientHistoryStatus.derivedStatus === 'failed';
        const lastHistoryJobCompletedWithErrors =
          patientHistoryStatus.derivedStatus === 'completeWithErrors';

        if (isError || lastHistoryJobFailed) {
          return {
            icon: faCircleExclamation,
            label: 'Last request failed',
            message: 'There was an error fetching records for this patient',
          };
        }

        if (latestHistoryJobPartiallyComplete) {
          return {
            icon: faArrowsRotate,
            iconClassName: 'animate-spin',
            label: 'Last request failed',
            message: 'Current request partially complete',
          };
        }

        if (latestHistoryJobInProgress) {
          return {
            icon: faArrowsRotate,
            iconClassName: 'animate-spin',
            label: 'Last request failed',
            message: 'Request in-progress',
          };
        }

        if (lastHistoryJobCompletedWithErrors) {
          return {
            icon: faWarning,
            label: 'Last updated',
            message: 'There was an error fetching some records for this patient',
          };
        }

        if (lastCompleted) {
          return {
            icon: faArrowsRotate,
            label: 'Last updated',
          };
        }

        return {
          icon: faArrowsRotate,
          label: 'Never requested',
        };
      })(),
    [
      isError,
      lastCompleted,
      patientHistory.details.latestInProgress,
      patientHistoryStatus.derivedStatus,
    ],
  );

  if (isLoading) {
    if (inline) {
      return <LoadingSpinner centered={true} className={tw`text-xs`} iconClass={tw`h-3 w-3`} />;
    }

    return <LoadingSpinner message="Loading..." className={tw`w-full whitespace-nowrap`} />;
  }

  return (
    <div
      className={twx('flex flex-col gap-1 overflow-hidden', {
        'h-full min-h-full flex-row items-center': inline,
      })}
    >
      <div className={tw`flex items-center gap-1`}>
        <Button
          type="button"
          variant="link"
          className={twx(
            'group overflow-hidden whitespace-nowrap text-sm transition-all duration-500',
          )}
          onClick={() => {
            // Skip modal if explicitly requested or if the package requires manual requests.
            if (shouldSkipModal || patientSubscription.data?.package?.requiresManualRequests) {
              void openHistoryRequestDrawer();
            } else {
              openRequestRecordsModal();
            }
            trackInteraction('request_records');
          }}
        >
          {expanded ? (
            <FontAwesomeIcon
              icon={status.icon}
              className={tw`h-[14px] min-h-[14px] w-[14px] min-w-[14px] pl-0.5 text-content-icon transition-all duration-300 group-hover:text-content-main`}
            />
          ) : (
            <Tooltip
              content={`${status.label} ${lastCompleted ? formatFHIRDate(lastCompleted.toISOString()) : ''}`}
              className={tw`flex items-center justify-center`}
            >
              <FontAwesomeIcon
                icon={status.icon}
                className={tw`h-[14px] min-h-[14px] w-[14px] min-w-[14px] pl-0.5 text-content-icon transition-all duration-300 group-hover:text-content-main`}
              />
            </Tooltip>
          )}
        </Button>
        <div className={tw`flex flex-col items-start justify-start gap-1 self-stretch`}>
          <div
            className={twx(
              'ml-1 overflow-hidden whitespace-nowrap font-normal text-content-subtle text-sm leading-tight transition-all duration-500',
              {
                'opacity-0': !expanded,
                'opacity-100': expanded,
                'w-0': !expanded && inline,
              },
            )}
          >
            {`${status.label} ${lastCompleted ? formatFHIRDate(lastCompleted.toISOString()) : ''}`}
          </div>
        </div>
      </div>
      {!(hideRequestButton || hasPatientRecordSearchFeature) && (
        <div className={tw`flex items-center gap-2 overflow-hidden rounded-md`}>
          <Button
            type="button"
            variant="link"
            className={twx(
              'ml-1 overflow-hidden whitespace-nowrap text-sm transition-all duration-500',
              {
                'opacity-0': !expanded,
                'w-auto opacity-100': expanded,
              },
            )}
            onClick={() => {
              // Skip modal if explicitly requested or if the package requires manual requests.
              if (shouldSkipModal || patientSubscription.data?.package?.requiresManualRequests) {
                void openHistoryRequestDrawer();
              } else {
                openRequestRecordsModal();
              }
              trackInteraction('request_records');
            }}
          >
            Request Records
          </Button>
        </div>
      )}
    </div>
  );
};
