import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { usePatientSearchList } from '@ctw/shared/api/fhir/patient-helper';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import {
  ComboboxField,
  type ComboxboxFieldOption,
} from '@ctw/shared/components/form/combobox-field';
import { tw } from '@ctw/shared/utils/tailwind';
import { Combobox } from '@headlessui/react';
import { orderBy } from 'lodash-es';
import { useEffect, useState } from 'react';

type CustomPatientOptionValue = Omit<ComboxboxFieldOption, 'value'> & {
  value: PatientModel;
};

interface PatientsSearchProps {
  onSearchClick?: (e: unknown) => void;
}

export const PatientsSearch = withErrorBoundary({
  boundaryName: 'PatientsSearch',
  includeTelemetryBoundary: true,
  Component: ({ onSearchClick }: PatientsSearchProps) => {
    const [patients, setPatients] = useState<Array<PatientModel>>([]);
    const [searchValue, setSearchValue] = useState<string | undefined>();

    const { data: patientsSearchResponse, isFetching, isError } = usePatientSearchList(searchValue);

    // Here we are setting the total and patients only when we know that useQuery
    // isn't fetching. This will prevent empty intermediate states where there
    // is no data because the value of `usePatientsTable()` hasn't settled yet.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (patientsSearchResponse) {
        setPatients(patientsSearchResponse);
      }
    }, [patientsSearchResponse, isError, isFetching]);

    // This resets our state when there is an error fetching patients from ODS.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (isError) {
        setPatients([]);
      }
    }, [isError, isFetching]);

    const options = orderBy(patients, 'lastName', 'asc').map((patient) => ({
      value: patient,
      label: patient.fullName,
      key: patient.id,
    }));

    return (
      <ComboboxField
        className={tw`flex-1`}
        enableSearchIcon={true}
        options={options}
        readonly={false}
        isLoading={isFetching}
        name="patient-search"
        onCustomSelectChange={onSearchClick}
        renderCustomOption={(e) => <CustomComboBox option={e as CustomPatientOptionValue} />}
        onSearchChange={(value) => {
          setSearchValue(value);
          if (value !== searchValue) {
            setPatients([]);
          }
        }}
        placeholder="Search by patient last name, first and last name, or identifier"
        usesSearchButton={true}
      />
    );
  },
});

export const CustomComboBox = ({ option }: { option: CustomPatientOptionValue }) => (
  <Combobox.Option
    value={option}
    className={({ active }) =>
      `relative flex cursor-default select-none space-x-2 py-2 pr-4 pl-4 ${
        active ? 'bg-primary-background-hover text-primary-text' : 'text-background-inverse'
      }`
    }
  >
    <div className={tw`space-x-1`}>
      <span className={tw`font-medium`}>
        {option.value.lastName}, {option.value.givenName}
      </span>
      {option.value.gender && (
        <span className={tw`font-medium`}>({option.value.gender[0].toUpperCase()})</span>
      )}
    </div>
    {option.value.officialOrUsualIdentifier && (
      <div className={tw`font-medium`}>{option.value.officialOrUsualIdentifier}</div>
    )}
    <div className={tw`space-x-1`}>
      <span>{option.value.dob}</span>
      {option.value.age !== undefined && <span>({option.value.age})</span>}
    </div>
  </Combobox.Option>
);
