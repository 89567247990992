import type { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { type PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

type ZapEventType = 'addToRecord';

interface ZapEventPayload<TResourceTypeName extends ResourceTypeString> {
  resourceType: TResourceTypeName;
  resource: ResourceType<TResourceTypeName>;
}

interface ZapEventPayloads extends Record<ZapEventType, ZapEventPayload<ResourceTypeString>> {
  addToRecord: ZapEventPayload<ResourceTypeString>;
}

interface ZapEvent<EventName extends ZapEventType = ZapEventType> {
  name: ZapEventType;
  payload: ZapEventPayloads[EventName];
}

interface ZapState {
  onZapEvent: <TEventName extends ZapEventType>(event: ZapEvent<TEventName>) => void;
  registerZapEventListener: <TEventName extends ZapEventType>(
    eventName: TEventName,
    handler: (event: ZapEvent<TEventName>) => void,
  ) => void;
  unregisterZapEventListener: <TEventName extends ZapEventType>(
    eventName: TEventName,
    handler: (event: ZapEvent<TEventName>) => void,
  ) => void;
}

const ZapContext = createContext<ZapState>({
  onZapEvent: () => {
    /* noop */
  },
  registerZapEventListener: () => {
    /* noop */
  },
  unregisterZapEventListener: () => {
    /* noop */
  },
});

interface ZapProviderProps extends PropsWithChildren {}

export const ZapProvider = ({ children }: ZapProviderProps) => {
  const [eventListeners, setEventListeners] = useState<
    Record<
      Parameters<ZapState['registerZapEventListener']>[0],
      Array<Parameters<ZapState['registerZapEventListener']>[1]>
    >
  >({
    addToRecord: [],
  });

  const state: ZapState = useMemo(
    () => ({
      onZapEvent: (event) => {
        for (const listener of eventListeners[event.name]) {
          listener(event);
        }
      },
      registerZapEventListener: (eventName, listener) => {
        setEventListeners((prev) => ({
          ...prev,
          [eventName]: [...prev[eventName], listener],
        }));
      },
      unregisterZapEventListener: (eventName, listener) => {
        setEventListeners((prev) => ({
          ...prev,
          [eventName]: prev[eventName].filter((l) => l !== listener),
        }));
      },
    }),
    [eventListeners],
  );

  return <ZapContext.Provider value={state}>{children}</ZapContext.Provider>;
};

export const useZapContext = () => useContext(ZapContext);
