// Want to understand how CareGaps and MeasureReports are related?  Check out the documentation on
// packages/shared/api/fhir/models/care-gap.ts

import { createOrEditFhirResource, deleteFhirResource } from '@ctw/shared/api/fhir/action-helper';
import type { Activity, CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { getUsersPractitionerReference } from '@ctw/shared/api/fhir/practitioner';
import {
  SYSTEM_ZUS_ACTIVITY_NOTE,
  SYSTEM_ZUS_ACTIVITY_STATUS,
  SYSTEM_ZUS_ACTIVITY_TYPE,
  SYSTEM_ZUS_ACTIVITY_UPDATED_BY,
} from '@ctw/shared/api/fhir/system-urls';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { dateToISO } from '@ctw/shared/utils/dates';
import { useQueryClient } from '@tanstack/react-query';
import type { MeasureReport } from 'fhir/r4';

export type UserActivityMeasureReportFormData = Partial<{
  activity: Activity;
  editingActivityId: string | null;
  note: string;
  zusStatus: string;
}>;

// useCreateOrEditUserActivityMeasureReport is a hook returning a function that creates or edits a measure report with a user activity.
export const useCreateOrEditUserActivityMeasureReport = (careGap: CareGapModel) => {
  const telemetry = useTelemetry();
  const queryClient = useQueryClient();
  const ctw = useCTW();

  return async ({
    note,
    zusStatus,
    activity,
    editingActivityId,
  }: UserActivityMeasureReportFormData) => {
    const practitionerReference = await getUsersPractitionerReference(ctw);
    const previousAuthor = editingActivityId ? careGap.latestMeasureReport.reporter : undefined;

    const fhirMeasureReport: MeasureReport = {
      resourceType: 'MeasureReport',
      date: dateToISO(new Date()),
      extension: [
        {
          url: SYSTEM_ZUS_ACTIVITY_NOTE,
          valueMarkdown: note,
        },
        {
          url: SYSTEM_ZUS_ACTIVITY_TYPE,
          valueString: activity,
        },
        ...(zusStatus && zusStatus !== careGap.status.slug
          ? [
              {
                url: SYSTEM_ZUS_ACTIVITY_STATUS,
                valueString: zusStatus,
              },
            ]
          : []),
      ],
      // TODO: Remove `group` from the Activity MeasureReport
      group: careGap.latestMeasureReport.group,
      measure: careGap.latestMeasureReport.measure,
      period: careGap.latestMeasureReport.period,
      reporter: {
        ...practitionerReference,
        extension: previousAuthor
          ? [
              {
                url: SYSTEM_ZUS_ACTIVITY_UPDATED_BY,
                valueReference: previousAuthor,
              },
            ]
          : undefined,
      },
      status: 'complete',
      subject: { type: 'Patient', reference: `Patient/${careGap.subjectId}` },
      type: 'individual',
    };

    if (editingActivityId) {
      fhirMeasureReport.id = editingActivityId;
    }

    try {
      const resource = (await createOrEditFhirResource(fhirMeasureReport, ctw)) as MeasureReport;
      queryClient.invalidateQueries();
      return resource;
    } catch (error) {
      telemetry.trackError({
        message: 'Unable to create MeasureReport due to error executing createOrEditFhirResource',
        error,
      });
      throw error;
    }
  };
};

// useDeleteUserActivityMeasureReport - hook that returns a callback to delete a MeasureReport
export const useDeleteUserActivityMeasureReport = (measureReport: MeasureReport) => {
  const queryClient = useQueryClient();
  const ctw = useCTW();

  return async () => {
    await deleteFhirResource(measureReport, ctw);

    queryClient.invalidateQueries();
  };
};
