import type { FeedbackRequest } from '@ctw/shared/api/ai/types';
import { usePrecomputedNewPatientSummaryForPatient } from '@ctw/shared/api/ai/use-precompute-summary';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { notifyFromDrawer } from '@ctw/shared/components/toast';
import type { TrackingMetadata } from '@ctw/shared/context/telemetry/tracking-metadata';
import { FeedbackComponent } from './feedback/feedback.v2';
import { PatientSummaryDrawerFooter } from './patient-summary-drawer-footer';
import './patient-summary-drawer.scss';
import { useDrawer } from '@ctw/shared/context/drawer-context';
import { tw } from '@ctw/shared/utils/tailwind';
import type { Resource } from 'fhir/r4';

export function usePatientSummaryDrawer<T extends Resource, M extends FHIRModel<T>>() {
  const { openDrawer, closeDrawer, isDrawerOpen } = useDrawer({
    boundaryName: 'PatientSummaryDrawer',
  });
  const { document } = usePrecomputedNewPatientSummaryForPatient('new_patient');

  return {
    openDrawer: ({
      model,
      trackingMetadata,
      feedback,
      setFeedback,
      triggerFeedback,
    }: {
      model?: M;
      trackingMetadata?: TrackingMetadata;
      feedback: FeedbackRequest;
      setFeedback: (feedback: FeedbackRequest) => void;
      triggerFeedback: () => void;
    }) => {
      const handleFeedbackUpdate = (updatedFeedback: FeedbackRequest) => {
        const newFeedback = {
          ...updatedFeedback,
          content: document?.hpi || '',
          citations: document?.citations || [],
        };
        setFeedback(newFeedback);
      };

      handleFeedbackUpdate(feedback);

      const handleFeedbackSubmit = () => {
        notifyFromDrawer({ type: 'success', title: 'Feedback Sent!' });
        void triggerFeedback();
        closeDrawer();
      };

      openDrawer({
        telemetryContext: {
          resourceType: model?.resourceType,
          ...trackingMetadata,
        },
        title: 'Feedback',
        content: {
          body: (
            <div className={tw`space-y-3 p-1`}>
              <FeedbackComponent feedback={feedback} onFeedbackUpdate={handleFeedbackUpdate} />
            </div>
          ),
          footer: (
            <PatientSummaryDrawerFooter
              feedbackEnabled={true}
              onClose={closeDrawer}
              onFeedbackClick={handleFeedbackSubmit}
            />
          ),
        },
      });
    },
    isDrawerOpen,
  };
}
