import { format } from 'date-fns-tz';

// Display a FHIR date (ISO 8601) or number representation of time into the M/d/yyyy format.
export const formatFHIRDate = (date: string | number): string | undefined => {
  if (!date) {
    return undefined;
  }

  const dateIso = typeof date === 'number' ? new Date(ensureJsTimestamp(date)).toISOString() : date;

  const [year, month, day] = dateIso.split('-');
  if (!(year && month && day)) {
    // biome-ignore lint/suspicious/noConsole: <explanation>
    console.warn(`Invalid date: ${date} does not match the expected format YYYY-MM-DD.`);
    return undefined;
  }

  // Remove leading zeros from month and day
  const formattedMonth = month.replace(/^0+/, '');
  const formattedDay = day.substring(0, 2).replace(/^0+/, '');

  return `${formattedMonth}/${formattedDay}/${year}`;
};

function ensureJsTimestamp(n: number) {
  return `${n}`.length <= 11 ? n * 1000 : n;
}

// Format a FHIR date (ISO 8601) into M/d/yyyy, and a datetime into M/d/yyyy HH:mm
export const formatFHIRDateOrDatetime = (datetime: string): string | undefined => {
  if (!datetime) {
    return undefined;
  }

  const [datePart, timePartWithZone] = datetime.split('T');
  const formattedDate = formatFHIRDate(datePart);

  if (!timePartWithZone) {
    return formattedDate; // Valid date without a time part
  }

  const [timePart, timezonePart] = timePartWithZone.match(/^([\d:.]+)([+-].+|Z)?$/)?.slice(1) || [];
  if (!timePart) {
    // biome-ignore lint/suspicious/noConsole: <explanation>
    console.warn(
      `Invalid datetime: ${datetime} does not match the expected format YYYY-MM-DDTHH:mm:ss[.SSS][Z|+|-].`,
    );
    return undefined;
  }

  // Extract HH:mm from time part
  const time = timePart.slice(0, 5); // Extract HH:mm

  // Handle timezone formatting
  let timezone: string | undefined;
  if (timezonePart && timezonePart.length > 0) {
    timezone = timezonePart === 'Z' ? 'UTC' : `GMT${timezonePart}`;
  }

  return `${formattedDate} ${time}${timezone ? ` ${timezone}` : ''}`;
};

// Formats a Date object into "M/D/YYYY, h:mma TZ" format in the user's local time zone.
// Example Output: "10/2/2024, 10:15AM PST"
export function formatDateToLocalString(date?: Date): string {
  if (!date) {
    return 'Unknown';
  }
  const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const pattern = 'M/d/yyyy, h:mma zzz';

  return format(date, pattern, { timeZone: userTimeZone })
    .replace(' AM', 'AM')
    .replace(' PM', 'PM');
}

// Returns the ISO string (YYYY-MM-DD) for a given date.
// We avoid using date-fn's format method to avoid timezone issues.
export function dateToISO(date?: Date) {
  if (date) {
    return date.toISOString().split('T')[0];
  }

  return undefined;
}
