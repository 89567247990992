import { type ReactNode, useMemo, useState } from 'react';
import { BulkActionContext, type ZAPSelectedResources } from './bulk-action-context';

interface BulkActionProviderProps {
  children: ReactNode;
}

export function BulkActionProvider({ children }: BulkActionProviderProps) {
  const [selectedResources, setSelectedResources] = useState<ZAPSelectedResources>({
    'conditions-all': [],
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const value = useMemo(
    () => ({
      selectedResources,
      setSelectedResources,
    }),
    [selectedResources, setSelectedResources],
  );
  return <BulkActionContext.Provider value={value}>{children}</BulkActionContext.Provider>;
}
