import type { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useEncounterDrawer } from '@ctw/shared/hooks/use-encounter-drawer';
import { usePatientEncountersWithClinicalNotes } from '@ctw/shared/hooks/use-encounters';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMemo } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { getDateRangeView } from '../resource/view-date-range';
import { patientEncounterColumns } from './helpers/columns';
import { encounterFilterPredicates, encounterFilters } from './helpers/filters';
import { defaultEncounterSort, encounterSortOptions } from './helpers/sorts';

export const PatientEncounters = withErrorBoundary({
  boundaryName: 'PatientEncounters',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { openDrawer } = useEncounterDrawer();
    const encountersQuery = usePatientEncountersWithClinicalNotes();
    const validFilters = useMemo(
      () => encounterFilters(encountersQuery.data),
      [encountersQuery.data],
    );
    const { viewOptions, allTime } = useMemo(
      () => getDateRangeView<EncounterModel>('periodStart'),
      [],
    );

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-encounters',
        viewOptions,
        defaultView: allTime.display,
        filterPredicates: encounterFilterPredicates,
        defaultSort: defaultEncounterSort,
        records: encountersQuery.data,
      });
    const isEmptyQuery = encountersQuery.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        viewOptions={{
          onChange: setViewOption,
          options: viewOptions,
          defaultView,
        }}
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: encounterSortOptions,
          onChange: setSort,
        }}
      />,
      [viewOptions, defaultView, validFilters, filters, defaultSort, encounterSortOptions],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={encountersQuery.isLoading}
          columns={patientEncounterColumns(requestContext.builderId)}
          data={data}
          stackedBreakpoint="sm"
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="encounters"
            />
          }
          enableDismissAndReadActions={true}
          onRowClick={(model) => {
            openDrawer({
              model,
              telemetryContext: { target: 'encounters' },
            });
          }}
        />
      </div>
    );
  },
});
