import { APP_TOAST_CONTAINER_ID } from '@ctw/shared/components/toast';
import type { ReactNode } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { ToastContext } from './toast-context';

interface ProviderProps {
  children: ReactNode;
  containerId?: string;
}

export function ToastProvider({ children, containerId }: ProviderProps) {
  return (
    <ToastContext.Provider value={undefined}>
      <ToastContainer
        position="bottom-left"
        autoClose={40000}
        hideProgressBar={true}
        closeOnClick={true}
        rtl={false}
        limit={1}
        transition={Zoom}
        pauseOnFocusLoss={true}
        pauseOnHover={true}
        theme="colored"
        enableMultiContainer={true}
        containerId={containerId || APP_TOAST_CONTAINER_ID}
      />
      {children}
    </ToastContext.Provider>
  );
}
