import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';

export const useObservationDrawer = () =>
  useResourceDrawer({
    resourceType: 'Observation',
    action: ({ model }) => ({
      title: model.title,
      content: {
        body: (
          <ContentCard
            variant="focal"
            title="Details"
            content={{
              header: <ResponsiveSourceDocumentLink model={model} />,
            }}
          >
            <DefinitionList
              items={[
                { label: 'Value', value: model.value },
                { label: 'Effective Date', value: model.dateDisplay },
              ]}
            />
          </ContentCard>
        ),
      },
    }),
  });
