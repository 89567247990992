import { usePatientConditionsAll } from '@ctw/shared/api/fhir/conditions';
import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import {
  conditionsColumnGroupedClassNames,
  patientConditionsColumnsGrouped,
} from '@ctw/shared/content/conditions/columns';
import {
  conditionFilterPredicates,
  conditionFilters,
} from '@ctw/shared/content/conditions/filters';
import { useConditionRowActions } from '@ctw/shared/content/conditions/row-actions';
import {
  groupedConditionSortOptions,
  lastUpdatedMostRecentConditionSort,
} from '@ctw/shared/content/conditions/sorts';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTableActions } from '@ctw/shared/content/resource/resource-table-actions';
import { BulkActionContext } from '@ctw/shared/context/bulk-action-context';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { useConditionDrawer } from '@ctw/shared/hooks/use-condition-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { isEmpty } from 'lodash-es';
import { useContext, useEffect, useMemo, useState } from 'react';
import { BulkActionHeaderSelectedState } from '../hooks/use-bulk-action-modal';
import { ResourceTableGrouped } from '../resource/resource-table-grouped';
import {
  RenderInfoBadge,
  RenderWritebackStatusIcon,
  ResourceTitleColumn,
  type WritebackAction,
  type WritebackState,
  type WritebackStatuses,
} from '../resource/resource-title-column';
import {
  bulkDismissConditions,
  conditionsBulkActionColumns,
  useBulkAddConditions,
} from './bulk-actions';
import { type ConditionViewOptions, statusView } from './views';

const writebackStateOnLoad = (condition: ConditionModel): WritebackState => {
  let status: WritebackState = 'not-known-to-builder';
  if (condition.isDismissed) {
    status = 'dismissed';
  } else if (condition.syncedWithRecord) {
    status = 'known-to-builder';
  }
  return status;
};

export const PatientConditions = withErrorBoundary({
  boundaryName: 'PatientConditions',
  includeTelemetryBoundary: true,
  Component: () => {
    const query = usePatientConditionsAll();
    const { isWritebackEnabled } = useWritebacks();
    const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
    const selectedConditions = selectedResources['conditions-all'];
    const conditionWritebackEnabled = useMemo(
      () => isWritebackEnabled('conditions-all'),
      [isWritebackEnabled],
    );
    const [writebackStatuses, setWritebackStatuses] = useState<WritebackStatuses>(
      Object.fromEntries(
        query.data.map((condition) => [
          condition.id,
          {
            state: writebackStateOnLoad(condition),
          },
        ]),
      ),
    );

    useEffect(() => {
      setWritebackStatuses((prev) => {
        const newStatuses = { ...prev };

        for (const condition of query.data) {
          if (!newStatuses[condition.id]) {
            newStatuses[condition.id] = {
              state: writebackStateOnLoad(condition),
            };
          }
        }

        return newStatuses;
      });
    }, [query.data]);

    const onWritebackStart = (action: WritebackAction, id: string) => {
      if (action === 'dismiss-single' || action === 'restore') {
        return; // We don't want a loading icon for this as the row button is already loading
      }
      setWritebackStatuses((prev) => ({
        ...prev,
        [id]: { state: 'in-progress' },
      }));
    };

    const onWritebackEnd = (
      action: WritebackAction,
      id: string,
      isError: boolean,
      errorMessage?: string,
    ) => {
      setWritebackStatuses((prev) => {
        if (isError) {
          return { ...prev, [id]: { state: 'error', message: errorMessage } };
        }

        const condition = query.data.find((c) => c.id === id);
        switch (action) {
          case 'add':
            return { ...prev, [id]: { state: 'known-to-builder-new' } };
          case 'dismiss-bulk':
            return { ...prev, [id]: { state: 'bulk-dismissed-new' } };
          case 'dismiss-single':
            return { ...prev, [id]: { state: 'dismissed' } };
          default:
            return condition ? { ...prev, [id]: { state: writebackStateOnLoad(condition) } } : prev;
        }
      });
    };

    const bulkAddConditions = useBulkAddConditions();
    const rowActions = useConditionRowActions(
      conditionWritebackEnabled,
      onWritebackStart,
      onWritebackEnd,
    );

    const { viewOptions, current } = statusView;
    const {
      data,
      filters,
      setFilters,
      setSort,
      viewOption,
      setViewOption,
      defaultSort,
      defaultView,
    } = useFilteredSortedData({
      cacheKey: 'patient-conditions-all-grouped',
      defaultSort: lastUpdatedMostRecentConditionSort,
      viewOptions,
      filterPredicates: conditionFilterPredicates,
      records: query.data,
      defaultView: current.display,
    });

    const isEmptyQuery = query.data.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;
    const showEmptyTable = isEmptyQuery || hasZeroFilteredRecords;

    const { openDrawer: openConditionDrawer } = useConditionDrawer({
      resourceActions: rowActions as never,
      enableDismissAndReadActions: true,
    });

    const select = (record: ConditionModel) => {
      setSelectedResources({
        ...selectedResources,
        'conditions-all': [...selectedConditions, record],
      });
    };
    const deselect = (record: ConditionModel) => {
      setSelectedResources({
        ...selectedResources,
        'conditions-all': selectedConditions.filter((r) => r.id !== record.id),
      });
    };

    const validConditionFilters = useMemo(
      () => conditionFilters(query.data, true, viewOption as ConditionViewOptions),
      [query.data, viewOption],
    );

    useMainContentHeader(
      <div className={tw`flex h-full w-full flex-col items-start justify-center gap-2`}>
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
          sortOptions={{
            defaultSort,
            options: groupedConditionSortOptions,
            onChange: setSort,
          }}
          filterOptions={{
            onChange: setFilters,
            filters: validConditionFilters,
            selected: filters,
          }}
        />
        {conditionWritebackEnabled && (
          <BulkActionHeaderSelectedState
            selectedKey="conditions-all"
            resourceName="condition"
            onWritebackStart={onWritebackStart}
            onWritebackEnd={onWritebackEnd}
            bulkAdd={bulkAddConditions}
            bulkDismiss={bulkDismissConditions}
            columns={conditionsBulkActionColumns(selectedResources, setSelectedResources)}
          />
        )}
      </div>,
      [
        conditionWritebackEnabled,
        bulkAddConditions,
        bulkDismissConditions,
        selectedResources,
        viewOptions,
        groupedConditionSortOptions,
        defaultSort,
        defaultView,
        filters,
        validConditionFilters,
      ],
    );

    return (
      <>
        {showEmptyTable && (
          <div className={tw`my-16`}>
            <EmptyPatientTable
              resourceName="conditions"
              hasZeroFilteredRecords={hasZeroFilteredRecords}
            />
          </div>
        )}

        {!showEmptyTable && (
          <ResourceTableGrouped
            data={data}
            onRowClick={(model) => openConditionDrawer({ model })}
            loading={query.isLoading}
            telemetryTargetName="condition"
            emptyStateMessage="We didn't find any condition records for this patient."
            rowClassName={tw`border-divider-main border-b last:border-none`}
            groupBy={(record: ConditionModel) => record.ccsChapter || 'Uncategorized'}
            rowActions={rowActions}
            columnHeaders={patientConditionsColumnsGrouped}
            enableDismissAndReadActions={true}
            testId="patient-conditions-all-grouped"
            selectedResources={selectedConditions}
            setSelectedResources={
              conditionWritebackEnabled
                ? (resources) => setSelectedResources({ 'conditions-all': resources })
                : undefined
            }
            writebackStatuses={writebackStatuses}
            onWritebackStart={onWritebackStart}
            onWritebackEnd={onWritebackEnd}
            renderResource={(record: ConditionModel) => {
              const writebackState = writebackStatuses[record.id] ?? {
                state: 'unknown',
              };
              return (
                <div
                  className={tw`group relative flex items-center justify-between space-x-1.5 text-left text-sm`}
                  data-testid="patient-conditions-all-row"
                >
                  <div className={twx(conditionsColumnGroupedClassNames.builderOwned)} role="cell">
                    {RenderWritebackStatusIcon(
                      writebackState,
                      {
                        isSelected: selectedConditions.includes(record),
                        changeState: (isBeingSelected: boolean) => {
                          if (isBeingSelected) {
                            select(record);
                          } else {
                            deselect(record);
                          }
                        },
                      },
                      conditionWritebackEnabled,
                    )}
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.displayName)} role="cell">
                    <ResourceTitleColumn
                      title={<CopyToClipboard>{record.display}</CopyToClipboard>}
                      renderIcon={RenderInfoBadge(
                        isEmpty(record.hccEnrichment) ? undefined : 'HCC',
                        '',
                        isEmpty(record.hccEnrichment) ? undefined : 'Risk-adjusting condition',
                      )}
                    />
                  </div>
                  <div
                    className={twx(conditionsColumnGroupedClassNames.latestDiagnosis)}
                    role="cell"
                  >
                    <div>{record.recordedDate}</div>
                    <div>{record.recorder}</div>
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.onset)} role="cell">
                    {record.onset}
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.type)} role="cell">
                    {record.typeDisplay}
                  </div>
                </div>
              );
            }}
          />
        )}
      </>
    );
  },
});
