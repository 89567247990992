import { tw, twx } from '@ctw/shared/utils/tailwind';
import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BadgeVariant = 'primary' | 'info' | 'muted' | 'caution';
export type BadgeSize = 'sm' | 'md' | 'lg';

export type BadgeProps = {
  className?: string;
  variant: BadgeVariant;
  size?: BadgeSize;
  content: string;
  icon?: IconDefinition;
  testId?: string;
};

export const Badge = ({ className, variant, size = 'md', content, icon, testId }: BadgeProps) => (
  <div
    className={twx(
      'flex max-h-fit max-w-fit items-center justify-center gap-1 whitespace-nowrap rounded-full border border-divider-main/25',
      {
        // Variant styling
        'bg-success-background text-success-text': variant === 'primary', // green
        'bg-info-background text-info-text': variant === 'info', // blue
        'bg-muted-background text-muted-text': variant === 'muted', // grey
        'bg-caution-background text-caution-text': variant === 'caution', // yellow
        // Size styling
        'px-1 py-1 text-[0.7rem] leading-[0.75rem]': size === 'sm',
        'px-2 py-1 text-xs': size === 'md',
        'px-2 py-1 text-sm': size === 'lg',
      },
      className,
    )}
    data-testid={testId}
  >
    {icon && (
      <FontAwesomeIcon
        icon={icon}
        className={twx({
          'text-success-main': variant === 'primary',
          'text-info-main': variant === 'info',
          'text-caution-main': variant === 'caution',
          'text-muted-main': variant === 'muted',
        })}
      />
    )}
    <div className={tw`font-medium uppercase`}>{content}</div>
  </div>
);
