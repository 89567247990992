import type { EpisodeOfCareModel } from '@ctw/shared/api/fhir/models/episode-of-care';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { useBinaries } from '@ctw/shared/api/fqs-rest/binaries';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList, type DefinitionListItem } from '@ctw/shared/components/definition-list';
import { Heading } from '@ctw/shared/components/heading';
import { Link } from '@ctw/shared/components/link';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { ViewCCDAButton } from '@ctw/shared/content/document/view-ccda-button';
import { PatientInfoLine } from '@ctw/shared/content/patients/patient-info-line';
import { TransitionDrawerPatientProvider } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useResourceDrawer } from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UseEpisodeOfCareDrawerOptions {
  linkToPatient?: boolean;
}

/*
Details drawer for episode of care. Drawer will show heading based on
patient if goToPatient is provided (Transitions use case), otherwise
drawer header will be based on the episode of care.
*/
export function useEpisodeOfCareDrawer(
  { linkToPatient = false }: UseEpisodeOfCareDrawerOptions = { linkToPatient: false },
) {
  return useResourceDrawer({
    resourceType: 'EpisodeOfCare',
    enableDismissAndReadActions: true,
    action: ({ model }) => ({
      title: 'Episode of Care',
      subtitle: model.location,
      content: {
        header: (
          <div className={tw`w-full py-2`}>
            {linkToPatient && model.patient && model.patientUPID ? (
              <PatientHeader
                patient={model.patient}
                patientUPID={model.patientUPID}
                linkToPatient={linkToPatient}
              />
            ) : (
              <EpisodeOfCareHeader episodeOfCare={model} />
            )}
            <PatientInfoLine patient={model.patient} />
          </div>
        ),
        body: model.patientUPID && (
          <TransitionDrawerPatientProvider patientUPID={model.patientUPID}>
            <div className={tw`flex flex-col gap-5`}>
              <ContentCard
                variant="focal"
                title="Details"
                content={{
                  header: <ResponsiveSourceDocumentLink model={model} />,
                }}
              >
                <DefinitionList items={episodeOfCareData(model)} />
              </ContentCard>
              <EncountersAndNotes model={model} />
            </div>
          </TransitionDrawerPatientProvider>
        ),
      },
    }),
  });
}

const episodeOfCareData = (toc: EpisodeOfCareModel): Array<DefinitionListItem> => {
  const admittingDiagnosis = toc.diagnosesForRole('AD');
  const dischargeDiagnosis = toc.diagnosesForRole('DD');

  return [
    { label: 'Start Date', value: toc.periodStart },
    { label: 'End Date', value: toc.periodEnd },
    { label: 'Type', value: toc.class },
    { label: 'Location', value: toc.location },
    {
      label: 'Admitting Diagnosis',
      value:
        admittingDiagnosis.length > 0 ? (
          <DiagnosisDetails diagnosis={admittingDiagnosis} />
        ) : undefined,
    },
    {
      label: 'Discharge Diagnosis',
      value:
        dischargeDiagnosis.length > 0 ? (
          <DiagnosisDetails diagnosis={dischargeDiagnosis} />
        ) : undefined,
    },

    { label: 'Discharge Disposition', value: toc.dischargeDisposition },
  ];
};

function EncountersAndNotes({ model }: { model: EpisodeOfCareModel }) {
  const query = useBinaries(model.dischargeBinaryIds);
  const { trackInteraction } = useTelemetry();

  if (model.dischargeBinaryIds.length === 0) {
    return null;
  }

  if (query.isLoading) {
    return <LoadingSpinner message="Loading documents" />;
  }

  const preferredBinaryIds = model.preferredDischargeBinaryIds;

  return (
    <div className={tw`space-y-4`}>
      <Heading level="h3">Documents</Heading>
      {query.data?.map((binary) => (
        <ViewCCDAButton
          onClick={() => {
            trackInteraction('view_adt_discharge_summary', {
              resourceType: 'EpisodeOfCare',
            });
          }}
          ccdaTitle="Encounter Summary"
          key={binary.id}
          binary={binary}
          patient={model.patient as PatientModel}
          preferred={preferredBinaryIds.includes(binary.id)}
        />
      ))}
    </div>
  );
}

type PatientHeaderProps = {
  patient: PatientModel;
  patientUPID: string;
  linkToPatient: boolean;
};

function PatientHeader({ patient, patientUPID, linkToPatient }: PatientHeaderProps) {
  const { display, gender } = patient;

  return (
    <div className={tw`flex justify-between`}>
      {display}
      {gender ? ` (${gender[0].toUpperCase()})` : ''}
      {linkToPatient && (
        <Link
          href={`/patients/${patientUPID}`}
          className={tw`flex cursor-pointer items-center space-x-2 border-0 bg-transparent p-0 text-primary-text leading-none outline-none hover:text-primary-main`}
          target="_blank"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} className={tw`h-3`} />
          <span className={tw`normal-case`}>Go To Profile</span>
        </Link>
      )}
    </div>
  );
}

function EpisodeOfCareHeader({ episodeOfCare }: { episodeOfCare: EpisodeOfCareModel }) {
  return <div className={tw`flex justify-between`}>{episodeOfCare.title}</div>;
}

type DiagnosisDetailsProps = {
  diagnosis: Array<string>;
};
function DiagnosisDetails({ diagnosis }: DiagnosisDetailsProps) {
  if (diagnosis.length === 1) {
    return <span>{diagnosis[0]}</span>;
  }

  return (
    <ul className={tw`list-inside list-disc`}>
      {diagnosis.map((d) => (
        <li key={d}>{d}</li>
      ))}
    </ul>
  );
}
