import { formatFHIRDate } from '@ctw/shared/utils/dates';
import type { Immunization } from 'fhir/r4';
import { capitalize } from 'lodash-es';
import { codeableConceptLabel, findCoding } from '../codeable-concept';
import { quantityLabel } from '../quantity';
import { findReference } from '../resource-helper';
import { SYSTEM_CVX } from '../system-urls';
import { FHIRModel } from './fhir-model';

export class ImmunizationModel extends FHIRModel<Immunization> {
  public kind = 'Immunization' as const;

  public get description(): string | undefined {
    return this.resource.vaccineCode.text;
  }

  public get cvxCode(): string | undefined {
    const cvxCoding = findCoding(SYSTEM_CVX, this.resource.vaccineCode);
    return cvxCoding?.code;
  }

  // String used to define a unique instance of an immunization administered for a patient.
  public get uniqueKey(): string {
    return `${this.cvxCode || this.description} - ${this.occurrence}`;
  }

  public get doseQuantity(): string {
    return quantityLabel(this.resource.doseQuantity);
  }

  public get route(): string {
    return codeableConceptLabel(this.resource.route);
  }

  public get site(): string {
    return codeableConceptLabel(this.resource.site);
  }

  public get status(): string {
    return capitalize(this.resource.status);
  }

  public get notesDisplay(): Array<string> {
    return this.resource.note?.map(({ text }) => text) || [];
  }

  public get occurrence(): string | undefined {
    if (this.resource.occurrenceDateTime) {
      return formatFHIRDate(this.resource.occurrenceDateTime);
    }

    return this.resource.occurrenceString;
  }

  public get managingOrganization(): string | undefined {
    const organizationDisplay = findReference(
      'Patient',
      this.resource.contained,
      this.includedResources,
      this.resource.patient,
    );

    const organizationName = findReference(
      'Organization',
      this.resource.contained,
      this.includedResources,
      organizationDisplay?.managingOrganization,
    )?.name;

    return organizationDisplay?.managingOrganization?.display || organizationName;
  }

  public get title() {
    return this.description ?? 'Unknown Immunization';
  }
}
