import type { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import { useHistory } from '@ctw/shared/content/resource/history';
import type { HistoryEntryProps } from '@ctw/shared/content/resource/history-entry';
import { QUERY_KEY_CONDITION_HISTORY } from '@ctw/shared/utils/query-keys';
import { tw } from '@ctw/shared/utils/tailwind';
import { capitalize, startCase } from 'lodash-es';

export function useConditionHistory(condition: ConditionModel) {
  return useHistory({
    resourceType: 'Condition',
    model: condition,
    queryKey: QUERY_KEY_CONDITION_HISTORY,
    valuesToDedupeOn,
    getFiltersFQS,
    getHistoryEntry,
  });
}

function getFiltersFQS(condition: ConditionModel) {
  const tokens = condition.knownCodings.map((coding) => `${coding.system}|${coding.code}`);

  if (tokens.length > 0) {
    return {
      code: { anymatch: tokens },
    };
  }

  return undefined;
}

function getHistoryEntry(condition: ConditionModel): HistoryEntryProps {
  const detailData = [
    {
      label: 'Recorder',
      value: condition.recorder,
    },
    {
      label: 'Clinical Status',
      value: capitalize(condition.clinicalStatus),
    },
    {
      label: 'Verification Status',
      value: capitalize(condition.verificationStatus),
    },
    {
      label: 'Category',
      value: startCase(condition.category),
    },
    {
      label: 'Note',
      value: condition.notes.length > 0 && (
        <div className={tw`flex flex-col space-y-4`}>
          {condition.notes.map((note) => (
            <div key={note}>
              <div>{note}</div>
            </div>
          ))}
        </div>
      ),
    },
    {
      label: 'Recorded Code(s)',
      value: (
        <div className={tw`space-y-1`}>
          {condition.knownCodings.map((coding) => (
            <div key={`${coding.system}-${coding.code}`}>{coding.display}</div>
          ))}
        </div>
      ),
    },
    {
      label: 'Onset Date',
      value: condition.onset,
    },
    {
      label: 'Abatement Date',
      value: condition.abatement,
    },
    {
      label: 'Encounter',
      value: condition.encounter,
    },
  ];

  return {
    id: condition.id,
    model: condition,
    date: condition.recordedDate,
    versionId: condition.versionId,
    title: condition.patientOrganizationName,
    details: detailData,
  };
}

const valuesToDedupeOn = (condition: ConditionModel) => [
  condition.recorder,
  condition.clinicalStatus,
  condition.verificationStatus,
  condition.recordedDate,
  condition.notes,
  condition.category,
  condition.onset,
  condition.abatement,
  condition.encounter,
  condition.knownCodings.map((coding) => [coding.system, coding.code]),
];
