import { formatFHIRDateOrDatetime } from '@ctw/shared/utils/dates';
import type { AllergyIntolerance, Condition } from 'fhir/r4';
import { formatAge } from './formatters';

export function displayOnset(resource: Condition | AllergyIntolerance): string | undefined {
  let dateISO = resource.onsetString;
  if (resource.onsetAge) {
    return formatAge(resource.onsetAge);
  }

  if (resource.onsetDateTime) {
    dateISO = resource.onsetDateTime;
  }

  if (resource.onsetPeriod) {
    dateISO = resource.onsetPeriod.start;
  }

  if (resource.onsetRange) {
    dateISO = resource.onsetRange.low?.value?.toString();
  }

  return dateISO ? formatFHIRDateOrDatetime(dateISO) : undefined;
}
