export const QUERY_KEY_AI_FEEDBACK = 'ai_feedback' as const;
export const QUERY_KEY_AI_CHART_REVIEW_SUMMARY = 'ai_chart_review_summary' as const;
export const QUERY_KEY_AI_SEARCH = 'ai_search' as const;
export const QUERY_KEY_ALLERGY_HISTORY = 'allergy_history' as const;
export const QUERY_KEY_BINARIES_BY_ID = 'binaries_by_id' as const;
export const QUERY_KEY_BINARY = 'binary' as const;
export const QUERY_KEY_BUILDER_CONFIG = 'builder_config' as const;
export const QUERY_KEY_BUILDER_PATIENTS_BY_UPID = 'builder_patients_by_upid' as const;
export const QUERY_KEY_CARETEAM = 'patient_care_team' as const;
export const QUERY_KEY_CCDA_PATIENT = 'ccda_patient' as const;
export const QUERY_KEY_CONDITION_HISTORY = 'condition_history' as const;
export const QUERY_KEY_DATA_INDICATORS = 'patient_data_indicators' as const;
export const QUERY_KEY_EHR_BACKFILL_REQUEST = 'ehr_backfill_request' as const;
export const QUERY_KEY_MATCHED_PATIENTS = 'matched_patients' as const;
export const QUERY_KEY_MEDICATION_HISTORY = 'medication_history' as const;
export const QUERY_KEY_ON_DEMAND_LENS_API = 'on_demand_lens_api' as const;
export const QUERY_KEY_PATIENT = 'patient' as const;
export const QUERY_KEY_PATIENT_CARE_GAPS = 'patient_care_gaps' as const;
export const QUERY_KEY_PATIENTS_LIST_FQS = 'patients_list_fqs' as const;
export const QUERY_KEY_PATIENT_SEARCH = 'patient_search' as const;
export const QUERY_KEY_PATIENT_ALLERGIES = 'patient_allergies' as const;
export const QUERY_KEY_BINARY_ID = 'patient_binary_id' as const;
export const QUERY_KEY_DEEP_LINKED_RESOURCE = 'deep_linked_resource' as const;
export const QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS = 'patient_builder_encounters' as const;
export const QUERY_KEY_PATIENT_BUILDER_MEDICATIONS = 'patient_builder_medications' as const;
export const QUERY_KEY_PATIENT_SUMMARY_CONDITIONS = 'patient_summary_conditions' as const;
export const QUERY_KEY_PATIENT_BUILDER_CONDITIONS = 'patient_builder_conditions' as const;
export const QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS = 'patient_diagnostic_reports' as const;
export const QUERY_KEY_PATIENT_DOCUMENTS = 'patient_documents' as const;
export const QUERY_KEY_PATIENT_DOCUMENTS_WITH_BASICS = 'patient_documents_with_basics' as const;
export const QUERY_KEY_PATIENT_EPISODES_OF_CARE = 'patient_episodes_of_care' as const;
export const QUERY_KEY_PATIENT_HISTORY_DETAILS = 'patient_history_details' as const;
export const QUERY_KEY_PATIENT_HISTORY_LIST = 'patient_history_list' as const;
export const QUERY_KEY_PATIENT_IMMUNIZATIONS = 'patient_immunizations' as const;
export const QUERY_KEY_PATIENT_SUBSCRIPTION = 'patient_subscription' as const;
export const QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS = 'patient_summary_encounters' as const;
export const QUERY_KEY_PATIENT_SERVICE_REQUESTS = 'patient_service_requests' as const;
export const QUERY_KEY_PATIENT_APPOINTMENTS = 'patient_appointments' as const;
export const QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS = 'patient_summary_medications' as const;
export const QUERY_KEY_PATIENT_VITALS = 'patient_vitals' as const;
export const QUERY_KEY_PATIENT_OBSERVATIONS = 'patient_observations' as const;
