// #################################################################################################
// # MeasureReport Resource Usage (a pseudo implementation guide)
// #################################################################################################
//
// There are two different ways that the MeasureReport gets used in the context of a CareGap:
// 1. To represent an actual gap in care (this patient's blood pressure is too high/just right)
// 2. To allow users to comment on gaps in care ("I called the patient to remind them to take their
//    medication").
//
// We are generally using the DaVinci "Data Exchange For Quality Measures Implementation Guide"
// implementation guide, but we aren't following it precisely, so we'll document each use case in
// here as a sort of abbreviated implementation guide.
//
// -------------------------------------------------------------------------------------------------
//  MeasureReports for gaps in care
// -------------------------------------------------------------------------------------------------
// - group.population:  We have three potential populations: numerator, denominator, and exclusion.
//   Each of these populations will have either 1 or 0 patients in them.  In all cases there will be
//   a numerator and deominator population, but the exclusion population is optional.
// - group.population.extension[http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription]:
//   We use this extension to store the reason why a patient is in a particular population.  There
//   may be multiple entries here.  This text gets rendered in the UI in order to explain a patient
//   is in a particular population (e.g. patient excluded to to fraility).
// - measure: This is a reference to the Measure resource that the CareGap is associated with.  The
//   FHIR standard says that this is suppossed to be a "canonical URL" which points to Measure.url
//   However, due to FQS limitations there's no way to look up Measure resources by their URL field,
//   so we use  two different approaches to populate this field:
//   - Put a "real" link to the resource in the field (e.g.
//     https://fqs.sandbox.zusapi.com/rest/Measure/1234-abcd).  When we encounter links of that
//     shape we extract the ID from the URL and use it as the measure ID.
//   - We also have canonical URLS like http://zusapi.com/quality/measure/ursa-hedis/CBP which are
//     mapped directly to Measure resoures by ID in the code.  You can see these in
//     packages/shared/api/fhir/mappings/canonical-measures.ts
// - period: This is a denormalization of the Measure's date range.  So if the MeasureReport is for a
//   2024 HEDIS measure, you'd expect the period to be 2024-01-01 to 2024-12-31.
// - subject: The patient this MeasureReport is about.
//
// -------------------------------------------------------------------------------------------------
// MeasureReports for user activity
// -------------------------------------------------------------------------------------------------
// - extension[https://zusapi.com/fhir/extension/caregap-activity-note]: This is a freetext markdown
//   field for the user to enter notes about the caregap.  Although we are storing this as Markdown,
//   the current rendering of notes treats them as raw text (as of now).
// - extension[https://zusapi.com/fhir/extension/caregap-activity-type]: This is a string field that
//   should be something like: "Appointment", "Documentation", "Patient Outreach", etc.  This field
//   is used to categorize what was happening at the moment when the user activity on the care gap
//   occurred. (Is this note from an appointment?  a phone call?)
// - extension[https://zusapi.com/fhir/extension/caregap-activity-status]: This is a string field
//   that should be something like: "Not met", "Pending", "Met", etc.  This field is used to
//   categorize the status of the care gap at the time of the user activity. These values map to the
//   Activity type definied in the care gap model (this file).  In some cases, this may result in a
//   change to the overall status of the care gap.
// - measure: This will be the same Measure resource that the CareGap is associated with.  We group
//   by measure when rendering.
// - reporter
//   - display:  The email of the user who took the action
//   - reference:  The reference to the Practitioner resource of the user who took the action (in
//     cases where there is a reference)
//   - extension[https://zusapi.com/fhir/extension/caregap-activity-updated-by]: If an activity gets
//     edited, this field will be set to the email of the user who made the edit.  If there haven't
//     been any edits, then this extension won't be included.  Note that in the case of multiple
//     edits, there will be nested extensions (one per edit)
// - group: This gets copied from the "parent" measure report, but as of this writing, we don't
//   really use it.

import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import {
  SYSTEM_ZUS_ACTIVITY_NOTE,
  SYSTEM_ZUS_ACTIVITY_STATUS,
  SYSTEM_ZUS_ACTIVITY_TYPE,
} from '@ctw/shared/api/fhir/system-urls';
import { ZUS_CREATION_DATE_URL } from '@ctw/shared/content/document/helpers/filters';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import type { Measure, MeasureReport, MeasureReportGroupPopulation } from 'fhir/r4';
import { find, isEmpty, orderBy } from 'lodash-es';
import { FHIRModel } from './fhir-model';

export type Activity =
  | 'Appointment'
  | 'Documentation'
  | 'Patient Outreach'
  | 'Referral'
  | 'Order or Referral'
  | 'Other';

export const activityTypes: Array<Activity> = [
  'Appointment',
  'Documentation',
  'Patient Outreach',
  'Order or Referral',
  'Other',
];

export type CareGapStatus = {
  slug: 'closed' | 'excluded' | 'not-met' | 'open' | 'pending';
  label: string;
  pastTense: string;
};

export type MeasureReportId = string;
export interface CareGapEvidence {
  [key: MeasureReportId]: Array<DiagnosticReportModel>;
}

export const ZUS_STATUS_NOT_MET = 'Not met';
export const ZUS_STATUS_PENDING = 'Pending';

export class CareGapModel extends FHIRModel<Measure> {
  public kind = 'CareGap' as const;

  public measureReports: Array<MeasureReport>;

  public readonly allEvidence: CareGapEvidence;

  public constructor(
    measure: Measure,
    measureReports: Array<MeasureReport> = [],
    evidence?: CareGapEvidence,
  ) {
    super(measure);
    this.allEvidence = evidence || {};
    // Sort MeasureReport resource from latest to earliest by `date`, falling back to resource
    // creation as a tie-breaker.
    this.measureReports = orderBy(
      measureReports,
      [
        (report) => (report.date ? new Date(report.date).getTime() : 0),
        (report) => {
          const instant = report.meta?.extension?.find(
            (e) => e.url === ZUS_CREATION_DATE_URL,
          )?.valueInstant;
          return instant ? new Date(instant).getTime() : 0;
        },
      ],
      ['desc', 'desc'],
    );
  }

  public get latestMeasureReport(): MeasureReport {
    return this.measureReports[0] ?? {};
  }

  public get resourceTypeTitle(): string {
    return 'Care Gap';
  }

  public get groupPopulation(): Array<MeasureReportGroupPopulation> {
    return this.latestMeasureReport.group?.[0].population ?? [];
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  public get inDenominator(): boolean {
    const denomPop = this.groupPopulation.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'denominator'),
    );
    return (denomPop?.count ?? 0) > 0;
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  public get inNumerator(): boolean {
    const numPop = this.groupPopulation.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'numerator'),
    );
    return (numPop?.count ?? 0) > 0;
  }

  // The title of the measure that this gap belongs to (e.g. "Controlling Blood Pressure").  This value
  // comes from the attached Measure resource.
  public get title(): string {
    return this.resource.title || 'Unnamed Measure';
  }

  public get practitionerActivity(): Activity | undefined {
    if (this.latestMeasureReport.reporter?.type !== 'Practitioner') {
      return undefined;
    }
    const activityExtension = this.latestMeasureReport.extension?.filter(
      (ext) => ext.url === SYSTEM_ZUS_ACTIVITY_TYPE,
    )[0];

    const activity = activityExtension?.valueString ?? '';
    if (!activityTypes.includes(activity as Activity)) {
      return undefined;
    }
    return activity as Activity;
  }

  public get practitionerNote(): string | undefined {
    if (this.latestMeasureReport.reporter?.type !== 'Practitioner') {
      return undefined;
    }
    const noteExtension = this.latestMeasureReport.extension?.filter(
      (ext) => ext.url === SYSTEM_ZUS_ACTIVITY_NOTE,
    )[0];

    return noteExtension?.valueMarkdown ?? undefined;
  }

  // The description of the measure that this gap belongs to (e.g. "The percentage of patients 45 to
  // 75 years of age with appropriate screening for colorectal cancer").  Although the FHIR spec
  // supports this field as "Markdown", our UI is expecting it to be in plain text.
  public get measureDescription(): string | undefined {
    return this.resource.description;
  }

  public get updatedDate(): string {
    if (!this.latestMeasureReport.date) {
      return '';
    }

    const updatedDate = new Date(this.latestMeasureReport.date);

    return updatedDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  public get lastUpdated(): string | undefined {
    const datetime = this.latestMeasureReport.meta?.lastUpdated;
    if (!datetime) {
      return undefined;
    }
    return formatFHIRDate(datetime);
  }

  // Excluded indicates that the care gap is excluded from the measure calculation.
  public get excluded(): boolean {
    const excludedPop = this.groupPopulation.find((pop) =>
      pop.code?.coding?.some((c) => c.code === 'denominator-exclusion'),
    );

    return !(this.inDenominator || this.inNumerator) && (excludedPop?.count ?? 0) > 0;
  }

  // Closed indicates that the care gap is closed by having the patient in both the numerator and
  // the denominator (in other words the clinical criteria for closure has been met).
  public get closed(): boolean {
    return this.inDenominator && this.inNumerator;
  }

  // status is the status of the care gap, not the status of the measure report.
  public get status(): CareGapStatus {
    const zusStatus = find(this.latestMeasureReport.extension, {
      url: SYSTEM_ZUS_ACTIVITY_STATUS,
    })?.valueString;
    if (this.closed) {
      return { slug: 'closed', label: 'Met', pastTense: 'Met' };
    }
    if (this.excluded) {
      return { slug: 'excluded', label: 'Excluded', pastTense: 'Excluded' };
    }
    if (zusStatus === ZUS_STATUS_NOT_MET) {
      return { slug: 'not-met', label: 'Not met', pastTense: 'Not met' };
    }
    if (zusStatus === ZUS_STATUS_PENDING) {
      return { slug: 'pending', label: 'Pending', pastTense: 'Pending' };
    }
    return { slug: 'open', label: 'Open', pastTense: 'Opened' };
  }

  public get exclusionReasons(): Array<string> {
    return (
      this.groupPopulation
        // Grab the populations that are marked as denominator-exclusion
        .find((pop) => pop.code?.coding?.some((c) => c.code === 'denominator-exclusion'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  public get windowEnd(): string | undefined {
    if (!this.latestMeasureReport.period.end) {
      return undefined;
    }

    const endDate = new Date(this.latestMeasureReport.period.end);
    return endDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  public get windowStart(): string | undefined {
    if (!this.latestMeasureReport.period.start) {
      return undefined;
    }

    const startDate = new Date(this.latestMeasureReport.period.start);
    return startDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  public get denominatorReasons(): Array<string> {
    return (
      this.groupPopulation
        // Grab the populations that are marked as denominator
        .find((pop) => pop.code?.coding?.some((c) => c.code === 'denominator'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  public get numeratorReasons(): Array<string> {
    return (
      this.groupPopulation
        // Grab the populations that are marked as denominator
        .find((pop) => pop.code?.coding?.some((c) => c.code === 'numerator'))
        // Extract the extentensions that are the population description
        ?.extension?.filter(
          (ext) =>
            ext.url ===
            'http://hl7.org/fhir/StructureDefinition/measurereport-populationDescription',
        )
        // Grab the exclusion reason from the markdown value
        .map((ext) => ext.valueMarkdown || '')
        // Get rid of empty strings
        .filter((reason) => !isEmpty(reason)) || []
    );
  }

  // As of this writing, CareGap evidence is limited to DiagnosticReport resources.  In the future
  // we'll need to figure how to handle the fact that different measure reports will have different
  // types of evidence.
  public get evidence(): Array<DiagnosticReportModel> {
    const latestMeasureReportId = this.latestMeasureReport.id;
    if (!latestMeasureReportId) {
      return [];
    }
    return this.allEvidence[latestMeasureReportId] ?? [];
  }

  public getEvidenceForSpecificReport(reportId: string): Array<DiagnosticReportModel> {
    return this.allEvidence[reportId] ?? [];
  }

  // latestReportReasons will return the first reason found in order of exclusion, numerator, denominator.
  public get latestReportReasons() {
    const { numeratorReasons, denominatorReasons, exclusionReasons } = this;
    if (exclusionReasons.length > 0) {
      return exclusionReasons;
    }
    if (numeratorReasons.length > 0) {
      return numeratorReasons;
    }
    return denominatorReasons;
  }

  // latestReportReasonsWithoutDenominator will return the first set of reasons found in order of exclusion, numerator
  public get latestReportReasonsWithoutDenominator() {
    const { numeratorReasons, exclusionReasons } = this;
    if (exclusionReasons.length > 0) {
      return exclusionReasons;
    }
    return numeratorReasons;
  }

  public get subjectId(): string {
    return this.latestMeasureReport.subject?.reference?.split('/')[1] || '';
  }
}
