import { getContactDetails, getHumanName } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { GeneralInfo } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import xpath from 'xpath';

export const getDataEntererData = (document: Document): GeneralInfo | undefined => {
  const dataEnterer = xpath.select1(
    "*[name()='ClinicalDocument']/*[name()='dataEnterer']/*[name()='assignedEntity']",
    document,
  ) as Document | undefined;

  if (!dataEnterer) {
    return undefined;
  }

  const assignedPerson = xpath.select1("*[name()='assignedPerson']", dataEnterer) as Document;

  const name = getHumanName(xpath.select("*[name()='name']", assignedPerson) as Array<Document>);

  const contactDetails = getContactDetails(
    xpath.select("*[name()='addr']", dataEnterer) as Array<Document>,
    xpath.select("*[name()='telecom']", dataEnterer) as Array<Document>,
  );

  return { name, contactDetails };
};
