import { isFunction, set } from 'lodash-es';
import type { ListBoxOptionStatus } from '../list-box/list-box';
import type { FilterItem } from './filter-bar-types';
import type { FilterChangeEvent, FilterValuesRecord } from './filter-bar-types';

export function displayFilterItem({ display }: FilterItem, status: ListBoxOptionStatus) {
  return <>{isFunction(display) ? display(status) : display}</>;
}

// Create onChange event from current <FilterBar /> state
export function filterChangeEvent(
  filters: Array<FilterItem>,
  activeFilterKeys: Array<string>,
  activeFilterValues: FilterValuesRecord,
): FilterChangeEvent {
  return activeFilterKeys.reduce((acc, key) => {
    const filter = filters.find((item) => item.key === key);
    if (!filter) {
      return acc;
    }
    return {
      ...acc,
      [filter.key]: {
        key: filter.key,
        type: filter.type,
        selected: filter.type === 'tag' ? true : activeFilterValues[filter.key],
        ...((filter.type === 'checkbox' || filter.type === 'tag') && filter.predicate
          ? { predicate: filter.predicate }
          : null),
      },
    };
  }, {});
}

// Convert a FilterChangeEvent into FilterValuesRecord (setting default state)
export function filterChangeEventToValuesRecord(state: FilterChangeEvent): FilterValuesRecord {
  return Object.keys(state).reduce((acc, key) => {
    const filterState = state[key];
    if (typeof filterState !== 'undefined') {
      const { type } = filterState;
      if (type === 'tag') {
        return set(acc, key, []);
      }
      return set(acc, key, filterState.selected);
    }
    return acc;
  }, {});
}
