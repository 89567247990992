import { usePatientDocuments } from '@ctw/shared/api/fhir/document';
import type { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import {
  isEmptyClinicalNote,
  isSectionDocument,
} from '@ctw/shared/content/document/helpers/filters';
import { getNoteDisplay } from '@ctw/shared/content/resource/notes';
import { NotesEntry } from '@ctw/shared/content/resource/notes-entry';
import {
  type UseResourceDrawerOptions,
  useResourceDrawer,
} from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { recursivelyTransposeTables } from '@ctw/shared/utils/transpose-table';
import { sortBy } from 'lodash-es';
import { type ReactNode, useEffect, useState } from 'react';
import { ContentCard } from '../components/containers/content-card';

interface UseDocumentReferenceDrawerOptions
  extends Pick<UseResourceDrawerOptions<'DocumentReference'>, 'resourceActions'> {}

export const useDocumentReferenceDrawer = ({
  resourceActions,
}: UseDocumentReferenceDrawerOptions = {}) =>
  useResourceDrawer({
    resourceType: 'DocumentReference',
    action: ({ model }) => ({
      label: 'Document',
      title: model.title,
      resourceActions,
      enableDismissAndReadActions: true,
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard
              variant="focal"
              title="Details"
              content={{
                header: <ResponsiveSourceDocumentLink model={model} />,
              }}
            >
              <DefinitionList items={documentData(model)} />
            </ContentCard>
            <DocumentNotes model={model} />
          </div>
        ),
      },
    }),
  });

const documentData = (document: DocumentModel) => [
  { label: 'Encounter Date', value: document.encounterDate },
  { label: 'Date Retrieved', value: document.dateCreated },
  { label: 'Author', value: document.custodian },
];

interface ClinicalNote {
  id: string;
  label: string;
  value: ReactNode;
  document: DocumentModel;
}

interface DocumentNotesProps {
  model: DocumentModel;
}

const DocumentNotes = ({ model }: DocumentNotesProps) => {
  const allDocuments = usePatientDocuments();
  const [clinicalNotes, setClinicalNotes] = useState<Array<ClinicalNote> | undefined>(undefined);

  useEffect(() => {
    /* recursivelyTransposeTables is slow, so run it async */
    setTimeout(() => {
      setClinicalNotes(
        isSectionDocument(model)
          ? [
              {
                id: model.id,
                label: 'Note',
                value: recursivelyTransposeTables(getNoteDisplay(model.displayContent), 0),
                document: model,
              },
            ]
          : sortBy(
              (allDocuments.data ?? []).filter(
                (document) =>
                  document.binaryId === model.binaryId &&
                  !isEmptyClinicalNote(document) &&
                  isSectionDocument(document),
              ),
              'title',
            ).map((document) => ({
              id: document.id,
              label: 'Note',
              value: recursivelyTransposeTables(getNoteDisplay(document.displayContent), 0),
              document,
            })),
      );
    }, 1);
  }, [allDocuments.data, model]);

  return (
    <div className={tw`space-y-4`}>
      <div className={tw`font-semibold text-lg`}>Notes</div>
      {clinicalNotes === undefined ? (
        <LoadingSpinner centered={true} message="Loading..." />
      ) : (
        clinicalNotes.map((clinicalNote) => (
          <NotesEntry
            key={`${clinicalNote.id}`}
            summary={
              clinicalNote.document.title ? (
                <div className={tw`font-semibold text-background-inverse`}>
                  {clinicalNote.document.title}
                </div>
              ) : (
                <div className={tw`text-content-icon`}>Unknown</div>
              )
            }
            details={clinicalNotes}
            isDetailShownOnOpen={clinicalNote.id === model.id}
          />
        ))
      )}
    </div>
  );
};
