import { PatientHistoryRequestModel } from '@ctw/shared/api/fhir/models/patient-history';
import { usePatientSubscription } from '@ctw/shared/api/subscriptions/subscriptions';
import { Alert } from '@ctw/shared/components/alert';
import { Link } from '@ctw/shared/components/link';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { ZusLogoIconOnly } from '@ctw/shared/components/zus-logo-icon-only';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { RequestRecordsButton } from '@ctw/shared/content/patient-history/request-records-button';
import { usePatientHistoryStatus } from '@ctw/shared/content/patient-history/use-patient-history-status';
import { useBuilderConfig } from '@ctw/shared/context/builder-config-context';
import { formatFHIRDate } from '@ctw/shared/utils/dates';
import { type ClassName, twx } from '@ctw/shared/utils/tailwind';
import { tw } from '@ctw/shared/utils/tailwind';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation, faWarning } from '@fortawesome/pro-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ReactElement, cloneElement, isValidElement } from 'react';
import { OverviewCard } from '../../components/containers/overview-card';
import { usePatientHistory } from '../patient-history/use-patient-history';
import { SupportFormLink } from '../support-form-link';

export const ZusInformationCard = withOverviewCardErrorBoundary({
  cardTitle: 'Records Provided by Zus Health',
  boundaryName: 'ZusInformationCardComponent',
  Component: () => {
    const patientHistory = usePatientHistory();

    return (
      <OverviewCard
        headerIcon={<ZusLogoIconOnly width={25} height={25} />}
        title="Records Provided by Zus Health"
        loading={patientHistory.isLoading}
        testId="zus-information-card"
        empty={patientHistory.isError}
        emptyStateMessage="Unable to load patient history information"
      >
        <div className={tw`space-y-2`}>
          <div className={tw`space-y-1.5 px-3`}>
            <PatientSubscriptionPackage />
            <PatientHistoryWithAction />
          </div>

          <div className={tw`h-px bg-background-subtle`} />

          <div className={tw`space-y-1.5 px-3 font-medium`}>
            <div className={tw`flex justify-between`}>
              <div>Have feedback or need support?</div>
              <SupportFormLink buttonText="Let us know" className={tw`block text-sm`} />
            </div>

            <div className={tw`flex justify-between`}>
              <div>Learn more about the ZAP</div>
              <Link
                href="https://clinicalguide.zushealth.com/docs/getting-started"
                className={tw`block text-sm`}
                target="_blank"
              >
                Clinical Guide
              </Link>
            </div>
          </div>
        </div>
      </OverviewCard>
    );
  },
});

const PatientHistoryWithAction = () => {
  const { isError, isLoading, details } = usePatientHistory();
  const patientSubscription = usePatientSubscription();
  const builderConfig = useBuilderConfig();
  const patientPackage = patientSubscription.data?.package;
  const shouldSkipModal = patientPackage ? patientPackage.requiresManualRequests : true;

  if (isError) {
    return <div className={tw`text-center text-sm`}>Unable to load patient history</div>;
  }

  if (isLoading) {
    return <LoadingSpinner message="Loading..." className={tw`w-full`} />;
  }

  const { latestInProgress, latestCompleted, nextScheduledAt } = details;
  const latestInProgressStatus =
    latestInProgress &&
    PatientHistoryRequestModel.getDerivedProviderStatus(latestInProgress.job.attributes.providers);
  const latestInProgressText =
    latestInProgressStatus === 'partiallyComplete'
      ? 'Current request partially complete'
      : 'Request in-progress';

  // Only show last completed if there is one or if there isn't a latest in progress or next scheduled
  const showLastCompleted = !!latestCompleted || !(latestInProgress || nextScheduledAt);

  return (
    <div className={tw`@container`}>
      {/* Use flex-col until we hit the 365px breakpoint and then use row.
          This is to accomodate the 'Current request partially complete' text and tooltip. */}
      <div
        className={tw`flex @[365px]:flex-row flex-col justify-between @[365px]:space-y-0 space-y-1.5 font-medium text-sm`}
      >
        <div className={tw`flex flex-col space-y-1.5`}>
          {latestInProgress && (
            <PatientHistoryDetail
              text={latestInProgressText}
              icon={<FontAwesomeIcon icon={faRefresh} className={tw`w-auto flex-none`} />}
              tooltip="Patient records are being retrieved"
            />
          )}
          {!latestInProgress && nextScheduledAt && (
            <PatientHistoryDetail text="Request scheduled" date={nextScheduledAt} />
          )}
          {showLastCompleted && (
            <PatientHistoryLastCompleted key="patient-history-last-completed" />
          )}
        </div>
        {builderConfig.showRequestRecords && (
          <div>
            <RequestRecordsButton skipModal={shouldSkipModal} />
          </div>
        )}
      </div>
    </div>
  );
};

type PatientHistoryLastCompletedProps = {
  className?: ClassName;
};

const PatientHistoryLastCompleted = ({ className }: PatientHistoryLastCompletedProps) => {
  const { isLoading, lastCompletedDate, derivedStatus } = usePatientHistoryStatus();

  if (isLoading) {
    return null;
  }

  if (derivedStatus === 'complete') {
    return (
      <PatientHistoryDetail
        text="Last updated"
        date={lastCompletedDate}
        className={twx(className)}
      />
    );
  }

  if (derivedStatus === 'completeWithErrors') {
    return (
      <PatientHistoryDetail
        text="Last updated"
        date={lastCompletedDate}
        className={twx(className)}
        icon={<FontAwesomeIcon icon={faWarning} className={tw`text-caution-main`} />}
        tooltip="There was an error fetching some records for this patient"
      />
    );
  }

  if (derivedStatus === 'failed') {
    return (
      <PatientHistoryDetail
        text="Last request failed"
        date={lastCompletedDate}
        className={twx(className, 'text-error-main')}
        icon={faCircleExclamation}
        tooltip="There was an error fetching records for this patient"
      />
    );
  }

  return <PatientHistoryDetail text="Records never requested" />;
};

interface PatientHistoryDetailProps {
  text: string;
  date?: Date;
  className?: ClassName;
}

interface PatientHistoryDetailWithTooltipProps extends PatientHistoryDetailProps {
  icon: IconDefinition | ReactElement<{ className?: string }>;
  tooltip?: string;
}

function PatientHistoryDetail(
  props: PatientHistoryDetailProps | PatientHistoryDetailWithTooltipProps,
) {
  const { text, date, className } = props;
  let tooltipElement: ReactElement | null = null;
  if (isPropsWithIcon(props)) {
    const { icon, tooltip } = props;
    const iconElement = isValidElement(icon) ? (
      cloneElement(icon, {
        className: twx('history-request-form-icon h-4', icon.props.className),
      })
    ) : (
      <FontAwesomeIcon
        icon={icon as IconDefinition}
        className={tw`history-request-form-icon h-4`}
      />
    );

    if (tooltip) {
      tooltipElement = <Tooltip content={tooltip}>{iconElement}</Tooltip>;
    }
  }

  return (
    <div className={twx('flex items-center space-x-1', className)}>
      <span>{text}</span>
      {date && <span>{formatFHIRDate(date.toISOString())}</span>}
      {tooltipElement}
    </div>
  );
}

function isPropsWithIcon(
  props: PatientHistoryDetailProps | PatientHistoryDetailWithTooltipProps,
): props is PatientHistoryDetailWithTooltipProps {
  return 'icon' in props;
}

const PatientSubscriptionPackage = () => {
  const patientSubscription = usePatientSubscription();

  if (patientSubscription.isLoading) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (patientSubscription.isError) {
    return <Alert type="error" header="Error loading data" />;
  }

  const patientPackage = patientSubscription.data?.package;

  if (!patientPackage) {
    return null;
  }

  return <div className={tw`font-semibold`}>Enrolled in {patientPackage.name}</div>;
};
