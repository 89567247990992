import type { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { usePatientVitals } from '@ctw/shared/api/fhir/vitals';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { RotatedTable } from '@ctw/shared/components/table/rotated-table';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { sort } from '@ctw/shared/utils/sort';
import { tw } from '@ctw/shared/utils/tailwind';
import { useEffect, useState } from 'react';
import { ResourceTableActions } from '../resource/resource-table-actions';
import type { ViewOption } from '../resource/view-button';
import { getDateRangeView } from '../resource/view-date-range';
import { getVitalRows } from './vital-rows';

import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

export const PatientVitals = withErrorBoundary({
  boundaryName: 'PatientVitals',
  includeTelemetryBoundary: true,
  Component: () => {
    const vitals = usePatientVitals();
    const [rows, setRows] = useState<ReturnType<typeof getVitalRows>>([]);

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-vitals',
        viewOptions: vitalViewOptions,
        defaultSort: defaultVitalSort,
        records: vitals.data,
        defaultView: defaultVitalView.display,
      });

    useEffect(() => {
      if (!vitals.isLoading) {
        setRows(getVitalRows(vitals.data));
      }
    }, [vitals.data, vitals.isLoading]);

    useMainContentHeader(
      <ResourceTableActions
        viewOptions={{
          onChange: setViewOption,
          options: vitalViewOptions,
          defaultView,
        }}
        filterOptions={{
          onChange: setFilters,
          filters: vitalFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: vitalSortOptions,
          onChange: setSort,
        }}
      />,
      [vitalViewOptions, defaultView, vitalFilters, filters, defaultSort, vitalSortOptions],
    );

    return (
      <div className={tw`h-full max-h-full w-full max-w-full overflow-hidden`}>
        <RotatedTable
          records={data}
          rows={rows}
          isLoading={vitals.isLoading}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={vitals.data ? vitals.data.length > 0 : false}
              resourceName="vitals"
            />
          }
        />
      </div>
    );
  },
});

const vitalFilters: Array<FilterItem> = [
  {
    key: 'showHidden', // Special key for filtering. See utils/filters.ts.
    type: 'tag',
    display: 'show hospital results',
    icon: faEye,
    toggleDisplay: 'hide hospital results',
    toggleIcon: faEyeSlash,
  },
];

const { past30days, past3months, past6Months, pastYear, allTime } =
  getDateRangeView<VitalsBucket>('date');

// Sorts the data by most recent and then takes the first 5.
// Note: This sort happens before the sort options sorting. So
//       the 5 most recent can still be sorted old to new.
const mostRecent5: ViewOption<VitalsBucket> = {
  display: '5 Most Recent',
  filters: [(data) => sort(data, 'date', 'desc', true).slice(0, 5)],
};

const vitalViewOptions = [mostRecent5, past30days, past3months, past6Months, pastYear, allTime];

const defaultVitalView = mostRecent5;

const defaultVitalSort: SortOption<VitalsBucket> = {
  display: 'Date (New to Old)',
  sorts: [{ key: 'dateTime', dir: 'desc' }],
};

const vitalSortOptions: Array<SortOption<VitalsBucket>> = [
  defaultVitalSort,
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'dateTime', dir: 'asc' }],
  },
];
