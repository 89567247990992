export const ZusLogo = () => (
  <svg width="62" height="32" viewBox="0 0 62 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Zus Logo</title>
    <g clipPath="url(#clip0_204_2088)">
      <path
        d="M23.0399 22.9626L15.4917 18.606L20.9338 9.17959C20.9693 9.11893 21.0321 9.00809 20.9338 8.83658C20.8355 8.66508 20.7058 8.66508 20.6368 8.66508H9.75049V-0.0523071H20.6368C23.9121 -0.0523071 26.8443 1.63972 28.482 4.4758C30.1196 7.31188 30.1196 10.698 28.482 13.5341L23.0399 22.9605V22.9626Z"
        fill="#008051"
      />
      <path
        d="M19.8066 32H9.02073C5.74544 32 2.81315 30.308 1.1755 27.4719C-0.462146 24.6358 -0.462146 21.2497 1.1755 18.4136L6.61759 8.98718L14.1637 13.3438L8.72164 22.7702C8.68609 22.8308 8.62334 22.9417 8.72164 23.1132C8.81995 23.2847 8.94962 23.2847 9.01864 23.2847H19.8045V32H19.8066Z"
        fill="#71B2C9"
      />
      <path
        d="M40.2825 13.9043C40.431 13.9043 40.4854 13.9963 40.4854 14.126V14.5317C40.4854 14.7158 40.5042 14.8266 40.3201 15.086L35.4511 21.7997H40.6527V22.8517H34.1795C34.031 22.8517 33.9766 22.7785 33.9766 22.6489V22.2243C33.9766 22.0402 33.9578 21.9294 34.1418 21.67L38.9753 14.9563H34.0498V13.9043H40.2846H40.2825Z"
        fill="white"
      />
      <path
        d="M49.8907 13.9043H51.1623V21.67C51.1623 22.0214 51.0515 22.241 50.7001 22.3895L49.7777 22.7576C49.3532 22.9229 49.1691 22.9605 48.709 22.9605H45.8875C45.1869 22.9605 44.8732 22.8308 44.4674 22.4251L43.9885 21.9461C43.4907 21.4483 43.4175 21.2643 43.4175 20.434V13.9043H44.6891V20.4716C44.6891 20.7665 44.7435 20.8774 44.9652 21.0991L45.3898 21.5236C45.6303 21.7453 45.7035 21.783 46.0716 21.783H48.6358C48.7466 21.783 48.8575 21.7642 48.9495 21.7265L49.7798 21.394C49.853 21.3563 49.8907 21.3208 49.8907 21.2287V13.9064V13.9043Z"
        fill="white"
      />
      <path
        d="M55.8308 18.8298C55.2598 18.7754 54.8164 18.6269 54.5215 18.3508C54.2078 18.0559 54.0425 17.5958 54.0425 16.8763V16.2865C54.0425 15.5482 54.1157 15.3098 54.6135 14.8099L55.0925 14.331C55.517 13.9252 55.8308 13.7955 56.5314 13.7955H58.4117C59.058 13.7955 59.334 13.852 59.7774 14.1281L60.8106 14.7555L60.3129 15.6591L59.058 15.0881C58.8007 14.9772 58.6146 14.9396 58.2841 14.9396H56.6799C56.3118 14.9396 56.2365 14.9772 55.9981 15.1989L55.5547 15.6423C55.333 15.864 55.2786 15.9749 55.2786 16.2698V16.9516C55.2786 17.4306 55.4083 17.5979 55.9249 17.6523L59.3006 17.966C60.0012 18.0392 60.407 18.1877 60.7019 18.4826C60.9968 18.7775 61.1265 19.2209 61.1265 19.8483V20.4381C61.1265 21.2685 61.0533 21.4525 60.5555 21.9503L60.0765 22.4293C59.6708 22.835 59.357 22.9647 58.6564 22.9647H56.2951C55.6488 22.9647 55.3727 22.9082 54.9126 22.6321L53.8794 22.0047L54.3772 21.1012L55.6321 21.691C55.8893 21.8018 56.0755 21.8206 56.4059 21.8206H58.4912C58.8593 21.8206 58.9346 21.783 59.173 21.5613L59.6164 21.1179C59.8381 20.8962 59.8925 20.7853 59.8925 20.4904V19.8442C59.8925 19.3652 59.7628 19.1979 59.2462 19.1435L55.8329 18.8298H55.8308Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_204_2088">
        <rect width="61.0719" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
