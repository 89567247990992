import { tw } from '@ctw/shared/utils/tailwind';

export const FormFieldLabel = ({
  label,
  required,
  name,
}: {
  label: string;
  name: string;
  required?: boolean;
}) => (
  <div className={tw`flex items-center justify-between`}>
    <label htmlFor={name} className={tw`capitalize`}>
      {label}
    </label>
    {!required && (
      <span className={tw`right-0 inline-block text-content-subtle text-xs`}>Optional</span>
    )}
    {required && <div className={tw`flex-grow text-background-tooltip`}>*</div>}
  </div>
);
