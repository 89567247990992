import { getId } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { LabelValueType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { isEmpty } from 'lodash-es';
import xpath from 'xpath';

export const getConsent = (
  document: Document,
): Record<string, Array<LabelValueType>> | undefined => {
  const consents = xpath.select(
    "*[name()='ClinicalDocument']/*[name()='authorization']/*[name()='consent']",
    document,
  ) as Array<Document>;

  if (isEmpty(consents)) {
    return undefined;
  }

  const result = consents.map((consent) => {
    const id = getId(xpath.select1("*[name()='id']", consent) as Document);
    const code = String(xpath.select1("string(*[name()='code']/@displayName)", consent));
    const status = String(xpath.select1("string(*[name()='statusCode']/@code)", consent));
    return [
      { label: 'ID:', value: id },
      { label: 'Code:', value: code },
      { label: 'Status:', value: status },
    ];
  });

  return result.reduce((acc, order, index) => ({ ...acc, [`consent${index + 1}`]: order }), {});
};
