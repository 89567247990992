export class RequestError extends Error {
  public statusCode: number;

  public statusText: string;

  public requestInfo: Response;

  public stack: string | undefined;

  public constructor(response: Response, message?: string, options?: ErrorOptions) {
    super(message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = 'RequestError';
    this.statusCode = response.status;
    this.statusText = response.statusText;
    this.requestInfo = response;
  }
}
