import { tw, twx } from '@ctw/shared/utils/tailwind';
import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type PropsWithChildren, type ReactNode, forwardRef } from 'react';

export interface ContentCardProps extends PropsWithChildren {
  titleIcon?: IconDefinition;
  title?: string;
  className?: string;
  variant: 'focal';
  fullbleed?: boolean;
  content?: {
    header: ReactNode;
  };
}

export const ContentCard = forwardRef<HTMLElement, ContentCardProps>(
  ({ fullbleed, title, titleIcon, className, content, children }, ref) => (
    <section
      ref={ref}
      className={twx(
        'flex min-h-max flex-col gap-2 overflow-hidden rounded-lg border border-content-focal bg-background-hover',
        {
          'p-4': !fullbleed,
        },
        className,
      )}
    >
      {title && (
        <div className={tw`flex items-center justify-between gap-2`}>
          <div className={tw`flex flex-1 items-center justify-between gap-2`}>
            {titleIcon && (
              <div className={tw`flex items-center justify-center`}>
                <FontAwesomeIcon icon={titleIcon} className={tw`-mt-1 h-4 w-4 text-content-icon`} />
              </div>
            )}
            <div
              className={tw`font-medium text-content-subtle text-sm uppercase leading-none tracking-wide`}
            >
              {title}
            </div>
          </div>
          {content?.header && (
            <div className={tw`flex flex-1 items-center justify-end gap-2`}>{content.header}</div>
          )}
        </div>
      )}
      <div className={tw`text-content-main text-sm leading-tight`}>{children}</div>
    </section>
  ),
);
