import { formatPhoneNumber } from '@ctw/shared/utils/phone-number';
import type { Organization } from 'fhir/r4';
import { FHIRModel } from './fhir-model';

export class OrganizationModel extends FHIRModel<Organization> {
  public kind = 'Organization' as const;

  public get name(): string | undefined {
    return this.resource.name;
  }

  public get phone(): string | undefined {
    const phone = this.resource.telecom?.find((t) => t.system === 'phone');
    return phone?.value ? formatPhoneNumber(phone.value) : '';
  }

  public get title() {
    return this.name ?? 'Unknown Organization';
  }
}
