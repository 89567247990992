import { ActionDropdown } from '@ctw/shared/components/dropdown/action-dropdown';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { FilterEntry } from '@ctw/shared/utils/filters';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export type ViewOption<T extends object> = {
  display: string;
  filters: Array<FilterEntry<T>>;
};

export type ViewButtonProps<T extends object> = {
  className?: ClassName;
  defaultView?: string;
  onChange: (view: ViewOption<T>) => void;
  options: Array<ViewOption<T>>;
};

export const ViewButton = <T extends object>({
  className,
  defaultView,
  onChange,
  options,
}: ViewButtonProps<T>) => {
  const defaultViewOption = options.find((o) => o.display === defaultView) ?? options[0];
  const [selected, setSelected] = useState<ViewOption<T>>(defaultViewOption);
  const { trackInteraction } = useTelemetry();

  return (
    <ActionDropdown
      type="select"
      buttonClassName={twx(
        className,
        'w-min rounded-md border border-divider-main bg-transparent p-0 shadow-sm',
      )}
      onItemSelect={(item) => {
        const selectedOption = options.filter((option) => option.display === item.key)[0];
        onChange(selectedOption);
        setSelected(selectedOption);
        trackInteraction('change_view', { value: selectedOption.display });
      }}
      items={options.map((option) => ({
        key: option.display,
        name: option.display,
        isSelected: selected.display === option.display,
      }))}
    >
      <div
        className={tw`flex items-center gap-2 whitespace-nowrap rounded-md border border-border-main border-transparent border-solid bg-white px-4 py-2 font-medium text-content-subtle text-sm capitalize shadow-sm hover:bg-background-hover`}
      >
        <span className={tw`w-full font-normal`}>{selected.display}</span>
        <FontAwesomeIcon icon={faChevronDown} className={tw`h-3 w-3`} />
      </div>
    </ActionDropdown>
  );
};
