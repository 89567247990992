import '@ctw/shared/styles/preflight';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const Root = withErrorBoundary({
  boundaryName: 'Smart on FHIR Root',
  includeTelemetryBoundary: true,
  Component: () => (
    <StrictMode>
      <App />
    </StrictMode>
  ),
});

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(<Root />);
}
