import type { Env } from '@ctw/shared/context/types';

interface UnleashFrontendEnvironmentConfig {
  url: string;
  clientKey: string;
}

/*
 * Map our env to the unleash environment, defaulting to "development".
 * NOTE: these keys are hardcoded in https://github.com/zeus-health/aws-base/blame/8eb18e694f5c94b70f15559254ce10027e74f4b4/unleash/scripts/create_secrets.sh#L33-L39
 * so they could change without notice. INFRA-729 will create these in AWS secrets for all
 * accounts but for now it is safe to store these in GitHub
 */
export const getUnleashProxyEnvironmentConfig = (env: Env): UnleashFrontendEnvironmentConfig => {
  switch (env) {
    case 'prod':
    case 'production':
      return {
        url: 'https://unleash-proxy-prod.zusapi.com/proxy',
        clientKey: 'MDE0NDU5NTQtNEIyNC00RUVGLUI4NDUtRTE3QjYyMUQ3NTAzCg==',
      };
    case 'phitest':
    case 'sandbox':
      return {
        url: 'https://unleash-proxy.zusapi.com/proxy',
        clientKey: 'Q0QwNUIxODgtQkFEMC00MTA2LUIwRDEtRDgwQ0FFRDBBMzBCCg==',
      };
    default:
      return {
        url: 'https://unleash-proxy-dev.zusapi.com/proxy',
        clientKey: 'NTk3QkM3RTItMTU4Qi00NDYwLTlFQjQtNjMyQ0ZFMENBNkY1Cg==',
      };
  }
};
