import { getZusServiceUrl } from '@ctw/shared/api/urls';
import type { Env } from '@ctw/shared/context/types';
import type { Measure } from 'fhir/r4';

// CanonicalMeasureMap - maps a canonical measure URL to the ID and title for that measure in a
// given environment.
type CanonicalMeasureMap = Partial<
  Record<string, { id?: string; title: string; description?: string }>
>;

const CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW =
  'http://zusapi.com/quality/measure/ursa-hedis/COA/Meds';
const CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW_TITLE = 'Care of Older Adults Medication Review';
const CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW_DESCRIPTION =
  'The percentage of adults 66 years and older who had a medication review during the measurement year.';

const GLYCEMIC_STATUS_ASSESSMENT_DIABETES = 'http://zusapi.com/quality/measure/ursa-hedis/';
const GLYCEMIC_STATUS_ASSESSMENT_DIABETES_TITLE = 'Glycemic Status Assessment Diabetes';

const HEMOGLOBIN_A1C_CONTROL = 'http://zusapi.com/quality/measure/ursa-hedis/HBD';
const HEMOGLOBIN_A1C_CONTROL_TITLE = 'Hemoglobin A1c Control for Patients with Diabetes';
const HEMOGLOBIN_A1C_CONTROL_DESCRIPTION =
  'The percentage of members 18–75 years of age with diabetes (types 1 and 2) whose most recent glycemic status (hemoglobin A1c [HbA1c] or glucose management indicator [GMI]) was at the following levels during the measurement year: Glycemic Status <8.0%.';

const CONTROLLING_HIGH_BLOOD_PRESSURE = 'http://zusapi.com/quality/measure/ursa-hedis/CBP';
const CONTROLLING_HIGH_BLOOD_PRESSURE_TITLE = 'Controlling High Blood Pressure';
const CONTROLLING_HIGH_BLOOD_PRESSURE_DESCRIPTION =
  'The percentage of members 18–85 years of age who had a diagnosis of hypertension (HTN) and whose blood pressure (BP) was adequately controlled (<140/90 mm Hg) during the measurement year.';

const COLORECTAL_CANCER_SCREENING = 'http://zusapi.com/quality/measure/ursa-hedis/COL-E';
const COLORECTAL_CANCER_SCREENING_TITLE = 'Colorectal Cancer Screening';
const COLORECTAL_CANCER_SCREENING_DESCRIPTION =
  'The percentage of members 45–75 years of age who had appropriate screening for colorectal cancer.';

const canonicalUrlMap: CanonicalMeasureMap = {
  [CONTROLLING_HIGH_BLOOD_PRESSURE]: {
    title: CONTROLLING_HIGH_BLOOD_PRESSURE_TITLE,
    description: CONTROLLING_HIGH_BLOOD_PRESSURE_DESCRIPTION,
  },
  [COLORECTAL_CANCER_SCREENING]: {
    title: COLORECTAL_CANCER_SCREENING_TITLE,
    description: COLORECTAL_CANCER_SCREENING_DESCRIPTION,
  },
  [CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW]: {
    title: CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW_TITLE,
    description: CARE_OF_OLDER_ADULTS_MEDICATION_REVIEW_DESCRIPTION,
  },
  [GLYCEMIC_STATUS_ASSESSMENT_DIABETES]: {
    title: GLYCEMIC_STATUS_ASSESSMENT_DIABETES_TITLE,
  },
  [HEMOGLOBIN_A1C_CONTROL]: {
    title: HEMOGLOBIN_A1C_CONTROL_TITLE,
    description: HEMOGLOBIN_A1C_CONTROL_DESCRIPTION,
  },
};

// Returns a default Measure resource for a given measure URL. If the measure is not found in the
// `canonicalMeasureMap` the ID will be the last part of the passed in URL and the title will be
// "Unknown measure" for FHIR URLs and the last part of the URL otherwise (as we'll assume it's a
// canonical URL we just don't have a mapping for). In either case, the UI will attempt to fetch the
// actual Measure resource and use that instead of the default provided. The reason we provide these
// defaults is to ensure that if a user doesn't have permissions to read a Measure referenced on a
// MeasureReport then the canonical URL that the UI can still present them with a meaningful the
// title (provided we have defaults for that Canonical URL).
export const getMeasureWithDefaultsFromCanonicalUrlMap = (
  measureIdOrUrl: string,
  env: Env,
): Measure => {
  const isFhirUrl = measureIdOrUrl.includes(getZusServiceUrl('fqs', env));
  const id = canonicalUrlMap[measureIdOrUrl]?.id ?? measureIdOrUrl.split('/').pop();

  let title: string;
  let description: string | undefined;

  if (isFhirUrl) {
    // The passed in URL is not a canonical URL so the best we can do set a title and hope the
    // user has permissions to read the Measure resource.
    title = 'Unknown measure';
  } else {
    title = canonicalUrlMap[measureIdOrUrl]?.title ?? `Measure: ${id}`;
    description =
      canonicalUrlMap[measureIdOrUrl]?.description ??
      `Quality measure with a canonical URL of ${measureIdOrUrl}`;
  }

  return {
    id,
    title,
    description,
    resourceType: 'Measure',
    status: 'unknown',
  };
};
