import { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { MAX_OBJECTS_PER_REQUEST, fqsRequest } from '@ctw/shared/api/fqs/client';
import { type AllergyGraphqlResponse, allergyQuery } from '@ctw/shared/api/fqs/queries/allergies';
import { applyAllergyFilters } from '@ctw/shared/content/allergies/helpers/allergies-filter';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_PATIENT_ALLERGIES } from '@ctw/shared/utils/query-keys';
import type { GraphQLClient } from 'graphql-request';
import { SYSTEM_SUMMARY } from './system-urls';

export const usePatientAllergies = (enabled = true) =>
  usePatientQuery({
    queryId: QUERY_KEY_PATIENT_ALLERGIES,
    queryFn: async ({ requestContext, telemetry, graphqlClient, patient }) => {
      const { data } = await fqsRequest<AllergyGraphqlResponse>(
        telemetry,
        graphqlClient,
        allergyQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: 500,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: { nonematch: [SYSTEM_SUMMARY] },
          },
        },
      );
      const models = data.AllergyIntoleranceConnection.edges.map(
        (x) => new AllergyModel(x.node, undefined, x.node.BasicList),
      );
      return applyAllergyFilters(models, requestContext.builderId);
    },
    enabled,
  });

export async function getAllergyIntolerancesById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: Array<string>,
) {
  const { data } = await fqsRequest<AllergyGraphqlResponse>(
    telemetry,
    graphqlClient,
    allergyQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
      sort: {},
    },
  );
  return data.AllergyIntoleranceConnection.edges.map((x) => new AllergyModel(x.node));
}
