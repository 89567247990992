import type { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import type { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const defaultAllergySort: SortOption<AllergyModel> = {
  display: 'Name (A-Z)',
  sorts: [{ key: 'lowercaseDisplay', dir: 'asc' }],
};

export const allergySortOptions: Array<SortOption<AllergyModel>> = [
  defaultAllergySort,
  {
    display: 'Date (New to Old)',
    sorts: [{ key: 'recordedDate', dir: 'desc', isDate: true }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'recordedDate', dir: 'asc', isDate: true }],
  },
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'lowercaseDisplay', dir: 'desc' }],
  },
];
