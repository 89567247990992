import type { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import type { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { useAddMedicationForm } from '@ctw/shared/content/medications/helpers/use-add-medication-form';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { useZapContext } from '@ctw/shared/context/zap-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useMedicationDrawer } from '@ctw/shared/hooks/use-medication-drawer';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { useMemo } from 'react';
import { useToggleRead } from '../hooks/use-toggle-read';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { getDateRangeView } from '../resource/view-date-range';
import { patientMedicationsAllColumns } from './helpers/columns';
import { medicationFilters } from './helpers/filters';
import { defaultMedicationSort, medicationSortOptions } from './helpers/sorts';

export const PatientMedications = withErrorBoundary({
  boundaryName: 'PatientMedications',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { isWritebackEnabled } = useWritebacks();
    const query = useQueryAllPatientMedications();
    const { onZapEvent } = useZapContext();
    const medicationWritebackEnabled = useMemo(
      () => isWritebackEnabled('medications-all'),
      [isWritebackEnabled],
    );
    const rowActions = useMedicationRowActions((record) => {
      onZapEvent({
        name: 'addToRecord',
        payload: {
          resourceType: 'MedicationStatement',
          resource: record.resource,
        },
      });
    });
    const { openDrawer } = useMedicationDrawer({
      resourceActions: medicationWritebackEnabled ? (rowActions as never) : undefined,
      enableDismissAndReadActions: true,
    });
    const { viewOptions, past6Months } = useMemo(
      () => getDateRangeView<MedicationStatementModel>('lastActivityDate'),
      [],
    );

    const validFilters = useMemo(
      () => medicationFilters(query.allMedications, true),
      [query.allMedications],
    );

    const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
      useFilteredSortedData({
        cacheKey: 'patient-medications-all',
        defaultSort: defaultMedicationSort,
        viewOptions,
        defaultView: past6Months.display,
        records: query.allMedications,
      });
    const isEmptyQuery = query.allMedications.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    const empty = useMemo(
      () => (
        <EmptyPatientTable
          hasZeroFilteredRecords={hasZeroFilteredRecords}
          resourceName="medications"
        />
      ),
      [hasZeroFilteredRecords],
    );

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: medicationSortOptions,
          onChange: setSort,
        }}
        viewOptions={{
          onChange: setViewOption,
          options: viewOptions,
          defaultView,
        }}
      />,
      [validFilters, filters, medicationSortOptions, viewOptions, defaultSort, defaultView],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={query.isLoading}
          data={data}
          columns={patientMedicationsAllColumns(requestContext.builderId)}
          onRowClick={(model) => openDrawer({ model })}
          rowActions={medicationWritebackEnabled ? rowActions : undefined}
          enableDismissAndReadActions={true}
          emptyMessage={empty}
        />
      </div>
    );
  },
});

export function useMedicationRowActions(
  onAddToRecord?: (record: MedicationStatementModel) => void,
) {
  const { requestContext } = useCTW();
  const showAddMedicationForm = useAddMedicationForm();
  const { toggleRead } = useToggleRead();

  return (record: MedicationStatementModel): RowActionsConfigProp<MedicationStatementModel> =>
    record.ownedByBuilder(requestContext.builderId)
      ? []
      : [
          {
            text: 'Add to Record',
            className:
              'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-text',
            onClick: () => {
              if (!record.isRead) {
                void toggleRead(record);
              }

              if (onAddToRecord) {
                onAddToRecord(record);
              } else {
                showAddMedicationForm(record);
              }
            },
          },
        ];
}
