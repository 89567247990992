import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import type { Patient } from 'fhir/r4';
import { useEffect, useState } from 'react';
import { useEhrFhirClient } from '../context/ehr-fhir-client-context';

type UniqueIdentifier = {
  system: string;
  value: string;
};

export type EhrPatient = {
  ehrPatient: Patient | undefined;
  uniqueIdentifier: UniqueIdentifier | undefined;
  error: Error | undefined;
};

export const usePatient = (): EhrPatient => {
  const [ehrPatient, setEhrPatient] = useState<Patient | undefined>(undefined);
  const [uniqueIdentifier, setUniqueIdentifier] = useState<UniqueIdentifier | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const telemetry = useTelemetry();

  const fhirClient = useEhrFhirClient();

  // get patient from EHR
  useEffect(() => {
    const getEHRPatient = async () => {
      const patient = await fhirClient.client?.patient.read();
      if (!patient) {
        throw new Error('Could not get patient from EHR');
      }
      setEhrPatient(patient);

      const uniqueId =
        (patient as Patient).identifier?.find((i) => i.value === fhirClient.client?.patient.id) ??
        (patient as Patient).identifier?.[0];

      if (!uniqueId) {
        throw new Error('Unable to find unique identifier for patient');
      }

      const { system, value } = uniqueId;

      if (!(system && value)) {
        throw new Error("Unique identifier doesn't have system and value");
      }
      setUniqueIdentifier({ system, value });
    };
    if (fhirClient.client?.patient.id && !ehrPatient && !error) {
      getEHRPatient().catch((e) => {
        telemetry.trackError({
          message: 'Failed to get patient from EHR',
          error: e,
          context: {
            ehrPatientId: fhirClient.client?.patient.id,
          },
        });
        setError(e);
      });
    }
  }, [fhirClient.client?.patient, ehrPatient, error, telemetry]);

  return {
    ehrPatient,
    uniqueIdentifier,
    error,
  };
};
