import {
  type Activity,
  type CareGapModel,
  type CareGapStatus,
  activityTypes,
} from '@ctw/shared/api/fhir/models/care-gap';
import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import {
  ActivityRadioGroup,
  NoteTextarea,
  StatusDropdown,
} from '@ctw/shared/content/care-gaps/helpers/form-fields';
import {
  useCreateOrEditUserActivityMeasureReport,
  useDeleteUserActivityMeasureReport,
} from '@ctw/shared/content/forms/actions/measure-report';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { getFormResponseErrors } from '@ctw/shared/utils/errors';
import { tw } from '@ctw/shared/utils/tailwind';
import type { MeasureReport } from 'fhir/r4';
import { uniq } from 'lodash-es';
import { type FormEvent, useEffect, useState } from 'react';

export type AddActivityFormProps = {
  careGap: CareGapModel;
  editingActivityId?: string | null;
  onCancelEdit: () => void;
  onSaveMeasureReport: (measureReport: MeasureReport) => void;
  onDeleteMeasureReport: (measureReport: MeasureReport) => void;
};

export const LOCKED_STATUSES: Array<CareGapStatus['slug']> = ['closed', 'excluded'];

export const AddActivityForm = withErrorBoundary({
  boundaryName: 'AddActivityForm',
  includeTelemetryBoundary: true,
  Component: ({
    careGap,
    editingActivityId,
    onCancelEdit,
    onSaveMeasureReport,
    onDeleteMeasureReport,
  }: AddActivityFormProps) => {
    const [zusStatus, setZusStatus] = useState(careGap.status.label);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [initialValues, setInitialValues] = useState<{
      activity?: Activity;
      note: string;
      zusStatus: string;
    }>({ note: '', zusStatus: careGap.status.label });
    const [activity, setActivity] = useState<Activity>();
    const [note, setNote] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<Array<string>>([]);
    const createOrEditMeasureReport = useCreateOrEditUserActivityMeasureReport(careGap);
    const deleteMeasureReport = useDeleteUserActivityMeasureReport(careGap.latestMeasureReport);
    const { trackInteraction } = useTelemetry();

    useEffect(() => {
      if (editingActivityId) {
        const values = {
          activity: careGap.practitionerActivity,
          note: careGap.practitionerNote ?? '',
          zusStatus: careGap.status.label,
        };
        setInitialValues(values);
        setActivity(values.activity);
        setNote(values.note);
        setZusStatus(values.zusStatus);
        setIsOpen(true);
      }
    }, [editingActivityId, careGap]);

    const hasChanges =
      activity !== initialValues.activity ||
      note !== initialValues.note ||
      zusStatus !== initialValues.zusStatus;

    const isFormValid = note.length > 0 && Boolean(activity);
    const isStatusLocked = LOCKED_STATUSES.includes(careGap.status.slug);
    const zusStatusOptions = uniq([careGap.status.label, 'Not met', 'Open', 'Pending'].sort());

    const openActivityForm = () => {
      trackInteraction('open_add_caregap_activity');
      setIsOpen(true);
    };

    const closeActivityForm = () => {
      setNote('');
      setZusStatus('');
      setActivity(undefined);
      setIsOpen(false);
      setIsSubmitting(false);
      setErrors([]);
      setInitialValues({ note: '', zusStatus: careGap.status.label });
      onCancelEdit();
    };

    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      trackInteraction(editingActivityId ? 'edit_caregap_activity' : 'create_caregap_activity');

      setErrors([]);
      setIsSubmitting(true);
      try {
        const measureReport = await createOrEditMeasureReport({
          activity,
          note,
          zusStatus,
          editingActivityId,
        });
        onSaveMeasureReport(measureReport);
        closeActivityForm();
      } catch (error: unknown) {
        setErrors(getFormResponseErrors(error).requestErrors);
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleDelete = async () => {
      trackInteraction('delete_caregap_activity');
      try {
        await deleteMeasureReport();
        onDeleteMeasureReport(careGap.latestMeasureReport);
        closeActivityForm();
      } catch (error: unknown) {
        setErrors(getFormResponseErrors(error).requestErrors);
      } finally {
        setIsSubmitting(false);
        setShowDeleteConfirm(false);
      }
    };

    const handleActivityChange = (value: Activity) => {
      if (activityTypes.includes(value)) {
        setActivity(value);
      }
    };

    return (
      <>
        <div className={tw`flex items-center justify-between`}>
          <div className={tw`flex justify-between space-x-2 text-content-subtle text-sm uppercase`}>
            <div className={tw`flex items-center justify-between`}>Activity</div>
          </div>
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={openActivityForm}
            disabled={isOpen || isSubmitting}
          >
            Add Activity
          </Button>
        </div>

        {isOpen && (
          <ContentCard
            variant="focal"
            className={tw`relative space-y-2 rounded-lg bg-white p-3 text-left shadow-sm outline outline-1 outline-primary-main`}
          >
            <form
              data-testid="add-activity-form"
              className={tw`space-y-6`}
              onSubmit={(e) => {
                void handleSubmit(e);
              }}
            >
              {errors.length > 0 && (
                <Alert type="error" header="There was an error with your submission:">
                  {errors.length === 1 ? (
                    errors[0]
                  ) : (
                    <ul className={tw`m-0 list-disc px-4`}>
                      {errors.map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  )}
                </Alert>
              )}

              <ActivityRadioGroup activity={activity} onChange={handleActivityChange} />

              <NoteTextarea note={note} onChange={setNote} />

              <StatusDropdown
                isStatusLocked={isStatusLocked}
                zusStatus={zusStatus}
                zusStatusOptions={zusStatusOptions}
                onStatusChange={setZusStatus}
              />

              <div className={tw`flex justify-between`}>
                <div className={tw`flex space-x-4`}>
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    disabled={isSubmitting || !isFormValid || !hasChanges}
                  >
                    <span className={tw`px-3`}>Save</span>
                  </Button>
                  <Button type="button" variant="secondary" size="sm" onClick={closeActivityForm}>
                    <span className={tw`px-3`}>Cancel</span>
                  </Button>
                </div>
                {editingActivityId && (
                  <Button
                    type="button"
                    variant="danger"
                    size="sm"
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </form>

            <ChangeActivityConfirmationOverlay
              isShown={showDeleteConfirm}
              onClose={() => setShowDeleteConfirm(false)}
              onConfirm={handleDelete}
            />
            {showDeleteConfirm && (
              <div
                data-testid="delete-activity-confirmation"
                className={tw`absolute inset-0 z-[800] flex flex-col items-center justify-center bg-white/90`}
              >
                <div className={tw`space-y-2 text-center`}>
                  <p className={tw`font-medium text-md`}>
                    Are you sure you want to permanently remove this activity?
                  </p>
                  <div className={tw`flex justify-center space-x-4`}>
                    <Button
                      type="button"
                      variant="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        void handleDelete();
                      }}
                    >
                      Yes, I&apos;m Sure
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => setShowDeleteConfirm(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </ContentCard>
        )}
      </>
    );
  },
});

function ChangeActivityConfirmationOverlay({
  isShown,
  onClose,
  onConfirm,
}: {
  isShown: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return !isShown ? null : (
    <div
      className={tw`absolute inset-0 z-50 flex flex-col items-center justify-center bg-white/90`}
    >
      <div className={tw`space-y-2 text-center`}>
        <p className={tw`font-medium text-md`}>
          Are you sure you want to permanently remove this activity?
        </p>
        <div className={tw`flex justify-center space-x-4`}>
          <Button
            type="button"
            variant="primary"
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              void onConfirm();
              onClose();
            }}
          >
            Yes, I&apos;m Sure
          </Button>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
