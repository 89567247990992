import type { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { Badge, type BadgeProps, type BadgeVariant } from '@ctw/shared/components/badge';
import {
  type IconDefinition,
  faBan,
  faClock,
  faFileExcel,
  faStar,
} from '@fortawesome/pro-regular-svg-icons';

export const careGapStatusBadgeProps = (gap: CareGapModel): BadgeProps => {
  let variant: BadgeVariant;
  let icon: IconDefinition;
  let testId: string;
  switch (gap.status.slug) {
    case 'closed':
      variant = 'primary';
      icon = faStar;
      testId = 'care-gap-badge-closed';
      break;
    case 'excluded':
      variant = 'muted';
      icon = faBan;
      testId = 'care-gap-badge-excluded';
      break;
    case 'not-met':
      variant = 'muted';
      icon = faFileExcel;
      testId = 'care-gap-badge-not-met';
      break;
    case 'pending':
      variant = 'info';
      icon = faClock;
      testId = 'care-gap-badge-pending';
      break;
    default:
      variant = 'info';
      icon = faClock;
      testId = 'care-gap-badge-open';
  }
  return { variant, content: gap.status.label, icon, testId };
};

export const CareGapStatusBadge = ({ gap }: { gap: CareGapModel }) => {
  const { variant, content, icon, testId } = careGapStatusBadgeProps(gap);
  return <Badge variant={variant} content={content} icon={icon} testId={testId} />;
};
