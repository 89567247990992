import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import type { RowActionsConfigProp } from '@ctw/shared/components/table/table';
import { useLabWritebackModal } from '@ctw/shared/content/diagnostic-reports/helpers/lab-writeback-modal';
import { useToggleRead } from '@ctw/shared/content/hooks/use-toggle-read';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useWritebacks } from '@ctw/shared/context/writeback-provider';
import { useCallback } from 'react';

export function useDiagnosticRowActions(): (
  r: DiagnosticReportModel,
) => RowActionsConfigProp<DiagnosticReportModel> {
  const { toggleRead } = useToggleRead();
  const { trackInteraction } = useTelemetry();
  const { isWritebackEnabled } = useWritebacks();
  const openLabWritebackModal = useLabWritebackModal();
  const isLabWritebackFeatureEnabled = useFeatureFlag('ehr-integrations-lab-writebacks');
  const isLabWritebackEnabled =
    isWritebackEnabled('diagnostic-reports') && isLabWritebackFeatureEnabled;

  return useCallback(
    (record: DiagnosticReportModel): RowActionsConfigProp<DiagnosticReportModel> => {
      if (!(isLabWritebackEnabled && record.isLabReport)) {
        return [];
      }

      return [
        {
          className:
            'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm bg-primary-main text-white hover:bg-primary-dark',
          text: 'Add to Record',
          onClick: () => {
            if (!record.isRead) {
              void toggleRead(record);
            }
            openLabWritebackModal(record);
            trackInteraction('add_to_record');
          },
        },
      ];
    },
    [toggleRead, trackInteraction, openLabWritebackModal, isLabWritebackEnabled],
  );
}
