// HL7 / FHIR

export const SYSTEM_ACT_CODE = 'urn:oid:2.16.840.1.113883.5.4';
export const SYSTEM_BASIC_RESOURCE_TYPE =
  'http://terminology.hl7.org/CodeSystem/basic-resource-type';
export const SYSTEM_CONDITION_CATEGORY = 'http://terminology.hl7.org/CodeSystem/condition-category';
export const SYSTEM_CONDITION_CLINICAL = 'http://terminology.hl7.org/CodeSystem/condition-clinical';
export const SYSTEM_CONDITION_VERIFICATION_STATUS =
  'http://terminology.hl7.org/CodeSystem/condition-ver-status';
export const SYSTEM_DIAGNOSIS_ROLE = 'http://terminology.hl7.org/CodeSystem/diagnosis-role';
export const SYSTEM_DIAGNOSTIC_SERVICE_SECTION_ID = 'http://terminology.hl7.org/CodeSystem/v2-0074';
export const SYSTEM_MARITAL_STATUS = 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus';
export const SYSTEM_NULL_FLAVOR = 'http://terminology.hl7.org/CodeSystem/v3-NullFlavor';
export const SYSTEM_PROVENANCE_ACTIVITY_TYPE =
  'http://terminology.hl7.org/3.1.0/CodeSystem-v3-DataOperation.html';
export const SYSTEM_PROVENANCE_AGENT_TYPE =
  'http://terminology.hl7.org/CodeSystem/provenance-participant-type';
export const SYSTEM_RELATIONSHIP = 'http://terminology.hl7.org/CodeSystem/v2-0131';
export const SYSTEM_TASK_STATUS = 'https://www.hl7.org/fhir/codesystem-task-status.html';

// CODE SYSTEMS

export const SYSTEM_CCS = 'https://www.hcup-us.ahrq.gov/toolssoftware/ccsr/dxccsr.jsp';
export const SYSTEM_CCI =
  'http://www.hcup-us.ahrq.gov/toolssoftware/chronic_icd10/chronic_icd10.jsp';

export const SYSTEM_CPT = 'http://www.ama-assn.org/go/cpt';

export const SYSTEM_CVX = 'http://hl7.org/fhir/sid/cvx';

export const SYSTEM_CMS_HCC = 'http://terminology.hl7.org/CodeSystem/cmshcc';

export const SYSTEM_ICD9 = 'http://hl7.org/fhir/sid/icd-9';

export const SYSTEM_ICD9_CM = 'http://hl7.org/fhir/sid/icd-9-cm';

export const SYSTEM_ICD10 = 'http://hl7.org/fhir/sid/icd-10';

export const SYSTEM_ICD10_CM = 'http://hl7.org/fhir/sid/icd-10-cm';

export const SYSTEM_RXNORM = 'http://www.nlm.nih.gov/research/umls/rxnorm';

export const SYSTEM_LOINC = 'http://loinc.org';

export const SYSTEM_NDC = 'http://hl7.org/fhir/sid/ndc';

export const SYSTEM_SNOMED = 'http://snomed.info/sct';

// ZUS

export const SYSTEM_SUMMARY = 'https://zusapi.com/summary';

export const SYSTEM_ZUS_CREATED_AT = 'https://zusapi.com/created-at';
export const SYSTEM_ZUS_LENS = 'https://zusapi.com/lens';
export const SYSTEM_ZUS_SUMMARY = 'https://zusapi.com/summary';
export const SYSTEM_ZUS_OWNER = 'https://zusapi.com/accesscontrol/owner';
export const SYSTEM_ZUS_PRACTITIONER_ID = 'https://zusapi.com/practitioner_id';
export const SYSTEM_ZUS_PROFILE_ACTION = 'https://zusapi.com/profile/action';
export const SYSTEM_ZUS_THIRD_PARTY = 'https://zusapi.com/thirdparty/source';
export const SYSTEM_ZUS_UNIVERSAL_ID = 'https://zusapi.com/fhir/identifier/universal-id';
export const SYSTEM_ZUS_UPI_RECORD_TYPE = 'https://zusapi.com/fhir/tag/upi-record-type';
export const SYSTEM_ZUS_ACTIVITY_STATUS =
  'https://zusapi.com/fhir/extension/caregap-activity-status';
export const SYSTEM_ZUS_ACTIVITY_UPDATED_BY =
  'https://zusapi.com/fhir/extension/caregap-activity-updated-by';
export const SYSTEM_ZUS_ACTIVITY_NOTE = 'https://zusapi.com/fhir/extension/caregap-activity-note';
export const SYSTEM_ZUS_ACTIVITY_TYPE = 'https://zusapi.com/fhir/extension/caregap-activity-type';
export const SYSTEM_ZUS_USER_TYPE = 'https://zusapi.com/user_type';

// ENRICHMENT

export const SYSTEM_ENRICHMENT = 'https://zusapi.com/terminology/enrichment';

// LENS
export const LENS_EXTENSION_AGGREGATED_FROM = 'https://zusapi.com/lens/extension/aggregatedFrom';
export const LENS_EXTENSION_ID = 'https://zusapi.com/lens/id';
export const LENS_EXTENSION_MEDICATION_LAST_FILL_DATE =
  'https://zusapi.com/lens/extension/medicationLastFillDate';
export const LENS_EXTENSION_MEDICATION_LAST_PRESCRIBED_DATE =
  'https://zusapi.com/lens/extension/medicationLastPrescribedDate';
export const LENS_EXTENSION_MEDICATION_QUANTITY =
  'https://zusapi.com/lens/extension/medicationQuantity';
export const LENS_EXTENSION_MEDICATION_DAYS_SUPPLY =
  'https://zusapi.com/lens/extension/medicationDaysSupply';
export const LENS_EXTENSION_MEDICATION_REFILLS =
  'https://zusapi.com/lens/extension/medicationRefills';
export const LENS_EXTENSION_MEDICATION_LAST_PRESCRIBER =
  'https://zusapi.com/lens/extension/medicationLastPrescriber';
export const LENS_EXTENSION_RELATED_BINARIES = 'https://zusapi.com/lens/extension/relatedBinaries';

// MISC

export const CTW_EXTENSION_LENS_AGGREGATED_FROM =
  'https://zusapi.com/ctw-component-library/extension/lensAggregatedFrom';

// CUSTOMERS
export const SYSTEM_ATHENA_PATIENT = 'https://fhir.athena.io/sid/ah-patient';
