import { usePatientDiagnosticReports } from '@ctw/shared/api/fhir/diagnostic-report';
import type { DiagnosticReportModel } from '@ctw/shared/api/fhir/models/diagnostic-report';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import {
  ResourceOverviewCard,
  type SpecificResourceOverviewCardProps,
} from '@ctw/shared/content/overview/resource-overview-card';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { useDiagnosticReportDrawer } from '@ctw/shared/hooks/use-diagnostic-report-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import { faFlask } from '@fortawesome/pro-solid-svg-icons';
import { compact } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { OverviewFilter } from '../overview/filters';
import { RenderSyncedWithRecordIcon, ResourceTitleColumn } from '../resource/resource-title-column';

const TITLE = 'Select Labs';

const hasA1CCode = (A1CCodes: Array<string>, r: DiagnosticReportModel) =>
  r.loincCode?.code && A1CCodes.includes(r.loincCode.code);

const containsText = (r: DiagnosticReportModel, text: string) =>
  r.displayName.toLowerCase().includes(text);

const cleanupA1CDisplay = (A1CCodes: Array<string>, r: DiagnosticReportModel) => {
  // First check that it is a 1AC display
  if (hasA1CCode(A1CCodes, r) || containsText(r, 'a1c')) {
    if (hasA1CCode(A1CCodes, r)) {
      return r.displayName;
    }
    return 'A1c';
  }

  // If not an a1c display don't manipulate text
  return r.displayName;
};

export const getOverviewFilters = (
  A1CCodes: Array<string>,
): Array<OverviewFilter<DiagnosticReportModel>> => [
  {
    key: 'a1c',
    label: 'Filter by A1C',
    description: 'Contains a1c in text or if it includes an a1c code',
    predicate: (r: DiagnosticReportModel) => hasA1CCode(A1CCodes, r) || containsText(r, 'a1c'),
  },
  {
    key: 'cbc',
    label: 'Filter by CBC',
    description: 'Match lowercase “cbc” or “complete blood count” but not containing “cd4/cd8”',
    predicate: (r: DiagnosticReportModel) =>
      /cbc(?!.*cd4\/cd8)|complete blood count/.test(r.displayName.toLowerCase()),
  },
  {
    key: 'cmp',
    label: 'Filter by CMP',
    description: 'Match lowercase “cmp” or “bmp” or “metabolic panel”',
    predicate: (r: DiagnosticReportModel) =>
      ['cmp', 'bmp', 'metabolic panel'].includes(r.displayName.toLowerCase()),
  },
];

type A1CCCodesResponseData = {
  content: Array<ValueSet>;
};

const useA1CCodes = () =>
  useCtwQuery<A1CCCodesResponseData>({
    queryId: 'a1cCodes',
    queryFn: async ({ requestContext, ctwFetch }) => {
      const { data } = await ctwFetch(
        `${getCTWBaseUrl(requestContext.env)}/api/terminology/valuesets/a1c`,
        {
          headers: {
            Authorization: `Bearer ${requestContext.authToken}`,
            'content-type': 'application/json',
          },
        },
      );
      return data as A1CCCodesResponseData;
    },
  });

type ValueSet = {
  code: string;
  display: string;
};

export const PatientDiagnosticReportsOverview = withOverviewCardErrorBoundary({
  cardTitle: TITLE,
  boundaryName: 'PatientDiagnosticReportsOverview',
  Component: ({ onSeeAllResources, isInPatientSummary }: SpecificResourceOverviewCardProps) => {
    const [A1CCodes, setA1CCodes] = useState<Array<string>>([]);
    const [mergedData, setMergedData] = useState<Array<DiagnosticReportModel>>([]);
    const { openDrawer: openDiagnosticReportDrawer } = useDiagnosticReportDrawer({
      enableDismissAndReadActions: false,
    });
    const { data, isLoading } = usePatientDiagnosticReports();
    const { requestContext } = useCTW();
    const useA1CCodesResponse = useA1CCodes();

    useEffect(() => {
      if (useA1CCodesResponse.data?.content) {
        setA1CCodes(useA1CCodesResponse.data.content.map((item: ValueSet) => item.code));
      }
    }, [useA1CCodesResponse.data]);

    // Wait for the A1C codes to load before rendering the data
    useEffect(() => {
      if (A1CCodes.length > 0) {
        const filteredData = compact(
          getOverviewFilters(A1CCodes).flatMap((filter) => data?.filter(filter.predicate)[0]),
        );
        setMergedData(filteredData);
      }
    }, [A1CCodes, data]);

    return (
      <ResourceOverviewCard
        onRowClick={(model) => openDiagnosticReportDrawer({ model })}
        data={mergedData}
        emptyStateMessage={
          !data || data.length === 0
            ? "We didn't find any lab records for this patient."
            : 'There are no metabolic or CBC panel and A1C labs for this patient.'
        }
        footerCTA={
          !data || data.length === 0
            ? undefined
            : {
                label: 'All Labs',
                onClick: onSeeAllResources,
              }
        }
        title={TITLE}
        helpText="Most recent Metabolic or CBC Panel and A1C"
        loading={isLoading || useA1CCodesResponse.isLoading}
        headerIcon={faFlask}
        telemetryTargetName="labs_overview"
        testId="labs-overview"
        isInPatientSummary={isInPatientSummary}
        renderResource={(record: DiagnosticReportModel) => (
          <div>
            <ResourceTitleColumn
              title={cleanupA1CDisplay(A1CCodes, record)}
              renderIcon={RenderSyncedWithRecordIcon(
                record.ownedByBuilder(requestContext.builderId),
              )}
            />
            <div className={tw`font-normal`}>Recorded: {record.dateDisplay}</div>
          </div>
        )}
      />
    );
  },
});
