import { BinaryModel } from '@ctw/shared/api/fhir/models/binary';
import { searchAllRecords } from '@ctw/shared/api/fqs-rest/search-helpers';
import { useCtwQuery } from '@ctw/shared/hooks/use-ctw-query';
import { QUERY_KEY_BINARIES_BY_ID } from '@ctw/shared/utils/query-keys';
import { orderBy } from 'lodash-es';

// Returns BinaryModel[], sorted by createdAt (newest first).
export const useBinaries = (binaryIds: Array<string>) =>
  useCtwQuery({
    queryId: QUERY_KEY_BINARIES_BY_ID,
    queryKey: [binaryIds],
    queryFn: async ({ requestContext, ctwFetch }) => {
      if (binaryIds.length === 0) {
        return [];
      }

      const binaries = await searchAllRecords(
        ctwFetch,
        {
          env: requestContext.env,
          builderId: requestContext.builderId,
          authToken: requestContext.authToken,
        },
        'Binary',
        {
          _id: binaryIds.join(','),
        },
      );
      return orderBy(
        binaries.map((binary) => new BinaryModel(binary)),
        'createdAt',
        'desc',
      );
    },
  });
