import { usePatientAllergies } from '@ctw/shared/api/fhir/allergies';
import type { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { useMainContentHeader } from '@ctw/shared/components/layout/main-content-with-dockable-drawer';
import { patientAllergiesColumns } from '@ctw/shared/content/allergies/helpers/column';
import { EmptyPatientTable } from '@ctw/shared/content/empty-patients-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useAllergyDrawer } from '@ctw/shared/hooks/use-allergy-drawer';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import { History } from '../resource/history';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { allergyFilter } from './helpers/filters';
import { useAllergiesHistory } from './helpers/history';
import { allergySortOptions, defaultAllergySort } from './helpers/sort';

export const allergyHistory = ({ model }: { model: AllergyModel }) => (
  <History getHistory={useAllergiesHistory} model={model} />
);

export const PatientAllergies = withErrorBoundary({
  boundaryName: 'PatientAllergies',
  includeTelemetryBoundary: true,
  Component: () => {
    const { requestContext } = useCTW();
    const { openDrawer } = useAllergyDrawer();
    const patientAllergiesQuery = usePatientAllergies();
    const validFilters = useMemo(() => allergyFilter(), []);

    const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
      cacheKey: 'patient-allergies',
      defaultSort: defaultAllergySort,
      records: patientAllergiesQuery.data,
    });
    const isEmptyQuery = patientAllergiesQuery.data?.length === 0;
    const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

    useMainContentHeader(
      <ResourceTableActions
        filterOptions={{
          onChange: setFilters,
          filters: validFilters,
          selected: filters,
        }}
        sortOptions={{
          defaultSort,
          options: allergySortOptions,
          onChange: setSort,
        }}
      />,
      [validFilters, filters, defaultSort, allergySortOptions],
    );

    return (
      <div>
        <ResourceTable
          showTableHead={true}
          isLoading={patientAllergiesQuery.isLoading}
          data={data}
          columns={patientAllergiesColumns(requestContext.builderId)}
          onRowClick={(model) => openDrawer({ model })}
          enableDismissAndReadActions={true}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="allergies"
            />
          }
        />
      </div>
    );
  },
});

export const allergyData = (allergy: AllergyModel) => [
  { label: 'Recorded Date', value: allergy.recordedDate },
  { label: 'Recording Organization', value: allergy.patientOrganizationName },
  { label: 'Status', value: allergy.clinicalStatus },
  { label: 'Type', value: capitalize(allergy.type) },
  { label: 'Onset', value: allergy.onset },
  { label: 'Reaction', value: capitalize(allergy.manifestations) },
  { label: 'Severity', value: capitalize(allergy.severity) },
  { label: 'Note', value: allergy.note },
];
