import { formatDate, parseToISOString } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import xpath from 'xpath';

export const getDate = (document: Document): string => {
  const effectiveTime = parseToISOString(
    String(
      xpath.select1(
        "string(*[name()='ClinicalDocument']/*[name()='effectiveTime']/@value)",
        document,
      ),
    ),
  );

  return formatDate(effectiveTime);
};

export const getTitle = (document: Document) => {
  const title = String(
    xpath.select1("string(*[name()='ClinicalDocument']/*[name()='title']/text())", document),
  );

  return title;
};
