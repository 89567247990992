import { RequestError } from '@ctw/shared/utils/error';

const xmlTypes = ['/xml', '/xhtml+xml', 'application/xml', 'text/xml'];
const pdfTypes = ['/pdf'];
const tiffTypes = ['/tif', '/tiff'];
const webImageTypes = ['/jpg', '/jpeg', '/png', '/apng', '/avif', '/gif', '/webp', '/svg+xml'];

export const getResponseContent = async (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType?.includes('json')) {
    return response.json();
  }
  if (contentType?.includes('text')) {
    return response.text();
  }
  if (
    [...xmlTypes, ...pdfTypes, ...tiffTypes, ...webImageTypes].some((type) =>
      contentType?.includes(type),
    )
  ) {
    return response.blob();
  }
  return response.json();
};

export async function assertResponseOK(response: Awaited<Response>) {
  if (!response.ok) {
    let data: object | undefined;
    let errorMsg = response.statusText;
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('json')) {
      data = await response.json();
      errorMsg = JSON.stringify(data);
    }

    // try to get error message directly from DA response
    if (data && 'detail' in data) {
      throw new Error(data.detail as never);
    }

    switch (response.status) {
      case 401:
        throw new RequestError(response, errorMsg);
      case 403:
        throw new RequestError(response, errorMsg);
      case 404:
        throw new RequestError(response, errorMsg);
      default:
        throw new RequestError(response, response.statusText);
    }
  }
}
