import type { ConditionStatuses } from '@ctw/shared/api/fhir/models/condition';
import {
  SYSTEM_CONDITION_CLINICAL,
  SYSTEM_CONDITION_VERIFICATION_STATUS,
} from '@ctw/shared/api/fhir/system-urls';
import type { CodeableConcept, Coding, Condition } from 'fhir/r4';
import { cloneDeep, isUndefined, omitBy } from 'lodash-es';

// Sets any autofill values that apply when a user adds a condition, whether creating or confirming.
export function getAddConditionWithDefaults(condition: Condition): Condition {
  return cloneDeep(condition);
}

export const getClincalAndVerificationStatus = (
  status: ConditionStatuses,
): { verificationStatus?: CodeableConcept; clinicalStatus?: CodeableConcept } => {
  let verificationStatus = '';
  let clinicalStatus = '';

  switch (status) {
    case 'Active':
      verificationStatus = 'confirmed';
      clinicalStatus = 'active';
      break;
    case 'Inactive':
      verificationStatus = 'confirmed';
      clinicalStatus = 'inactive';
      break;
    case 'Pending':
      verificationStatus = 'unconfirmed';
      clinicalStatus = 'active';
      break;
    case 'Refuted':
      verificationStatus = 'refuted';
      clinicalStatus = 'inactive';
      break;
    case 'Entered in Error':
      verificationStatus = 'entered-in-error';
      break;
    default:
      throw new Error('status is should be of type ConditionStatus');
  }

  return omitBy(
    {
      verificationStatus: {
        coding: [
          {
            system: SYSTEM_CONDITION_VERIFICATION_STATUS,
            code: verificationStatus,
          },
        ],
      },
      ...(clinicalStatus && {
        clinicalStatus: {
          coding: [{ system: SYSTEM_CONDITION_CLINICAL, code: clinicalStatus }],
        },
      }),
    },
    isUndefined,
  );
};

export type ConditionType = 'Chronic' | 'Acute' | 'Chronic and acute' | 'Unknown';

export type CreateOrEditConditionFormData = {
  id: string;
  status: ConditionStatuses;
  type: ConditionType;
  condition: Coding;
  abatement?: Date;
  onset?: Date;
  note?: string;
};

export function getChronicity(type: ConditionType) {
  switch (type) {
    case 'Chronic':
      return 'C';
    case 'Acute':
      return 'A';
    case 'Chronic and acute':
      return 'B';
    default:
      return '';
  }
}
