import { type ClassName, twx } from '@ctw/shared/utils/tailwind';

export interface VerticalDividerProps {
  thickness?: 'regular' | 'thick';
  className?: ClassName;
}

export const VerticalDivider = ({ className, thickness = 'regular' }: VerticalDividerProps) => (
  <div
    role="presentation"
    className={twx(
      'mx-2 h-full min-h-full w-0 max-w-0 border-divider-main',
      {
        'border-l-[1.5px]': thickness === 'regular',
        'border-l-2': thickness === 'thick',
      },
      className,
    )}
  />
);
