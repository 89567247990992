import { Button } from '@ctw/shared/components/button';
import { DropdownMenuSimple } from '@ctw/shared/components/dropdown/simple';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import type {
  MinRecordItem,
  RowActionsConfigProp,
  RowActionsProps,
} from '@ctw/shared/components/table/table';
import type { WritebackAction } from '@ctw/shared/content/resource/resource-title-column';
import { twx } from '@ctw/shared/utils/tailwind';
import { tw } from '@ctw/shared/utils/tailwind';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { ReactJason } from 'react-jason';
import github from 'react-jason/themes/github';
import { useToggleRead } from '../hooks/use-toggle-read';
import './view-fhir.scss';
import { toggleDismiss } from '@ctw/shared/api/fhir/basic';
import type { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Heading } from '@ctw/shared/components/heading';
import { notify } from '@ctw/shared/components/toast';
import { useCTW } from '@ctw/shared/context/ctw-context';
import { useModal } from '@ctw/shared/context/modal-context';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Resource } from 'fhir/r4';
import { useCallback, useState } from 'react';

export type AdditionalResourceActionsProps<T extends MinRecordItem> = {
  onWritebackStart?: (action: WritebackAction, id: string) => void;
  onWritebackEnd?: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  onToggleRead?: () => void;
  rowActions?: (r: T) => RowActionsConfigProp<T>;
  enableDismissAndReadActions?: boolean;
  enableViewFhirButton?: boolean;
  // Will adjust the way the dropdown menu appears if the content will be rendered in a footer
  isInFooter?: boolean;
};

export const useAdditionalResourceActions = <T extends Resource, M extends FHIRModel<T>>({
  onWritebackStart,
  onWritebackEnd,
  onToggleRead,
  rowActions,
  enableViewFhirButton = true,
  enableDismissAndReadActions,
  isInFooter = false,
}: AdditionalResourceActionsProps<M>) => {
  const { featureFlags } = useCTW();
  const [selectedAction, setSelectedAction] = useState('card');
  const dismissAndReadActions = useDismissAndReadActions(
    onWritebackStart ??
      (() => {
        /* noop */
      }),
    onWritebackEnd ??
      (() => {
        /* noop */
      }),
    onToggleRead,
    enableDismissAndReadActions,
  );

  return ({ record, onSuccess, stacked = false }: RowActionsProps<M>) => {
    const extraActions = dismissAndReadActions(record) ?? [];
    const actions = rowActions?.(record) ?? [];
    const combinedActions = [...extraActions, ...actions];
    if (combinedActions.length === 0 && !featureFlags.enableViewFhirButton) {
      return null;
    }

    if (stacked) {
      return (
        <div className={tw`flex space-x-2`}>
          {featureFlags.enableViewFhirButton && <ViewFHIR resource={record.resource} />}
          {combinedActions.length > 0 && (
            <DropdownMenuSimple
              align="end"
              buttonClassName={twx('bg-blue border-none bg-transparent p-0')}
              onItemSelect={(item) => {
                const selectedOption = combinedActions.filter(
                  (action) => action.text === item.key,
                )[0];
                setSelectedAction(selectedOption.text);
                setTimeout(() => {
                  // This is a hack to make sure the dropdown menu closes before the action is executed to avoid
                  // react state updates on unmounted components
                  void selectedOption.onClick(record, onSuccess);
                }, 1);
              }}
              items={combinedActions.map((item) => ({
                key: item.text,
                name: item.text,
                display: item.render?.({ record, onSuccess, stacked }) ?? item.text,
                isSelected: selectedAction === item.text,
                disabled: item.disabled,
              }))}
            >
              <div
                className={tw`flex items-center space-x-2 rounded-md border border-border-main border-transparent border-solid bg-white px-4 py-2 font-medium text-sm capitalize shadow hover:bg-background-hover`}
              >
                <span
                  className={tw`!border-r-1 divide-background-disabled border-0 border-solid py-1 pr-2`}
                >
                  select action
                </span>
                <FontAwesomeIcon
                  icon={isInFooter ? faChevronUp : faChevronDown}
                  className={tw`w-3`}
                />
              </div>
            </DropdownMenuSimple>
          )}
        </div>
      );
    }

    return (
      <div className={tw`flex space-x-2`}>
        {featureFlags.enableViewFhirButton && enableViewFhirButton && (
          <ViewFHIR resource={record.resource} />
        )}
        {combinedActions.map(({ text, testId, onClick, disabled = false, className, render }) => (
          <Button
            key={text}
            type="button"
            disabled={disabled}
            variant="unstyled"
            className={twx(className, 'row-action h-[36px] min-h-[36px]')}
            testId={testId}
            onClick={() => {
              void onClick(record, onSuccess);
            }}
          >
            {render?.({ record, onSuccess, stacked }) ?? text}
          </Button>
        ))}
      </div>
    );
  };
};

function useDismissAndReadActions(
  onWritebackStart: (action: WritebackAction, id: string) => void,
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void,
  onToggleRead?: () => void,
  enableDismissAndReadActions = false,
) {
  const { requestContext } = useCTW();
  const { trackInteraction } = useTelemetry();

  const { isLoading: isToggleDismissLoading, toggleDismiss } = useToggleDismiss();
  const { isLoading: isToggleReadLoading, toggleRead } = useToggleRead();

  return useCallback(
    (record: FHIRModel<Resource>): RowActionsConfigProp<FHIRModel<Resource>> => {
      const archiveLabel = record.isDismissed ? 'Restore' : 'Dismiss';

      const readLabel = record.isRead ? 'Unread' : 'Read';

      // In production, we want to avoid non-builder users from inadvertently marking records as read
      // In lower environments this is allowed for demos/testing
      const disableReadButton =
        requestContext.env === 'production' && requestContext.authTokenState.userType !== 'builder';
      if (!enableDismissAndReadActions || record.ownedByBuilder(requestContext.builderId)) {
        return [];
      }
      return [
        {
          className:
            'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover',
          disabled: isToggleDismissLoading || isToggleReadLoading,
          text: archiveLabel,
          onClick: async () => {
            trackInteraction('toggle_record_archive', {
              action: record.isDismissed ? 'restore' : 'dismiss',
            });
            try {
              const action = record.isDismissed ? 'restore' : 'dismiss-single';
              onWritebackStart(action, record.id);
              await toggleDismiss(record);
              onWritebackEnd(action, record.id, false);
            } catch (error) {
              onWritebackEnd(
                'dismiss-single',
                record.id,
                true,
                error instanceof Error ? error.message : String(error),
              );
              return;
            }
            if (!record.isRead) {
              await toggleRead(record);
            }
          },
          render() {
            return (
              <div className={tw`flex`}>
                {isToggleDismissLoading && (
                  <LoadingSpinner message="Loading..." className={tw`mx-4 align-middle`} />
                )}
                {!isToggleDismissLoading && archiveLabel}
              </div>
            );
          },
        },
        {
          className:
            'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-border-main bg-white capitalize text-content-subtle hover:bg-background-hover',
          disabled: disableReadButton || isToggleDismissLoading || isToggleReadLoading,
          text: readLabel,
          onClick: async () => {
            if (record.isRead) {
              trackInteraction('toggle_record_read', {
                action: 'mark_as_unread',
              });
            } else {
              trackInteraction('toggle_record_read', { action: 'mark_as_read' });
            }
            await toggleRead(record);
            onToggleRead?.();
          },
          render() {
            return (
              <div className={tw`flex`}>
                {isToggleReadLoading && (
                  <LoadingSpinner message="Loading..." className={tw`mx-4 align-middle`} />
                )}
                {!isToggleReadLoading && readLabel}
              </div>
            );
          },
        },
      ];
    },
    [
      enableDismissAndReadActions,
      isToggleDismissLoading,
      isToggleReadLoading,
      onToggleRead,
      onWritebackEnd,
      onWritebackStart,
      requestContext.authTokenState.userType,
      requestContext.builderId,
      requestContext.env,
      toggleDismiss,
      toggleRead,
      trackInteraction,
    ],
  );
}

interface ViewFHIRProps {
  name?: string;
  resource: Resource;
}

function useFHIRModal() {
  const { openModal } = useModal({
    boundaryName: 'ViewFHIRModal',
    layout: { overflowBehavior: 'scroll', mode: 'full', fullbleed: false, showCloseButton: true },
  });

  return (name: string, resource: Resource) => {
    openModal({
      content: {
        body: (
          <>
            <Heading level="h2" className={tw`text-lg`}>
              {name}
            </Heading>
            <div className={tw`react-json-pre-wrapper h-full text-sm`}>
              <ReactJason value={resource} theme={github} />
            </div>
          </>
        ),
      },
      telemetryContext: { action: 'view_fhir' },
    });
  };
}

function ViewFHIR({ name = 'View FHIR', resource }: ViewFHIRProps) {
  const openModal = useFHIRModal();

  return (
    <Button
      className={tw`h-[36px] min-h-[36px]`}
      type="button"
      ariaLabel={name}
      variant="secondary"
      onClick={() => {
        openModal(name, resource);
      }}
    >
      <span className={tw`flex h-4 w-4 items-center justify-center`}>
        <FontAwesomeIcon icon={faFire} className={tw`h-4 w-4`} />
      </span>
    </Button>
  );
}

interface UseToggleDismissResult {
  /**
   * Function to call to toggle the archive status of the FHIR model
   */
  toggleDismiss: (model: FHIRModel<Resource>) => Promise<void>;

  /**
   * True when `toggleArchive` is called
   */
  isLoading: boolean;
}

/**
 * This hook is toggles the dismiss status for the specified FHIR model.
 */
function useToggleDismiss(): UseToggleDismissResult {
  const ctw = useCTW();
  const telemetry = useTelemetry();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleToggleDismiss = useCallback(async (model: FHIRModel<Resource>) => {
    setIsLoading(true);
    await toggleDismiss(model, ctw, telemetry);
    notify({
      type: 'info',
      title: `${model.title} ${model.isDismissed ? 'dismissed' : 'restored'}.`,
    });
    setIsLoading(false);
  }, []);

  return {
    toggleDismiss: handleToggleDismiss,
    isLoading,
  };
}
