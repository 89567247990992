// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as amplitude from '@amplitude/analytics-browser';
import type { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { mapTelemetryContext } from '@ctw/shared/context/telemetry/telemetry-context-mapper';
import type { TelemetryUserInteraction } from '@ctw/shared/context/telemetry/user-interactions';
import type { Env } from '@ctw/shared/context/types';
import type { ZusService } from '@ctw/shared/context/zui-provider';
import { DelegatedLogger } from '@ctw/shared/utils/delegated-logger';
import type { WithLimitedKeys } from '@ctw/shared/utils/types';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { cloneDeep, entries, isError } from 'lodash-es';
import type { Diff, Intersection } from 'utility-types';
import { RequestError } from '../../utils/error';
import { ReactQueryError } from '../../utils/react-query-error';
import { Session } from '../../utils/session';

export interface WithTimerMetricOperationFnContext {
  setScopedContextProperties: (properties: Record<string, unknown>) => void;
  setScopedContextProperty: (name: string, value: unknown) => void;
  getScopedContextProperties: () => Record<string, unknown>;
  getScopedContextProperty: (name: string) => unknown;
}

export interface StartTimerMetricContext {
  setScopedContextProperties: (properties: Record<string, unknown>) => void;
  setScopedContextProperty: (name: string, value: unknown) => void;
  getScopedContextProperties: () => Record<string, unknown>;
  getScopedContextProperty: (name: string) => unknown;
}

export type TelemetryService = 'datadogLogs' | 'datadogRum' | 'amplitude';
type TelemetryMetricType = 'loading' | 'query' | 'render_component' | 'render_page';
type TelemetryEnv = 'dev' | 'prod';

type TelemetryInteractionCondition = 'every_occurence' | 'once_per_session' | 'once_per_page_view';

interface TelemetryInitArgs {
  requestContext: CTWRequestContext;
}

type TelemetryInterface = typeof Telemetry;
interface DeferredTelemetryEvents {
  setGlobalContextProperty: Array<Parameters<TelemetryInterface['setGlobalContextProperty']>>;
  trackError: Array<Parameters<TelemetryInterface['trackError']>[0]>;
  trackInteractionWithCondition: Array<
    Parameters<TelemetryInterface['trackInteractionWithCondition']>
  >;
  trackMetric: Array<Parameters<TelemetryInterface['trackMetric']>>;
  logger: Array<
    | ['debug', ...Parameters<DelegatedLogger['debug']>]
    | ['info', ...Parameters<DelegatedLogger['info']>]
    | ['warn', ...Parameters<DelegatedLogger['warn']>]
    | ['error', ...Parameters<DelegatedLogger['error']>]
  >;
}

type AmplitudeInitConfiguration = WithLimitedKeys<{
  apiKey: string;
  options: WithLimitedKeys<Exclude<Parameters<typeof amplitude.init>[2], undefined>>;
}>;

type AmplitudeCommonInitConfiguration = Omit<WithLimitedKeys<AmplitudeInitConfiguration>, 'apiKey'>;

type InternalDatadogLogsInitConfiguration = Parameters<typeof datadogLogs.init>[0];
type InternalDatadogRumInitConfiguration = Parameters<typeof datadogRum.init>[0];
type DatadogCommonInitConfiguration = Omit<
  WithLimitedKeys<
    Intersection<InternalDatadogLogsInitConfiguration, InternalDatadogRumInitConfiguration>
  >,
  'beforeSend'
>;
type DatadogLogsInitConfiguration = WithLimitedKeys<
  Diff<InternalDatadogLogsInitConfiguration, DatadogCommonInitConfiguration>
>;
type DatadogRumInitConfiguration = WithLimitedKeys<
  Diff<InternalDatadogRumInitConfiguration, DatadogCommonInitConfiguration>
>;

type DatadogTelemetryServiceConfig = {
  global: WithLimitedKeys<DatadogCommonInitConfiguration>;
  logs: WithLimitedKeys<DatadogLogsInitConfiguration>;
  rum: WithLimitedKeys<DatadogRumInitConfiguration>;
};

type CommonDatadogTelemetryServiceConfig = {
  global: Partial<DatadogTelemetryServiceConfig['global']>;
  logs: Partial<DatadogTelemetryServiceConfig['logs']>;
  rum: Partial<DatadogTelemetryServiceConfig['rum']>;
};

type TelemetryConfigs = {
  [env in TelemetryEnv]: {
    [service in ZusService]: {
      amplitude: AmplitudeInitConfiguration;
      datadog: WithLimitedKeys<DatadogTelemetryServiceConfig>;
    };
  };
};

const telemetryEnvPatternMappings = {
  dev: [/^([a-z]+)\.(dev|phitest)\.(zushealth|zusapi)\.com$/i, /localhost/i, /127\.0\.0\.1/i],
  prod: [/^([a-z]+)\.(sandbox\.)?(zushealth|zusapi)\.com$/i],
} as const;

const datadogCommonEnvironmentConfig: CommonDatadogTelemetryServiceConfig = {
  global: {
    site: 'datadoghq.com',
    useSecureSessionCookie: true,
    usePartitionedCrossSiteSessionCookie: true,
    telemetrySampleRate: 100,
    telemetryConfigurationSampleRate: 100,
    telemetryUsageSampleRate: 100,
    sessionSampleRate: 100,
  },
  logs: {
    forwardConsoleLogs: ['error'],
    forwardErrorsToLogs: true,
  },
  rum: {
    allowedTracingUrls: [],
    defaultPrivacyLevel: 'mask',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    sessionReplaySampleRate: 100,
  },
} as const;

const datadogDevEnvironmentConfig: DatadogTelemetryServiceConfig = {
  global: {
    clientToken: 'pub29b659b1cd402a88d57c4f8c923c1eea', // gitleaks:allow
    ...datadogCommonEnvironmentConfig.global,
  },
  logs: {
    ...datadogCommonEnvironmentConfig.logs,
  },
  rum: {
    applicationId: '48fec1f8-b187-492a-afdd-e809cc6b3b82', // gitleaks:allow
    ...datadogCommonEnvironmentConfig.rum,
  },
} as const;

const datadogProdEnvironmentConfig: DatadogTelemetryServiceConfig = {
  global: {
    clientToken: 'pub6c5b408646325f595789b3e8127211fd', // gitleaks:allow
    ...datadogCommonEnvironmentConfig.global,
  },
  logs: {
    ...datadogCommonEnvironmentConfig.logs,
  },
  rum: {
    applicationId: '3dfeeabc-d289-4fe4-8118-06c9817f3c27', // gitleaks:allow
    ...datadogCommonEnvironmentConfig.rum,
  },
} as const;

const amplitudeCommonEnvironmentConfig: AmplitudeCommonInitConfiguration = {
  options: {
    autocapture: {
      attribution: false,
      pageViews: false,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
      elementInteractions: false,
    },
  },
};

const amplitudeDevEnvironmentConfig: AmplitudeInitConfiguration = {
  apiKey: '4aa3735b24424ff8f01536ee58883f90', // gitleaks:allow
  options: {
    ...amplitudeCommonEnvironmentConfig.options,
  },
} as const;

const amplitudeProdEnvironmentConfig: AmplitudeInitConfiguration = {
  apiKey: 'a02c1e71c9a5a05a85d07589468ec2b1', // gitleaks:allow
  options: {
    ...amplitudeCommonEnvironmentConfig.options,
  },
} as const;

const telemetryConfigs: TelemetryConfigs = {
  dev: {
    standalone: {
      amplitude: amplitudeDevEnvironmentConfig,
      datadog: datadogDevEnvironmentConfig,
    },
    zap: {
      amplitude: amplitudeDevEnvironmentConfig,
      datadog: datadogDevEnvironmentConfig,
    },
    'smart-on-fhir': {
      amplitude: amplitudeDevEnvironmentConfig,
      datadog: datadogDevEnvironmentConfig,
    },
  },
  prod: {
    standalone: {
      amplitude: amplitudeProdEnvironmentConfig,
      datadog: datadogProdEnvironmentConfig,
    },
    zap: {
      amplitude: amplitudeProdEnvironmentConfig,
      datadog: datadogProdEnvironmentConfig,
    },
    'smart-on-fhir': {
      amplitude: amplitudeProdEnvironmentConfig,
      datadog: datadogProdEnvironmentConfig,
    },
  },
} as const;

const REDACTED_HEADERS = [
  'cookie',
  'set-cookie',
  'x-middleware-set-cookie',
  'authorization',
] as const;

/**
 * Telemetry via DataDog Logs, DataDog RUM, and Amplitude.
 */
export class Telemetry {
  public static logger = new DelegatedLogger({
    beforeDebug: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.debug(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['debug', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeInfo: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.info(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['info', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeWarn: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.warn(`[${tag}] ${message}`, context);
      } else {
        this.deferredEvents.logger.push(['warn', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
    beforeError: (tag, message, context) => {
      if (this.isInitialized) {
        datadogLogs.logger.error(`[${tag}] ${isError(message) ? message.message : message}`, {
          ...context,
          ...(typeof message === 'string' ? {} : { error: message }),
        });
      } else {
        this.deferredEvents.logger.push(['error', tag, message, context]);
      }

      return { logToConsole: this.telemetryEnv === 'dev' };
    },
  });

  private static configHash?: number;

  private static requestContext: CTWRequestContext;

  private static telemetryEnv: TelemetryEnv;

  private static deferredEvents: DeferredTelemetryEvents = {
    setGlobalContextProperty: [],
    trackError: [],
    trackInteractionWithCondition: [],
    trackMetric: [],
    logger: [],
  };

  private static previouslyTrackedPageInteractions: Array<TelemetryUserInteraction> = [];

  private static previouslyTrackedSessionInteractions: Array<TelemetryUserInteraction> = [];

  private static timerMetrics: Record<
    string,
    {
      type: TelemetryMetricType;
      id: string;
      vitalName: string;
      context: Record<string, unknown>;
      startTime: number;
      endTime?: number;
    }
  > = {};

  private static globalContextProperties: Record<string, unknown> = {};

  public static isInitialized = false;

  public static async init({ requestContext }: TelemetryInitArgs) {
    Telemetry.requestContext = cloneDeep(requestContext);
    Telemetry.requestContext.serviceEnv = Telemetry.normalizeServiceEnv(
      Telemetry.requestContext.serviceEnv,
    );
    Telemetry.telemetryEnv = Telemetry.determineTelemetryEnv();

    try {
      const previouslyTrackedSessionInteractionsJson =
        window.sessionStorage.getItem('previouslyTrackedSessionInteractions') ?? '[]';
      const previouslyTrackedSessionInteractionsJsonArray = JSON.parse(
        previouslyTrackedSessionInteractionsJson,
      );

      if (Array.isArray(previouslyTrackedSessionInteractionsJsonArray)) {
        Telemetry.previouslyTrackedSessionInteractions =
          previouslyTrackedSessionInteractionsJsonArray;
      }
    } catch {
      /* pass */
    }

    Telemetry.initGlobalContextProperties();

    if (!Telemetry.telemetryEnabled() && Telemetry.configHash === undefined) {
      Telemetry.logger.info('Telemetry', 'Telemetry data sending is disabled in this environment', {
        service: Telemetry.requestContext.service,
        serviceEnv: Telemetry.requestContext.serviceEnv,
        telemetryEnv: Telemetry.telemetryEnv,
      });
    }

    if (datadogLogs.getInternalContext() === undefined) {
      datadogLogs.init({
        ...telemetryConfigs[Telemetry.telemetryEnv][Telemetry.requestContext.service].datadog
          .global,
        ...telemetryConfigs[Telemetry.telemetryEnv][Telemetry.requestContext.service].datadog.logs,
        env: Telemetry.requestContext.serviceEnv,
        service: Telemetry.requestContext.service,
        version: Telemetry.requestContext.serviceVersion,
      });
    }

    if (Telemetry.telemetryEnabled() && amplitude.getSessionId() === undefined) {
      await amplitude.init(
        telemetryConfigs[Telemetry.telemetryEnv][Telemetry.requestContext.service].amplitude.apiKey,
        telemetryConfigs[Telemetry.telemetryEnv][Telemetry.requestContext.service].amplitude
          .options,
      ).promise;

      amplitude.setUserId(Telemetry.requestContext.authTokenState.zusUserId);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('email', Telemetry.requestContext.authTokenState.email);
      identifyEvent.set('builderId', Telemetry.requestContext.authTokenState.builderId);
      identifyEvent.set('builderName', Telemetry.requestContext.authTokenState.builderName);
      amplitude.identify(identifyEvent, {
        user_id: Telemetry.requestContext.authTokenState.zusUserId,
      });
    }

    if (Telemetry.telemetryEnabled() && datadogRum.getInternalContext() === undefined) {
      datadogRum.init({
        ...telemetryConfigs[Telemetry.telemetryEnv][requestContext.service].datadog.global,
        ...telemetryConfigs[Telemetry.telemetryEnv][requestContext.service].datadog.rum,
        env: Telemetry.requestContext.serviceEnv,
        service: Telemetry.requestContext.service,
        version: Telemetry.requestContext.serviceVersion,
      });
    }

    const newConfigHash = Telemetry.getConfigHash(requestContext);
    if (Telemetry.isInitialized && Telemetry.configHash !== newConfigHash) {
      Telemetry.configHash = newConfigHash;

      Telemetry.logger.info('Telemetry', 'Reinitializing telemetry with new configuration', {
        service: Telemetry.requestContext.service,
        serviceEnv: Telemetry.requestContext.serviceEnv,
        telemetryEnv: Telemetry.telemetryEnv,
      });
    }

    Telemetry.isInitialized = true;
    Telemetry.flushDeferredEvents();
  }

  public static setPatient({
    patientID,
    patientResourceID,
    systemURL,
    patientUPID,
  }: {
    patientID?: string;
    patientResourceID?: string;
    systemURL?: string;
    patientUPID?: string;
  }): void {
    Telemetry.setGlobalContextProperty('patientUPID', patientUPID);
    Telemetry.setGlobalContextProperty('patientContext', {
      patientUPID,
      patientID,
      patientResourceID,
      systemURL,
    });
  }

  public static resetPatient(): void {
    Telemetry.setGlobalContextProperty('patientContext', undefined);
    Telemetry.setGlobalContextProperty('patientUPID', undefined);
  }

  public static trackError({
    tag,
    message: maybeMessage,
    error,
    context = {},
  }: {
    tag: string;
    message?: string;
    error: Error | unknown;
    context?: Record<string, unknown>;
  }): void {
    if (!Telemetry.isInitialized) {
      Telemetry.deferredEvents.trackError.push({ tag, message: maybeMessage, error, context });
      return;
    }

    const serializedError = Telemetry.serializeError(error);
    const message = maybeMessage ?? serializedError.message;

    const errorContext: Record<string, unknown> = {
      serializedError,
      ...context,
    };

    if (error instanceof RequestError) {
      errorContext.statusCode = error.statusCode;
    }

    if (error instanceof ReactQueryError) {
      errorContext.query = {
        queryKeys: error.otherQueryKeys,
        identifier: error.identifier,
        requestErrors: error.requestErrors,
        responseErrors: error.responseErrors,
      };
    }

    Telemetry.logger.error(tag, message, {
      error: {
        ...errorContext,
      },
    });

    if (Telemetry.telemetryEnabled()) {
      datadogRum.addError(error, errorContext);
    }
  }

  public static trackInteraction(
    action: TelemetryUserInteraction,
    context: Record<string, unknown> = {},
  ): void {
    Telemetry.trackInteractionWithCondition(action, 'every_occurence', context);
  }

  public static trackInteractionWithCondition(
    action: TelemetryUserInteraction,
    condition: TelemetryInteractionCondition,
    context: Record<string, unknown> = {},
  ): void {
    if (!Telemetry.isInitialized) {
      Telemetry.deferredEvents.trackInteractionWithCondition.push([action, condition, context]);
      return;
    }

    Telemetry.reportActiveSession();

    if (condition === 'once_per_session') {
      if (Telemetry.previouslyTrackedSessionInteractions.includes(action)) {
        return;
      }

      Telemetry.previouslyTrackedSessionInteractions.push(action);

      try {
        window.sessionStorage.setItem(
          'previouslyTrackedSessionInteractions',
          JSON.stringify(Telemetry.previouslyTrackedSessionInteractions),
        );
      } catch {
        /* pass */
      }
    }

    if (condition === 'once_per_page_view') {
      if (Telemetry.previouslyTrackedPageInteractions.includes(action)) {
        return;
      }

      Telemetry.previouslyTrackedPageInteractions.push(action);
    }

    if (Telemetry.telemetryEnabled()) {
      datadogRum.addAction(action, context);
      amplitude.track(
        action,
        mapTelemetryContext('amplitude', {
          ...Telemetry.globalContextProperties,
          ...context,
        }),
      );
    }
  }

  public static trackMetric(name: string, context: Record<string, unknown> = {}): void {
    if (!Telemetry.isInitialized) {
      Telemetry.deferredEvents.trackMetric.push([name, context]);
      return;
    }

    if (Telemetry.telemetryEnabled()) {
      datadogRum.addAction(`ctw.metric.${name}`, context);
    }
  }

  public static withTimerMetric<TReturn, TContext extends Record<string, unknown>>(
    type: TelemetryMetricType,
    id: string,
    operationFn: (context: TContext & WithTimerMetricOperationFnContext) => Promise<TReturn>,
  ): (context: TContext) => Promise<TReturn> {
    return /* operationFnCurriedWithTimer */ async (context) => {
      const scopedContextProperties: Record<string, unknown> = {};

      const setScopedContextProperties = (properties: Record<string, unknown>) => {
        for (const [name, value] of Object.entries(properties)) {
          scopedContextProperties[name] = value;
        }
      };

      const setScopedContextProperty = (name: string, value: unknown) => {
        scopedContextProperties[name] = value;
      };

      const getScopedContextProperty = (name: string) => scopedContextProperties[name];

      const getScopedContextProperties = () => ({ ...scopedContextProperties });

      let returnValue: Awaited<TReturn> | undefined;
      try {
        Telemetry.startTimerMetric(type, id, scopedContextProperties);
        returnValue = await operationFn({
          ...context,
          setScopedContextProperties,
          setScopedContextProperty,
          getScopedContextProperty,
          getScopedContextProperties,
        });
      } catch (e) {
        returnValue = undefined;
        throw e;
      } finally {
        Telemetry.stopTimerMetric(type, id, scopedContextProperties);
      }

      return returnValue;
    };
  }

  public static startTimerMetric(
    type: TelemetryMetricType,
    id: string,
    context?: Record<string, unknown>,
  ): StartTimerMetricContext {
    const vitalName = `ctw.${type}.${id}`;

    if (!Object.keys(Telemetry.timerMetrics).includes(vitalName)) {
      Telemetry.timerMetrics[vitalName] = {
        type,
        id,
        vitalName,
        context: context ?? {},
        startTime: Date.now(),
      };
    }

    const setScopedContextProperties = (properties: Record<string, unknown>) => {
      for (const [name, value] of Object.entries(properties)) {
        Telemetry.timerMetrics[vitalName].context[name] = value;
      }
    };

    const setScopedContextProperty = (name: string, value: unknown) => {
      Telemetry.timerMetrics[vitalName].context[name] = value;
    };

    const getScopedContextProperties = () => ({ ...Telemetry.timerMetrics[vitalName].context });

    const getScopedContextProperty = (name: string) =>
      Telemetry.timerMetrics[vitalName].context[name];

    return {
      setScopedContextProperties,
      getScopedContextProperty,
      getScopedContextProperties,
      setScopedContextProperty,
    };
  }

  public static stopTimerMetric(
    type: TelemetryMetricType,
    id: string,
    context?: Record<string, unknown>,
  ): void {
    const vitalName = `ctw.${type}.${id}`;

    if (Object.keys(Telemetry.timerMetrics).includes(vitalName)) {
      Telemetry.timerMetrics[vitalName].endTime = Date.now();
      Telemetry.timerMetrics[vitalName].context = {
        ...Telemetry.timerMetrics[vitalName].context,
        ...(context ?? {}),
      };

      if (Telemetry.telemetryEnabled() && Telemetry.isInitialized) {
        Telemetry.flushTimerMetric(vitalName);
      }
    }
  }

  public static trackTimerMetric(
    type: TelemetryMetricType,
    id: string,
    startTime: number,
    endTime: number,
    context?: Record<string, unknown>,
  ): void {
    const vitalName = `ctw.${type}.${id}`;

    Telemetry.timerMetrics[vitalName] = {
      type,
      id,
      vitalName,
      context: context ?? {},
      startTime,
      endTime,
    };

    if (Telemetry.telemetryEnabled() && Telemetry.isInitialized) {
      Telemetry.flushTimerMetric(vitalName);
    }
  }

  public static redactSensitiveHttpHeaders(
    headers: HeadersInit | undefined,
  ): Record<string, unknown> {
    if (headers === undefined) {
      return {};
    }

    const headersRecord: Record<string, unknown> = Array.isArray(headers)
      ? Object.fromEntries(headers)
      : (headers as Record<string, unknown>);

    for (const [key, value] of Object.entries(headers)) {
      if (REDACTED_HEADERS.includes(key.toLowerCase() as never)) {
        headersRecord[key] = '[REDACTED]';
      } else {
        headersRecord[key] = value;
      }
    }

    return headersRecord;
  }

  private static flushTimerMetric(vitalName: string): void {
    if (Object.keys(Telemetry.timerMetrics).includes(vitalName)) {
      const timerMetric = Telemetry.timerMetrics[vitalName];

      if (timerMetric.endTime !== undefined) {
        datadogRum.addDurationVital(vitalName, {
          startTime: timerMetric.startTime,
          duration: timerMetric.endTime - timerMetric.startTime,
          context: timerMetric.context,
        });
        delete Telemetry.timerMetrics[vitalName];
      }
    }
  }

  private static flushDeferredEvents(): void {
    const deferredEvents = { ...Telemetry.deferredEvents };
    Telemetry.deferredEvents = {
      setGlobalContextProperty: [],
      trackError: [],
      trackInteractionWithCondition: [],
      trackMetric: [],
      logger: [],
    };

    for (const [key, value] of deferredEvents.setGlobalContextProperty) {
      Telemetry.setGlobalContextProperty(key, value);
    }

    for (const { tag, message, error, context } of deferredEvents.trackError) {
      Telemetry.trackError({
        tag,
        message,
        error,
        context: {
          ...context,
          deferred: true,
        },
      });
    }

    for (const [action, condition, context] of deferredEvents.trackInteractionWithCondition) {
      Telemetry.trackInteractionWithCondition(action, condition, { ...context, deferred: true });
    }

    for (const [name, context] of deferredEvents.trackMetric) {
      Telemetry.trackMetric(name, { ...context, deferred: true });
    }

    for (const metric of Object.values(Telemetry.timerMetrics)) {
      if (metric.endTime !== undefined) {
        Telemetry.flushTimerMetric(metric.vitalName);
      }
    }

    for (const [level, tag, message, context] of deferredEvents.logger) {
      switch (level) {
        case 'debug':
          Telemetry.logger.debug(tag, message, { ...context, deferred: true });
          break;
        case 'info':
          Telemetry.logger.info(tag, message, { ...context, deferred: true });
          break;
        case 'warn':
          Telemetry.logger.warn(tag, message, { ...context, deferred: true });
          break;
        case 'error':
          Telemetry.logger.error(tag, message, { ...context, deferred: true });
          break;
        default:
          Telemetry.logger.error('Telemetry', `Unknown log level: ${level}`, {
            level,
            tag,
            message,
            context,
            deferred: true,
          });
      }
    }
  }

  public static determineTelemetryEnv(): TelemetryEnv {
    const { hostname } = window.location;

    for (const [env, patterns] of entries(telemetryEnvPatternMappings)) {
      for (const pattern of patterns) {
        if (pattern.test(hostname)) {
          return env as TelemetryEnv;
        }
      }
    }

    return 'dev';
  }

  private static serializeError(error: Error | unknown): {
    name: string;
    message: string;
    [key: string]: unknown;
  } {
    const serializedError: Record<string, unknown> = {};

    if (typeof error === 'object') {
      for (const propertyName of Object.getOwnPropertyNames(error)) {
        serializedError[propertyName] = (error as never)[propertyName];
      }
    }

    return {
      name: 'Unknown',
      message: 'Unknown',
      ...serializedError,
    };
  }

  private static normalizeServiceEnv(env: Env) {
    const loweredEnv = env.toLowerCase();
    switch (loweredEnv) {
      case 'dev':
      case 'development':
        return 'dev';
      case 'prod':
      case 'production':
        return 'prod';
      default:
        return loweredEnv;
    }
  }

  private static initGlobalContextProperties(): void {
    Telemetry.setGlobalContextProperty('ehr', Telemetry.requestContext.ehr);
    Telemetry.setGlobalContextProperty('builderId', Telemetry.requestContext.builderId);
    Telemetry.setGlobalContextProperty('service', Telemetry.requestContext.service);
    Telemetry.setGlobalContextProperty('serviceEnv', Telemetry.requestContext.serviceEnv);
    Telemetry.setGlobalContextProperty('serviceVersion', Telemetry.requestContext.serviceVersion);
    Telemetry.setGlobalContextProperty('serviceVariant', Telemetry.requestContext.serviceVariant);
    Telemetry.setGlobalContextProperty('telemetryEnv', Telemetry.telemetryEnv);

    Session.setSessionUserId(Telemetry.requestContext.authTokenState.zusUserId);

    datadogLogs.setUser(Telemetry.requestContext.authTokenState);
    datadogRum.setUser(Telemetry.requestContext.authTokenState);
  }

  private static telemetryEnabled(): boolean {
    return Telemetry.telemetryEnv === 'prod';
  }

  public static setGlobalContextProperty(key: string, value: unknown): void {
    if (!Telemetry.isInitialized) {
      Telemetry.deferredEvents.setGlobalContextProperty.push([key, value]);
      return;
    }

    datadogLogs.setGlobalContextProperty(key, value);
    datadogRum.setGlobalContextProperty(key, value);

    if (value === undefined) {
      delete Telemetry.globalContextProperties[key];
    } else {
      Telemetry.globalContextProperties[key] = value;
    }
  }

  public static setGlobalContextProperties(properties: Record<string, unknown>): void {
    for (const [key, value] of Object.entries(properties)) {
      Telemetry.setGlobalContextProperty(key, value);
    }
  }

  public static getGlobalContextProperty(key: string): unknown {
    return Telemetry.globalContextProperties[key];
  }

  public static getGlobalContextProperties(): Record<string, unknown> {
    return { ...Telemetry.globalContextProperties };
  }

  public static afterNavigate() {
    Telemetry.previouslyTrackedPageInteractions = [];
  }

  private static reportActiveSession() {
    try {
      if (Session.isActive()) {
        return;
      }

      Session.setSessionUserId(Telemetry.requestContext.authTokenState.zusUserId);
      Session.setSessionLastActiveTimestamp();
    } catch (error) {
      Telemetry.trackError({
        tag: 'Telemetry',
        message: 'Telemetry error reporting active session',
        error,
      });
    }
  }

  private static getConfigHash(config: object) {
    return JSON.stringify(config)
      .split('')
      .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
  }
}
