import { getContactDetails } from '@ctw/shared/content/CCDA/ccda-viewer/helpers';
import type { GeneralInfo } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import xpath from 'xpath';

export const getCustodianData = (document: Document): GeneralInfo | undefined => {
  const representedCustodianOrganization = xpath.select1(
    "*[name()='ClinicalDocument']/*[name()='custodian']/*[name()='assignedCustodian']/*[name()='representedCustodianOrganization']",
    document,
  ) as Document | undefined;

  if (!representedCustodianOrganization) {
    return undefined;
  }

  const name = String(xpath.select1("string(*[name()='name'])", representedCustodianOrganization));

  const contactDetails = getContactDetails(
    xpath.select1(
      "*[name()='ClinicalDocument']/*[name()='custodian']/*[name()='assignedCustodian']/*[name()='representedCustodianOrganization']/*[name()='addr']",
      document,
    ) as Document,
    xpath.select1(
      "*[name()='ClinicalDocument']/*[name()='custodian']/*[name()='assignedCustodian']/*[name()='representedCustodianOrganization']/*[name()='telecom']",
      document,
    ) as Document,
  );

  return { name, contactDetails };
};
