import { ErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { Modal, type ModalProps } from '@ctw/shared/components/modal';
import { TelemetryBoundary, useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

export interface OpenModalAction extends Pick<ModalProps, 'content'> {
  allowDocking?: boolean;
  telemetryContext?: Record<string, unknown>;
  onModalEvent?: (eventType: ModalEventType) => void;
}

type ModalEventType = 'open' | 'afterOpen' | 'close' | 'afterClose';

export type ModalEventHandler = (eventType: ModalEventType) => void;

export interface ModalBoundary {
  name: string;
  context?: Record<string, unknown>;
}

type ModalLayoutMode = 'fit' | 'full';
type ModalLayoutOverflowBehavior = 'visible' | 'scroll';

export interface ModalLayout {
  mode: ModalLayoutMode;
  overflowBehavior: ModalLayoutOverflowBehavior;
  fullbleed: boolean;
  showCloseButton: boolean;
}

export interface ModalState {
  isModalOpen: boolean;
  openModal: (action: OpenModalAction) => void;
  closeModal: () => void;
  boundary: ModalBoundary;
  setBoundary: (boundary: ModalBoundary) => void;
  setLayout: (layout: ModalLayout) => void;
  onModalEvent: (boundaryName: string, eventHandler: ModalEventHandler) => () => void;
}

const emptyModalAction: OpenModalAction = {
  content: {
    body: null,
  },
} as const;

const defaultModalLayout: ModalLayout = {
  mode: 'fit',
  overflowBehavior: 'scroll',
  fullbleed: false,
  showCloseButton: true,
} as const;

const defaultModalBoundary: ModalBoundary = {
  name: 'Modal',
} as const;

const ModalContext = createContext<ModalState | null>(null);

interface ModalProviderChildrenArgs {
  modal: ReactNode;
}

interface ModalProviderProps {
  children: (args: ModalProviderChildrenArgs) => ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const telemetry = useTelemetry();
  const [openModalAction, setOpenModalAction] = useState<OpenModalAction>(emptyModalAction);
  const [boundary, setBoundary] = useState<ModalBoundary>(defaultModalBoundary);
  const [layout, setLayout] = useState<ModalLayout>(defaultModalLayout);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const eventRegistrations: Record<string, Array<ModalEventHandler>> = useMemo(() => ({}), []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const openModal: ModalState['openModal'] = useCallback(
    (action) => {
      telemetry.trackInteraction('open_modal', action.telemetryContext);
      setIsModalOpen(true);
      setOpenModalAction(action);
    },
    [boundary],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const closeModal: ModalState['closeModal'] = useCallback(() => {
    handleEvent('close');
    telemetry.trackInteraction('close_modal', openModalAction.telemetryContext);
    setIsModalOpen(false);
    setTimeout(() => {
      setOpenModalAction(emptyModalAction);
      setBoundary(defaultModalBoundary);
      setLayout(defaultModalLayout);
    }, 300);
  }, [boundary, openModalAction]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const state: ModalState = useMemo(
    () => ({
      openModal,
      closeModal,
      isModalOpen,
      boundary,
      setBoundary,
      setLayout,
      onModalEvent: (boundaryName, eventHandler) => {
        if (!Array.isArray(eventRegistrations[boundaryName])) {
          eventRegistrations[boundaryName] = [];
        }

        eventRegistrations[boundaryName].push(eventHandler);

        return () => {
          eventRegistrations[boundaryName] = eventRegistrations[boundaryName].filter(
            (handler) => handler !== eventHandler,
          );
        };
      },
    }),
    [boundary, closeModal, isModalOpen, openModal],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleEvent = useCallback(
    (eventType: ModalEventType) => {
      openModalAction.onModalEvent?.(eventType);

      if (Array.isArray(eventRegistrations[boundary.name])) {
        eventRegistrations[boundary.name].forEach((eventHandler) => {
          const invokeEventHandler = async () => eventHandler(eventType);
          void invokeEventHandler();
        });
      }
    },
    [boundary, openModalAction],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const modal = useMemo(
    () => (
      <ErrorBoundary boundaryName={boundary.name} initialContext={boundary.context}>
        <TelemetryBoundary boundaryName={boundary.name} initialContext={boundary.context}>
          <Modal
            layout={layout}
            isOpen={isModalOpen}
            onOpen={() => handleEvent('open')}
            onAfterOpen={() => handleEvent('afterOpen')}
            close={closeModal}
            onAfterClose={() => handleEvent('afterClose')}
            {...openModalAction}
          />
        </TelemetryBoundary>
      </ErrorBoundary>
    ),
    [boundary, closeModal, handleEvent, isModalOpen, openModalAction],
  );

  return (
    <ModalContext.Provider value={state}>
      {children({
        modal,
      })}
    </ModalContext.Provider>
  );
};

export interface UseModalOptions {
  boundaryName: string;
  layout?: ModalLayout;
  telemetryContext?: Record<string, unknown>;
  onModalEvent?: (eventType: ModalEventType) => void;
}

export const useModal = ({
  boundaryName,
  layout,
  telemetryContext,
  onModalEvent,
}: UseModalOptions) => {
  const modalContext = useContext(ModalContext);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const modal: ModalState | null = useMemo(() => {
    if (!modalContext) {
      return null;
    }

    return {
      ...modalContext,
      openModal: (action: OpenModalAction) => {
        modalContext.setBoundary({
          name: boundaryName,
          context: { ...telemetryContext, ...action.telemetryContext },
        });

        if (layout) {
          modalContext.setLayout(layout);
        }

        modalContext.openModal({ ...action });
      },
    };
  }, [boundaryName, telemetryContext, modalContext]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(
    () =>
      modal && onModalEvent
        ? modal.onModalEvent(boundaryName, onModalEvent)
        : () => {
            /* noop */
          },
    [boundaryName, onModalEvent],
  );

  if (!modal) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return modal;
};
