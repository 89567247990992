import type { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { usePatientVitals } from '@ctw/shared/api/fhir/vitals';
import { OverviewCard } from '@ctw/shared/components/containers/overview-card';
import { withOverviewCardErrorBoundary } from '@ctw/shared/content/overview/with-overview-card-error-boundary';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/resource-title-column';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { tw } from '@ctw/shared/utils/tailwind';
import { faHeartPulse } from '@fortawesome/pro-solid-svg-icons';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

export type PatientVitalsOverviewProps = {
  onSeeAllResources: () => void;
};

export const PatientVitalsOverview = withOverviewCardErrorBoundary({
  cardTitle: 'Vitals',
  boundaryName: 'PatientVitalsOverview',
  Component: ({ onSeeAllResources }: PatientVitalsOverviewProps) => {
    const { trackInteraction } = useTelemetry();
    const { data, isLoading } = usePatientVitals();
    const vitals = usePatientVitalsOverview();

    return (
      <OverviewCard
        headerIcon={faHeartPulse}
        loading={isLoading}
        title="Most Recent Vitals"
        helpText="Displays most recent available recorded vitals"
        emptyStateMessage="No vitals records found."
        empty={data?.length === 0}
        footerCTA={
          data?.length === 0
            ? undefined
            : {
                label: 'All Vitals',
                onClick: () => {
                  trackInteraction('see_all_of_resource', { target: 'vitals_overview' });
                  onSeeAllResources();
                },
              }
        }
        testId="patient-vitals-overview"
        fullbleed={true}
      >
        {vitals.data.map((vital) => (
          <div
            className={tw`flex space-x-1.5 px-2 py-1.5`}
            data-testid="overview-row"
            key={vital.vital}
          >
            <div className={tw`flex justify-between`}>
              <div className={tw`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem]`}>
                {RenderSyncedWithRecordIcon(vital.firstParty ?? false)}
              </div>
              <div className={tw`w-14 font-medium`}>{vital.vital}</div>
            </div>
            <div className={tw`w-[7.25rem] text-right`}>{vital.value}</div>
            <div className={tw`w-[5.25rem] text-right`}>
              {vital.date ? vital.date : <span className={tw`text-content-icon`}>N/A</span>}
            </div>
          </div>
        ))}
      </OverviewCard>
    );
  },
});

type OverviewVitals = {
  isLoading: boolean;
  data: Array<OverviewVital>;
};

type OverviewVital = {
  vital: string;
  value?: string;
  date?: string;
  firstParty?: boolean;
};

const OVERVIEW_VITALS = [
  { title: 'BP', dataIndex: 'bloodPressure' },
  { title: 'HR', dataIndex: 'pulse' },
  { title: 'Temp', dataIndex: 'temperature' },
  { title: 'RR', dataIndex: 'respiratoryRate' },
  { title: 'SpO2', dataIndex: 'oxygenSaturation' },
  { title: 'Ht/Lt', dataIndex: 'height' },
  { title: 'Wt', dataIndex: 'weight' },
  { title: 'BMI', dataIndex: 'bmi' },
  { title: 'HC', dataIndex: 'headCircumference' },
  { title: 'LMP', dataIndex: 'lmp' },
  { title: 'Pain', dataIndex: 'pain' },
] as Array<{ title: string; dataIndex: keyof VitalsBucket }>;

const VITALS_TO_DROP_WHEN_BLANK = ['HC', 'LMP', 'Pain'];

function usePatientVitalsOverview(): OverviewVitals {
  const { data, isLoading } = usePatientVitals();

  return useMemo(() => {
    if (isLoading || data?.length === 0) {
      return {
        isLoading,
        data: [],
      };
    }

    // Get newest encounters first, so that we grab most recent vitals first.
    const sortedBuckets = orderBy(data, (e) => e.dateTime, 'desc');

    // For each vital, find the most recent encounter that has that vital.
    const overviewVitals: Array<OverviewVital> = OVERVIEW_VITALS.map((vital) => {
      const vitalBucket = sortedBuckets.find((e) => e[vital.dataIndex]);
      const value = vitalBucket?.[vital.dataIndex] as
        | {
            display: string;
            firstParty: boolean;
          }
        | undefined;
      const date = vitalBucket?.date;

      return {
        vital: vital.title,
        date,
        value: value?.display,
        firstParty: value?.firstParty,
      };
    });

    // Filter out some of our empty vitals.
    return {
      isLoading: false,
      data: overviewVitals.filter(
        (v) => !VITALS_TO_DROP_WHEN_BLANK.includes(v.vital) || v.value !== undefined,
      ),
    };
  }, [data, isLoading]);
}
