import type { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import type { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { dismissFilter } from '@ctw/shared/content/resource/filters';
import { uniqueValues } from '@ctw/shared/utils/filters';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

export function medicationFilters(
  medications: Array<MedicationStatementModel>,
  includeDismissed: boolean,
): Array<FilterItem> {
  const prescriberNames = uniqueValues(medications, 'lastPrescriber');
  const filters: Array<FilterItem> = [];

  if (includeDismissed) {
    filters.push(dismissFilter);
  }

  if (prescriberNames.length > 1) {
    filters.push({
      key: 'lastPrescriber',
      type: 'checkbox',
      icon: faUser,
      values: prescriberNames,
      display: 'prescriber',
    });
  }

  return filters;
}
