import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import {
  ALL_PAGES,
  MAX_OBJECTS_PER_REQUEST,
  fqsRequest,
  fqsRequestAll,
} from '@ctw/shared/api/fqs/client';
import { useIncrementallyPaginatedFqsQueryForPatient } from '@ctw/shared/api/fqs/client.client';
import {
  type DocumentReferenceWithBasicsGraphqlResponse,
  documentReferencesQuery,
  documentReferencesWithBasicsQuery,
} from '@ctw/shared/api/fqs/queries/documents';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import {
  QUERY_KEY_PATIENT_DOCUMENTS,
  QUERY_KEY_PATIENT_DOCUMENTS_WITH_BASICS,
} from '@ctw/shared/utils/query-keys';
import type { DocumentReference } from 'fhir/r4';
import type { GraphQLClient } from 'graphql-request';
import { groupBy, isEqual, orderBy, uniqWith } from 'lodash-es';
import { useMemo } from 'react';

// AN IMPORTANT NOTE ON HOW DA GENERATES DOCUMENTS!
// DA creates document references for sections of a CDA (eg. "Assessments") and the full CDA (eg. "Summary of Care").
// These "section documents" are not what a user would consider a "document" that would be rendered in our documents list.
// However, they contain free text content that we do want to display, often as an "encounter note".

export function usePatientRenderableDocuments(limit = MAX_OBJECTS_PER_REQUEST) {
  const { data, isError, isFetching, isLoading } = usePatientDocumentsWithBasics(limit);

  const topLevelDocuments = useMemo(
    () =>
      orderBy(filterToRenderableDocuments(data ?? []), 'resource.content[0].attachment.creation', [
        'desc',
      ]),
    [data],
  );

  return {
    data: topLevelDocuments,
    isError,
    isFetching,
    isLoading,
  };
}

export function usePatientDocumentsWithBasics(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  return usePatientQuery({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_DOCUMENTS_WITH_BASICS,
    queryKey: [limit],
    queryFn: async ({ graphqlClient, telemetry, patient }) =>
      getDocumentReferencesWithBasics(telemetry, graphqlClient, patient, [MAX_OBJECTS_PER_REQUEST]),
    enabled,
  });
}

export function usePatientDocuments(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  return usePatientQuery({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_DOCUMENTS,
    queryKey: [limit],
    queryFn: async ({ graphqlClient, telemetry, patient }) =>
      getDocumentReferences(telemetry, graphqlClient, patient, [MAX_OBJECTS_PER_REQUEST]),
    enabled,
  });
}

export function useLazyLoadedPatientDocuments(
  limit = MAX_OBJECTS_PER_REQUEST,
  pageLimit = ALL_PAGES,
) {
  return useIncrementallyPaginatedFqsQueryForPatient<DocumentModel, DocumentReference>({
    gcTime: 0,
    staleTime: 0,
    queryId: QUERY_KEY_PATIENT_DOCUMENTS,
    pageLimit,
    perPageLimit: limit,
    graphqlQuery: documentReferencesQuery,
    namespace: 'DocumentReferenceConnection',
    ModelClass: DocumentModel,
  });
}

export async function getDocumentReferences(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  keys: Array<number> = [],
) {
  const limit = keys[0];
  const { data } = await fqsRequestAll<DocumentReferenceWithBasicsGraphqlResponse>(
    telemetry,
    graphqlClient,
    documentReferencesQuery,
    'DocumentReferenceConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: limit,
    },
  );
  return data.DocumentReferenceConnection.edges.map(
    (x) => new DocumentModel(x.node, undefined, x.node.BasicList),
  );
}

export async function getDocumentReferencesWithBasics(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  keys: Array<number> = [],
) {
  const limit = keys[0];
  const { data } = await fqsRequestAll<DocumentReferenceWithBasicsGraphqlResponse>(
    telemetry,
    graphqlClient,
    documentReferencesWithBasicsQuery,
    'DocumentReferenceConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: limit,
    },
  );

  return data.DocumentReferenceConnection.edges.map(
    (x) => new DocumentModel(x.node, undefined, x.node.BasicList),
  );
}

export async function getDocumentReferenceWithBasicsById(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: Array<string> = [],
) {
  const { data } = await fqsRequest<DocumentReferenceWithBasicsGraphqlResponse>(
    telemetry,
    graphqlClient,
    documentReferencesWithBasicsQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
      sort: {
        lastUpdated: 'DESC',
      },
    },
  );
  return data.DocumentReferenceConnection.edges.map((x) => new DocumentModel(x.node));
}

export const filterToRenderableDocuments = (data: Array<DocumentModel>) => {
  const highestLevelDocs = filterOutSectionDocuments(data);
  const isHighestLevelDocument = (docRef: DocumentReference) =>
    highestLevelDocs.find((docModel) => docModel.id === docRef.id);

  const documentModels = data.filter(
    (doc) =>
      doc.isImage ||
      doc.isPdf ||
      (doc.isCDA && isHighestLevelDocument(doc.resource) && !doc.isZusGeneratedReciprocityDocument),
  );

  return uniqWith(documentModels, (a, b) => isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)));
};

const filterOutSectionDocuments = (docs: Array<DocumentModel>) => {
  const highestLevelDocs: Array<DocumentModel> = [];
  const documentGroups = groupBy(docs, (d) => d.binaryId);

  // If multiple documents share the same binaryId, we want to keep the one with highest number of categories
  // as that is currently the most reliable way to determine the "top level" document.
  Object.keys(documentGroups).forEach((binaryId) => {
    if (binaryId) {
      highestLevelDocs.push(orderBy(documentGroups[binaryId], 'category.length', 'desc')[0]);
    } else {
      highestLevelDocs.push(...documentGroups[binaryId]);
    }
  });

  return highestLevelDocs;
};

const valuesToDedupeOn = (document: DocumentModel) => [
  document.encounterDate,
  document.dateCreated,
  document.custodian,
  document.title,
];
