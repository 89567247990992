import { useMedicationHistory } from '@ctw/shared/api/fhir/medications';
import type { MedicationModel } from '@ctw/shared/api/fhir/models/medication';
import { MedicationAdministrationModel } from '@ctw/shared/api/fhir/models/medication-administration';
import { MedicationDispenseModel } from '@ctw/shared/api/fhir/models/medication-dispense';
import { MedicationRequestModel } from '@ctw/shared/api/fhir/models/medication-request';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { ContentCard } from '@ctw/shared/components/containers/content-card';
import { DefinitionList } from '@ctw/shared/components/definition-list';
import { History } from '@ctw/shared/content/resource/history';
import type { HistoryEntryProps } from '@ctw/shared/content/resource/history-entry';
import {
  type UseResourceDrawerOptions,
  useResourceDrawer,
} from '@ctw/shared/hooks/use-resource-drawer';
import { tw } from '@ctw/shared/utils/tailwind';
import type { UseQueryResult } from '@tanstack/react-query';
import type {
  MedicationAdministration,
  MedicationDispense,
  MedicationRequest,
  MedicationStatement,
} from 'fhir/r4';
import { capitalize, compact } from 'lodash-es';
import { entryFromArray } from '../utils/entry-from-array';

const medicationHistory = ({ model }: { model: MedicationStatementModel }) => (
  <History getHistory={useMedicationHistoryEntries} model={model} />
);

interface UseMedicationDrawerProps
  extends Pick<UseResourceDrawerOptions<'MedicationStatement'>, 'resourceActions'> {
  enableDismissAndReadActions?: boolean;
}

export const useMedicationDrawer = ({
  resourceActions,
  enableDismissAndReadActions = true,
}: UseMedicationDrawerProps = {}) =>
  useResourceDrawer({
    resourceType: 'MedicationStatement',
    resourceActions,
    enableDismissAndReadActions,
    action: ({ model }) => ({
      label: 'Medication',
      title: model.display,
      content: {
        body: (
          <div className={tw`flex flex-col gap-5`}>
            <ContentCard variant="focal" title="Details">
              <DefinitionList
                items={[
                  { label: 'Status', value: model.displayStatus },
                  { label: 'Last Fill Date', value: model.lastFillDate },
                  { label: 'Quantity', value: model.quantity },
                  { label: 'Days Supply', value: model.daysSupply },
                  { label: 'Refills', value: model.refills },
                  { label: 'Instructions', value: model.dosage },
                  { label: 'Prescriber', value: model.lastPrescriber ?? 'n/a' },
                  { label: 'Last Prescribed Date', value: model.lastPrescribedDate },
                  ...entryFromArray('Note', model.notesDisplay),
                ]}
              />
            </ContentCard>
            {medicationHistory({ model })}
          </div>
        ),
      },
    }),
  });

function useMedicationHistoryEntries(medication: MedicationStatementModel) {
  const medHistoryQuery = useMedicationHistory(medication);
  const { medications = [] } = medHistoryQuery.data ?? {};
  // Filter out medication statements from history unless they are the only entries we have to display.
  const filtered = medications.filter((med) => med.resourceType !== 'MedicationStatement');

  return {
    ...medHistoryQuery,
    data: (filtered.length > 0 ? filtered : medications).map(createMedicationDetailsCard),
  } as unknown as UseQueryResult<Array<HistoryEntryProps>>;
}

function createMedicationDetailsCard(medication: MedicationModel): HistoryEntryProps {
  if (medication.isPrescription()) {
    return createPrescriptionCard(medication);
  }

  if (medication.resourceType === 'MedicationDispense') {
    return createMedicationFilledCard(medication);
  }

  if (medication.resourceType === 'MedicationAdministration') {
    return createMedicationAdminCard(medication);
  }

  return createMedicationReviewedCard(medication);
}

function createMedicationReviewedCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as MedicationStatement;
  const medStatement = new MedicationStatementModel(resource, medication.includedResources);

  return {
    date: medication.dateDisplay,
    id: medication.id,
    model: medication,
    title: 'Medication Reviewed',
    hideEmpty: false,
    subtitle: medication.medicationCodeableConceptText ?? medStatement.patient?.organization?.name,
    details: [
      {
        label: 'Status',
        value: capitalize(medStatement.displayStatus),
      },
      {
        label: 'Instructions',
        value: medStatement.dosage,
      },
    ],
  };
}

function createPrescriptionCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as MedicationRequest;
  const { prescriber } = medication;
  const { name, address, telecom } = new MedicationRequestModel(
    resource,
    medication.includedResources,
  ).pharmacy;
  const { numberOfRepeatsAllowed = '', initialFill } = resource.dispenseRequest || {};
  const { value = '', unit = '' } = initialFill?.quantity || {};

  return {
    date: medication.dateDisplay,
    id: medication.id,
    title: 'Prescription Ordered',
    model: medication,
    subtitle: medication.medicationCodeableConceptText ?? prescriber,
    hideEmpty: false,
    details: [
      { label: 'Quantity', value: [value, unit].join(' ') },
      {
        label: 'Refills Allowed',
        value: numberOfRepeatsAllowed,
      },
      {
        label: 'Instructions',
        value: medication.dosage,
      },
      { label: 'Prescriber', value: prescriber },
      {
        label: 'Pharmacy',
        value: (
          <>
            {name && <div>{name}</div>}
            {address && <div>{address}</div>}
            {telecom && <div>T: {telecom}</div>}
          </>
        ),
      },
    ],
  };
}

function createMedicationFilledCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as MedicationDispense;
  const medDispense = new MedicationDispenseModel(resource, medication.includedResources);

  const { quantityDisplay, supplied, performerDetails } = medDispense;
  const { name, address, telecom } = performerDetails;

  return {
    date: medication.dateDisplay,
    hideEmpty: false,
    id: medication.id,
    title: 'Medication Filled',
    model: medication,
    subtitle:
      medication.medicationCodeableConceptText ??
      compact([quantityDisplay, supplied ? `${supplied} supplied` : null]).join(', '),
    details: [
      { label: 'Quantity', value: quantityDisplay },
      {
        label: 'Days supply',
        value: supplied,
      },
      {
        label: 'Pharmacy',
        value: (
          <>
            {name && <div>{name}</div>}
            {address && <div>{address}</div>}
            {telecom && <div>T: {telecom}</div>}
          </>
        ),
      },
    ],
  };
}

function createMedicationAdminCard(medication: MedicationModel): HistoryEntryProps {
  const resource = medication.resource as MedicationAdministration;
  const medAdmin = new MedicationAdministrationModel(resource, medication.includedResources);

  return {
    id: medication.id,
    date: medication.dateDisplay,
    model: medication,
    hideEmpty: false,
    title: 'Medication Administered',
    subtitle:
      medication.medicationCodeableConceptText ??
      compact([medAdmin.dosageDisplay, medAdmin.dosageRoute]).join(', '),
    details: [
      { label: 'Dosage', value: medAdmin.dosageDisplay },
      {
        label: 'Route',
        value: medAdmin.dosageRoute,
      },
      {
        label: 'Start Date',
        value: medAdmin.effectivePeriod.start,
      },
      {
        label: 'End Date',
        value: medAdmin.effectivePeriod.end,
      },
    ],
  };
}
