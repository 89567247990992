import { twx } from '@ctw/shared/utils/tailwind';
import { uniqueId } from 'lodash-es';
import { type PropsWithChildren, type ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { type PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

export interface TooltipProps {
  className?: string;
  tooltipClassName?: string;
  children: ReactNode;
  place?: PlacesType;
  hidden?: boolean;
  content: string;
  noArrow?: boolean;
}

export function Tooltip({
  className,
  hidden,
  place = 'bottom',
  tooltipClassName,
  children,
  content,
  noArrow = false,
}: TooltipProps) {
  const id = useMemo(() => `tooltip-${uniqueId()}`, []);

  return (
    <>
      <div data-tooltip-id={id} className={twx(className)}>
        {children}
      </div>
      <TooltipPortal>
        <ReactTooltip
          positionStrategy={'fixed'}
          place={place}
          noArrow={noArrow}
          id={id}
          html={content}
          className={twx('z-tooltip', { hidden }, tooltipClassName)}
        />
      </TooltipPortal>
    </>
  );
}
const TooltipPortal = ({ children }: PropsWithChildren) => {
  return createPortal(children, document.body);
};
