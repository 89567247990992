import type { ChartReviewResponse } from '@ctw/shared/api/ai/types';
import { NotFoundSummaryHPI } from '@ctw/shared/api/ai/use-precompute-summary';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { SetTimeProgressBar } from '@ctw/shared/components/progress/set-time-progress-bar';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { useFeatureFlag } from '@ctw/shared/context/feature-flag-provider';
import { formatDateToLocalString } from '@ctw/shared/utils/dates';
import { type ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { CitationText } from '../citations/citation-text';

interface PrecomputedSummaryProps {
  document?: ChartReviewResponse;
  onGenerateSummary: () => void;
  className?: ClassName;
}

export const PrecomputedSummary = withErrorBoundary({
  boundaryName: 'PrecomputedSummary',
  includeTelemetryBoundary: true,
  Component: ({ document, className }: PrecomputedSummaryProps) => {
    const hasHPIStandaloneZAP = useFeatureFlag('ctw-hpi-standalone-zap');

    if (!document) {
      return (
        <SetTimeProgressBar
          totalTimeSeconds={30}
          stages={{
            0: 'Finding patient data',
            20: 'Grouping data by medical relevancy',
            40: 'Generating summary',
          }}
        />
      );
    }

    if (document.hpi === NotFoundSummaryHPI) {
      return (
        <ContentError title="A summary is not available for this patient.">
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
      );
    }

    if (hasHPIStandaloneZAP) {
      return (
        <div className={twx(className)}>
          <CitationText citations={document.citations} content={document.hpi} />
          <div className={tw`pt-2 text-xs italic`}>
            This health record summary is generated using AI technology and is intended for
            informational purposes only. While efforts have been made to ensure accuracy, the
            information provided may not capture all relevant details or updates.
          </div>
        </div>
      );
    }

    return (
      <div className={twx(className, 'rounded-lg bg-background-hover p-4')}>
        <span className={tw`font-medium text-content-subtle text-xs uppercase`}>
          Summary of all available information - {formatDateToLocalString(document.createdAt)}
        </span>
        <CitationText citations={document.citations} content={document.hpi} />
        <div className={tw`pt-2 text-xs italic`}>
          This health record summary is generated using AI technology and is intended for
          informational purposes only. While efforts have been made to ensure accuracy, the
          information provided may not capture all relevant details or updates.
        </div>
      </div>
    );
  },
});
