import type { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import { fqsRequest } from '@ctw/shared/api/fqs/client';
import {
  type CareTeamGraphqlResponse,
  careTeamsQuery,
} from '@ctw/shared/api/fqs/queries/care-teams';
import { usePatientQuery } from '@ctw/shared/context/patient-provider';
import type { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { QUERY_KEY_CARETEAM } from '@ctw/shared/utils/query-keys';
import type { CareTeam } from 'fhir/r4';
import type { GraphQLClient } from 'graphql-request';
import { isEqual, orderBy, uniqWith } from 'lodash-es';
import { CareTeamModel } from './models/careteam';
import { CareTeamPractitionerModel } from './models/careteam-practitioner';

export function usePatientCareTeamMembers() {
  return usePatientQuery({
    queryId: QUERY_KEY_CARETEAM,
    queryFn: async ({ graphqlClient, telemetry, patient }) => {
      const { data } = await fqsRequest<CareTeamGraphqlResponse>(
        telemetry,
        graphqlClient,
        careTeamsQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: 500,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: { nonematch: [SYSTEM_SUMMARY] },
          },
        },
      );

      const nodes = data.CareTeamConnection.edges.map((x) => x.node);
      return getFilteredCareTeamMembers(nodes);
    },
  });
}

export async function getCareTeamMembersByIdFromFQS(
  telemetry: ReturnType<typeof useTelemetry>,
  graphqlClient: GraphQLClient,
  patient: PatientModel,
  ids: Array<string>,
) {
  const { data } = await fqsRequest<CareTeamGraphqlResponse>(
    telemetry,
    graphqlClient,
    careTeamsQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 500,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );

  const nodes = data.CareTeamConnection.edges.map((x) => x.node);
  return getFilteredCareTeamMembers(nodes);
}

export const getFilteredCareTeamMembers = (data: Array<CareTeam>) => {
  const careTeams = data.map((careteam) => new CareTeamModel(careteam));

  const careTeamMembers: Array<CareTeamPractitionerModel> = [];
  careTeams.forEach((careTeam) =>
    careTeam.resource.participant?.forEach((participant) => {
      const practitioner = careTeam.getPractitionerByID(participant.member);
      if (practitioner?.id && participant.member?.reference) {
        careTeamMembers.push(new CareTeamPractitionerModel(careTeam, practitioner));
      }
    }),
  );

  const filteredCareTeamMembers = careTeamMembers.filter((ct) => ct.practitionerName);

  const sortedCareTeamPractitionerModels = orderBy(
    filteredCareTeamMembers,
    ['effectiveStartDate', 'id'],
    ['desc', 'desc'],
  );

  return uniqWith(sortedCareTeamPractitionerModels, (a, b) =>
    isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)),
  );
};

const valuesToDedupeOn = (careTeam: CareTeamPractitionerModel) => [
  careTeam.practitionerName.toLocaleUpperCase(),
];
